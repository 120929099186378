import { http } from './http';
import { apiHelper } from 'shared';

class CustomerBudgetItems {
  getBillableUsingQueryFilterBydistrictIds(customerId, districtIds, projectId, paging, sorting, filters) {
    let queryString = '';

    queryString = apiHelper.composeQueryString({
      projectId,
      status: 1,
      ...paging,
      ...sorting,
      ...filters
    });

    if (districtIds.length) {
      queryString += '&districtIds=' + districtIds.join(';');
    }

    return http.get(`/customers/${customerId}/budget-items${queryString}`);
  }

  async createSelfInvoices(projectId, format) {
    return http.post(`/customers/self-invoices/${projectId}`, format);
  }
}

export const customerBudgetItems = new CustomerBudgetItems();
