import { http } from './http';
import { APIS } from 'app.apis.const';
import { apiHelper } from 'shared';

const controllerName = 'supplier-documents-groups';

class SupplierDocumentsGroups {
  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/${controllerName}/${queryString}`, {}, APIS.FINANCES);
  }

  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });
    const response = await http.get(`/${controllerName}${queryString}`, {}, APIS.FINANCES);
    const items = response.data && response.data.items;

    return items;
  }

  getById(id) {
    return http.get(`/${controllerName}/${id}`, {}, APIS.FINANCES);
  }

  create(documentsGroup) {
    return http.post(`/${controllerName}`, documentsGroup, {}, APIS.FINANCES);
  }

  update(id, documentsGroup) {
    return http.put(`/${controllerName}/${id}`, documentsGroup, {}, APIS.FINANCES);
  }

  delete(id, multiple) {
    return http.delete(`/${controllerName}/${id}`, { multiple }, APIS.FINANCES);
  }

  async getLevels() {
    var response = await http.get(`/${controllerName}/document-type-levels`, {}, APIS.FINANCES);
    const scopeTypes = response && response.data;
    if (scopeTypes) {
      return scopeTypes.map(scopeType => {
        return { value: scopeType.id, name: scopeType.name };
      });
    }
  }
}

export const supplierDocumentsGroups = new SupplierDocumentsGroups();
