const CATASTRO_CLASE_URBANO = 'UR';

function generateURL(cadastralData) {
  let output = '';
  if (cadastralData.rc.length === 20)
    output =
      'https://www1.sedecatastro.gob.es/CYCBienInmueble/OVCConCiud.aspx?' +
      'UrbRus=' +
      (cadastralData.tipoInmueble === CATASTRO_CLASE_URBANO ? 'U' : 'R') +
      '&RefC=' +
      cadastralData.rc +
      '&esBice=' +
      '&RCBice1=' +
      '&RCBice2=' +
      '&DenoBice=' +
      '&from=OVCBusqueda' +
      '&pest=rc' +
      '&RCCompleta=' +
      cadastralData.rc +
      '&final=' +
      '&del=' +
      cadastralData.direccion.codigoDelegacion +
      '&mun=' +
      cadastralData.direccion.codigoMunicipio;
  else if (cadastralData.rc.length === 14)
    output =
      'https://www1.sedecatastro.gob.es/CYCBienInmueble/OVCListaBienes.aspx?' +
      'RC1=' +
      cadastralData.rc.substring(0, 7) +
      '&RC2=' +
      cadastralData.rc.substring(7, 14) +
      '&RC3=' +
      '&RC4=' +
      '&esBice=' +
      '&RCBice1=' +
      '&RCBice2=' +
      '&DenoBice=' +
      '&from=OVCBusqueda' +
      '&pest=rc' +
      '&RCCompleta=' +
      cadastralData.rc +
      '&final=' +
      '&tipoCarto=nuevo';

  return output;
}

export const cadastralHelper = {
  generateURL
};
