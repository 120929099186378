import React from 'react';
import { useSignalR } from './useSignalR';

export const SignalRNotificationsContext = React.createContext({});

export const useSignalRNotificationsContext = urlConnection => {
  const { connection, connected, disconnect, connect, setToken } = useSignalR(urlConnection);

  return { connection, connected, disconnect, connect, setToken };
};
