import { makeStyles } from '@material-ui/core/styles';

export const useStatusesSelectStyles = makeStyles(theme => ({
  'workOrder-open': {
    '& .MuiInputLabel-outlined': {
      color: `${theme.palette.status.green} !important`
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.status.green} !important`
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.status.green} !important`,
      borderWidth: '2px'
    }
  },
  'workOrder-inProgress': {
    '& .MuiInputLabel-outlined': {
      color: `${theme.palette.status.yellow} !important`
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.status.yellow} !important`
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.status.yellow} !important`,
      borderWidth: '2px'
    }
  },
  'workOrder-cancelled': {
    '& .MuiInputLabel-outlined': {
      color: `${theme.palette.status.red} !important`
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.status.red} !important`
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.status.red} !important`,
      borderWidth: '2px'
    }
  },
  'workOrder-closed': {
    '& .MuiInputLabel-outlined': {
      color: `${theme.palette.status.navyBlue} !important`
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.status.navyBlue} !important`
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.status.navyBlue} !important`,
      borderWidth: '2px'
    }
  },
  'document-pending': {
    '& .MuiInputLabel-outlined': {
      color: `${theme.palette.status.red} !important`
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.status.red} !important`
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.status.red} !important`,
      borderWidth: '2px'
    }
  },
  'document-notApplicable': {
    '& .MuiInputLabel-outlined': {
      color: `${theme.palette.status.yellow} !important`
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.status.yellow} !important`
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.status.yellow} !important`,
      borderWidth: '2px'
    }
  },
  'document-inProcess': {
    '& .MuiInputLabel-outlined': {
      color: `${theme.palette.status.blue} !important`
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.status.blue} !important`
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.status.blue} !important`,
      borderWidth: '2px'
    }
  },
  'document-available': {
    '& .MuiInputLabel-outlined': {
      color: `${theme.palette.status.green} !important`
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.status.green} !important`
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.status.green} !important`,
      borderWidth: '2px'
    }
  },
  'document-sentToCustomer': {
    '& .MuiInputLabel-outlined': {
      color: `${theme.palette.status.green} !important`
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.status.green} !important`
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.status.green} !important`,
      borderWidth: '2px'
    }
  },
  'document-sentToSupplier': {
    '& .MuiInputLabel-outlined': {
      color: `${theme.palette.status.green} !important`
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.status.green} !important`
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.status.green} !important`,
      borderWidth: '2px'
    }
  }
}));
