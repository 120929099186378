import { useCallback } from 'react';
import { notifications } from 'services';
import { skeletonFactory, useHandleRequest, useViewTableData } from 'shared';
import { useUserStateContext } from 'state/useUserStateContext';

const notificationsDummy = skeletonFactory.notifications();

export function useNotifications() {
  const {
    data: notificationsData,
    get: getNotifications,
    setErrorInfo,
    undoData
  } = useViewTableData({
    service: notifications,
    paginatedSkeleton: notificationsDummy
  });
  const { notificationsCounter, setNotificationsCounter } = useUserStateContext();
  const { request, errorInfo } = useHandleRequest();

  const getCountPending = useCallback(async () => {
    await request(async () => {
      const response = await notifications.getCountPending();
      if (response) {
        setNotificationsCounter(response.data || 0);
      }
    }, false);
  }, [request, setNotificationsCounter]);

  const setNotificationsViewed = useCallback(async () => {
    try {
      await request(async () => {
        await notifications.setViewed();
        setNotificationsCounter(0);
      });
    } catch (error) {
      undoData();
    }
  }, [request, undoData]);

  return {
    notifications: notificationsData,
    errorInfo: errorInfo,
    setErrorInfo,
    counter: notificationsCounter,
    getCountPending,
    getNotifications,
    setNotificationsViewed
  };
}
