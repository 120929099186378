import { http } from './http';
import { apiHelper } from 'shared';
import { APIS } from 'app.apis.const';

class SupplierDocumentTypes {
  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/supplier-document-types${queryString}`, {}, APIS.FINANCES);
    const items = response.data && response.data.items;

    return items;
  }

  async get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/supplier-document-types/${queryString}`, {}, APIS.FINANCES);
  }

  getById(id) {
    return http.get(`/supplier-document-types/${id}`, {}, APIS.FINANCES);
  }

  create(supplierDocumentType) {
    return http.post('/supplier-document-types', supplierDocumentType, {}, APIS.FINANCES);
  }

  delete(id, multiple) {
    return http.delete(`/supplier-document-types/${id}`, { multiple }, APIS.FINANCES);
  }

  update(supplierDocumentType) {
    return http.put(`/supplier-document-types/${supplierDocumentType.id}`, supplierDocumentType, {}, APIS.FINANCES);
  }
}

export const supplierDocumentTypes = new SupplierDocumentTypes();
