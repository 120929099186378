import { http } from './http';
import { apiHelper } from 'shared';
import { APIS } from 'app.apis.const';

const controllerName = 'suppliers';
const partialName = 'credit-insurers';

class SupplierCreditInsurers {
  get(supplierId, paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/${controllerName}/${supplierId}/${partialName}/${queryString}`, {}, APIS.FINANCES);
  }

  getById(supplierId, id) {
    return http.get(`/${controllerName}/${supplierId}/${partialName}/${id}`, {}, APIS.FINANCES);
  }

  create(creditInsurer, supplierId) {
    return http.post(`/${controllerName}/${supplierId}/${partialName}`, creditInsurer, {}, APIS.FINANCES);
  }

  delete(supplierId, id, multiple) {
    return http.delete(`/${controllerName}/${supplierId}/${partialName}/${id}`, { multiple }, APIS.FINANCES);
  }

  update(creditInsurer, supplierId) {
    return http.put(
      `/${controllerName}/${supplierId}/${partialName}/${creditInsurer.id}`,
      creditInsurer,
      {},
      APIS.FINANCES
    );
  }
}

export const supplierCreditInsurers = new SupplierCreditInsurers();
