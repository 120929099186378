import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { PageHeader } from 'shared/PageHeader';
import { Toolbar } from 'shared/Toolbar';

import { useToolbarContainerStyles } from './toolbarContainer.styles';

export function ToolbarContainer({ title, actions, accordion, className, containerClassName, ...props }) {
  const classes = useToolbarContainerStyles();
  const [expanded, setExpanded] = useState('change');

  const hasHeader = title || actions;

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      {accordion && (
        <Accordion
          expanded={expanded === 'change'}
          onChange={handleChange('change')}
          square
          classes={clsx(classes.noMargin, classes.blueContainer)}
        >
          <AccordionSummary
            IconButtonProps={{ edge: 'start' }}
            expandIcon={<ExpandMore />}
            className={clsx(classes.blueContainer, classes.header)}
          >
            <PageHeader>{title}</PageHeader>
            <Toolbar>{actions}</Toolbar>
          </AccordionSummary>
          <hr className={classes.noMargin} />
          <AccordionDetails
            className={clsx(classes.blueContainer, classes.noMargin, classes.displayBlock, containerClassName)}
          >
            {props.children}
          </AccordionDetails>
        </Accordion>
      )}

      {!accordion && (
        <div className={classes.wrapper}>
          {hasHeader && (
            <div className={clsx(classes.blueContainer, classes.header, className)}>
              <PageHeader className={classes.title}>{title}</PageHeader>
              <Toolbar>{actions}</Toolbar>
            </div>
          )}
          {hasHeader && <hr className={classes.noMargin} />}
          <div className={clsx(classes.blueContainer, classes.content, containerClassName, 'toolbarContainerContent')}>
            {props.children}
          </div>
        </div>
      )}
    </>
  );
}

ToolbarContainer.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  title: PropTypes.string,
  actions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  accordion: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};
