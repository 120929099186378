import { apiHelper } from 'shared';
import { http } from './http';

class Items {
  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/items${queryString}`, {});
    const items = response.data && response.data.items;

    return items.map(item => {
      return { ...item, fullName: `${item.reference}${item.description ? ' | ' + item.description : ''}` };
    });
  }

  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/items/all/${queryString}`);
  }

  getById(id) {
    return http.get(`/items/${id}`);
  }

  create(item) {
    return http.post('/items', item);
  }

  delete(id, multiple) {
    return http.delete(`/items/${id}`, { multiple });
  }

  update(id, item) {
    return http.put(`/items/${id}`, {
      ...item,
      forceUpdateItemCategorySubSpecialityId: true
    });
  }

  activate(id) {
    return http.put(`/items/${id}/activation`);
  }

  desactivate(id) {
    return http.delete(`/items/${id}/activation`);
  }
}

export const items = new Items();
