import { useCallback } from 'react';
import { useHandleRequest } from 'shared/useHandleRequest';

export function useActivateOrDesactivate(service, setData, onError) {
  const { request } = useHandleRequest();

  const activateDesactivate = useCallback(
    async (itemRow, activate, parentId, secondParentId) => {
      try {
        await request(async () => {
          setData(prevItems => ({
            ...prevItems,
            items: prevItems.items.map(prevItem =>
              prevItem.id === itemRow.id ? { ...prevItem, ...itemRow, isActive: activate, isDisabled: !activate } : prevItem
            )
          }));
          activate ? await service.activate(itemRow.id, parentId, secondParentId) : await service.desactivate(itemRow.id, parentId, secondParentId);
        });
      } catch (error) {
        onError();
      }
    },
    [onError, request, service, setData]
  );

  return { activateDesactivate };
}
