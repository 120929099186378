function formatPhoneNumber(phone) {
  const phoneNumber = phone.replaceAll(/\s/g, '');
  const first = phoneNumber.substr(0, phoneNumber.length - 9);
  const last = phoneNumber.substr(phoneNumber.length > 9 ? phoneNumber.length - 9 : 0, phoneNumber.length);
  const formattedLastPart = last.replace(/(.{3})/g, '$1 ');

  return (first + ' ' + formattedLastPart.trim()).trim();
}

function isPhoneValid(phone) {
  const re = /^[+0-9\b]+$/;
  const phoneNumber = phone.replaceAll(/\s/g, '');
  return re.test(phoneNumber);
}
export const phoneHelper = {
  formatPhoneNumber,
  isPhoneValid
};
