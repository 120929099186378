import { http } from './http';
import { apiHelper } from 'shared';

const controllerName = 'mobility';

class Mobility {
  isMobilityIntegrated() {
    return http.get(`/${controllerName}/integration`, {});
  }

  async getTaskDefinitionsQueryFilter(filters) {
    let queryString = apiHelper.composeQueryString({ ...filters });
    const response = await http.get(`/${controllerName}/task-definitions${queryString}`, {});
    
    return response.data;
  }
}

export const mobility = new Mobility();
