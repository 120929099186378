import { SHAPE } from './overlays.const';

const addEventListenersDeck = {};

function getShapeType(shape) {
  if (shape.height === SHAPE.ROMBO) {
    return 'rombo';
  }

  if (shape.height === SHAPE.SQUARE) {
    return 'square';
  }

  if (shape.height === SHAPE.CIRCLE) {
    return 'circle';
  }

  return 'default';
}

function loadSelect(id, title, elements, currentSelectedId, onChange) {
  function handleShow(event) {
    event.style.display = 'flex';
    const height = event.scrollHeight + 'px';
    event.classList.add('select--is-visible');
    event.style.height = height;
    const tootgleItems = event.querySelectorAll('.select-item');

    tootgleItems.forEach(item => {
      if (item.id === currentSelectedId) {
        item.classList.add('select-item--selected');
      }
    });
  }

  function handleHide(event) {
    event.style.height = event.scrollHeight + 'px';
    event.style.height = '0';
    event.classList.remove('select--is-visible');
  }

  function handleToggle(e) {
    e.stopPropagation();
    setTimeout(() => {
      const $element = document.querySelector(`#loadselect-${id}`);
      if (!$element) {
        return;
      }

      if ($element.classList.contains('select--is-visible')) {
        handleHide($element);
      } else {
        handleShow($element);
      }
    }, 300);
  }

  setTimeout(() => {
    const $buble = document.querySelector(`#bubleselect-${id}`);
    if (!$buble) {
      return;
    }

    if (addEventListenersDeck[`bublesselect${id}`]) {
      // WORK_AROUND refresh eventlistener for new buble
      $buble.removeEventListener('click', addEventListenersDeck[`bublesselect${id}`]);
      $buble.addEventListener('click', handleToggle, false);
      addEventListenersDeck[`bublesselect${id}`] = handleToggle;
    } else {
      addEventListenersDeck[`bublesselect${id}`] = handleToggle;
      $buble.addEventListener('click', handleToggle, false);
    }
  }, 300);

  return `<div class="select-wrapper">
    <div class="buble-execution" id="bubleselect-${id}">${title}</div>
    <div class="select-content" id="loadselect-${id}">
      <ul class="select-list">
        ${elements
          .map(element => {
            setTimeout(() => {
              const $item = document.getElementById(element.id);
              $item &&
                $item.addEventListener(
                  'click',
                  e => {
                    e.stopPropagation();
                    onChange(element.id);
                  },
                  false
                );
            }, 300);

            return `<li id='${element.id}' class="select-item">
              ${element.name}
            </li>`;
          })
          .join('')}
      </ul>
    </div>
   </div>
`;
}

function loadTooltip(hoverTooltip) {
  return `
    <div class="tooltip-top">
      ${hoverTooltip.title ? `<h4 class="text">${hoverTooltip.title}</h4>` : ''}
      <p class="text">${hoverTooltip.description}</p>
      <i></i>
    </div>
  `;
}

function loadMultipleTooltip(hoverTooltip) {
  let multipleTooltip = '<div class="tooltip-top">';

  hoverTooltip.forEach(tooltip => {
    multipleTooltip += `${tooltip.title ? `<h4 class="text">${tooltip.title}</h4>` : ''}`;
    multipleTooltip += `<p class="text">${tooltip.description}</p>`;
  });

  multipleTooltip += '<i></i></div>';

  return multipleTooltip;
}

function overlayHTML(shape, id, type, outlined, hoverTooltip, executions) {
  return `<div id='petter' style="width:${shape.width}px;height:${shape.height}px;">
	${executions ? loadSelect(id, executions.count, executions.elements, executions.current, executions.onChange) : ''}
    <div  style="width:${shape.width}px;height:${shape.height}px;"
          class="highlight-overlay highlight-overlay-${type}${outlined ? '-outlined' : ''} ${getShapeType(
    shape
  )}"></div>
    <button id="${id}"class="BPMNelement btn">
      ${
        hoverTooltip
          ? Array.isArray(hoverTooltip)
            ? loadMultipleTooltip(hoverTooltip)
            : loadTooltip(hoverTooltip)
          : ''
      }
    </button>
  <div>`;
}

export function generateElementOverlays(overlays, elementRegistry, elements, i = 0, ids = []) {
  if (elements.length === i) {
    return ids;
  }

  const element = elements[i];
  const shape = elementRegistry.get(element.name);
  let updateIds = ids;

  if (shape) {
    const id = element.name + i;
    const html = overlayHTML(shape, id, element.type, element.outlined, element.hoverTooltip, element.executions);
    const elementOnOverlayId = overlays.add(element.name, {
      position: {
        top: 0,
        left: 0
      },
      html
    });

    updateIds = [...ids, elementOnOverlayId];
  }

  return generateElementOverlays(overlays, elementRegistry, elements, i + 1, updateIds);
}
