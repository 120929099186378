import { makeStyles } from '@material-ui/core/styles';

export const useToolbarContainerStyles = makeStyles(theme => ({
  blueContainer: {
    padding: theme.spacing(3),
    background: theme.palette.primary.lighter,
    '& .MuiAccordionSummary-content': {
      margin: '0 !important'
    }
  },
  header: {
    display: 'flex',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    justifyContent: 'space-between',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    minHeight: '70px'
  },
  noMargin: {
    margin: 0,
    borderTop: `1px solid ${theme.palette.primary.light}`,
    borderLeft: 'none'
  },
  content: {
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    flexGrow: 1,
    '& .MuiGrid-container':{
      background: 'white',
      padding: '12px',
      margin: 0,
      width: 'auto'
    },
    '& .MuiGrid-item': {
      padding: '12px !important',
    }
  },
  displayBlock: {
    display: 'block',
    float: 'left',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%'
  },
  title: {
    marginBottom: '0'
  }
}));
