import { makeStyles } from '@material-ui/core/styles';

export const useToolbarStyles = makeStyles(theme => ({
  toolbar: {
    float: 'right',
    flexWrap: 'wrap',
    alignContent: 'center',
    '& > *':{
      float: 'left',
      marginLeft: '1rem'
    }
  }
}));
