import { http } from './http';
import { apiHelper } from 'shared';
import { APIS } from 'app.apis.const';
import { objectHelper } from 'shared/helper';

class Scopes {
  cachedScopes = [];

  async getCachedElements(t) {
    if (!this.cachedScopes.length) {
      const response = await http.get('/scopes', {}, APIS.DOCUMENTARY);
      const scopes = response.data && response.data.items;
      if (scopes) {
        this.cachedScopes = scopes
          .map(scope => {
            return { ...scope, translation: t(`enums.scopes.${scope.name}`) };
          });
      }
    }

    return objectHelper.deepClone(this.cachedScopes);
  }

  async getUsingQueryFilterWithTranslation(queryFilter, t) {
    const scopes = await this.getCachedElements(t);

    if (queryFilter)
      return scopes.filter(f => f.translation.toLowerCase().includes(queryFilter.toLowerCase()));
      
    return scopes;
  }

  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/scopes${queryString}`, {}, APIS.DOCUMENTARY);
    const items = response.data && response.data.items;

    return items;
  }

  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/scopes/${queryString}`, {}, APIS.DOCUMENTARY);
  }

  getById(id) {
    return http.get(`/scopes/${id}`, {}, APIS.DOCUMENTARY);
  }
}

export const scopes = new Scopes();
