import { useHandleOpen } from '@engloba-tech/englobity';
import CachedIcon from '@material-ui/icons/Cached';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import FilterListIcon from '@material-ui/icons/FilterList';
import { NAME_SPACE } from 'i18n';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DialogModal } from 'shared';

export function AdvancedFilters({ onAcceptFilters, getActiveFilters, form }) {
  const { t } = useTranslation(NAME_SPACE.COMMON);
  const { isOpen, handleClose, handleOpen } = useHandleOpen(false);
  const $refFilter = useRef(null);

  const activeFilters = getActiveFilters ? getActiveFilters() : null;

  function handleAccept(filters) {
    handleClose();
    onAcceptFilters(filters);
  }

  function handleReset() {
    handleClose();
    onAcceptFilters({});
  }

  return (
    <>
      <Button text={t('actions.filter')} icon={<FilterListIcon />} onClick={handleOpen} />

      <DialogModal
        title={t('actions.advancedFilters')}
        fullWidth
        isOpen={isOpen}
        onClose={handleClose}
        buttons={[
          {
            children: <CloseIcon />,
            text: t('common:actions.close'),
            type: 'secondary',
            onClick: handleClose
          },
          {
            children: <CachedIcon />,
            text: t('common:actions.clear'),
            type: 'secondary',
            onClick: handleReset
          },
          {
            children: <DoneIcon />,
            text: t('common:actions.apply'),
            type: 'primary',
            onClick: () => $refFilter.current.submit()
          }
        ]}
      >
        {form({
          formRef: $refFilter,
          onSubmit: handleAccept,
          activeFilters: activeFilters
        })}
      </DialogModal>
    </>
  );
}

AdvancedFilters.propTypes = {
  onAcceptFilters: PropTypes.func.isRequired,
  getActiveFilters: PropTypes.func,
  disabled: PropTypes.bool,
  form: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })])
};
