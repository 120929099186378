import { InputLabel } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import { useTreeSelectStyles } from './treeSelect.styles';
import { useTreeSelect } from './useTreeSelect';

export const TreeSelect = ({
  label,
  isLoading,
  inputs,
  onSelectEntity,
  required = false,
  disabled = false,
  propName,
  entityId,
  fetchData
}) => {
  const classes = useTreeSelectStyles();
  const { options, updateChecked, getArrayOptions } = useTreeSelect({
    propName,
    entityId,
    fetchData,
    value: { name: inputs?.name, id: inputs?.id }
  });

  const onChange = currentNode => {
    updateChecked({ id: currentNode.value, isChecked: currentNode.checked });
    onSelectEntity(currentNode.checked === false ? null : currentNode);
  };
  const labelClassNames =
    'MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined Mui-required Mui-required';

  useEffect(() => {
    if (!inputs?.id) updateChecked({ elements: [], id: null, isChecked: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs?.id]);

  return isLoading ? (
    <Skeleton height={48} />
  ) : (
    <div className="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth">
      <InputLabel className={labelClassNames} required={required}>
        {label}
      </InputLabel>
      <DropdownTreeSelect
        className={clsx(classes.select, { [classes.hidePlaceholder]: inputs?.id })}
        data={options}
        mode="radioSelect"
        onChange={onChange}
        onFocus={getArrayOptions}
        keepTreeOnSearch={true}
        disabled={disabled}
      />
    </div>
  );
};

TreeSelect.propTypes = {
  label: PropTypes.string,
  isLoading: PropTypes.bool,
  inputs: PropTypes.object,
  entity: PropTypes.shape({ id: PropTypes.string, name: PropTypes.string }),
  onSelectEntity: PropTypes.func.isRequired,
  propName: PropTypes.string.isRequired,
  entityId: PropTypes.string,
  fetchData: PropTypes.func.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool
};
