import { SKELETON_KEY, SKELETON_KEY_NUMBER, SKELETON_KEY_DATE } from './skeletonFactory';

export function skeletonSupplierRating() {
  return {
    id: SKELETON_KEY,
    supplier: SKELETON_KEY,
    description: SKELETON_KEY,
    ratingComplianceDeliveryDeadlines: SKELETON_KEY_NUMBER,
    ratingQualityWorks: SKELETON_KEY_NUMBER,
    ratingQualityPriceRatio: SKELETON_KEY_NUMBER,
    totalRatingAverage: SKELETON_KEY_NUMBER,
    remarks: SKELETON_KEY,
    user: SKELETON_KEY,
    updatedDate: SKELETON_KEY_DATE
  };
}
