import { http } from './http';
import { apiHelper } from 'shared';

const controllerName = 'work-orders';
class Comments {
  get(workOrderId, paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/${controllerName}/${workOrderId}/comments/${queryString}`);
  }

  getReminders(workOrderId, paging) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, isReminder: true });

    return http.get(`/${controllerName}/${workOrderId}/comments/${queryString}`);
  }

  getById(workOrderId, id) {
    return http.get(`/${controllerName}/${workOrderId}/comments/${id}`);
  }

  create(comment, workOrderId) {
    return http.post(`/${controllerName}/${workOrderId}/comments/`, comment);
  }

  update(comment, workOrderId) {
    return http.put(`/${controllerName}/${workOrderId}/comments/${comment.id}`, comment);
  }

  delete(workOrderId, id, multiple) {
    return http.delete(`/${controllerName}/${workOrderId}/comments/${id}`, { multiple });
  }
}

export const workOrderComments = new Comments();
