import { APIS } from 'app.apis.const';
import { http } from './http';

class Auth {
  changePassword(request, userName, site) {
    return http.post(
      '/users/change-password',
      { email: userName, oldPassword: request.currentPassword, newPassword: request.newPassword, confirmPassword: request.repeatNewPassword, site },
      {},
      APIS.IDENTITY,
      true
    );
  }
}

export const auth = new Auth();
