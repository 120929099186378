import { http } from './http';
import { apiHelper } from 'shared';

class AssetsRealEstate {
  get(workOrderId, paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/work-orders/${workOrderId}/real-estates/${queryString}`);
  }

  getById(workOrderId, id) {
    return http.get(`/work-orders/${workOrderId}/real-estates/${id}`);
  }

  create(workOrderId, asset) {
    return http.post(`/work-orders/${workOrderId}/real-estates`, asset);
  }

  link(workOrderId, assetId) {
    return http.post(`/work-orders/${workOrderId}/real-estates/${assetId}`);
  }

  delete(workOrderId, id, multiple) {
    return http.delete(`/work-orders/${workOrderId}/real-estates/${id}`, { multiple });
  }

  update(workOrderId, id, asset) {
    return http.put(`/work-orders/${workOrderId}/real-estates/${id}`, { ...asset, forceUpdateAddress: true });
  }

  async getUsingQueryFilter(queryFilter) {
    let queryString = '';

    queryString = apiHelper.composeQueryString({ reference: queryFilter });
    const response = await http.get(`/assets/real-estates${queryString}`, {});
    const items = response.data && response.data.items;

    return items;
  }

  async getByWorkOrderUsingQueryFilter(workOrderId, queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ reference: queryFilter });

    const response = await http.get(`/work-orders/${workOrderId}/real-estates${queryString}`);
    const items = response.data && response.data.items;
    return items.map(item => {
      return { ...item, fullDescription: `${item.externalReference} | ${item.fullAddress}` };
    });
  }

  async getByWorkOrderIdToAppointmentUsingQueryFilter(workOrderId, queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ reference: queryFilter });

    const response = await http.get(`/work-orders/${workOrderId}/real-estates${queryString}`);
    const items = response.data && response.data.items;

    return items.map(item => {
      return {
        ...item,
        fullDescription: `${item.externalReference}${
          item.secondExternalReference ? ' | ' + item.secondExternalReference : ''
        } | ${item.fullAddress}`
      };
    });
  }
}

export const assetsRealEstate = new AssetsRealEstate();
