import { errorToaster, successToaster } from '@engloba-tech/englobity';

import i18n from 'i18n';
import { stringHelper } from 'shared';

import { lessProgress, moreProgress, stopProgress } from './progress';

function splitAndTranslateEach(value = '') {
  if (value.includes('/nl')) {
    return value.split('/nl').map(item => `${i18n.t(item)}`);
  }
  return i18n.t(value);
}

export function configureRequestInterceptor(instance) {
  instance.interceptors.request.use(
    async config => {
      moreProgress();

      if (!config.headers.Accept) config.headers.Accept = 'application/json';
      if (!config.headers['Content-Type']) config.headers['Content-Type'] = 'application/json';

      return config;
    },
    error => {
      errorToaster(i18n.t('request.error'), error.message);
      throw error;
    }
  );
}

export function configureResponseInterceptor(instance) {
  instance.interceptors.response.use(
    async response => {
      lessProgress();
      if (response.config?.method !== 'get' && !response.config?.multiple && !response.config?.disableErrorToasters) {
        successToaster(i18n.t('request.succes'));
      }
      return response;
    },
    error => {
      if (error.response?.status === 403) {
        error.message = i18n.t('common:validations:noWritePermission');
      }

      if (error.config?.multiple) {
        stopProgress();
        throw error;
      }

      if (error.config?.disableErrorToasters) {
        stopProgress();
        throw error;
      }

      let errorMsg = { message: error.message, detail: '' };
      if (error?.response?.data) {
        try {
          errorMsg = JSON.parse(
            error.response ? (error.response.data ? error.response.data : error.message) : error.message
          );
        } catch (x) {
          if (error.response.data.message) errorMsg = error.response.data;
        }
      }

      const errorDetail = errorMsg.detail ? stringHelper.normalizeQuotes(errorMsg.detail) : '';

      // Prevents to show error at the bottom right side if there is a modal opened (editModal)
      const editModal = document.getElementById('edit-modal');
      if (!editModal)
        errorToaster(
          i18n.t(stringHelper.normalizeQuotes(errorMsg.message)),
          splitAndTranslateEach(stringHelper.normalizeQuotes(errorDetail))
        );

      stopProgress();
      throw error;
    }
  );
}
