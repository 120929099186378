import { AsyncInputAutocomplete } from '@engloba-tech/englobity';
import { Chip, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NAME_SPACE } from 'i18n';

import { DialogModal, ManagersForm, isSkeletonLoading } from 'shared';
import { useManagersInputStyles } from './managersInput.styles';

export function ManagersInput({
  onFilled,
  entity,
  propName,
  getRoles,
  isLoading,
  title,
  disabled,
  isManager = true,
  listAllRoles = false
}) {
  const { t } = useTranslation(NAME_SPACE.WORK_ORDERS);
  const classes = useManagersInputStyles();

  const $formManagersModalRef = useRef(null);
  const [showManagersModal, setShowManagersModal] = useState(false);
  const [processRoles, setProcessRoles] = useState([]);

  const getAndSetRoles = async () => {
    const processRolesItems = getRoles && (await getRoles());
    setProcessRoles(processRolesItems);
  };

  async function handleManagersSubmit(changedInputs) {
    const formIsValid = await $formManagersModalRef.current.isFormValid();
    if (formIsValid) {
      setShowManagersModal(false);
      onFilled(changedInputs);
    }
  }

  function handleManagersModalAccept() {
    $formManagersModalRef.current.submit();
  }

  return (
    <>
      <AsyncInputAutocomplete
        isLoading={isLoading}
        className={clsx(classes.formManagers, 'form-managers')}
        id="form-managers"
        name={t('properties.managers')}
        label={title || t('properties.managers')}
        variant="outlined"
        value={entity[propName] || []}
        disabled={disabled}
        multiple
        renderTags={(value, getTagProps) =>
          !isSkeletonLoading(value) &&
          value.map(val => {
            return (
              <Tooltip
                key={val.processRoleId}
                title={`${val.processRole}: ${val.manager ?? '-'}`}
                placement="top"
                classes={{ popper: classes.tooltipPopper, tooltip: classes.tooltipNoMaxWidth }}
              >
                <Chip
                  label={
                    <>
                      {val.processRole}: <div className={classes.chipManager}>{val.manager ?? '-'}</div>
                    </>
                  }
                  style={{ marginRight: 5, marginTop: 5 }}
                  className={clsx({
                    [classes.chipInformed]: listAllRoles && val.managerId,
                    [classes.chipEmpty]: listAllRoles && !val.managerId
                  })}
                />
              </Tooltip>
            );
          })
        }
        onClick={async () => {
          if (disabled) return;

          await getAndSetRoles();
          setShowManagersModal(true);
        }}
        inputProps={{ 'aria-label': title || t('properties.managers'), endAdornment: null }}
        popupIcon={null}
      />

      {showManagersModal && (
        <DialogModal
          title={title || t('address:fiscalManagers')}
          description=""
          isOpen={showManagersModal}
          onClose={() => setShowManagersModal(false)}
          className={classes.modal}
          buttons={[
            {
              children: <CloseIcon />,
              text: t('common:actions.close'),
              type: 'secondary',
              onClick: () => setShowManagersModal(false)
            },
            {
              children: <DoneIcon />,
              text: t('common:actions.accept'),
              type: 'primary',
              onClick: handleManagersModalAccept
            }
          ]}
        >
          <ManagersForm
            formRef={$formManagersModalRef}
            onSubmit={handleManagersSubmit}
            t={t}
            entity={{ ...entity }}
            propName={propName}
            processRoles={processRoles}
            isManager={isManager}
            listAllRoles={listAllRoles}
          />
        </DialogModal>
      )}
    </>
  );
}

ManagersInput.propTypes = {
  onFilled: PropTypes.func.isRequired,
  entity: PropTypes.shape({
    id: PropTypes.string,
    managers: PropTypes.string
  }),
  propName: PropTypes.string,
  getRoles: PropTypes.func,
  isLoading: PropTypes.bool,
  required: PropTypes.bool,
  requiredProps: PropTypes.array,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  isManager: PropTypes.bool,
  listAllRoles: PropTypes.bool
};
