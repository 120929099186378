import PropTypes from 'prop-types';
import React from 'react';
import { useCarouselWidgetStyles } from './carouselWidget.styles';

export const CarouselLine = ({ children, onClickLine, item, index }) => {
  const classes = useCarouselWidgetStyles();

  return (
    <div className={classes.line} onClick={() => onClickLine(item, index)}>
      {children}
    </div>
  );
};

CarouselLine.propTypes = {
  children: PropTypes.node,
  onClickLine: PropTypes.func,
  item: PropTypes.object,
  index: PropTypes.number
};
