import { apiHelper } from 'shared';

import { http } from './http';

const controllerName = 'work-order-statuses';

class WorkOrderStatuses {
  cachedStatuses = [];
  currentStatusesVisibility = undefined;

  async getUsingQueryFilter(queryFilter, visibility) {
    if (!this.cachedStatuses.length || visibility !== this.currentStatusesVisibility) {
      let queryString = '';
      queryString = apiHelper.composeQueryString({ name: queryFilter, visibility });
      const response = await http.get(`/${controllerName}${queryString}`, {});
      const statuses = response.data && response.data.items;
      if (statuses) {
        this.cachedStatuses = statuses
          .map(status => {
            return { value: status.id, name: status.name, isEndStatus: status.isEndStatus };
          })
          .sort((a, b) => a.name.localeCompare(b.name));
      }
      this.currentStatusesVisibility = visibility;
    }
    return this.cachedStatuses;
  }

  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/${controllerName}${queryString}`, {});
  }

  getById(id) {
    return http.get(`/${controllerName}/${id}`, {});
  }

  create(status) {
    this.cachedStatuses = [];
    return http.post(`/${controllerName}`, status, {});
  }

  delete(id, multiple) {
    this.cachedStatuses = [];
    return http.delete(`/${controllerName}/${id}`, { multiple });
  }

  update(status) {
    this.cachedStatuses = [];
    return http.put(`/${controllerName}/${status.id}`, status, {});
  }

  async getPriorities() {
    var response = await http.get(`/${controllerName}/priorities`, {});
    const priorities = response && response.data;
    if (priorities) {
      return priorities.map(priority => {
        return { value: priority.id, name: priority.name };
      });
    }
  }
}

export const workOrderStatuses = new WorkOrderStatuses();
