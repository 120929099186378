import { makeStyles } from '@material-ui/core/styles';
import { color } from '../../styles/color.styles';

export const useFormPageStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    '& .MuiFormLabel-root': {
      color: theme.palette.primary.dark,
      fontWeight: 'bold'
    },
    '& input': {
      color: theme.palette.primary.dark
    },
    '& .pageHeader': {
      marginBottom: 0
    }
  },
  breadcrumbs: {
    margin: '2rem 0 1.5rem 0'
  },
  containerStyled: {
    background: 'transparent',
    width: '100%',
    '& form': {
      background: 'white',
      padding: theme.spacing(3),
      boxShadow: color.boxShadow,
      borderRadius: '4px'
    }
  },

  container: {
    background: 'transparent',
    width: '100%',
    display: 'flex',
    '& form': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      '& .formContainer': {
        marginTop: 0,
        paddingBottom: theme.spacing(3)
      },
      '& .formContainer ~ .formContainer': {
        paddingBottom: theme.spacing(3),
        marginTop: theme.spacing(3)
      },
    }
  },

  containerChildForm: {
    marginBottom: '0 !important',
    boxShadow: 'none'
  },
  contentChildForm: {
    paddingBottom: '6px !important'
  },
  breadcrum: {
    minWidth: '3rem'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    flex: 2,
    whiteSpace: 'nowrap',
    alignSelf: 'end',
    '& svg': {
      marginLeft: '1rem'
    }
  },
  actions: {
    flex: 11,
    alignSelf: 'end',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    marginLeft: theme.spacing(2)
  },

  '& .primary': {
    background: 'red'
  },

  '& .scondary': {
    background: 'yellow'
  },
  '.multiActionButtonPopper': {
    zIndex: '1500 !important'
  },
  content: {
    flexDirection: 'column',
    flexGrow: 1,
    display: 'flex',
    '& form': {
      flexGrow: 0
    }
  },
  noWritePermission: {
    marginBottom: '1rem',
    flexBasis: 'auto'
  },
  header: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: theme.spacing(3),
    justifyContent: 'space-between'
  }
}));
