import { makeStyles } from '@material-ui/core';
import { color } from 'styles/color.styles';

export const useWidgetsStyles = makeStyles(theme => ({
  widget: {
    backgroundColor: 'white',
    gridColumn: props => `span ${props.span}`,
    gridRow: 'span 1',
    position: 'relative',
    background: 'white',
    height: '390px', // Fija la altura del widget
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
    alignItems: 'center',
    userSelect: 'none',
    touchAction: 'none',
    opacity: props => (props.isDragging ? 0.5 : 1),
    transform: props => (props.transform ? `translate(${props.transform.x}px, ${props.transform.y}px)` : 'none'),
    transition: props => props.transition,
    '& .viewtable': {
      margin: 0,
      justifyContent: 'space-between'
    },
    '& .viewtable .MuiTableContainer-root .MuiTable-root .MuiTableBody-root .MuiTableRow-root': {
      height: '44px !important'
    },
    '& .viewtable div:nth-child(2)': {
      borderTop: 'none',
      borderRadius: '8px'
    }
  },
  isActive: {
    backgroundColor: color.primary.light,
    opacity: '0.3',
    height: '100%',
    padding: 0,
    borderRadius: '8px',
    transform: props => (props.isReallyActive ? 'scale(1.05)' : 'none')
  },
  pageHeader: {
    width: '100%',
    float: 'right',
    borderBottom: `1px solid ${color.primary.light}`,
    fontSize: '17px',
    padding: '10px',
    margin: '0 !important'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
  },
  actions: {
    display: 'flex',
    gap: '10px'
  },
  drag: {
    padding: '1px',
    color: color.secondary.dark,
    cursor: 'pointer',
    transition: '0.3s',
    display: 'flex',
    width: '25px',
    alignItems: 'center',
    justifyContent: 'center',
    flex: ' 0 0 auto',
    touchAction: 'none',
    borderRadius: '5px',
    border: 'none',
    outline: 'none',
    appearance: 'none',
    backgroundColor: 'transparent',
    webkitTapHighlightColor: 'transparent',
    '& svg': {
      flex: '0 0 auto',
      margin: 'auto',
      height: '100%',
      overflow: 'visible',
      fill: '#919eab'
    },
    '&:hover': {
      backgroundColor: '#D5D9DE',
      color: color.buttons.disabled.text,
      '& svg': {
        fill: '#6f7b88'
      }
    }
  },
  links: {
    padding: '1px',
    cursor: 'pointer',
    border: 'none',
    '&:hover': {
      borderRadius: '4px',
      backgroundColor: color.primary.light
    }
  },
  widgetRepository: {
    backgroundColor: '#e8f0fe',
    padding: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '8px',
    gap: '15px',
    height: '95px',
    '& > div svg': {
      color: color.primary.main
    }
  },
  widgetRepository_info: {
    '& >p': {
      margin: 0,
      fontSize: '15px',
      fontWeight: 800,
      color: color.primary.main
    },
    '& > span': {
      fontSize: '12px'
    }
  },
  widgetRepository_container: {
    display: 'flex',
    gap: '15px'
  }
}));
