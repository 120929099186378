import { http } from './http';
import { APIS } from 'app.apis.const';
import { apiHelper } from 'shared';

const controllerName = 'received-invoices';

class ReceivedInvoiceLines {
  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/${controllerName}${queryString}`, {}, APIS.FINANCES);
    const items = response.data && response.data.items;

    return items;
  }

  get(receivedInvoiceId, paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/${controllerName}/${receivedInvoiceId}/lines${queryString}`, {}, APIS.FINANCES);
  }

  getById(receivedInvoiceId, receivedInvoiceLineId) {
    return http.get(`/${controllerName}/${receivedInvoiceId}/lines/${receivedInvoiceLineId}`, {}, APIS.FINANCES);
  }

  create(receivedInvoiceId, receivedInvoiceLine) {
    return http.post(`/${controllerName}/${receivedInvoiceId}/lines`, receivedInvoiceLine, {}, APIS.FINANCES);
  }

  update(receivedInvoiceId, receivedInvoiceLine) {
    return http.put(`/${controllerName}/${receivedInvoiceId}/lines/${receivedInvoiceLine.id}`, receivedInvoiceLine, {}, APIS.FINANCES);
  }

  delete(receivedInvoiceId, receivedInvoiceLineId, multiple) {
    return http.delete(`/${controllerName}/${receivedInvoiceId}/lines/${receivedInvoiceLineId}`, { multiple }, APIS.FINANCES);
  }

}

export const receivedInvoicesLines = new ReceivedInvoiceLines();
