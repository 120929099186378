import { makeStyles } from '@material-ui/core/styles';

export const useMailToModalStyles = makeStyles(theme => ({
  modal: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: '50rem',
      '& .MuiDialogTitle-root': {
        textAlign: 'center'
      }
    }
  },
  form: {
    flexGrow: 1
  },
  container: {
    maxWidth: 'auto'
  },
  marginTop: {
    marginTop: '-30px'
  }
}));
