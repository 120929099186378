import { SKELETON_KEY, SKELETON_KEY_DATE, SKELETON_KEY_NUMBER } from './skeletonFactory';

export function skeletonReceivedInvoiceLine() {
  return {
    id: SKELETON_KEY,
    externalReference: SKELETON_KEY,
    districtId: SKELETON_KEY_DATE,
    quantity: SKELETON_KEY_NUMBER,
    amount: SKELETON_KEY_NUMBER
  };
}

export function skeletonReceivedInvoiceDocument() {
  return {
    id: SKELETON_KEY,
    receivedInvoiceId: SKELETON_KEY,
    documentName: SKELETON_KEY,
    documentUrl: SKELETON_KEY,
    entryDate: SKELETON_KEY_DATE,
    userId: SKELETON_KEY,
    user: SKELETON_KEY,
    remarks: SKELETON_KEY,
    documentType: SKELETON_KEY,
    documentTypeId: SKELETON_KEY
  };
}
export function skeletonReceivedInvoiceTaxBase() {
  return {
    id: SKELETON_KEY,
    taxBase: SKELETON_KEY_NUMBER,
    applicableTaxName: SKELETON_KEY,
    applicableTaxType: SKELETON_KEY_NUMBER,
    tax: SKELETON_KEY_NUMBER,
    irpfName: SKELETON_KEY,
    irpfTypeRate: SKELETON_KEY_NUMBER,
    irpfTax: SKELETON_KEY_NUMBER,
    total: SKELETON_KEY_NUMBER
  };
}

export function skeletonReceivedInvoice(linesNum, taxBasesNum = 2) {
  return {
    id: SKELETON_KEY,
    receivedDate: SKELETON_KEY_DATE,
    invoiceDate: SKELETON_KEY_DATE,
    invoiceNumber: SKELETON_KEY,
    supplierName: SKELETON_KEY,
    supplierFirstSurname: SKELETON_KEY,
    supplierSecondSurname: SKELETON_KEY,
    supplierFullName: SKELETON_KEY,
    supplierAlias: SKELETON_KEY,
    project: SKELETON_KEY,
    dueDate: SKELETON_KEY_DATE,
    registrationDate: SKELETON_KEY_DATE,
    paymentDate: SKELETON_KEY_DATE,
    taxBase: SKELETON_KEY_NUMBER,
    outlays: SKELETON_KEY_NUMBER,
    applicableTaxType: SKELETON_KEY_NUMBER,
    tax: SKELETON_KEY_NUMBER,
    total: SKELETON_KEY_NUMBER,
    totalToPay: SKELETON_KEY_NUMBER,
    districtIds: Array(linesNum).fill(SKELETON_KEY),
    paymentMethodTermName: SKELETON_KEY,
    retention: SKELETON_KEY_NUMBER,
    retentionType: SKELETON_KEY_NUMBER,
    workOrder: SKELETON_KEY,
    user: SKELETON_KEY,
    status: SKELETON_KEY_NUMBER,
    lines: Array(linesNum).fill(skeletonReceivedInvoiceLine()),
    taxBases: Array(taxBasesNum).fill(skeletonReceivedInvoiceTaxBase())
  };
}

export function skeletonReceivedInvoiceRetentionTypes() {
  return {
    id: SKELETON_KEY_NUMBER,
    name: SKELETON_KEY
  };
}
