import { http } from './http';
import { apiHelper } from 'shared';
import { APIS } from 'app.apis.const';

const controllerName = 'real-estate-document-types';

class RealEstateDocumentTypes {
  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/${controllerName}${queryString}`, {}, APIS.REAL_ESTATES);
    const items = response.data && response.data.items;

    return items;
  }

  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/${controllerName}${queryString}`, {}, APIS.REAL_ESTATES);
  }

  getById(id) {
    return http.get(`/${controllerName}/${id}`, {}, APIS.REAL_ESTATES);
  }
}

export const realEstateDocumentTypes = new RealEstateDocumentTypes();
