import { useState, useEffect } from 'react';
import { alerts } from 'services';
import { skeletonFactory } from 'shared';

const alertTypesDummy = skeletonFactory.alertTypes();

export function useAlertTypes() {
  const [alertTypesData, setAlertTypesData] = useState(alertTypesDummy);

  useEffect(() => {
    async function getData() {
      const response = await alerts.getTypes();
      if (!response) {
        return;
      }

      setAlertTypesData(response.data || []);
    }
    getData();
  }, []);

  return { alertTypes: alertTypesData, setAlertTypesData };
}
