import PropTypes from 'prop-types';
import React from 'react';
import { ViewTable } from 'shared/ViewTable';

export const ViewTableWidget = ({ rows, headCells, onFetchData, defaultOrderBy, totalRows }) => {
  return (
    <ViewTable
      rowsPerPage={5}
      allowRowChecking={false}
      rows={rows}
      cells={headCells}
      defaultOrderBy={defaultOrderBy}
      onFetchData={onFetchData}
      totalRows={totalRows}
      serverSidePaging
      allowRowFilter={false}
      disableOrderBy={true}
      numberRowsPerPage={5}
      rowPerPageOptions={[]}
    />
  );
};
ViewTableWidget.propTypes = {
  rows: PropTypes.array.isRequired,
  headCells: PropTypes.array.isRequired,
  onFetchData: PropTypes.func.isRequired,
  defaultOrderBy: PropTypes.shape({ property: PropTypes.string, direction: PropTypes.string }),
  totalRows: PropTypes.number.isRequired
};
