import { apiHelper } from 'shared';
import { http } from './http';

class Statuses {
  getWorkOrder() {
    return http.get('/statuses/work-order');
  }
  getDocument() {
    return http.get('/statuses/document');
  }
  getBudgetItemStatus() {
    return http.get('/statuses/budgetItem');
  }
  getBudgetItem_supplierBillingStatus() {
    return http.get('/statuses/budgetItem/supplierBillingStatuses');
  }

  async getBudgetItemStatusUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/statuses/budgetItem${queryString}`);
    const items = response.data;
    return items;
  }
}

export const statuses = new Statuses();
