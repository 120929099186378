import { useState, useEffect } from 'react';
import { workOrderStatuses } from 'services';
import { useTranslation } from 'react-i18next';

export function useWorkOrderStatuses(withAnyLabel, visibility) {

  const { t } = useTranslation();
  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    async function getStatuses() {
      const statuses = await workOrderStatuses.getUsingQueryFilter(null, visibility);
      if (statuses.length) {
        setStatuses(
          withAnyLabel ? [{ value: t('common:options.anyone'), name: t('common:options.anyone'), isAny: true }, ...statuses] : statuses
        );
      }
    }
    getStatuses();
  }, [t, withAnyLabel,visibility]);

  return { statuses };
}
