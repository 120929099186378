import { http } from './http';
import { apiHelper } from 'shared';

class Roles {
  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/roles/${queryString}`);
  }

  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });
    const response = await http.get(`/roles${queryString}`, {});
    const items = response.data && response.data.items;

    return items;
  }

  delete(id, multiple) {
    return http.delete(`/roles/${id}`, { multiple });
  }

  getById(id) {
    return http.get(`/roles/${id}`);
  }

  create(role) {
    return http.post('/roles', role);
  }

  update(id, role) {
    return http.put(`/roles/${id}`, role);
  }
}

export const roles = new Roles();
