import React from 'react';
import HomeWorkIcon from '@material-ui/icons/HomeWork';

// TODO: add more assets types

export const ASSETS = {
  0: 'realEstate'
};

export const ASSETS_TYPE = {
  REAL_ESTATE: 'RealEstate'
};

export const ASSETS_ICONS = {
  [ASSETS_TYPE.REAL_ESTATE]: <HomeWorkIcon />
};
