import { errorToaster, successToaster, warningToaster } from '@engloba-tech/englobity';
import { useCallback, useRef } from 'react';
import { skeletonFactory, useHandleRequest, useStateWithBackup } from 'shared';

const documentsDummy = skeletonFactory.realEstateDocuments();
const documentDummy = skeletonFactory.realEstateDocument();

export function useDocumentsPaginated({
  entityId,
  service,
  extraIdRequest,
  afterSave,
  t,
  mainKey,
  secondaryKey,
  defaultFilters = []
}) {
  const [documentsData, setDocumentsData, undoDocuments] = useStateWithBackup(documentsDummy);
  const { request } = useHandleRequest();
  const lastState = useRef(null);

  const getDocuments = useCallback(
    async ({ paging, sorting, filteredCells }) => {
      await request(async () => {
        lastState.current = { paging, sorting, filteredCells };
        const response = extraIdRequest
          ? await service.get(entityId, ...extraIdRequest, paging, sorting, { ...defaultFilters, ...filteredCells })
          : await service.get(entityId, paging, sorting, { ...defaultFilters, ...filteredCells });
        const documents = response?.data;
        if (documents) {
          setDocumentsData(documents);
        }
      }, false);
    },
    [request, extraIdRequest, service, entityId, defaultFilters, setDocumentsData]
  );

  const refresh = useCallback(async () => {
    getDocuments(lastState.current);
  }, [getDocuments]);

  const deleteDocuments = useCallback(
    async realEstateDocumentsToDelete => {
      await request(async () => {
        await Promise.allSettled(
          realEstateDocumentsToDelete.map(element => {
            setDocumentsData(prevElement => {
              prevElement.items = prevElement.items.map(w => {
                if (!realEstateDocumentsToDelete.find(o => o.id === w.id)) {
                  return w;
                }
                return documentDummy;
              });
              return { ...prevElement };
            });
            return extraIdRequest
              ? service.delete(entityId, ...extraIdRequest, element.id, realEstateDocumentsToDelete.length > 1)
              : service.delete(entityId, element.id, realEstateDocumentsToDelete.length > 1);
          })
        ).then(async responses => {
          if (responses.length <= 1) {
            if (responses[0].status === 'rejected') undoDocuments();
            else {
              await getDocuments(lastState.current);
              afterSave && (await afterSave());
            }
            return;
          }

          if (responses.filter(resp => resp.status === 'rejected').length === responses.length) {
            undoDocuments();

            errorToaster(
              t('multiple.deleteFailed'),
              responses
                .filter(res => res.status === 'rejected')
                .map(resp => {
                  let element = realEstateDocumentsToDelete.find(
                    e =>
                      e.id ===
                      resp.reason.config.url.substring(
                        resp.reason.config.url.lastIndexOf('/') + 1,
                        resp.reason.config.url.length
                      )
                  );
                  if (element) {
                    return `${mainKey ? element[mainKey] : 'No key provided'}  ${
                      secondaryKey ? '- ' + element[secondaryKey] + ' ' : ' '
                    }| ${t(JSON.parse(resp.reason.response.data).message)}`;
                  }

                  return `Unknown | ${t(JSON.parse(resp.reason.response.data).message)}`;
                })
            );
          } else if (
            responses.some(resp => resp.status === 'rejected') &&
            responses.some(resp => resp.status === 'fulfilled')
          ) {
            warningToaster(
              t('multiple.deleteWithErrors'),
              responses
                .filter(res => res.status === 'rejected')
                .map(resp => {
                  let element = realEstateDocumentsToDelete.find(
                    e =>
                      e.id ===
                      resp.reason.config.url.substring(
                        resp.reason.config.url.lastIndexOf('/') + 1,
                        resp.reason.config.url.length
                      )
                  );
                  return `${mainKey ? element[mainKey] : 'No key provided'}  ${
                    secondaryKey ? '- ' + element[secondaryKey] + ' ' : ' '
                  }| ${t(JSON.parse(resp.reason.response.data).message)}`;
                })
            );
            await getDocuments(lastState.current);
            afterSave && (await afterSave());
          } else {
            successToaster(t('request.succes'));
            await getDocuments(lastState.current);
            afterSave && (await afterSave());
          }
        });
      });
    },
    [request, setDocumentsData, extraIdRequest, service, entityId, undoDocuments, getDocuments, afterSave, t]
  );

  return {
    documents: documentsData,
    getDocuments,
    deleteDocuments,
    setDocumentsData,
    refresh
  };
}
