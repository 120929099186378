import { useState } from 'react';
import { groupHelper } from 'shared/helper';

export function useCheckedElements({ t, mainKey, secondaryKey, prefix, action, onlyOneCheck = false }) {
  const [checkedElements, setCheckedElements] = useState([]);

  const handleCheckedElements = async checkedElements => {
    await action(checkedElements);
    setCheckedElements([]);
  };

  const onCheckElement = element => {
    if (onlyOneCheck) {
      checkOnlyOneElement(element);
      return;
    }
    if (!checkedElements.find(elem => elem.id === element.id)) {
      setCheckedElements([...checkedElements, element]);
    } else {
      setCheckedElements(checkedElements.filter(elem => elem.id !== element.id));
    }
  };

  const checkOnlyOneElement = element => {
    if (!checkedElements.find(elem => elem.id === element.id)) {
      setCheckedElements([element]);
    } else {
      setCheckedElements([]);
    }
  };

  const contains = (array1, array2) => array1.map(element => element.id).includes(...array2.map(element => element.id));

  const uncheckElements = (array1, array2) =>
    array1.filter(element => !array2.map(element => element.id).includes(element.id));

  const onCheckAllElements = (elements, uncheckOnlySelected = false) => {
    setCheckedElements(() => {
      if (!uncheckOnlySelected) {
        if (contains(checkedElements, elements)) return uncheckElements(checkedElements, elements);
        return [...checkedElements, ...elements.filter(e => !e.isAccordionHeader)];
      }
      let nowChecked = checkedElements;
      if (checkedElements.length) {
        if (checkedElements.includes(...elements))
          return checkedElements.filter(element => !elements.includes(element)) ?? [];
        else
          return groupHelper.removeDuplicates(
            nowChecked.concat(checkedElements.concat(elements.filter(e => !e.isAccordionHeader))),
            'id'
          );
      } else {
        return groupHelper.removeDuplicates(checkedElements.concat(elements.filter(e => !e.isAccordionHeader)), 'id');
      }
    });
  };

  const getDeleteDescription = () => {
    const message = prefix ? `${prefix}.removeConfirm.message` : 'removeConfirm.message';
    const singularMessage = prefix ? `${prefix}.removeConfirm.singularMessage` : 'removeConfirm.singularMessage';
    if (checkedElements && checkedElements.length) {
      return checkedElements.length > 1
        ? t(message, { length: checkedElements.length })
        : t(singularMessage, {
            [mainKey]: checkedElements[0][mainKey],
            [secondaryKey]: checkedElements[0][secondaryKey]
          });
    }
  };

  const isChecked = elem => {
    return checkedElements?.some(element => element.id === elem.id);
  };

  return {
    checkedElements,
    getDeleteDescription,
    onCheckElement,
    onCheckAllElements,
    setCheckedElements,
    isAnyChecked: Boolean(checkedElements.length),
    isChecked,
    handleCheckedElements,
    checkedElementsCount: checkedElements.length
  };
}
