import { makeStyles } from '@material-ui/core/styles';
import { color } from 'styles/color.styles';

export const useManagersInputStyles = makeStyles(theme => ({
  form: {
    flexGrow: 1
  },
  divider: {
    width: '100%',
    marginTop: '1rem',
    marginBottom: '2rem'
  },
  modal: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: '50rem',
      '& .MuiDialogContent-root': {
        margin: theme.spacing(2),
        overflow: 'visible'
      },
      '& .MuiDialogTitle-root': {
        textAlign: 'center'
      },
      '& .MuiDialogActions-root': {
        marginTop: theme.spacing(3)
      }
    }
  },
  chipManager: {
    fontWeight: 'bold',
    display: 'inline'
  },
  chipInformed: {
    backgroundColor: `${color.branding.green}99 !important`
  },
  chipEmpty: {
    backgroundColor: `${color.branding.red}CC !important`
  }
}));
