import { makeStyles } from '@material-ui/core/styles';
import { color } from 'styles/color.styles';

export const useCarouselWidgetStyles = makeStyles(theme => ({
  carousel: {
    width: '100%',
    '& .carousel': {
      height: '340px',
      display: 'flex',
      flexDirection: 'column'
    },
    '& .carousel .control-dots': {
      width: 'min-content',
      display: 'flex',
      alignSelf: 'center',
      padding: '5px',
      borderRadius: '10px',
      backgroundColor: 'rgba(32, 33, 36, 0.2)',
      '& .dot': {
        boxShadow: 'none'
      }
    },
    '& .carousel .slider-wrapper': {
      margin: '0',
      height: '100%',
      display: 'flex'
    },
    '& .carousel .control-arrow': {
      display: 'none'
    }
  },
  info: {
    margin: '15px'
  },
  line: {
    cursor: 'pointer',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    height: '60px',
    borderBottom: `1px solid ${color.primary.light}`,
    '& >p': {
      margin: 0,
      color: color.primary.main,
      fontWeight: 600
    },
    '& >span': {
      fontWeight: 200,
      fontSize: '12px'
    },
    '&:hover': {
      backgroundColor: color.primary.main,
      color: 'white',
      '& >p': {
        color: 'white'
      }
    }
  },
  loader: {
    position: 'fixed',
    width: '100%',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: '0.4s all ease'
  }
}));
