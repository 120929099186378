export const resources = {
  en: {
    common: require('./en/common.json'),
    sources: require('./en/sources.json'),
    workOrders: require('./en/workOrders.json'),
    processDefinition: require('./en/processDefinition.json'),
    items: require('./en/items.json'),
    measures: require('./en/measures.json'),
    rates: require('./en/rates.json'),
    users: require('./en/users.json'),
    currentMilestones: require('./en/currentMilestones.json'),
    documentTypes: require('./en/documentTypes.json'),
    projects: require('./en/projects.json'),
    suppliers: require('./en/suppliers.json'),
    customers: require('./en/customers.json'),
    businessLines: require('./en/businessLines.json'),
    notifications: require('./en/notifications.json'),
    receivedInvoices: require('./en/receivedInvoices.json'),
    issuedInvoices: require('./en/issuedInvoices.json'),
    statuses: require('./en/statuses.json'),
    address: require('./en/address.json'),
    calendars: require('./en/calendars.json'),
    workOrderStatuses: require('./en/workOrderStatuses.json'),
    companies: require('./en/companies.json'),
    workGroups: require('./en/workGroups.json'),
    supplierDocumentGroups: require('./en/supplierDocumentGroups.json'),
    realEstates: require('./en/realEstates.json'),
    paymentMethods: require('./en/paymentMethods.json'),
    paymentTerms: require('./en/paymentTerms.json'),
    supplierRatings: require('./en/supplierRatings.json'),
    alerts: require('./en/alerts.json'),
    supplierTypes: require('./en/supplierTypes.json'),
    insuranceCompanies: require('./en/insuranceCompanies.json'),
    integrations: require('./en/integrations.json'),
    rehTaskForm: require('./en/rehTaskForm'),
    equivalences: require('./en/equivalences.json'),
    creditors: require('./en/creditors.json'),
    appointmentTypes: require('./en/appointmentTypes.json'),
    commonUserTasks: require('./en/commonUserTasks.json'),
    agenda: require('./en/agenda.json'),
    processExecutions: require('./en/processExecutions.json'),
    galleryTags: require('./en/galleryTags.json'),
    galleryImages: require('./en/galleryImages.json'),
    reminders: require('./en/reminders.json'),
    reports: require('./en/reports.json'),
    specialities: require('./en/specialities.json'),
    workOrderAutomations: require('./en/workOrderAutomations.json'),
    aggrupations: require('./en/aggrupations.json'),
    directoryTemplates: require('./en/directoryTemplates.json'),
    directoryTree: require('./en/directoryTree.json'),
    contactRoles: require('./en/contactRoles.json'),
    home: require('./en/home.json')
  },
  es: {
    common: require('./es/common.json'),
    sources: require('./es/sources.json'),
    workOrders: require('./es/workOrders.json'),
    processDefinition: require('./es/processDefinition.json'),
    items: require('./es/items.json'),
    measures: require('./es/measures.json'),
    rates: require('./es/rates.json'),
    users: require('./es/users.json'),
    currentMilestones: require('./es/currentMilestones.json'),
    documentTypes: require('./es/documentTypes.json'),
    projects: require('./es/projects.json'),
    suppliers: require('./es/suppliers.json'),
    customers: require('./es/customers.json'),
    businessLines: require('./es/businessLines.json'),
    notifications: require('./es/notifications.json'),
    receivedInvoices: require('./es/receivedInvoices.json'),
    issuedInvoices: require('./es/issuedInvoices.json'),
    statuses: require('./es/statuses.json'),
    address: require('./es/address.json'),
    calendars: require('./es/calendars.json'),
    workOrderStatuses: require('./es/workOrderStatuses.json'),
    companies: require('./es/companies.json'),
    workGroups: require('./es/workGroups.json'),
    supplierDocumentGroups: require('./es/supplierDocumentGroups.json'),
    realEstates: require('./es/realEstates.json'),
    paymentMethods: require('./es/paymentMethods.json'),
    paymentTerms: require('./es/paymentTerms.json'),
    supplierRatings: require('./es/supplierRatings.json'),
    alerts: require('./es/alerts.json'),
    supplierTypes: require('./es/supplierTypes.json'),
    insuranceCompanies: require('./es/insuranceCompanies.json'),
    integrations: require('./es/integrations.json'),
    rehTaskForm: require('./es/rehTaskForm'),
    equivalences: require('./es/equivalences.json'),
    creditors: require('./es/creditors.json'),
    appointmentTypes: require('./es/appointmentTypes.json'),
    commonUserTasks: require('./es/commonUserTasks.json'),
    agenda: require('./es/agenda.json'),
    processExecutions: require('./es/processExecutions.json'),
    galleryTags: require('./es/galleryTags.json'),
    galleryImages: require('./es/galleryImages.json'),
    reminders: require('./es/reminders.json'),
    reports: require('./es/reports.json'),
    specialities: require('./es/specialities.json'),
    workOrderAutomations: require('./es/workOrderAutomations.json'),
    aggrupations: require('./es/aggrupations.json'),
    directoryTemplates: require('./es/directoryTemplates.json'),
    directoryTree: require('./es/directoryTree.json'),
    contactRoles: require('./es/contactRoles.json'),
    home: require('./es/home.json')
  },
  ca: {
    common: require('./ca/common.json'),
    sources: require('./ca/sources.json'),
    workOrders: require('./ca/workOrders.json'),
    processDefinition: require('./ca/processDefinition.json'),
    items: require('./ca/items.json'),
    measures: require('./ca/measures.json'),
    rates: require('./ca/rates.json'),
    users: require('./ca/users.json'),
    currentMilestones: require('./ca/currentMilestones.json'),
    documentTypes: require('./ca/documentTypes.json'),
    projects: require('./ca/projects.json'),
    suppliers: require('./ca/suppliers.json'),
    customers: require('./ca/customers.json'),
    businessLines: require('./ca/businessLines.json'),
    notifications: require('./ca/notifications.json'),
    receivedInvoices: require('./ca/receivedInvoices.json'),
    issuedInvoices: require('./ca/issuedInvoices.json'),
    statuses: require('./ca/statuses.json'),
    address: require('./ca/address.json'),
    calendars: require('./ca/calendars.json'),
    workOrderStatuses: require('./ca/workOrderStatuses.json'),
    companies: require('./ca/companies.json'),
    workGroups: require('./ca/workGroups.json'),
    supplierDocumentGroups: require('./ca/supplierDocumentGroups.json'),
    realEstates: require('./ca/realEstates.json'),
    paymentMethods: require('./ca/paymentMethods.json'),
    paymentTerms: require('./ca/paymentTerms.json'),
    supplierRatings: require('./ca/supplierRatings.json'),
    alerts: require('./ca/alerts.json'),
    supplierTypes: require('./ca/supplierTypes.json'),
    insuranceCompanies: require('./ca/insuranceCompanies.json'),
    integrations: require('./ca/integrations.json'),
    rehTaskForm: require('./ca/rehTaskForm'),
    equivalences: require('./ca/equivalences.json'),
    creditors: require('./ca/creditors.json'),
    appointmentTypes: require('./ca/appointmentTypes.json'),
    commonUserTasks: require('./ca/commonUserTasks.json'),
    agenda: require('./ca/agenda.json'),
    processExecutions: require('./ca/processExecutions.json'),
    galleryTags: require('./ca/galleryTags.json'),
    galleryImages: require('./ca/galleryImages.json'),
    reminders: require('./ca/reminders.json'),
    reports: require('./ca/reports.json'),
    specialities: require('./ca/specialities.json'),
    workOrderAutomations: require('./ca/workOrderAutomations.json'),
    aggrupations: require('./ca/aggrupations.json'),
    directoryTemplates: require('./ca/directoryTemplates.json'),
    directoryTree: require('./ca/directoryTree.json'),
    contactRoles: require('./ca/contactRoles.json'),
    home: require('./ca/home.json')
  }
};
