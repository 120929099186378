import { apiHelper } from 'shared';
import { http } from './http';

const controllerName = 'users';
class Users {
  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/${controllerName}/all/${queryString}`);
  }

  async getUsingQueryFilter(alias, projectId, processRoleNames) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ alias, projectId, processRoles: processRoleNames });

    const response = await http.get(`/${controllerName}${queryString}`);
    const items = response.data && response.data.items;

    return items;
  }

  delete(id, multiple) {
    return http.delete(`/${controllerName}/${id}`, { multiple });
  }

  getById(id) {
    return http.get(`/${controllerName}/${id}`);
  }

  create(user) {
    return http.post(`/${controllerName}`, { ...user, baseWebUrl: window.location.origin });
  }

  update(id, user) {
    return http.put(`/${controllerName}/${id}`, user);
  }

  resetPassword(id) {
    return http.post(`/${controllerName}/${id}/reset-password`, {});
  }

  getMy() {
    return http.get(`/${controllerName}/my`);
  }

  updateMy(user) {
    return http.put(`/${controllerName}/my`, user);
  }

  activate(id) {
    return http.put(`/${controllerName}/${id}/activation`, {});
  }

  desactivate(id) {
    return http.delete(`/${controllerName}/${id}/activation`, {});
  }

  myUserUi() {
    return http.get(`/${controllerName}/my/ui`);
  }

  myUpdateMyUserUi(ui) {
    const config = {
      disableErrorToasters: true
    };
    return http.put(`/${controllerName}/my/ui`, ui, config);
  }
}

export const users = new Users();
