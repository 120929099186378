import { http } from './http';
import { apiHelper } from 'shared';
import { APIS } from 'app.apis.const';

class SupplierWorkerDocuments {
  get(supplierId, agreementId, workerId, paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters, level: 0 });

    return http.get(
      `/suppliers/${supplierId}/agreements/${agreementId}/workers/${workerId}/documents/${queryString}`,
      {},
      APIS.FINANCES
    );
  }

  getRequired(supplierId, agreementId, workerId) {
    return http.get(
      `/suppliers/${supplierId}/agreements/${agreementId}/workers/${workerId}/required-documents`,
      {},
      APIS.FINANCES
    );
  }

  getById(supplierId, agreementId, workerId, id) {
    return http.get(
      `/suppliers/${supplierId}/agreements/${agreementId}/workers/${workerId}/documents/${id}`,
      {},
      APIS.FINANCES
    );
  }

  create(supplierId, agreementId, workerId, document) {
    const { fileObject, selectedFile, ...cleanedRequest } = document;
    return http.post(
      `/suppliers/${supplierId}/agreements/${agreementId}/workers/${workerId}/documents`,
      cleanedRequest,
      {},
      APIS.FINANCES
    );
  }

  delete(supplierId, agreementId, workerId, id, multiple) {
    return http.delete(
      `/suppliers/${supplierId}/agreements/${agreementId}/workers/${workerId}/documents/${id}`,
      { multiple },
      APIS.FINANCES
    );
  }

  update(supplierId, agreementId, workerId, id, document) {
    const { fileObject, selectedFile, ...cleanedRequest } = document;
    return http.put(
      `/suppliers/${supplierId}/agreements/${agreementId}/workers/${workerId}/documents/${id}`,
      { ...cleanedRequest, forceUpdateValidTo: true, forceUpdateValidFrom: true },
      {},
      APIS.FINANCES
    );
  }
}

export const supplierWorkerDocuments = new SupplierWorkerDocuments();
