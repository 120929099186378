import { useState, useEffect } from 'react';
import { sources } from 'services';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';

export function useActivityTypes(withAnyLabel) {
  const { t } = useTranslation(NAME_SPACE.SOURCES);
  const [activityTypes, setActivityTypes] = useState([]);

  useEffect(() => {
    async function getData() {
      const { data: types } = await sources.getActivityTypes();
      if (!types) {
        return;
      }
      const mappedTypes = types.filter(type => type.name !== 'Status').map(type => ({ value: type.id.toString(), name: t(`activityTypes.${type.name}`) }));
      setActivityTypes(withAnyLabel ? [{ value: null, name: t('common:options.anyone'), isAny: true }, ...mappedTypes] : mappedTypes || []);
    }
    getData();
  }, [t, withAnyLabel]);

  return { activityTypes };
}
