import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@engloba-tech/englobity';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { useButtonDownloadFile } from './buttonDownloadFile.styles';

export const ButtonDownloadFile = ({ onClick, disabled }) => {
  const { t } = useTranslation(NAME_SPACE.COMMON);
  const classes = useButtonDownloadFile();
  return (
    <Button onClick={onClick} rootClassName={classes.buttonRoot} className={classes.downloadButton} {...{disabled}}>
      <GetAppIcon />
      {t('actions.downloadFile')}
    </Button>
  );
};

ButtonDownloadFile.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};
