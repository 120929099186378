import { useState, useEffect } from 'react';
import { statuses } from 'services';
import { stringHelper, skeletonFactory } from 'shared';

const statusesDummy = skeletonFactory.workOrderStatuses();

export function useStatuses(type) {
  const [statusesData, setStatusesData] = useState(statusesDummy);

  useEffect(() => {
    async function getData() {
      const method = `get${stringHelper.capitalizeFirstLetter(type)}`;
      const response = await statuses[method]();

      if (!response) {
        return;
      }

      setStatusesData(response.data || []);
    }
    getData();
  }, [type]);

  return { statuses: statusesData, setStatusesData };
}
