import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PATH } from 'app.routes.const';
import { NAME_SPACE } from 'i18n';
import { reminders as remindersService } from 'services';
import {
  ButtonDelete,
  skeletonFactory,
  ToolbarContainer,
  useCheckedElements,
  useViewTableData,
  ViewTable
} from 'shared';
import { UserContext } from 'auth';
import { useRemindersBuildTable } from './useRemindersBuildTable';

const remindersDummy = skeletonFactory.reminders();

export function RemindersList() {
  const {
    data: reminders,
    get: getReminders,
    deleteElements: deleteReminders
  } = useViewTableData({
    service: remindersService,
    paginatedSkeleton: remindersDummy
  });
  const { t } = useTranslation(NAME_SPACE.REMINDERS);
  const { rows, headCells } = useRemindersBuildTable(reminders, t, handleLink);
  const { getRemindersCount } = useContext(UserContext);

  const handleDeleteReminder = useCallback(async (checkedElements) => {
    await deleteReminders(checkedElements);
    getRemindersCount();
  }, [deleteReminders, getRemindersCount]);

  const {
    checkedElements,
    getDeleteDescription,
    handleCheckedElements,
    onCheckElement,
    onCheckAllElements,
    isChecked,
    isAnyChecked,
    checkedElementsCount
  } = useCheckedElements({
    t,
    mainKey: 'description',
    action: handleDeleteReminder
  });


  function handleLink(workOrderId) {
    window.open(PATH.WORK_ORDERS.EDIT.replace(':id', workOrderId));
  }

  return (
    <ToolbarContainer
      actions={
        <ButtonDelete
          elementsToDelete={checkedElements}
          confirmDescription={getDeleteDescription}
          onConfirm={handleCheckedElements}
        />
      }
    >
      <ViewTable
        rows={rows}
        cells={headCells}
        defaultOrderBy={{ property: 'entryDate', direction: 'desc' }}
        onFetchData={getReminders}
        totalRows={reminders.total}
        onCheckElement={onCheckElement}
        onCheckAllElements={onCheckAllElements}
        checkedElementsCount={checkedElementsCount}
        isAnyChecked={isAnyChecked}
        isChecked={isChecked}
        serverSidePaging
        allowRowFilter={true}
      />
    </ToolbarContainer>
  );
}
