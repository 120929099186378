import { makeStyles } from '@material-ui/core/styles';
import { color } from '../../../../../src/styles/color.styles';

export const useRmindersStyles = makeStyles(theme => ({
  modal: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: '70rem'
    }
  }, 
  reminders: {
    '& .MuiBadge-colorPrimary':{
      backgroundColor: color.branding.red
    }
  }
}));
