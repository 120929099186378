import { useCallback } from 'react';
import { useHandleRequest } from 'shared/useHandleRequest';

export function useCreateOrUpdate({ service, parentId, childId }) {
  const { request } = useHandleRequest();

  const createOrUpdate = useCallback(
    async element => {
      return await request(async () => {
        return !element.id ? await service.create(element, parentId, childId) : await service.update(element, parentId, childId);
      }, true);
    },
    [childId, parentId, request, service]
  );

  return { createOrUpdate };
}
