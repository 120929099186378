import { SKELETON_KEY, SKELETON_KEY_DATE } from './skeletonFactory';

export function skeletonReminder() {
  return {
    id: SKELETON_KEY,
    workOrderReference: SKELETON_KEY,
    description: SKELETON_KEY,
    entryDate: SKELETON_KEY_DATE,
  };
}
