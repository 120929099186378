import { apiHelper } from 'shared';
import { http } from './http';

const controllerName = 'work-orders';
class WorkOrderBudgetItemHistory {
  get(workOrderId, workOrderBudgetItemId, paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(
      `/${controllerName}/${workOrderId}/budget-items/${workOrderBudgetItemId}/history${queryString}`,
      {}
    );
  }

  async getUsingQueryFilter(workOrderId, workOrderBudgetItemId, queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });
    const response = await http.get(
      `/${controllerName}/${workOrderId}/budget-items/${workOrderBudgetItemId}/history${queryString}`,
      {}
    );
    const items = response.data && response.data.items;

    return items;
  }
}

export const workOrderBudgetItemHistory = new WorkOrderBudgetItemHistory();
