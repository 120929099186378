import { http } from './http';
import { apiHelper } from 'shared';
import { APIS } from 'app.apis.const';

class SupplierAgreementDocuments {
  get(supplierId, agreementId, paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters, level: 0 });

    return http.get(`/suppliers/${supplierId}/agreements/${agreementId}/documents/${queryString}`, {}, APIS.FINANCES);
  }

  getRequired(supplierId, agreementId) {
    return http.get(`/suppliers/${supplierId}/agreements/${agreementId}/required-documents`, {}, APIS.FINANCES);
  }

  getById(supplierId, agreementId, id) {
    return http.get(`/suppliers/${supplierId}/agreements/${agreementId}/documents/${id}`, {}, APIS.FINANCES);
  }

  create(supplierId, agreementId, document) {
    const { fileObject, selectedFile, ...cleanedRequest } = document;
    return http.post(`/suppliers/${supplierId}/agreements/${agreementId}/documents`, cleanedRequest, {}, APIS.FINANCES);
  }

  delete(supplierId, agreementId, id, multiple) {
    return http.delete(
      `/suppliers/${supplierId}/agreements/${agreementId}/documents/${id}`,
      { multiple },
      APIS.FINANCES
    );
  }

  update(supplierId, agreementId, id, document) {
    const { fileObject, selectedFile, ...cleanedRequest } = document;
    return http.put(
      `/suppliers/${supplierId}/agreements/${agreementId}/documents/${id}`,
      { ...cleanedRequest, forceUpdateValidTo: true, forceUpdateValidFrom: true },
      {},
      APIS.FINANCES
    );
  }
}

export const supplierAgreementDocuments = new SupplierAgreementDocuments();
