import { SKELETON_KEY } from './skeletonFactory';

function measure() {
  return {
    id: SKELETON_KEY,
    name: SKELETON_KEY,
    remarks: SKELETON_KEY
  };
}

export const skeletonMeasure = {
  measure
};
