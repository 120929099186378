import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { usePasswordPolicyTooltipStyles } from './passwordPolicyTooltip.styles';

export function PasswordPolicyTooltip({placement, children}) {

  const { t } = useTranslation(NAME_SPACE.USERS);
  const classes = usePasswordPolicyTooltipStyles();

  return (
    <Tooltip placement={placement} classes={{ tooltip: classes.tooltip }} arrow title={
    <>
      {t('changePassword.policyTitle')}
      <ul>
        <li>{t('changePassword.policy1')}</li>
        <li>{t('changePassword.policy2')}</li>
        <li>{t('changePassword.policy3')}</li>
        <li>{t('changePassword.policy4')}</li>
        <li>{t('changePassword.policy5')}</li>
      </ul>
    </>
    }>
      {children}
    </Tooltip>
  );
}

PasswordPolicyTooltip.propTypes = {
  children: PropTypes.object,
  placement: PropTypes.string
};
