import * as signalR from '@microsoft/signalr';
import { useEffect, useState, useCallback } from 'react';
import { authStorage } from 'auth';
import { ANONYMOUS_PATH } from 'app.routes.const';
import { authHelper } from 'shared';

function start(connection, setConnected) {
  if (connection && connection.connectionState === 'Disconnected') {
    connection
      .start()
      .then(() => {
        setConnected(true);
      })
      .catch(err => {
        console.error(err);
        setTimeout(() => start(connection, setConnected), 60000);
      });
  } else setTimeout(() => start(connection, setConnected), 60000);
}

export function useSignalR(urlConnection) {
  const [connection, setConnection] = useState(null);
  const [connected, setConnected] = useState(false);
  const [token, setToken] = useState(null);

  const disconnect = useCallback(() => {
    if (connected) {
      connection.stop();
      setConnected(false);
    }
  }, [connection, connected]);

  const connect = useCallback(() => {
    function getToken() {
      let token = authStorage.getToken();
      if (token && !authHelper.checkIfTokenIsExpired(token)) {
        setToken(token);
      } else setTimeout(() => getToken(), 5000);
    }

    getToken();
  }, []);

  useEffect(() => {
    connect();
  }, [connect]);

  useEffect(() => {
    if (token && window.location.pathname !== ANONYMOUS_PATH.RENEW) {
      const connect = new signalR.HubConnectionBuilder()
        .withUrl(urlConnection, {
          accessTokenFactory: () => {
            return authStorage.getToken();
          }
        })
        .build();

      setConnection(connect);

      start(connect, setConnected);

      return () => {
        connect.stop();
      };
    }
  }, [urlConnection, token]);

  return { connection, connected, disconnect, connect, setToken };
}
