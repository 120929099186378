import { AsyncInputAutocomplete, Form, Input } from '@engloba-tech/englobity';
import { Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { NAME_SPACE } from 'i18n';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { places } from 'services';
import { isSkeletonLoading } from 'shared';
import { useAddressFormStyles } from './addressForm.styles';

export function AddressForm({ formRef, onSubmit, entity, requiredProps, dynamicCountry }) {
  const [inputs, setInputs] = useState({ ...entity });

  const { t } = useTranslation(NAME_SPACE.ADDRESS);
  const classes = useAddressFormStyles();

  function handleSubmit() {
    onSubmit(inputs);
  }

  function handleZipCodeAutocomplete(events, element) {
    if (element) {
      setInputs(prevInputs => {
        return {
          ...prevInputs,
          city: element.city,
          cityId: element.cityId,
          zipCode: element.code,
          zipCodeId: element.id,
          district: element.district,
          districtId: element.districtId
        };
      });
    } else {
      setInputs(prevInputs => {
        return {
          ...prevInputs,
          city: null,
          cityId: null,
          zipCode: null,
          zipCodeId: null,
          district: null,
          districtId: null
        };
      });
    }
  }

  async function getSpain() {
    const spainCountry = await places.getCountriesUsingQueryFilter('España');
    setInputs(prevInputs => {
      return {
        ...prevInputs,
        country: spainCountry[0].name,
        countryId: spainCountry[0].id
      };
    });
  }

  useEffect(() => {
    if (dynamicCountry && !entity.country) {
      getSpain();
    }
  }, []);

  function handleUrbanTypeAutocomplete(events, element) {
    if (element) {
      setInputs(prevInputs => {
        return {
          ...prevInputs,
          urbanType: element.name,
          urbanTypeId: element.id
        };
      });
    }
  }

  function handleCityAutocomplete(events, element) {
    if (element) {
      setInputs(prevInputs => {
        return {
          ...prevInputs,
          city: element?.name,
          cityId: element.id,
          zipCode: null,
          zipCodeId: null,
          district: element.district,
          districtId: element.districtId
        };
      });
    } else {
      setInputs(prevInputs => {
        return {
          ...prevInputs,
          city: null,
          cityId: null,
          zipCode: null,
          zipCodeId: null,
          district: null,
          districtId: null
        };
      });
    }
  }

  const handleCountryAutocomplete = (e, element) => {
    setInputs(prevInputs => {
      if (element) {
        return {
          ...prevInputs,
          country: element.name,
          countryId: element.id,
          zipCode: null,
          zipCodeId: null,
          district: null,
          districtId: null,
          cityId: null,
          city: null
        };
      } else {
        return {
          ...prevInputs,
          country: null,
          countryId: null,
          zipCode: null,
          zipCodeId: null,
          district: null,
          districtId: null,
          cityId: null,
          city: null
        };
      }
    });
  };
  const handleInputField =
    fieldName =>
    ({ target }) =>
      setInputs(prevInputs => ({
        ...prevInputs,
        [fieldName]: target.value
      }));

  return (
    <Form className={classes.root} elementRef={formRef} onSubmit={handleSubmit} autoComplete="off">
      <Grid container spacing={3}>
        {!!inputs && (
          <>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <AsyncInputAutocomplete
                  isLoading={isSkeletonLoading(inputs.urbanType)}
                  className={classes.form}
                  icon={<SearchIcon />}
                  required
                  onChange={handleUrbanTypeAutocomplete}
                  label={t('address:urbanType')}
                  value={!inputs?.urbanType ? null : { name: inputs?.urbanType, id: inputs?.urbanTypeId }}
                  defaultInputValue={inputs?.urbanType}
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={option => option.name}
                  requestAction={places.getUrbanTypesUsingQueryFilter}
                  validators={['required']}
                  errorMessages={[t('validations.required')]}
                />
              </Grid>
              <Grid item xs={7}>
                <Input
                  isLoading={isSkeletonLoading(inputs.street)}
                  className={classes.form}
                  onChange={handleInputField('street')}
                  name={t('address:street')}
                  label={t('address:street')}
                  variant="outlined"
                  value={inputs?.street || ''}
                  inputProps={{ 'aria-label': 'street' }}
                  required
                  validators={['required']}
                  errorMessages={[t('validations.required')]}
                />
              </Grid>
              <Grid item xs={2}>
                <Input
                  isLoading={isSkeletonLoading(inputs.streetNumber)}
                  className={classes.form}
                  onChange={handleInputField('streetNumber')}
                  name={t('address:streetNumber')}
                  label={t('address:streetNumber')}
                  variant="outlined"
                  value={inputs?.streetNumber || ''}
                  inputProps={{ 'aria-label': 'streetNumber' }}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  isLoading={isSkeletonLoading(inputs.building)}
                  className={classes.form}
                  onChange={handleInputField('building')}
                  name={t('address:building')}
                  label={t('address:building')}
                  variant="outlined"
                  value={inputs?.building || ''}
                  inputProps={{ 'aria-label': 'building' }}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  isLoading={isSkeletonLoading(inputs.staircase)}
                  className={classes.form}
                  onChange={handleInputField('staircase')}
                  name={t('address:staircase')}
                  label={t('address:staircase')}
                  variant="outlined"
                  value={inputs?.staircase || ''}
                  inputProps={{ 'aria-label': 'staircase' }}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  isLoading={isSkeletonLoading(inputs.floor)}
                  className={classes.form}
                  onChange={handleInputField('floor')}
                  name={t('address:floor')}
                  label={t('address:floor')}
                  variant="outlined"
                  value={inputs?.floor || ''}
                  inputProps={{ 'aria-label': 'floor' }}
                />
              </Grid>
              <Grid item xs={dynamicCountry ? 3 : 4}>
                <Input
                  isLoading={isSkeletonLoading(inputs.doorNumber)}
                  className={classes.form}
                  onChange={handleInputField('doorNumber')}
                  name={t('address:door')}
                  label={t('address:door')}
                  variant="outlined"
                  value={inputs?.doorNumber || ''}
                  inputProps={{ 'aria-label': 'door' }}
                />
              </Grid>
              <Grid item xs={dynamicCountry ? 3 : 4}>
                <Input
                  isLoading={isSkeletonLoading(inputs.letter)}
                  className={classes.form}
                  onChange={handleInputField('letter')}
                  name={t('address:letter')}
                  label={t('address:letter')}
                  variant="outlined"
                  value={inputs?.letter || ''}
                  inputProps={{ 'aria-label': 'letter' }}
                />
              </Grid>
              <Grid item xs={dynamicCountry ? 2 : 4}>
                <Input
                  isLoading={isSkeletonLoading(inputs.km)}
                  className={classes.form}
                  onChange={handleInputField('km')}
                  name={t('address:km')}
                  label={t('address:km')}
                  variant="outlined"
                  value={inputs?.km || ''}
                  inputProps={{ 'aria-label': 'km' }}
                />
              </Grid>
              <Grid item xs={4}>
                <AsyncInputAutocomplete
                  isLoading={isSkeletonLoading(inputs.zipCode)}
                  className={classes.form}
                  icon={<SearchIcon />}
                  required={requiredProps?.some(prop => prop === 'zipCode')}
                  onChange={handleZipCodeAutocomplete}
                  label={t('address:zipCode')}
                  value={!inputs?.zipCode ? null : { code: inputs?.zipCode, id: inputs?.zipCodeId }}
                  defaultInputValue={inputs?.zipCode}
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={option => option.code}
                  requestAction={queryString =>
                    places.getZipCodesUsingQueryFilter({
                      countryId: inputs.countryId,
                      city: inputs.city,
                      code: queryString,
                      pageSize: 200
                    })
                  }
                  validators={requiredProps?.some(prop => prop === 'zipCode') && ['required']}
                  errorMessages={requiredProps?.some(prop => prop === 'zipCode') && [t('validations.required')]}
                  disabled={dynamicCountry ? !inputs.countryId : false}
                  composed
                />
              </Grid>

              <Grid item xs={4}>
                <AsyncInputAutocomplete
                  isLoading={isSkeletonLoading(inputs.city)}
                  required
                  className={classes.form}
                  icon={<SearchIcon />}
                  onChange={handleCityAutocomplete}
                  label={t('address:city')}
                  value={!inputs?.cities ? null : { city: inputs?.city, id: inputs?.cityId }}
                  defaultInputValue={inputs?.city}
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={option => option.name}
                  requestAction={queryString =>
                    places.getCitiesUsingQueryFilter({ name: inputs.city || queryString, pageSize: 200 })
                  }
                  validators={['required']}
                  errorMessages={[t('validations.required')]}
                  disabled={dynamicCountry ? !inputs.countryId : false}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  isLoading={isSkeletonLoading(inputs.district)}
                  className={classes.form}
                  onChange={handleInputField('district')}
                  name={t('address:district')}
                  label={t('address:district')}
                  variant="outlined"
                  value={inputs?.district || ''}
                  inputProps={{ 'aria-label': 'district' }}
                  disabled
                />
              </Grid>
              {dynamicCountry && (
                <Grid item xs={4}>
                  <AsyncInputAutocomplete
                    isLoading={isSkeletonLoading(inputs.city)}
                    required
                    className={classes.form}
                    icon={<SearchIcon />}
                    onChange={handleCountryAutocomplete}
                    label={t('address:country')}
                    value={!inputs?.country ? null : { name: inputs?.country, id: inputs?.countryId }}
                    defaultInputValue={inputs?.country}
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionLabel={option => option.name}
                    requestAction={query => places.getCountriesUsingQueryFilter(query, true)}
                    validators={['required']}
                    errorMessages={[t('validations.required')]}
                  />
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Form>
  );
}

AddressForm.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  onSubmit: PropTypes.func.isRequired,
  entity: PropTypes.shape({
    id: PropTypes.string,
    country: PropTypes.string,
    countryId: PropTypes.string
  }),
  requiredProps: PropTypes.array,
  dynamicCountry: PropTypes.bool
};
