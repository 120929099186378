import { http } from './http';
import { APIS } from 'app.apis.const';
import { apiHelper } from 'shared';

const controllerName = 'payment-methods';

class PaymentMethods {
  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/${controllerName}${queryString}`, {}, APIS.FINANCES);
    const items = response.data && response.data.items;

    return items;
  }

  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/${controllerName}/all/${queryString}`, {}, APIS.FINANCES);
  }

  getById(id) {
    return http.get(`/${controllerName}/${id}`, {}, APIS.FINANCES);
  }

  create(paymentMethod) {
    return http.post(`/${controllerName}`, paymentMethod, {}, APIS.FINANCES);
  }

  delete(id, multiple) {
    return http.delete(`/${controllerName}/${id}`, { multiple }, APIS.FINANCES);
  }

  update(paymentMethod) {
    return http.put(`/${controllerName}/${paymentMethod.id}`, paymentMethod, {}, APIS.FINANCES);
  }

  activate(id) {
    return http.put(`/${controllerName}/${id}/activation`, {}, {}, APIS.FINANCES);
  }

  desactivate(id) {
    return http.delete(`/${controllerName}/${id}/activation`, {}, APIS.FINANCES);
  }

  async getTermsUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/${controllerName}/terms${queryString}`, {}, APIS.FINANCES);
    const items = response.data && response.data.items;

    return items;
  }
}

export const paymentMethods = new PaymentMethods();
