import { apiHelper } from 'shared';
import { http } from './http';

const controllerName = 'contact-roles';

class ContactRoles {
  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/${controllerName}/${queryString}`);
  }

  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/${controllerName}${queryString}`);
    const items = response.data && response.data.items;

    return items;
  }

  async getWithWorkOrderScope() {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ scopes: 'WorkOrder', filterType: 'MATCH' });

    const response = await http.get(`/${controllerName}${queryString}`);
    return response.data && response.data.items;
  }

  create(contactRole) {
    return http.post(`/${controllerName}`, contactRole);
  }

  update(contactRole) {
    return http.put(`/${controllerName}/${contactRole.id}`, contactRole);
  }

  getById(id) {
    return http.get(`/${controllerName}/${id}`);
  }

  delete(id, multiple) {
    return http.delete(`/${controllerName}/${id}`, { multiple });
  }
}

export const contactRoles = new ContactRoles();
