import { http } from './http';
import { apiHelper } from 'shared';
import { APIS } from 'app.apis.const';

class ReceivedInvoiceDocuments {
  get(receivedInvoiceId, paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/received-invoices/${receivedInvoiceId}/documents/${queryString}`, {}, APIS.FINANCES);
  }

  getById(receivedInvoiceId, id) {
    return http.get(`/received-invoices/${receivedInvoiceId}/documents/${id}`, {}, APIS.FINANCES);
  }

  create(receivedInvoiceId, document) {
    const { fileObject, selectedFile, ...cleanedRequest } = document;
    return http.post(`/received-invoices/${receivedInvoiceId}/documents`, cleanedRequest, {}, APIS.FINANCES);
  }

  delete(receivedInvoiceId, id, multiple) {
    return http.delete(`/received-invoices/${receivedInvoiceId}/documents/${id}`, { multiple }, APIS.FINANCES);
  }

  update(receivedInvoiceId, id, document) {
    const { fileObject, selectedFile, ...cleanedRequest } = document;
    return http.put(`/received-invoices/${receivedInvoiceId}/documents/${id}`, cleanedRequest, {}, APIS.FINANCES);
  }
}

export const receivedInvoiceDocuments = new ReceivedInvoiceDocuments();
