import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';

export function NumberFormatCustom({ inputRef, onChange = () => {}, name, suffix = '€', decimalScale, ...other }) {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: name,
            value: values.value
          }
        });
      }}
      placeholder={`0 ${suffix}`}
      isNumericString
      allowNegative={true}
      decimalSeparator={','}
      decimalScale={decimalScale ? decimalScale : 2}
      thousandSeparator={'.'}
      suffix={suffix ? ' ' + suffix : ''}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  suffix: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  decimalScale: PropTypes.number
};
