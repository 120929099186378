import { useCallback, useEffect, useState } from 'react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { PATH, ANONYMOUS_PATH } from 'app.routes.const';
import { authStorage } from 'auth';
import { users, reminders, mobility } from 'services';
import { authHelper, bigintHelper, useHandleRequest } from 'shared';
import { useAuth } from 'react-oidc-context';

export const UserContext = React.createContext({});

function userInfoMapper(user) {
  return {
    alias: user.alias,
    avatar: user.avatar,
    exp: user.exp,
    roles: user['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'],
    name: user['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'],
    access: bigintHelper.or(user.fullAccess, user.readOnlyAccess),
    fullAccess: user.fullAccess,
    readOnlyAccess: user.readOnlyAccess,
    id: user.sub
  };
}

// TODO: tests
export function useUserContext() {
  const auth = useAuth();
  const { request, errorInfo, setErrorInfo } = useHandleRequest();
  const [user, setUser] = useState({});
  const [authToken, setAuthToken] = useState('');
  const [isLogged, setIsLogged] = useState(!!authStorage.getToken());
  const [isLoading, setIsLoading] = useState(true);
  const [externalLoginError, setExternalLoginError] = useState(null);
  const [currentPathAccess, setCurrentPathAccess] = useState(null);
  const [remindersCounter, setRemindersCounter] = useState(null);
  const [isMobilityIntegrated, setIsMobilityIntegrated] = useState(null);

  const history = useHistory();
  const clear = useCallback(() => {
    authStorage.setToken('');
    setAuthToken('');
    setUser({});
    setIsLogged(false);
    setExternalLoginError(null);
    setIsLoading(true);
    setRemindersCounter(null);
    history.push(PATH.HOME);
  }, [history]);

  const setToken = useCallback(async _userTokenData => {
    if (_userTokenData?.access_token) {
      authStorage.setToken(_userTokenData.access_token);
      setAuthToken(_userTokenData.access_token);
    }
  }, []);

  const getMobilityIntegration = useCallback(async () => {
    await request(async () => {
      const response = await mobility.isMobilityIntegrated();
      if (response) setIsMobilityIntegrated(response.data);
    }, false);
  }, [request, setIsMobilityIntegrated]);

  useEffect(() => {
    const route = history.location?.search
      ? `${history.location.pathname}${history.location.search}`
      : history.location.pathname;
    if (!isLogged && history.location.pathname !== PATH.HOME && history.location.pathname !== ANONYMOUS_PATH.CALLBACK) {
      authStorage.setPathToGo(route);
    }
    if (window.location.pathname !== ANONYMOUS_PATH.RENEW && window.location.pathname !== ANONYMOUS_PATH.CALLBACK) {
      authStorage.setPathToGo(route);
    }
  }, [authToken]);

  useEffect(() => {
    // the `return` is important - addAccessTokenExpiring() returns a cleanup function
    return auth.events.addAccessTokenExpiring(async () => {
      try {
        const silentUser = await auth.signinSilent();
        if (!silentUser?.access_token)
          throw new Error('signinSilent failed - timeout');
        
        setToken(silentUser);
      } catch (e) { console.log(e); auth.signinRedirect(); }
    });
  }, [auth, setToken]);

  useEffect(() => {
    async function login() {
      await setToken(auth.user);
      setIsLoading(false);
      setIsLogged(true);
      if (isMobilityIntegrated == null) await getMobilityIntegration();
    }

    if (window.location.pathname !== ANONYMOUS_PATH.RENEW) {
      if (auth.isAuthenticated) login();
    }
  }, [auth.isAuthenticated, auth.user, setToken, isMobilityIntegrated, getMobilityIntegration]);

  const setUserLogged = useCallback(
    async token => {
      if (token) {
        const userInfo = authHelper.claimDeserializer(token);
        if (userInfo) {
          const user = userInfoMapper(userInfo);
          const responseUser = await users.getMy();
          setUser({ ...user, ...(responseUser?.data || {}) });
          setIsLogged(true);
          setErrorInfo(null);
          setExternalLoginError(null);
        }
      } else {
        setUser({});
      }
    },
    [setErrorInfo]
  );

  useEffect(() => {
    async function userLogged() {
      await setUserLogged(authToken);
    }

    if (window.location.pathname !== ANONYMOUS_PATH.RENEW) userLogged();
  }, [authToken, setUserLogged]);

  const logout = useCallback(
    async user => {
      await request(async () => {
        auth.signoutRedirect();
        clear();
      });
    },
    [auth, request, clear]
  );

  const externalLogin = useCallback(async () => {
    const tokenResult = {
      token: new URLSearchParams(window.location.search).get('token')
    };

    if (tokenResult.token) {
      await setUserLogged(tokenResult);
      return tokenResult;
    }

    const errorResult = { message: new URLSearchParams(window.location.search).get('message') };
    if (errorResult) setExternalLoginError(errorResult);
  }, [setUserLogged]);

  const hasFullAccess = path => {
    if (!path) return !currentPathAccess ? true : Boolean(bigintHelper.and(user.fullAccess, currentPathAccess));
    return path && currentPathAccess ? Boolean(bigintHelper.and(user.fullAccess, path || currentPathAccess)) : true;
  };

  const getRemindersCount = useCallback(async () => {
    await request(async () => {
      const response = await reminders.getCountPending();
      if (response) {
        setRemindersCounter(response.data.total || 0);
      }
    }, false);
  }, [request, setRemindersCounter]);

  return {
    user,
    authToken,
    isLogged,
    isLoading,
    setUser,
    setAuthToken,
    setIsLogged,
    logout,
    externalLogin,
    errorInfo,
    externalLoginError,
    setErrorInfo,
    setCurrentPathAccess,
    currentPathAccess,
    hasFullAccess,
    remindersCounter,
    getRemindersCount,
    isMobilityIntegrated
  };
}
