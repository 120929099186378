import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { UserContext, useUserContext } from 'auth';
import { LoadingProvider } from 'loading';
import React from 'react';
import { http } from 'services/http';
import { KEY_SETTINGS, settings } from 'settings';
import {
  SignalRCMMSContext,
  SignalRNotificationsContext,
  useSignalRCMMSContext,
  useSignalRNotificationsContext
} from 'signalR';
import { UserStateProvider } from 'state/useUserStateContext';
import { AnonymousRoutes, Routes, SwitchRoutes } from './App.routes';

function App() {
  const userContext = useUserContext();
  const signalRCMMSContext = useSignalRCMMSContext(settings.getSetting(KEY_SETTINGS.SIGNALR_URL));
  const signalRNotificationsContext = useSignalRNotificationsContext(
    settings.getSetting(KEY_SETTINGS.SIGNALR_NOTIFICATIONS_URL)
  );

  http.setOnUnauthorized(() => userContext.setIsLogged(false));

  return (
    <SignalRCMMSContext.Provider value={signalRCMMSContext}>
      <SignalRNotificationsContext.Provider value={signalRNotificationsContext}>
        <UserContext.Provider value={userContext}>
          <UserStateProvider>
            <LoadingProvider>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <>
                  <AnonymousRoutes />
                  <Routes />
                  <SwitchRoutes />
                </>
              </MuiPickersUtilsProvider>
            </LoadingProvider>
          </UserStateProvider>
        </UserContext.Provider>
      </SignalRNotificationsContext.Provider>
    </SignalRCMMSContext.Provider>
  );
}

export default App;
