import { IconButton, TextField, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NAME_SPACE } from 'i18n';
import { AddressForm, DialogModal, SKELETON_KEY, addressHelper } from 'shared';

import { Skeleton } from '@material-ui/lab';
import { useFullAddressInputStyles } from './fullAddressInput.styles';

export function FullAddressInput({
  onFilled,
  entity,
  isLoading,
  required,
  title,
  requiredProps,
  dynamicCountry = false,
  disabled
}) {
  const { t } = useTranslation(NAME_SPACE.ADDRESS);
  const classes = useFullAddressInputStyles();
  const $formAddressModalRef = useRef(null);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [fullAddress, setFullAddress] = useState(SKELETON_KEY);

  useEffect(() => {
    setFullAddress(addressHelper.getFullAddress(entity, t));
  }, [entity, t]);

  async function handleSupplierAddressSubmit(changedInputs) {
    const formIsValid = await $formAddressModalRef.current.isFormValid();
    if (formIsValid) {
      setFullAddress(addressHelper.getFullAddress(changedInputs, t));
      setShowAddressModal(false);
      onFilled(changedInputs);
    }
  }

  function handleAddressModalAccept() {
    $formAddressModalRef.current.submit();
  }

  function handleClearFields() {
    setShowAddressModal(false);
    onFilled({
      ...entity,
      urbanTypeId: '',
      urbanType: '',
      street: '',
      streetNumber: '',
      building: '',
      staircase: '',
      floor: '',
      doorNumber: '',
      letter: '',
      km: '',
      zipCode: '',
      cityId: '',
      city: '',
      district: ''
    });
  }

  return (
    <>
      {isLoading ? (
        <Skeleton height={48} />
      ) : (
        <div className={classes.fullAddressContainer}>
          <TextField
            name={t('address:fiscalAddress')}
            label={title || t('address:fiscalAddress')}
            variant="outlined"
            required={required}
            value={fullAddress}
            onClick={() => setShowAddressModal(true)}
            inputProps={{ 'aria-label': t('address:fiscalAddress') }}
            validators={required && ['required']}
            errorMessages={required && [t('validations.required')]}
            disabled={disabled}
          />
          {!disabled && (
            <Tooltip placement="bottom" title={t('common:actions.remove')}>
              <IconButton className={classes.deleteIcon} onClick={handleClearFields}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      )}
      {showAddressModal && (
        <DialogModal
          title={title || t('address:fiscalAddress')}
          description=""
          isOpen={showAddressModal}
          onClose={() => setShowAddressModal(false)}
          className={classes.modal}
          buttons={[
            {
              children: <CloseIcon />,
              text: t('common:actions.close'),
              type: 'secondary',
              onClick: () => setShowAddressModal(false)
            },
            {
              children: <DoneIcon />,
              text: t('common:actions.accept'),
              type: 'primary',
              onClick: handleAddressModalAccept
            }
          ]}
        >
          <AddressForm
            formRef={$formAddressModalRef}
            onSubmit={handleSupplierAddressSubmit}
            entity={{ ...entity }}
            requiredProps={requiredProps}
            dynamicCountry={dynamicCountry}
          />
        </DialogModal>
      )}
    </>
  );
}

FullAddressInput.propTypes = {
  onFilled: PropTypes.func.isRequired,
  entity: PropTypes.shape({
    id: PropTypes.string,
    fullAddress: PropTypes.string
  }),
  isLoading: PropTypes.bool,
  required: PropTypes.bool,
  requiredProps: PropTypes.array,
  title: PropTypes.string,
  dynamicCountry: PropTypes.bool,
  disabled: PropTypes.bool
};
