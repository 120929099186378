import { http } from './http';
import { apiHelper } from 'shared';
import { APIS } from 'app.apis.const';

const controllerName = 'suppliers';
const partialName = 'garnishments';

class SupplierGarnishments {
  get(supplierId, paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/${controllerName}/${supplierId}/${partialName}/${queryString}`, {}, APIS.FINANCES);
  }

  getById(supplierId, id) {
    return http.get(`/${controllerName}/${supplierId}/${partialName}/${id}`, {}, APIS.FINANCES);
  }

  create(garnishment, supplierId) {
    const { fileObject, selectedFile, ...cleanedRequest } = garnishment;
    return http.post(`/${controllerName}/${supplierId}/${partialName}`, cleanedRequest, {}, APIS.FINANCES);
  }

  delete(supplierId, id, multiple) {
    return http.delete(`/${controllerName}/${supplierId}/${partialName}/${id}`, { multiple }, APIS.FINANCES);
  }

  update(garnishment, supplierId) {
    const { fileObject, selectedFile, ...cleanedRequest } = garnishment;
    return http.put(
      `/${controllerName}/${supplierId}/${partialName}/${garnishment.id}`,
      cleanedRequest,
      {},
      APIS.FINANCES
    );
  }
}

export const supplierGarnishments = new SupplierGarnishments();
