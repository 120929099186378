import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { useAdvancedFiltersRendererStyles } from './advancedFiltersRenderer.styles';
import { Chip, Grid } from '@material-ui/core';
import { objectHelper } from 'shared/helper';

export function AdvancedFiltersRenderer({ onDeleteFilter, translationKey, getActiveFilters }) {
  const { t } = useTranslation(NAME_SPACE.COMMON);
  const classes = useAdvancedFiltersRendererStyles();

  const activeFilters = getActiveFilters ? getActiveFilters() : null;

  const renderFilterChip = (filter, objPropertyName, index = null) => {
    return (
      <Chip
        onDelete={() => onDeleteFilter(objPropertyName, index)}
        className={classes.advancedFiltersChip}
        key={`filter${objPropertyName}${index}`}
        label={`${t(`${translationKey}.${objPropertyName}`) + ': ' + filter.chipLabel}`}
        style={{ marginRight: '1rem', marginBottom: '1rem' }}
      />
    );
  };

  return (
    activeFilters &&
    !objectHelper.isEmpty(activeFilters) && (
      <Grid item xs={12} className={classes.chipsContainer}>
        {Object.keys(activeFilters).map((objPropertyName, i) => {
          const filter = activeFilters[objPropertyName];
          const isFilterArray = Array.isArray(filter);
          return !isFilterArray
            ? renderFilterChip(filter, objPropertyName)
            : filter.map((filter, index) => {
                return renderFilterChip(filter, objPropertyName, index);
              });
        })}
      </Grid>
    )
  );
}

AdvancedFiltersRenderer.propTypes = {
  onDeleteFilter: PropTypes.func.isRequired,
  translationKey: PropTypes.string.isRequired,
  getActiveFilters: PropTypes.func
};
