import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { Button } from '../Button';
import PublishIcon from '@material-ui/icons/Publish';
import { UserContext } from 'auth';

export function CmmsButtonImport({ disabled, text, onClick, ...props }) {
  const { t } = useTranslation(NAME_SPACE.COMMON);
  const { hasFullAccess } = useContext(UserContext);
  return (
    hasFullAccess() && (
      <Button
        disabled={disabled}
        text={text || t('actions.import')}
        onClick={onClick}
        requiresAccess
        icon={<PublishIcon />}
        {...props}
      />
    )
  );
}

CmmsButtonImport.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  text: PropTypes.string
};
