import { SKELETON_KEY } from './skeletonFactory';

export function skeletonEquivalence() {
  return {
    id: SKELETON_KEY,
    platformEntity: SKELETON_KEY,
    platform: SKELETON_KEY,
    internalValue: SKELETON_KEY,
    platformValue: SKELETON_KEY
  };
}
