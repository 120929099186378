import { http } from './http';
import { apiHelper } from 'shared';
import { APIS } from 'app.apis.const';
import { objectHelper } from 'shared';

const controllerNameTags = 'gallery-tags';
const controllerNameImages = 'gallery-images';
class Gallery {
  cachedThumbnails = [];

  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/${controllerNameTags}${queryString}`, {}, APIS.DOCUMENTARY);
    const items = response.data && response.data.items;

    return items;
  }

  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/${controllerNameTags}/${queryString}`, {}, APIS.DOCUMENTARY);
  }

  getById(id) {
    return http.get(`/${controllerNameTags}/${id}`, {}, APIS.DOCUMENTARY);
  }

  create(galleryTag) {
    return http.post(`/${controllerNameTags}`, galleryTag, {}, APIS.DOCUMENTARY);
  }

  delete(id, multiple) {
    return http.delete(`/${controllerNameTags}/${id}`, { multiple }, APIS.DOCUMENTARY);
  }

  update(galleryTag) {
    return http.put(`/${controllerNameTags}/${galleryTag.id}`, galleryTag, {}, APIS.DOCUMENTARY);
  }

  getThumbnail(imageId) {
    return http.get(`/${controllerNameImages}/${imageId}/thumbnail`, {}, APIS.DOCUMENTARY);
  }

  async getCachedThumbnail(id) {
    const askedThumbnail = () => this.cachedThumbnails.find(cachedThumbnail => cachedThumbnail.id === id);

    if (!this.cachedThumbnails.length || !askedThumbnail()) {
      const response = await http.get(`/${controllerNameImages}/${id}/thumbnail`, {}, APIS.DOCUMENTARY);
      const thumbnail = response && response.data;
      if (thumbnail) {
        this.cachedThumbnails = [...this.cachedThumbnails, { id, thumbnail }];
      }
    }

    return objectHelper.deepClone(askedThumbnail().thumbnail);
  }
}

export const gallery = new Gallery();
