import { APIS } from 'app.apis.const';
import { apiHelper } from 'shared';
import { http } from './http';

const controllerName = 'business-lines';
class BusinessLines {
  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/${controllerName}${queryString}`, {}, APIS.FINANCES);
    const items = response.data && response.data.items;

    return items;
  }

  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/${controllerName}/all/${queryString}`, {}, APIS.FINANCES);
  }

  async getMyUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/${controllerName}/my${queryString}`, {}, APIS.CMMS);
    const items = response.data && response.data.items;

    return items;
  }

  getMyBusinessLines(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/${controllerName}/my/${queryString}`, {}, APIS.CMMS);
  }

  getById(id) {
    return http.get(`/${controllerName}/${id}`, {}, APIS.FINANCES);
  }

  create(businessLine) {
    return http.post(`/${controllerName}`, businessLine, {}, APIS.FINANCES);
  }

  delete(id, multiple) {
    return http.delete(`/${controllerName}/${id}`, { multiple }, APIS.FINANCES);
  }

  update(id, businessLine) {
    return http.put(`/${controllerName}/${id}`, businessLine, {}, APIS.FINANCES);
  }

  activate(id) {
    return http.put(`/${controllerName}/${id}/activation`, {}, {}, APIS.FINANCES);
  }
  desactivate(id) {
    return http.delete(`/${controllerName}/${id}/activation`, {}, APIS.FINANCES);
  }
}

export const businessLines = new BusinessLines();
