import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { React, createContext, useContext, useState } from 'react';
import { useLoadingStyles } from './useLoading.styles';

const LoadingContext = createContext({
  loading: false,
  setLoading: null
});

export function LoadingProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const classes = useLoadingStyles();

  const activateLoading = () => {
    setLoading(true);
    let body = document.body;
    body.classList.add(classes.overflowHidden);
  };

  const deactivateLoading = () => {
    setLoading(false);
    let body = document.body;
    body.classList.remove(classes.overflowHidden);
  };

  const handleLoading = async action => {
    try {
      if (action) {
        activateLoading();
        await action();
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setTimeout(() => {
        deactivateLoading();
      }, 300);
    }
  };

  let body = document.body;
  body.classList.add('MyClass');

  const value = { loading, handleLoading, activateLoading, deactivateLoading };
  return (
    <LoadingContext.Provider value={value}>
      {loading && (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      )}
      {children}
    </LoadingContext.Provider>
  );
}

LoadingProvider.propTypes = {
  children: PropTypes.node
};

export function useLoading() {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error('useLoading must be used within LoadingProvider');
  }
  return context;
}
