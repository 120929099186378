import { SKELETON_KEY_DATE } from 'shared';
import { SKELETON_KEY } from './skeletonFactory';

function dayOff() {
  return { name: SKELETON_KEY, date: SKELETON_KEY_DATE };
}

export function skeletonCalendar() {
  return {
    id: SKELETON_KEY,
    name: SKELETON_KEY,
    monday: SKELETON_KEY,
    tuesday: SKELETON_KEY,
    wednesday: SKELETON_KEY,
    thursday: SKELETON_KEY,
    friday: SKELETON_KEY,
    saturday: SKELETON_KEY,
    sunday: SKELETON_KEY,
    daysOff: Array(10).fill(dayOff())
  };
}
