import { SKELETON_KEY } from './skeletonFactory';

function skeletonVisibility() {
  return {
    id: SKELETON_KEY,
    name: SKELETON_KEY
  };
}

function _skeletonProcessDefinition() {
  return {
    id: SKELETON_KEY,
    name: SKELETON_KEY,
    bpmn: SKELETON_KEY,
    processVisibility: SKELETON_KEY
  };
}

export const skeletonProcessDefinition = {
  processVisibility: skeletonVisibility,
  processDefinition: _skeletonProcessDefinition
};
