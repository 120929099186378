import { ButtonAdd } from '@engloba-tech/englobity';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from 'auth';
import { NAME_SPACE } from 'i18n';

export function CmmsButtonAdd({ disabled, condition, tooltip, text, onClick, requiredAccess, ...props }) {
  const { t } = useTranslation(NAME_SPACE.COMMON);
  const { hasFullAccess } = useContext(UserContext);

  const renderButton = () => {
    return (
      <ButtonAdd
        disabled={disabled}
        text={text || t('actions.add')}
        tooltip={{
          title: (disabled && tooltip?.disabled) || '',
          placement: 'top'
        }}
        onClick={onClick}
        {...props}
      />
    );
  };

  return requiredAccess ? hasFullAccess(requiredAccess) && renderButton() : hasFullAccess() && renderButton();
}

CmmsButtonAdd.propTypes = {
  disabled: PropTypes.bool,
  unsaved: PropTypes.bool,
  text: PropTypes.string,
  onClick: PropTypes.func,
  requiredAccess: PropTypes.bool,
  disableUnsavedFeature: PropTypes.bool
};
