import { useMemo } from 'react';

export const useBooleanElements = t => {
  return useMemo(() => {
    const TEXTS = {
      true: 'true',
      false: 'false'
    };

    let booleanMapped = Object.keys(TEXTS).map(key => {
      return {
        value: key,
        name: t(TEXTS[key])
      };
    });

    const booleanElements = [{ value: -1, name: t('common:selectFirstOptionComboFilter') }, ...booleanMapped];
    return { booleanElements };
  }, [t]);
};
