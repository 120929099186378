import { makeStyles } from '@material-ui/core/styles';

export const useLoadingStyles = makeStyles(theme => ({
  loader: {
    position: 'fixed',
    width: '100%',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 9999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: '0.4s all ease',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  overflowHidden: {
    overflow: 'hidden'
  }
}));
