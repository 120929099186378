export const APIS = {
  CMMS: 'CMMS',
  FINANCES: 'FINANCES',
  PLACES: 'PLACES',
  AZURE_STORAGE: 'AZURE_STORAGE',
  NOTIFICATIONS: 'NOTIFICATIONS',
  REAL_ESTATES: 'REAL_ESTATES',
  DOCUMENTARY: 'DOCUMENTARY',
  IDENTITY: 'IDENTITY'
};
