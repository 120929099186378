import { http } from './http';
import { apiHelper } from 'shared';

class ItemRates {
  get(rateId, paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/rates/${rateId}/item-rates${queryString}`);
  }

  getById(id, rateId) {
    return http.get(`/rates/${rateId}/item-rates/${id}`);
  }

  create(itemRate, rateId) {
    return http.post(`/rates/${rateId}/item-rates`, itemRate);
  }

  delete(rateId, id, multiple) {
    return http.delete(`/rates/${rateId}/item-rates/${id}`, { multiple });
  }

  update(id, rateId, itemRate) {
    return http.put(`/rates/${rateId}/item-rates/${id}`, itemRate);
  }

  async getItemRatesByProjectUsingQueryFilter(projectId, queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/projects/${projectId}/item-rates${queryString}`);
    const items = response.data && response.data.items;
    return items.map(item => {
      return { ...item, fullName: `${item.name}${item.description ? ' | ' + item.description : ''}` };
    });
  }

  async getByRateAndQueryString(rateId, queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/rates/${rateId}/item-rates${queryString}`);
    const items = response.data && response.data.items;
    return items.map(item => {
      return { ...item, fullName: `${item.reference}${item.description ? ' | ' + item.description : ''}` };
    });
  }
}

export const itemRates = new ItemRates();
