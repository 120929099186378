import { http } from './http';
import { APIS } from 'app.apis.const';
import { apiHelper } from 'shared';

const controllerName = 'companies';

class Companies {

  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/${controllerName}${queryString}`, {}, APIS.FINANCES);
    const items = response.data && response.data.items;

    return items;
  }

  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/${controllerName}/all/${queryString}`, {}, APIS.FINANCES);
  }

  getById(id) {
    return http.get(`/${controllerName}/${id}`, {}, APIS.FINANCES);
  }

  create(company) {
    return http.post(`/${controllerName}`, company, {}, APIS.FINANCES);
  }

  delete(id, multiple) {
    return http.delete(`/${controllerName}/${id}`, { multiple }, APIS.FINANCES);
  }

  update(id, company) {
    return http.put(`/${controllerName}/${id}`, company, {}, APIS.FINANCES);
  }

  activate(id) {
    return http.put(`/${controllerName}/${id}/activation`, {}, {}, APIS.FINANCES);
  }

  desactivate(id) {
    return http.delete(`/${controllerName}/${id}/activation`, {}, APIS.FINANCES);
  }
}

export const companies = new Companies();
