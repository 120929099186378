import { http } from './http';
import { APIS } from 'app.apis.const';
import { apiHelper } from 'shared';

const controllerName = 'insurance-companies';

class InsuranceCompanies {
  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ reference: queryFilter });
    const response = await http.get(`/${controllerName}/${queryString}`, {}, APIS.FINANCES);
    const items = response.data && response.data.items;

    return items;
  }

  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/${controllerName}/${queryString}`, {}, APIS.FINANCES);
  }

  getById(id) {
    return http.get(`/${controllerName}/${id}`, {}, APIS.FINANCES);
  }

  create(insuranceCompany) {
    return http.post(`/${controllerName}`, insuranceCompany, {}, APIS.FINANCES);
  }

  delete(id, multiple) {
    return http.delete(`/${controllerName}/${id}`, { multiple }, APIS.FINANCES);
  }

  update(id, insuranceCompany) {
    return http.put(`/${controllerName}/${id}`, insuranceCompany, {}, APIS.FINANCES);
  }
}

export const insuranceCompanies = new InsuranceCompanies();
