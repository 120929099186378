import { SKELETON_KEY, SKELETON_KEY_NUMBER } from './skeletonFactory';

export function skeletonCustomer() {
  return {
    id: SKELETON_KEY,
    fullName: SKELETON_KEY,
    name: SKELETON_KEY,
    firstSurname: SKELETON_KEY,
    secondSurname: SKELETON_KEY,
    customerGroup: SKELETON_KEY,
    documentIdType: SKELETON_KEY,
    documentIdValue: SKELETON_KEY,
    customerGroups: [SKELETON_KEY, SKELETON_KEY]
  };
}

export function skeletonCustomerBudgetItem() {
  return {
    id: SKELETON_KEY,
    workOrderReference: SKELETON_KEY,
    workOrderStatus: SKELETON_KEY_NUMBER,
    assetReference: SKELETON_KEY,
    itemReference: SKELETON_KEY,
    itemDescription: SKELETON_KEY,
    quantity: SKELETON_KEY_NUMBER,
    unitSellingPrice: SKELETON_KEY_NUMBER
  };
}
