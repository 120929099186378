import { apiHelper } from 'shared';
import { http } from './http';

const controllerName = 'appointment-types';
const cmmsControllerName = 'agenda-appointment-types';

class AppointmentTypes {
  async getUsingQueryFilter(queryFilter, groupReference) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter, groupReference });

    const response = await http.get(`/${cmmsControllerName}${queryString}`, {});
    const items = response.data && response.data.items;

    return items;
  }

  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/${cmmsControllerName}${queryString}`, {});
  }

  getById(id) {
    return http.get(`/${cmmsControllerName}/${id}`, {});
  }

  create(appointmentType) {
    return http.post(`/${controllerName}`, appointmentType, {});
  }

  update(appointmentType) {
    return http.put(`/${controllerName}/${appointmentType.id}`, {...appointmentType, forceUpdateTaskDefinition: true}, {});
  }
}

export const appointmentTypes = new AppointmentTypes();
