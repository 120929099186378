import { http } from './http';
import { apiHelper } from 'shared';
import FileSaver from 'file-saver';

class Rates {
  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/rates${queryString}`);
    const items = response.data && response.data.items;

    return items;
  }

  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/rates/${queryString}`);
  }

  getById(id) {
    return http.get(`/rates/${id}`);
  }

  create(rate) {
    return http.post('/rates', rate);
  }

  delete(id, multiple) {
    return http.delete(`/rates/${id}`, { multiple });
  }

  update(id, rate) {
    return http.put(`/rates/${id}`, {...rate, forceUpdateValidTo: true});
  }

  importItemRates(id, blobUrl) {
    const obj = { blobUrl: blobUrl };
    return http.post(`/rates/${id}/import-item-rates`, obj);
  }

  importSupplierItemRates(id, parameters) {
    return http.post(`/rates/${id}/import-supplier-item-rates`, parameters);
  }

  async downloadImportItemRatesTemplate() {
    const headersT = await http.getBearerToken();
    const api_url = localStorage.getItem('API_URL');
    const options = {
      headers: headersT.headers,
      method: 'GET',
      responseType: 'blob'
    };

    const url = `${api_url}/api/v1.0/rates/import-item-rates/template`;
    const response = await fetch(url, options);

    if (response) {
      const miBlob = await response.blob().catch(e => {
        throw e;
      });
      FileSaver.saveAs(miBlob, 'import_item_rates_template.xlsx');
    }
  }
}

export const rates = new Rates();
