import { http } from './http';
import { apiHelper } from 'shared';

const controllerName = 'process-executions';

class ProcessExecutions {
  getEvents(processExecutionId, milestoneId) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ milestoneId });

    return http.get(
      `/${controllerName}/${processExecutionId}/events${queryString}`,
      {},
      { disableErrorToasters: true }
    );
  }

  sendEvent(processExecutionId, message, processInstanceId, eventName, milestoneId) {
    return http.post(`/${controllerName}/${processExecutionId}/events`, {
      processInstanceId,
      eventName,
      message,
      milestoneId
    });
  }

  getBPMNFormData(processExecutionId, milestoneId, processInstanceId, executionId, taskId) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ processInstanceId, executionId, taskId });

    return http.get(
      `/${controllerName}/${processExecutionId}/milestone/${milestoneId}/form-data${queryString}`,
      {},
      {
        disableErrorToasters: true
      }
    );
  }

  updateBPMNFormData(processExecutionId, milestoneId, formData) {
    return http.put(`/${controllerName}/${processExecutionId}/milestone/${milestoneId}/form-data`, formData, {
      disableErrorToasters: true
    });
  }

  updateBPMNFormDataComplete(processExecutionId, milestoneId, formData) {
    return http.put(`/${controllerName}/${processExecutionId}/milestone/${milestoneId}/form-data/complete`, formData, {
      disableErrorToasters: true
    });
  }

  getBPMN(entityId, entityType, milestoneId, processInstanceId) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ entityId, entityType, milestoneId, processInstanceId });
    const config = {
      disableErrorToasters: true
    };
    return http.get(`/${controllerName}/bpmn${queryString}`, config);
  }
}

export const processExecutions = new ProcessExecutions();
