import { apiHelper } from 'shared';
import { http } from './http';

class BudgetItems {
  get(workOrderId, paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/work-orders/${workOrderId}/budget-items/${queryString}`);
  }

  getById(workOrderId, id) {
    return http.get(`/work-orders/${workOrderId}/budget-items/${id}`);
  }

  create(budgetItem, workOrderId) {
    return http.post(`/work-orders/${workOrderId}/budget-items`, budgetItem);
  }

  delete(workOrderId, id, multiple) {
    return http.delete(`/work-orders/${workOrderId}/budget-items/${id}`, { multiple });
  }

  update(budgetItem, workOrderId) {
    return http.put(`/work-orders/${workOrderId}/budget-items/${budgetItem.id}`, {
      ...budgetItem,
      forceUpdateStartDate: true,
      forceUpdateEndDate: true,
      forceUpdateExpectedStartDate: true,
      forceUpdateExpectedEndDate: true,
      forceUpdateSupplier: true,
      forceUpdateItemSubspeciality: true,
      forceUpdateAggrupation: true
    });
  }

  multipleUpdate(budgetItem, workOrderId) {
    return http.put(`/work-orders/${workOrderId}/budget-items/multiple`, budgetItem);
  }

  async getSuppliersFromWorkOrder(workOrderId, queryFilter) {
    const queryString = apiHelper.composeQueryString({ name: queryFilter });
    const response = await http.get(`/work-orders/${workOrderId}/budget-items/suppliers${queryString}`);
    const items = response.data && response.data.items;
    return items;
  }

  async getSuppliersFromProjectAndDistrict(
    workOrderId,
    districtId,
    itemId,
    queryFilter,
    bypassAgreements,
    specialityIds,
    averageRatingStart,
    averageRatingEnd
  ) {
    const queryString = apiHelper.composeQueryString({
      name: queryFilter,
      districtId,
      itemId,
      bypassAgreements,
      specialityIds,
      averageRatingStart,
      averageRatingEnd
    });

    const response = await http.get(`/work-orders/${workOrderId}/budget-items/suppliers/available${queryString}`);
    const items = response.data && response.data.items;
    return items;
  }

  async getBudgetItemsQueryString(workOrderId, paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });
    const response = await http.get(`/work-orders/${workOrderId}/budget-items/${queryString}`);
    const items = response.data && response.data.items;
    return items;
  }

  async getDistinctAggrupations(workOrderId) {
    const response = await http.get(`/work-orders/${workOrderId}/budget-items/aggrupations`);
    return response.data;
  }
}

export const budgetItems = new BudgetItems();
