import { http } from './http';
import { APIS } from 'app.apis.const';
import { apiHelper } from 'shared';

const controllerName = 'process-roles';
class ProcessRoles {
  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/${controllerName}${queryString}`, {}, APIS.CMMS);
    const items = response.data && response.data.items;

    return items;
  }

  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/${controllerName}/all/${queryString}`, {}, APIS.CMMS);
  }

  getById(id) {
    return http.get(`/${controllerName}/${id}`, {}, APIS.CMMS);
  }

  create(businessLine) {
    return http.post(`/${controllerName}`, businessLine, {}, APIS.CMMS);
  }

  activate(id) {
    return http.put(`/${controllerName}/${id}/activation`, {}, {}, APIS.CMMS);
  }

  desactivate(id) {
    return http.delete(`/${controllerName}/${id}/activation`, {}, APIS.CMMS);
  }
}

export const processRoles = new ProcessRoles();
