import { SKELETON_KEY } from './skeletonFactory';

function documentTypes() {
  return { documentTypeId: SKELETON_KEY, documentType: SKELETON_KEY, level: SKELETON_KEY };
}

export function skeletonDocumentGroup() {
  return {
    id: SKELETON_KEY,
    name: SKELETON_KEY,
    documentTypes: Array(10).fill(documentTypes())
  };
}
