import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Select } from '@engloba-tech/englobity';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { isSkeletonLoading } from 'shared';
import { ALERT_TYPES } from 'pages/shared';

export function AlertTypesSelect({ alertTypes, value, withSelectOption, ...props }) {
  const { t } = useTranslation(NAME_SPACE.ALERTS);

  const elements = useMemo(() => {
    return [
      withSelectOption && { value: null, name: t('common:options.choose') },
      ...alertTypes?.map(alertType => {
        return {
          value: alertType.id,
          name: t(`types.${ALERT_TYPES[alertType.id]}`)
        };
      })
    ];
  }, [alertTypes, t, withSelectOption]);

  return (
    <Select
      isLoading={isSkeletonLoading(alertTypes[0]) || isSkeletonLoading(value)}
      {...props}
      value={value}
      displayEmpty
      title={t('properties.type')}
      elements={elements}
    />
  );
}

AlertTypesSelect.propTypes = {
  value: PropTypes.number,
  alertTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  withSelectOption: PropTypes.bool
};
