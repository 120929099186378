const WORKORDER_HISTORY_ENTITIES = {
  0: 'workOrders:singularName',
  1: 'workOrders:budgetItems.singularName',
  2: 'workOrders:documents.singularName',
  3: 'workOrders:events.singularName',
  4: 'workOrders:statuses.singularName',
  5: 'workOrders:milestones.singularName'
};

const ISSUED_INVOICE_HISTORY_ENTITIES = {
  0: 'issuedInvoices:singularName',
  2: 'issuedInvoices:documents.singularName',
  3: 'workOrders:events.singularName',
  4: 'workOrders:statuses.singularName',
  5: 'workOrders:milestones.singularName'
};

const RECEIVED_INVOICE_HISTORY_ENTITIES = {
  0: 'receivedInvoices:singularName',
  2: 'receivedInvoices:documents.singularName',
  3: 'workOrders:events.singularName',
  4: 'workOrders:statuses.singularName',
  5: 'workOrders:milestones.singularName'
};

export const HISTORY_ENTITIES = {
  0: WORKORDER_HISTORY_ENTITIES,
  1: RECEIVED_INVOICE_HISTORY_ENTITIES,
  2: ISSUED_INVOICE_HISTORY_ENTITIES
};
