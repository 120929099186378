import { makeStyles } from '@material-ui/core/styles';
import { color } from 'styles/color.styles';

export const useFolderStyles = makeStyles(theme => ({
  main: {
    cursor: 'pointer',
    padding: '15px 5px 5px 5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '135px',
    borderRadius: '4px',
    width: '80px'
  },
  folderName: {
    fontSize: '11px',
    margin: 0,
    paddingTop: '2px',
    width: '60px',
    textAlign: 'center',
    wordWrap: 'break-word',
    hyphens: 'auto',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    lineClamp: 3,
    WebkitLineClamp: 3,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical'
  },
  checked: {
    backgroundColor: '#f6f7fe',
    border: `1px solid ${color.primary.main}`,
  },
}));
