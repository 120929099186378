import { http } from './http';
import { apiHelper } from 'shared';
import { APIS } from 'app.apis.const';

class IssuedInvoiceDocuments {
  get(issuedInvoiceId, paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/issued-invoices/${issuedInvoiceId}/documents/${queryString}`, {}, APIS.FINANCES);
  }

  getById(issuedInvoiceId, id) {
    return http.get(`/issued-invoices/${issuedInvoiceId}/documents/${id}`, {}, APIS.FINANCES);
  }

  create(issuedInvoiceId, document) {
    const { fileObject, selectedFile, ...cleanedRequest } = document;
    return http.post(`/issued-invoices/${issuedInvoiceId}/documents`, cleanedRequest, {}, APIS.FINANCES);
  }

  delete(issuedInvoiceId, id, multiple) {
    return http.delete(`/issued-invoices/${issuedInvoiceId}/documents/${id}`, { multiple }, APIS.FINANCES);
  }

  update(issuedInvoiceId, id, document) {
    const { fileObject, selectedFile, ...cleanedRequest } = document;
    return http.put(`/issued-invoices/${issuedInvoiceId}/documents/${id}`, cleanedRequest, {}, APIS.FINANCES);
  }
}

export const issuedInvoiceDocuments = new IssuedInvoiceDocuments();
