import { SKELETON_KEY_DATE } from 'shared';
import { SKELETON_KEY } from './skeletonFactory';

export function skeletonMetadata() {
  return {
    id: SKELETON_KEY,
    key: SKELETON_KEY,
    value: SKELETON_KEY
  };
}

export function skeletonExecution() {
  return {
    id: SKELETON_KEY,
    creationDate: SKELETON_KEY,
    isSuccessful: SKELETON_KEY,
    log: SKELETON_KEY
  };
}

export function skeletonIntegration() {
  return {
    id: SKELETON_KEY,
    name: SKELETON_KEY,
    functionName: SKELETON_KEY,
    execution: SKELETON_KEY,
    connectionString: SKELETON_KEY,
    lastExecutionDate: SKELETON_KEY_DATE,
    metadata: Array(10).fill(skeletonMetadata())
  };
}
