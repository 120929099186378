import { errorToaster, successToaster, useHandleOpen, warningToaster } from '@engloba-tech/englobity';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRequestParams } from 'shared';
import { useHandleRequest } from './useHandleRequest';
import { useStateWithBackup } from './useStateWithBackup';

export function useViewTableData({
  service,
  paginatedSkeleton = {},
  parentId,
  childId,
  mainKey = 'name',
  secondaryKey
}) {
  const { t } = useTranslation();
  const [data, setData, undoData] = useStateWithBackup(paginatedSkeleton); // should be paginated
  const { request, errorInfo, setErrorInfo } = useHandleRequest();
  const { paging, sorting, filteredCells, advancedFilters, currentRequestParams, setRequestParams } =
    useRequestParams();
  const [selectedItem, setSelectedItem] = useState(null);
  const { isOpen, handleClose, handleOpen } = useHandleOpen(false);

  const keepAdding = useRef(false);
  const [successMessage, setSuccessMessage] = useState(null);

  const get = useCallback(
    async ({ paging, sorting, filteredCells, advancedFilters }) => {
      await request(async () => {
        setRequestParams(paging, sorting, filteredCells, advancedFilters);
        const response = parentId
          ? childId
            ? await service.get(parentId, childId, paging, sorting, filteredCells, advancedFilters)
            : await service.get(parentId, paging, sorting, filteredCells, advancedFilters)
          : await service.get(paging, sorting, filteredCells, advancedFilters);
        if (response) {
          setData(response.data || []);
        }
      }, false);
    },
    [request, setRequestParams, parentId, childId, service, setData]
  );

  const exportElements = useCallback(
    async (headCells, fileName, advancedFilters) => {
      await request(async () => {
        const response = await service.export(sorting, filteredCells, advancedFilters);
        if (response.data) {
          generateAndDownloadCSV(headCells, response.data.items, fileName);
        }
      }, false);
    },
    [filteredCells, request, service, sorting]
  );

  const deleteElements = useCallback(
    async elementsToDelete => {
      await request(async () => {
        await Promise.allSettled(
          elementsToDelete.map(element => {
            setData(prevElement => {
              prevElement.items = prevElement.items.map(w => {
                if (!elementsToDelete.find(o => o.id === w.id)) {
                  return w;
                }
                return paginatedSkeleton.items[0];
              });
              return { ...prevElement };
            });
            return parentId
              ? childId
                ? service.delete(parentId, childId, element.id, elementsToDelete.length > 1)
                : service.delete(parentId, element.id, elementsToDelete.length > 1)
              : service.delete(element.id, elementsToDelete.length > 1);
          })
        ).then(async responses => {
          if (responses.length <= 1) {
            if (responses[0].status === 'rejected') undoData();
            else await get({ paging, sorting, filteredCells, advancedFilters });
            return;
          }

          if (responses.filter(resp => resp.status === 'rejected').length === responses.length) {
            undoData();

            errorToaster(
              t('multiple.deleteFailed'),
              responses
                .filter(res => res.status === 'rejected')
                .map(resp => {
                  let element = elementsToDelete.find(
                    e =>
                      e.id ===
                      resp.reason.config.url.substring(
                        resp.reason.config.url.lastIndexOf('/') + 1,
                        resp.reason.config.url.length
                      )
                  );
                  if (element) {
                    return `${mainKey ? element[mainKey] : 'No key provided'} ${
                      secondaryKey ? '- ' + element[secondaryKey] + ' ' : ' '
                    }| ${t(JSON.parse(resp.reason.response.data).message)}`;
                  }

                  return `Unknown | ${t(JSON.parse(resp.reason.response.data).message)}`;
                })
            );
          } else if (
            responses.some(resp => resp.status === 'rejected') &&
            responses.some(resp => resp.status === 'fulfilled')
          ) {
            warningToaster(
              t('multiple.deleteWithErrors'),
              responses
                .filter(res => res.status === 'rejected')
                .map(resp => {
                  let element = elementsToDelete.find(
                    e =>
                      e.id ===
                      resp.reason.config.url.substring(
                        resp.reason.config.url.lastIndexOf('/') + 1,
                        resp.reason.config.url.length
                      )
                  );
                  return `${mainKey ? element[mainKey] : 'No key provided'}  ${
                    secondaryKey ? '- ' + element[secondaryKey] + ' ' : ' '
                  }| ${t(JSON.parse(resp.reason.response.data).message)}`;
                })
            );
            await get({ paging, sorting, filteredCells, advancedFilters });
          } else {
            successToaster(t('request.succes'));
            await get({ paging, sorting, filteredCells, advancedFilters });
          }
        });
      });
    },
    [
      request,
      setData,
      parentId,
      service,
      paginatedSkeleton.items,
      undoData,
      get,
      paging,
      sorting,
      filteredCells,
      advancedFilters,
      t,
      mainKey,
      secondaryKey
    ]
  );

  const generateAndDownloadCSV = (headers, rows, fileName) => {
    const csvHeaders = [headers.map(cell => cell.label)].map(e => e.join(';'));
    const csvRows = rows
      .map(row => headers.map(cell => (cell.translate ? t(row[cell.id]) : row[cell.id])))
      .map(e => e.join(';'))
      .join('\n');
    let csvContent = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csvHeaders + '\n' + csvRows);
    var link = document.createElement('a');
    link.setAttribute('href', csvContent);
    link.setAttribute('download', `${fileName}_${new Date().toLocaleDateString()}.csv`);
    document.body.appendChild(link);
    link.click();
  };
  const handleSelectedItem = id => {
    setSelectedItem(id ? data.items.find(item => item.id === id) : {});
    setErrorInfo(null);
    handleOpen();
  };

  const handleCloseEditModal = useCallback(() => {
    handleClose();
    setSelectedItem(null);
    setSuccessMessage(null);
    setErrorInfo(null);
  }, [handleClose, setErrorInfo]);

  const handleAcceptEditModal = useCallback(createMore => {
    keepAdding.current = createMore;
  }, []);

  const activateDesactivateItem = useCallback(
    async itemRow => {
      try {
        await request(async () => {
          setData(prevItems => ({
            ...prevItems,
            items: prevItems.items.map(prevItem =>
              prevItem.id === itemRow.id ? { ...prevItem, ...itemRow, isDisabled: !itemRow.isActive } : prevItem
            )
          }));
          itemRow.isActive ? await service.activate(itemRow.id) : await service.desactivate(itemRow.id);
        });
      } catch (error) {
        undoData();
      }
    },
    [request, service, setData, undoData]
  );

  return {
    data,
    get,
    deleteElements,
    exportElements,
    errorInfo,
    setErrorInfo,
    setData,
    undoData,
    paging,
    sorting,
    filteredCells,
    selectedItem,
    editModalOpen: isOpen,
    handleSelectedItem,
    handleCloseEditModal,
    handleAcceptEditModal,
    successMessage,
    activateDesactivateItem,
    currentRequestParams,
  };
}
