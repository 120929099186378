import { SKELETON_KEY } from './skeletonFactory';

export function skeletonPaymentMethod() {
  return {
    id: SKELETON_KEY,
    name: SKELETON_KEY,
    terms: Array(10).fill({
      name: SKELETON_KEY,
      id: SKELETON_KEY,
      days: SKELETON_KEY,
      requiresValidation: SKELETON_KEY
    })
  };
}
