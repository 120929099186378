import React from 'react';
import PropTypes from 'prop-types';
import { Badge, IconButton } from '@material-ui/core';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import { useRemindersButtonStyles } from './remindersButton.styles';
import clsx from 'clsx';

export function RemindersButton({
	onClick,
	className,
	counter
}) {
  const styles = useRemindersButtonStyles();

  return (
    <IconButton onClick={onClick} className={clsx(className, styles.remindersIcon)}>
      <Badge
        color="primary"
        badgeContent={counter ?? 0}
        invisible={!(counter > 0)}
      >
        <ChatBubbleIcon />
      </Badge>
    </IconButton>
  );
}

RemindersButton.propTypes = {
	onClick: PropTypes.func.isRequired,
	className: PropTypes.string,
	counter: PropTypes.number.isRequired
};