import React from 'react';

import defaultFile from 'assets/images/DocumentIcons/DefaultFile.svg';
import emptyFile from 'assets/images/DocumentIcons/EmptyFile.svg';
import csvFile from 'assets/images/DocumentIcons/CSVDoc.svg';
import wordDoc from 'assets/images/DocumentIcons/WordDoc.svg';
import imageIcon from 'assets/images/DocumentIcons/ImageIcon.svg';
import msgDoc from 'assets/images/DocumentIcons/MSGDoc.svg';
import pdfDoc from 'assets/images/DocumentIcons/PDFDoc.svg';
import txtDoc from 'assets/images/DocumentIcons/TXTDoc.svg';
import excelDoc from 'assets/images/DocumentIcons/ExelDoc.svg';
import xmlDoc from 'assets/images/DocumentIcons/XMLDoc.svg';

export const FIcons = {
    default: defaultFile,
    empty: emptyFile,
    csv: csvFile,
    doc: wordDoc,
    docx: wordDoc,
    jpg: imageIcon,
    jpeg: imageIcon,
    png: imageIcon,
    msg: msgDoc,
    pdf: pdfDoc,
    txt: txtDoc,
    xls: excelDoc,
    xlsx: excelDoc,
    xml: xmlDoc,
};

export default function FileIcon({ extension, size = 64, empty = false }) {
    return <img
        src={empty ? FIcons.empty : FIcons[extension] ?? FIcons.default}
        width={size}
        alt={extension}
    />;
}