import { settings, KEY_SETTINGS } from 'settings';

export let userManager;

export function getOidcConfig() {
  const { location } = window;
  const IDENTITY_URL = settings.getSetting(KEY_SETTINGS.IDENTITY_URL);
  const SITE_ID = settings.getSetting(KEY_SETTINGS.SITE_ID);
  const HOST_URL = `${location.protocol}//${location.hostname}${location.port ? `:${location.port}` : ''}`;
  const METADATA_OIDC = {
    issuer: IDENTITY_URL,
    jwks_uri: `${IDENTITY_URL}/.well-known/openid-configuration/jwks`,
    authorization_endpoint: `${IDENTITY_URL}/connect/authorize`,
    token_endpoint: `${IDENTITY_URL}/connect/token`,
    userinfo_endpoint: `${IDENTITY_URL}/connect/userinfo`,
    end_session_endpoint: `${IDENTITY_URL}/connect/endsession`,
    check_session_iframe: `${IDENTITY_URL}/connect/checksession`,
    revocation_endpoint: `${IDENTITY_URL}/connect/revocation`,
    introspection_endpoint: `${IDENTITY_URL}/connect/introspect`
  };
  const IDENTITY_CONFIG = {
    authority: IDENTITY_URL,
    client_id: 'nc-setflows',
    redirect_uri: `${HOST_URL}/callback`,
    login: `${IDENTITY_URL}/login`,
    automaticSilentRenew: false,
    filterProtocolClaims: true,
    loadUserInfo: false,
    silent_redirect_uri: `${HOST_URL}/renew`,
    silentRequestTimeoutInSeconds: 60,
    accessTokenExpiringNotificationTime: 100,
    post_logout_redirect_uri: `${HOST_URL}/`,
    response_type: 'code',
    scope: 'engloba.cmms.api engloba.finances.api engloba.realestates.api engloba.documentary.api engloba.places.api engloba.notifications.api engloba.identity.api openid profile',
    extraQueryParams: {
      site: SITE_ID
    },
    extraTokenParams: {
      site: SITE_ID
    }
  };

  return {
    ...IDENTITY_CONFIG,
    metadata: {
      ...METADATA_OIDC
    }
  };
}
