import { SKELETON_KEY } from './skeletonFactory';

export function skeletonInsuranceCompany() {
  return {
    id: SKELETON_KEY,
    name: SKELETON_KEY,
    documentIdType: SKELETON_KEY,
    documentIdValue: SKELETON_KEY,
    contact: SKELETON_KEY,
    email: SKELETON_KEY,
    phoneNumber: SKELETON_KEY
  };
}
