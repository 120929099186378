import { http } from './http';
import { apiHelper } from 'shared';
import FileSaver from 'file-saver';

const controllerName = 'integrations';

class Integrations {
  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/${controllerName}/${queryString}`, {});
  }

  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });
    const response = await http.get(`/${controllerName}/${queryString}`, {});
    const items = response.data && response.data.items;

    return items;
  }

  getById(id) {
    return http.get(`/${controllerName}/${id}`, {});
  }

  create(integration) {
    return http.post(`/${controllerName}`, integration, {});
  }

  update(id, integration) {
    return http.put(`/${controllerName}/${id}`, integration, {});
  }

  delete(id, multiple) {
    return http.delete(`/${controllerName}/${id}`, { multiple });
  }

  activate(id) {
    return http.put(`/${controllerName}/${id}/activation`, {}, {});
  }

  desactivate(id) {
    return http.delete(`/${controllerName}/${id}/activation`, {});
  }

  force(id) {
    return http.post(`/${controllerName}/${id}/force`, {});
  }

  async downloadLog(integrationId, executionId) {
    const headersT = await http.getBearerToken();
    const api_url = localStorage.getItem('API_URL');
    const options = {
      headers: headersT.headers,
      method: 'GET',
      responseType: 'blob'
    };

    const url = `${api_url}/api/v1.0/integrations/${integrationId}/executions/${executionId}/log`;
    const response = await fetch(url, options);

    if (response && response.status === 200) {
      const miBlob = await response.blob().catch(e => {
        throw e;
      });
      FileSaver.saveAs(miBlob, `integration_execution_${executionId}.txt`);
    }
  }
}

export const integrations = new Integrations();
