import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import React, { useMemo } from 'react';

import { dateHelper, isSkeletonLoading } from 'shared';
import { FILTER_TYPE } from 'shared/ViewTable/AdvancedFilters';

export function useRemindersBuildTable(inputs, t, handleLink) {
  const headCells = useMemo(() => {
    return [
      { id: 'workOrderReference', numeric: false, disablePadding: false, label: t('properties.workOrderReference')},
      {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: t('properties.description'),
      },
      {
        id: 'entryDate',
        numeric: false,
        disablePadding: false,
        label: t('properties.entryDate'),
        filterType: FILTER_TYPE.DATE
      },
      { id: 'link', action: true }
    ];
  }, [t]);

  const rows = useMemo(() => {
    if (!inputs || !inputs.items) {
      return [];
    }
    return inputs.items.map(reminder => ({
      workOrderReference: reminder.workOrderReference,
      id: reminder.id,
      description: reminder.description,
      entryDate: isSkeletonLoading(reminder.entryDate)
        ? reminder.entryDate
        : reminder.entryDate
        ? dateHelper.toShortDate(reminder.entryDate)
        : '',
      link: {
        title: t('actions.link'),
        component: <OpenInNewOutlinedIcon onClick={() => handleLink(reminder.workOrderId)} />
      }
    }));
  }, [inputs, t, handleLink]);

  return { headCells, rows };
}
