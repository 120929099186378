import { AsyncInputAutocomplete, Form, InfoSummary } from '@engloba-tech/englobity';
import { Grid, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { users } from 'services';
import { isSkeletonLoading } from 'shared';
import { useManagersFormStyles } from './managersForm.styles';

export function ManagersForm({ formRef, onSubmit, t, entity, propName, processRoles, isManager, listAllRoles }) {
  const [inputs, setInputs] = useState(entity[propName] || []);

  const classes = useManagersFormStyles();

  function handleSubmit() {
    onSubmit(inputs?.filter(o => o?.processRoleId));
  }

  function handleManagerAutocomplete(element, processRole) {
    setInputs(prevManagers =>
      processRoles.map(pr => {
        const previous = prevManagers.find(o => o?.processRoleId === pr.id);
        if (pr.id === processRole.id) {
          if (!element && !listAllRoles) return null;

          return {
            ...previous,
            processRoleId: processRole.id,
            processRole: processRole.name,
            managerId: element?.id,
            manager: element?.alias
          };
        } else {
          return previous;
        }
      })
    );
  }

  const getCurrentItem = id => {
    return inputs?.find(o => o?.processRoleId === id);
  };

  return (
    <Form className={classes.root} elementRef={formRef} onSubmit={handleSubmit} autoComplete="off">
      {processRoles?.length ? (
        <Grid container spacing={3}>
          {processRoles.map(pr => {
            const currentItem = getCurrentItem(pr.id);

            return (
              <Grid key={pr.id} item xs={12}>
                <Tooltip title={!pr.isMyProcessRole && !isManager ? t('managers.incorrectProcessRole') : ''}>
                  <span>
                    <AsyncInputAutocomplete
                      isLoading={isSkeletonLoading(inputs)}
                      onChange={(e, element) => handleManagerAutocomplete(element, pr)}
                      label={pr.name}
                      value={currentItem ? currentItem : null}
                      defaultInputValue={currentItem?.manager}
                      getOptionSelected={(option, value) => option.id === value.id}
                      getOptionLabel={option => option?.manager ?? option?.alias}
                      disabled={!pr.isMyProcessRole && !isManager}
                      requestAction={queryString => users.getUsingQueryFilter(queryString, entity.projectId, [pr.name])}
                      composed
                    />
                  </span>
                </Tooltip>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <InfoSummary text={t('managers.noProcessRoles')} />
      )}
    </Form>
  );
}

ManagersForm.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  onSubmit: PropTypes.func.isRequired,
  entity: PropTypes.shape({
    id: PropTypes.string,
    projectId: PropTypes.string,
    managers: PropTypes.array
  }),
  propName: PropTypes.string,
  processRoles: PropTypes.array,
  t: PropTypes.func,
  isManager: PropTypes.bool,
  listAllRoles: PropTypes.bool
};
