import { useHandleOpen } from '@engloba-tech/englobity';
import CloseIcon from '@material-ui/icons/Close';
import { UserContext } from 'auth';
import { NAME_SPACE } from 'i18n';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogModal, RemindersButton } from 'shared';
import { RemindersList } from '../RemindersList';
import { useRmindersStyles } from './reminders.styles';

export function Reminders() {
  const { isOpen, handleClose, handleOpen } = useHandleOpen(false);
  const { isLogged, isLoading, getRemindersCount, remindersCounter } = useContext(UserContext);
  const { t } = useTranslation(NAME_SPACE.REMINDERS);
  const classes = useRmindersStyles();

  useEffect(() => {
    async function getData() {
      if (remindersCounter === null && isLogged && !isLoading) await getRemindersCount();
    }
    getData();
  }, [getRemindersCount, remindersCounter, isLogged, isLoading]);

  function handleDialogClose() {
    handleClose();
  }

  return (
    <>
      <RemindersButton className={classes.reminders} onClick={handleOpen} counter={remindersCounter} />

      <DialogModal
        fullWidth
        className={classes.modal}
        title={t('name')}
        description={''}
        isOpen={isOpen}
        onClose={handleDialogClose}
        buttons={[
          {
            children: <CloseIcon />,
            text: t('common:actions.close'),
            type: 'secondary',
            onClick: handleDialogClose
          }
        ]}
      >
        <RemindersList />
      </DialogModal>
    </>
  );
}
