import { useEffect } from 'react';
import { useState } from 'react';
import { useAzureStorage, domHelper } from 'shared';

export const useDownloadDocument = (inputs, containerId, documentUrlProp = 'documentUrl') => {
  const [document, setDocument] = useState();
  const [showDownloadBtn, setShowDownloadBtn] = useState(false);
  const { getReadToken } = useAzureStorage();

  useEffect(() => {
    setDocument(inputs);
  }, [inputs]);

  useEffect(() => {
    inputs?.id && inputs[documentUrlProp] && setShowDownloadBtn(true);
  }, []);

  async function handleDownload() {
    if (document) {
      const token = await getReadToken(containerId);
      const downloadUrl = document[documentUrlProp].split('?')[0] + '?' + token;
      domHelper.downloadFile(downloadUrl, document.documentName, document.version);
    }
  }

  return { handleDownload, showDownloadBtn, setShowDownloadBtn };
};
