import { APIS } from 'app.apis.const';
import { apiHelper } from 'shared';
import { http } from './http';

const controllerName = 'directory-trees';

class DirectoryTrees {
  async getByEntity(entityId, entityName, sorting) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({
      entityId,
      entityName,
      ...sorting
    });

    const response = await http.get(`/${controllerName}${queryString}`, {}, APIS.DOCUMENTARY);
    const items = response.data && response.data.items;
    return items;
  }

  create(folder) {
    return http.post(`/${controllerName}`, folder, {}, APIS.DOCUMENTARY);
  }

  rename(folder) {
    return http.put(`/${controllerName}/${folder.id}`, folder, {}, APIS.DOCUMENTARY);
  }

  delete(workOrderId, id, disableErrorToasters = false) {
    const config = {
      disableErrorToasters: disableErrorToasters
    };
    return http.delete(`/work-orders/${workOrderId}/directory-trees/${id}`, config);
  }
}

export const directoryTrees = new DirectoryTrees();
