import { makeStyles } from '@material-ui/core/styles';

export const useAdvancedFiltersRendererStyles = makeStyles(theme => ({
  advancedFiltersChip:{
    backgroundColor: `${theme.palette.primary.light} !important`,
    color: theme.palette.primary.main
  },
  chipsContainer:{
    float: 'left',
    clear: 'both',
    marginBottom: '1rem',
    flexBasis: 0
  }
}));
