import { http } from './http';
import { APIS } from 'app.apis.const';
import { apiHelper } from 'shared';

const controllerName = 'alerts';
class Alerts {
  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ reference: queryFilter });
    const response = await http.get(`/${controllerName}/${queryString}`, {}, APIS.CMMS);
    const items = response.data && response.data.items;

    return items;
  }

  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/${controllerName}${queryString}`, {}, APIS.CMMS);
  }

  getById(id) {
    return http.get(`/${controllerName}/${id}`, {}, APIS.CMMS);
  }

  create(alert) {
    return http.post(`/${controllerName}`, alert, {}, APIS.CMMS);
  }

  delete(id, multiple) {
    return http.delete(`/${controllerName}/${id}`, { multiple }, APIS.CMMS);
  }

  update(id, alert) {
    return http.put(`/${controllerName}/${id}`, { ...alert, forceUpdateWorkGroupId: true }, APIS.CMMS);
  }

  getTypes() {
    return http.get(`/${controllerName}/alert-types`, {}, APIS.CMMS);
  }
}

export const alerts = new Alerts();
