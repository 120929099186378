import { NotificationsButton, useHandleOpen } from '@engloba-tech/englobity';
import CloseIcon from '@material-ui/icons/Close';
import { UserContext } from 'auth';
import { NAME_SPACE } from 'i18n';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogModal } from 'shared';
import { NotificationsList } from '../NotificationsList';
import { useNotifications } from '../useNotifications';
import { useNotificationsSignalR } from '../useNotificationsSignalR';
import { useNotificationsStyles } from './notifications.styles';

export function Notifications() {
  const { isOpen, handleClose, handleOpen } = useHandleOpen(false);
  const { isLogged, isLoading } = useContext(UserContext);
  const { counter, getCountPending, setNotificationsViewed } = useNotifications();
  const { onNewNotification } = useNotificationsSignalR();
  const { t } = useTranslation(NAME_SPACE.NOTIFICATIONS);
  const classes = useNotificationsStyles();

  useEffect(() => {
    onNewNotification(messageFromHub => {
      getCountPending();
    });
  }, [getCountPending, onNewNotification]);

  useEffect(() => {
    async function getData() {
      if (counter === null && isLogged && !isLoading) await getCountPending();
    }
    getData();
  }, [getCountPending, isLogged, isLoading, counter]);

  function handleDialogClose() {
    if (counter > 0) {
      setNotificationsViewed();
    }
    handleClose();
  }
  return (
    <>
      <NotificationsButton
        className={classes.notifications}
        onClick={handleOpen}
        counter={counter}
      ></NotificationsButton>

      <DialogModal
        fullWidth
        className={classes.modal}
        title={t('name')}
        description={''}
        isOpen={isOpen}
        onClose={handleDialogClose}
        buttons={[
          {
            children: <CloseIcon />,
            text: t('common:actions.close'),
            type: 'secondary',
            onClick: handleDialogClose
          }
        ]}
      >
        <NotificationsList />
      </DialogModal>
    </>
  );
}
