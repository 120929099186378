import { http } from './http';
import { apiHelper } from 'shared';

const controllerName = 'reports';

class Reports {
  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/${controllerName}/${queryString}`, {});
  }

  getById(id) {
    return http.get(`/${controllerName}/${id}`, {});
  }

  create(report) {
    return http.post(`/${controllerName}`, report, {});
  }

  update(report) {
    return http.put(`/${controllerName}/${report.id}`, { ...report, forceUpdateDocumentTypeId: true }, {});
  }

  delete(id) {
    return http.delete(`/${controllerName}/${id}`, {});
  }
}

export const reports = new Reports();
