import { makeStyles } from '@material-ui/core/styles';
import { color } from 'styles/color.styles';

export const useButtonOptionsStyles = makeStyles(theme => ({
  btn: {
    backgroundColor: color.primary.light,
    color: color.primary.main,
    height: 'auto',
    minWidth: '38px',
    width: '38px !important',
    borderRadius: '50%',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: color.primary.main,
      color: 'white',
      boxShadow: 'none'
    }
  }
}));
