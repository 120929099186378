import { objectHelper } from 'shared/helper';
import { useAzureStorage } from 'shared/useAzureStorage';
import { useHandleRequest } from 'shared/useHandleRequest';

export function useCreateOrUpdateWithFile({ service, entity, newId, documentPropertyName = 'templateUrl' }) {
  const { request } = useHandleRequest();
  const containerId = entity?.id ?? newId;
  const { uploadFile } = useAzureStorage(containerId);

  const createOrUpdate = async element => {
    return await request(async () => {
      if (!objectHelper.isEmpty(element.selectedFile)) {
        const urlFile = await uploadFile(element.selectedFile);
        element[documentPropertyName] = urlFile;
      }
      return !element.id ? await service.create({ ...element, id: newId }) : await service.update(element);
    }, true);
  };

  return { createOrUpdate };
}
