import { APIS } from 'app.apis.const';
import { apiHelper } from 'shared';

import { http } from './http';

const controllerName = 'suppliers';
const partialName = 'agreements';

class SupplierAgreements {
  get(supplierId, paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/${controllerName}/${supplierId}/${partialName}/${queryString}`, {}, APIS.FINANCES);
  }

  getByApplicableProject(supplierId, projectId) {
    return http.get(
      `/${controllerName}/${supplierId}/${partialName}/applicable-project/${projectId}`,
      {},
      APIS.FINANCES
    );
  }

  getById(supplierId, id) {
    return http.get(`/${controllerName}/${supplierId}/${partialName}/${id}`, {}, APIS.FINANCES);
  }

  create(supplierId, agreement) {
    return http.post(
      `/${controllerName}/${supplierId}/${partialName}`,
      this.formatRequest(agreement),
      {},
      APIS.FINANCES
    );
  }

  update(supplierId, id, agreement) {
    return http.put(
      `/${controllerName}/${supplierId}/${partialName}/${id}`,
      this.formatRequest({
        ...agreement,
        forceUpdateTaxRateId: true,
        forceUpdateEndDate: true,
        forceUpdateRetention: true
      }),
      {},
      APIS.FINANCES
    );
  }

  formatRequest(agreement) {
    return {
      ...agreement,
      businessLineProjects: agreement.projects?.length
        ? agreement.projects.map(pr => {
            return { projectId: pr.id, businessLineId: agreement.businessLines[0]?.id };
          })
        : agreement.businessLines.map(bl => {
            return { projectId: null, businessLineId: bl.id };
          })
    };
  }
}

export const supplierAgreements = new SupplierAgreements();
