import { SKELETON_KEY } from './skeletonFactory';

export function skeletonReport() {
  return {
    id: SKELETON_KEY,
    name: SKELETON_KEY,
    project: SKELETON_KEY,
    documentType: SKELETON_KEY
  };
}
