import { http } from './http';
import { apiHelper } from 'shared';

class Documents {
  get(workOrderId, paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/work-orders/${workOrderId}/documents/${queryString}`);
  }

  getById(workOrderId, id) {
    return http.get(`/work-orders/${workOrderId}/documents/${id}`);
  }

  async getUsingQueryFilter(workOrderId, queryFilter, directoryId, sorting) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({
      documentName: queryFilter,
      directoryTreeId: directoryId,
      onlyRootDocuments: false,
      ...(sorting ?? { orderBy: 'documentName', orderDesc: 'asc' })
    });

    const response = await http.get(`/work-orders/${workOrderId}/documents/${queryString}`);
    return response.data && response.data.items;
  }

  create(workOrderId, document) {
    const { fileObject, selectedFile, ...cleanedRequest } = document;
    return http.post(`/work-orders/${workOrderId}/documents`, cleanedRequest);
  }

  delete(workOrderId, id, multiple) {
    return http.delete(`/work-orders/${workOrderId}/documents/${id}`, { multiple });
  }

  update(workOrderId, id, document) {
    const { fileObject, selectedFile, ...cleanedRequest } = document;
    return http.put(`/work-orders/${workOrderId}/documents/${id}`, {
      ...cleanedRequest,
      forceUpdateExpectedDate: true,
      forceUpdateSentDate: true,
      forceUpdateAssetId: true,
      forceUpdateSupplierId: true,
      ForceUpdateDocument: true,
      ForceUpdateDirectoryTree: true
    });
  }
}

export const documents = new Documents();
