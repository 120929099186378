import { Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useFolderStyles } from './folder.styles';
import folderIcon from 'assets/images/DocumentIcons/Folder.svg';


export const Folder = ({ onClick, onDoubleClick, folder, isChecked }) => {
  const classes = useFolderStyles();
  return (
    <Tooltip title={folder.name} placement="top">
      <div
        className={clsx(classes.main, isChecked(folder) && classes.checked)}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
      >
        <img src={folderIcon} width={64} alt={'Folder'} />
        <p className={classes.folderName}>{folder.name}</p>
      </div>
    </Tooltip>
  );
};
Folder.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
  folder: PropTypes.shape({ name: PropTypes.string }),
  isChecked: PropTypes.func
};
