import { DialogModal } from '@engloba-tech/englobity';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { NAME_SPACE } from 'i18n';
import PropTypes from 'prop-types';
import { useUserStateContext } from 'state/useUserStateContext';

export function CmmsDialogModal({
  handleClose,
  handleModalAccept,
  buttons,
  extraButtons,
  children,
  onlyCloseBtn,
  ...props
}) {
  const { t } = useTranslation(NAME_SPACE.COMMON);
  const { isLoading } = useUserStateContext();

  const mappedButtons = useMemo(() => {
    return buttons
      ? buttons?.map(btn => {
          if (btn?.type === 'primary') return { ...btn, loading: isLoading };
          return btn;
        })
      : onlyCloseBtn
      ? [
          {
            children: <CloseIcon />,
            text: t('common:actions.close'),
            type: 'secondary',
            onClick: handleClose
          }
        ]
      : [
          {
            children: <CloseIcon />,
            text: t('common:actions.close'),
            type: 'secondary',
            onClick: handleClose
          },
          {
            children: <DoneIcon />,
            text: t('common:actions.accept'),
            type: 'primary',
            onClick: handleModalAccept,
            loading: isLoading
          }
        ];
  }, [buttons, handleClose, handleModalAccept, isLoading, onlyCloseBtn, t]);

  return (
    <DialogModal
      buttons={extraButtons ? [...mappedButtons, extraButtons] : mappedButtons}
      handleClose={handleClose}
      handleModalAccept={handleModalAccept}
      {...props}
    >
      {children}
    </DialogModal>
  );
}

CmmsDialogModal.propTypes = {
  handleClose: PropTypes.func,
  handleModalAccept: PropTypes.func,
  buttons: PropTypes.arrayOf(PropTypes.object),
  extraButtons: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.node,
  onlyCloseBtn: PropTypes.bool
};
