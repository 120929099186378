import { AsyncInputAutocomplete, Form, Input, TextArea, WarningSummary } from '@engloba-tech/englobity';
import { Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogModal, isSkeletonLoading } from 'shared';
import { useMailToModalStyles } from './mailToModal.styles';

export function MailToModal({ subject, body, title, onClose, recipientElements }) {
  const { t } = useTranslation();
  const classes = useMailToModalStyles();
  const $formClaimDocumentationContacts = useRef(null);
  const [selectedRecipients, setSelectedRecipients] = useState([]);

  const handleSubmit = () => {
    window.open(
      `mailto:${selectedRecipients.map(contact => contact.email).join(';')}?subject=${encodeURIComponent(subject)}
      &body=${encodeURI(body)}`
    );
  };

  return (
    <>
      <DialogModal
        {...{ title, onClose }}
        className={classes.modal}
        isOpen={true}
        buttons={[
          {
            children: <CloseIcon />,
            text: t('actions.close'),
            type: 'secondary',
            onClick: onClose
          },
          {
            children: <SendIcon />,
            text: t('mailTo.generateEmail'),
            type: 'primary',
            onClick: () => $formClaimDocumentationContacts.current.submit()
          }
        ]}
      >
        <Form
          className={`${classes.form} ${classes.container}`}
          elementRef={$formClaimDocumentationContacts}
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <Grid container spacing={3} className={classes.marginTop}>
            <Grid item xs={12}>
              <WarningSummary text={t('mailTo.alert')} />
            </Grid>
            <Grid item xs={12}>
              <AsyncInputAutocomplete
                isLoading={isSkeletonLoading(recipientElements && recipientElements[0])}
                multiple
                required
                icon={<SearchIcon />}
                onChange={(e, element) => setSelectedRecipients(element)}
                label={t('mailTo.recipients')}
                getOptionLabel={option => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                value={selectedRecipients || []}
                requestAction={() => recipientElements?.filter(r => r.email)}
                validators={!selectedRecipients.length ? ['required'] : []}
                errorMessages={[t('validations.required')]}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                required
                label={t('mailTo.recipientEmail')}
                variant="outlined"
                value={selectedRecipients?.map(r => r.email).join(';')}
                inputProps={{ 'aria-label': t('properties.name') }}
                validators={['required', 'maxStringLength:255']}
                errorMessages={[t('validations.required'), t('common:validations.maxStringLength', { length: 255 })]}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                required
                label={t('mailTo.subject')}
                variant="outlined"
                value={subject}
                inputProps={{ 'aria-label': t('properties.name') }}
                validators={['required', 'maxStringLength:255']}
                errorMessages={[t('validations.required'), t('common:validations.maxStringLength', { length: 255 })]}
              />
            </Grid>
            <Grid item xs={12}>
              <TextArea label={t('mailTo.generateEmail')} required value={body} minRows={7} />
            </Grid>
          </Grid>
        </Form>
      </DialogModal>
    </>
  );
}

MailToModal.propTypes = {
  onClose: PropTypes.func,
  recipientElements: PropTypes.array,
  subject: PropTypes.string,
  body: PropTypes.string,
  title: PropTypes.string
};
