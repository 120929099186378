import { makeStyles } from '@material-ui/core/styles';
import { color } from 'styles/color.styles';

export const useTreeSelectStyles = makeStyles(theme => ({
  select: {
    '& .dropdown': {
      width: '100%'
    },
    '& .dropdown .dropdown-trigger': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '45.625px',
      borderRadius: '8px',
      borderColor: color.primary.main,
      overflow: 'hidden',
      flexWrap: 'nowrap'
    },

    '& .dropdown .dropdown-trigger.arrow.disabled': {
      border: '1px solid #c8c8c8'
    },

    '& .dropdown .dropdown-trigger.arrow.bottom:after': {
      color: '#757575'
    },
    '& .tag-list': {
      height: '40px',
      paddingTop: '2px',
      '& .tag': {
        borderRadius: '4px',
        backgroundColor: color.primary.lighter,
        margin: 0,
        padding: '5px',
        border: 'none',
        '& .tag-remove': {
          width: '25px',
          color: color.primary.main,
          margin: '0 5px',
          fontSize: '14px'
        }
      }
    },
    '& .tag-item .search': {
      width: '100%',
      borderBottom: 'none',
      backgroundColor: 'white',
      '&::placeholder': {
        display: 'none',
        color: 'white'
      }
    },
    '& .dropdown-content': {
      width: 'max',
      maxHeight: '16.5em',
      overflow: 'auto',
      borderRadius: '8px',
      padding: '5px',
      paddingRight: '2em !important',
      zIndex: '1101 !important',
      position: 'fixed !important',
      '& .root': {
        margin: '5px'
      }
    },
    '& .infinite-scroll-component .node.tree': {
      display: 'flex',
      alignItems: 'center',
      gap: '2px',
      marginLeft: '10px',
      '& .toggle.collapsed:after': {
        fontSize: '20px',
        width: '10%',
        borderRadius: '4px',
        textAlign: 'center',
        fontStyle: 'normal',
        padding: '0px 5px',
        color: color.primary.main,
        backgroundColor: color.table.header
      },
      '& .toggle.expanded:after': {
        fontSize: '20px',
        width: '10%',
        borderRadius: '4px',
        textAlign: 'center',
        fontStyle: 'normal',
        padding: '0px 5px',
        color: color.primary.main,
        backgroundColor: color.table.header
      }
    },

    '& .infinite-scroll-component .node.leaf': {
      display: 'flex',
      alignItems: 'center',
      gap: '2px',
      marginLeft: '10px',
      '& .toggle.collapsed:after': {
        fontSize: '20px',
        width: '10%',
        borderRadius: '4px',
        textAlign: 'center',
        fontStyle: 'normal',
        padding: '0px 5px',
        color: color.primary.main,
        backgroundColor: color.table.header
      },
      '& .toggle.expanded:after': {
        fontSize: '20px',
        width: '10%',
        borderRadius: '4px',
        textAlign: 'center',
        fontStyle: 'normal',
        padding: '0px 5px',
        color: color.primary.main,
        backgroundColor: color.table.header
      }
    }
  },
  hidePlaceholder: {
    '& a ul li .search': {
      display: 'none'
    }
  }
}));
