import { APIS } from 'app.apis.const';
import { apiHelper } from 'shared';
import { http } from './http';

const controllerName = 'suppliers';

class SupplierContacts {
  async getUsingQueryFilter(supplierId, queryFilter, withEmail) {
    const queryParams = withEmail ? { name: queryFilter, withEmail } : { name: queryFilter };
    const queryString = apiHelper.composeQueryString(queryParams);

    const response = await http.get(`/${controllerName}/${supplierId}/contacts${queryString}`, {}, APIS.FINANCES);
    const items = response.data && response.data.items;

    return items;
  }
}

export const supplierContacts = new SupplierContacts();
