import { apiHelper } from 'shared';
import { http } from './http';

const controllerName = 'work-orders';
const controllerRoute = 'gallery-images';

class GalleryImages {
  get(workOrderId, paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/${controllerName}/${workOrderId}/${controllerRoute}/${queryString}`);
  }

  getFromBudgetItem(workOrderId, budgetItemId, paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/${controllerName}/${workOrderId}/${controllerRoute}/budget-item/${budgetItemId}/${queryString}`);
  }

  getFromAsset(workOrderId, assetId, paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/${controllerName}/${workOrderId}/${controllerRoute}/asset/${assetId}/${queryString}`);
  }

  async getTagsByBudgetItemId(workOrderId, budgetItemId) {
    const response = await http.get(`/${controllerName}/${workOrderId}/gallery-tags/${budgetItemId}`);
    const items = response.data && response.data.items;

    return items;
  }

  async getTagsByWorkOrderId(workOrderId) {
    const response = await http.get(`/${controllerName}/${workOrderId}/gallery-tags`);
    const items = response.data && response.data.items;

    return items;
  }

  getById(workOrderId, id) {
    return http.get(`/${controllerName}/${workOrderId}/${controllerRoute}/${id}`);
  }

  create(galleryImage, workOrderId) {
    return http.post(`/${controllerName}/${workOrderId}/${controllerRoute}/`, galleryImage);
  }

  update(galleryImage, workOrderId) {
    return http.put(`/${controllerName}/${workOrderId}/${controllerRoute}/${galleryImage.id}`, {
      ...galleryImage,
      forceUpdateAsset: true,
      forceUpdateBudgetItem: true
    });
  }

  delete(workOrderId, id, multiple) {
    return http.delete(`/${controllerName}/${workOrderId}/${controllerRoute}/${id}`, { multiple });
  }
}

export const workOrderGalleryImages = new GalleryImages();
