import { http } from './http';
import { apiHelper } from 'shared';

const controllerName = 'agenda';

class Agenda {
  get(filters) {
    let queryString = apiHelper.composeQueryString({ ...filters });
    return http.get(`/${controllerName}${queryString}`, {});
  }
}

export const agenda = new Agenda();
