import { makeStyles } from '@material-ui/core/styles';
import { color } from '../../../../../src/styles/color.styles';

export const useNotificationsStyles = makeStyles(theme => ({
  modal: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: '70rem'
    }
  }, notifications: {
    '& .MuiBadge-colorPrimary':{
      backgroundColor: color.branding.red
    }
  }
}));
