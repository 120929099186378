import { makeStyles } from '@material-ui/core/styles';

export const useFullAddressInputStyles = makeStyles(theme => ({
  form: {
    flexGrow: 1
  },
  divider: {
    width: '100%',
    marginTop: '1rem',
    marginBottom: '2rem'
  },
  modal: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: '50rem',
      '& .MuiDialogContent-root': {
        padding: '30px',
        margin: theme.spacing(2)
      },
      '& .MuiDialogTitle-root': {
        textAlign: 'center'
      },
      '& .MuiDialogActions-root': {
        marginTop: theme.spacing(3)
      }
    }
  },

  fullAddressContainer: {
    display: 'flex',
    background: 'white',
    '& > div': {
      flexGrow: 1
    },
    '& .MuiSkeleton-root': {
      width: '100%'
    }
  },
  deleteIcon: {
    marginLeft: theme.spacing(2),
    borderRadius: '5px',
    background: theme.palette.action.delete.light,
    color: theme.palette.action.delete.main,
    '&:hover': {
      color: 'white',
      background: theme.palette.action.delete.main
    }
  },
  container: {
    padding: '0 24px',
    backgroundColor: 'white'
  }
}));
