import { APIS } from 'app.apis.const';
import { KEY_SETTINGS, settings } from 'settings';
import { apiHelper } from 'shared';
import { http } from './http';
class Places {
  async getZipCodesUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString(queryFilter);

    try {
      const response = await http.get(`/zip-codes${queryString}&filterType=2`, {}, APIS.PLACES);
      const items = response.data && response.data.items;

      return items;
    } catch (error) {
      console.error(error);
    }
  }
  async getCitiesUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString(queryFilter);

    const response = await http.get(`/cities${queryString}`, {}, APIS.PLACES);
    const items = response.data && response.data.items;

    return items;
  }

  async getUrbanTypesUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/urban-types${queryString}`, {}, APIS.PLACES);
    const items = response.data && response.data.items;

    return items;
  }

  async getDistrictsUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/districts${queryString}`, {}, APIS.PLACES);
    const items = response.data && response.data.items;

    return items;
  }

  async getDistrictById(disctrictId) {
    return http.get(`/districts/${disctrictId}`, {}, APIS.PLACES);
  }

  async getStatesUsingQueryFilter(queryFilter) {
    let queryString = '';
    var countryCodes = settings.getSetting(KEY_SETTINGS.COUNTRY_CODES).split(',');
    queryString = apiHelper.composeQueryString({ name: queryFilter, countryCodes });

    const response = await http.get(`/states${queryString}`, {}, APIS.PLACES);
    const items = response.data && response.data.items;

    return items;
  }

  async getCountriesUsingQueryFilter(queryFilter, withDistricts = false) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter, withDistricts });

    const response = await http.get(`/countries${queryString}`, {}, APIS.PLACES);
    const items = response.data && response.data.items;

    return items;
  }
}

export const places = new Places();
