import { http } from './http';
import { apiHelper } from 'shared';
import { APIS } from 'app.apis.const';

const controllerName = 'suppliers';
const partialName1 = 'agreements';
const partialName2 = 'workers';

class SupplierWorkers {
  get(supplierId, agreementId, paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(
      `/${controllerName}/${supplierId}/${partialName1}/${agreementId}/${partialName2}/${queryString}`,
      {},
      APIS.FINANCES
    );
  }

  getById(supplierId, agreementId, id) {
    return http.get(
      `/${controllerName}/${supplierId}/${partialName1}/${agreementId}/${partialName2}/${id}`,
      {},
      APIS.FINANCES
    );
  }

  create(supplierId, agreementId, worker) {
    return http.post(
      `/${controllerName}/${supplierId}/${partialName1}/${agreementId}/${partialName2}`,
      worker,
      {},
      APIS.FINANCES
    );
  }

  delete(supplierId, agreementId, id, multiple) {
    return http.delete(
      `/${controllerName}/${supplierId}/${partialName1}/${agreementId}/${partialName2}/${id}`,
      { multiple },
      APIS.FINANCES
    );
  }

  update(supplierId, agreementId, id, worker) {
    return http.put(
      `/${controllerName}/${supplierId}/${partialName1}/${agreementId}/${partialName2}/${id}`,
      worker,
      {},
      APIS.FINANCES
    );
  }

  activate(id, supplierId, agreementId) {
    return http.put(
      `/${controllerName}/${supplierId}/${partialName1}/${agreementId}/${partialName2}/${id}/activation`,
      {},
      {},
      APIS.FINANCES
    );
  }

  desactivate(id, supplierId, agreementId) {
    return http.delete(
      `/${controllerName}/${supplierId}/${partialName1}/${agreementId}/${partialName2}/${id}/activation`,
      {},
      APIS.FINANCES
    );
  }

  copyWorkers(supplierId, sourceAgreementId, targetAgreementId) {
    return http.post(
      `/${controllerName}/${supplierId}/${partialName1}/${targetAgreementId}/${partialName2}/copy`,
      { copyFromAgreementId: sourceAgreementId },
      {},
      APIS.FINANCES
    );
  }
}

export const supplierWorkers = new SupplierWorkers();
