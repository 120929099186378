import { Button } from '@engloba-tech/englobity';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import shortid from 'shortid';

import { UserContext } from 'auth';

import { useButtonStyles } from './button.styles';

export function ButtonCmms({
  disabled,
  condition,
  tooltip,
  type,
  className,
  onClick,
  icon,
  text,
  requiresAccess,
  loading,
  ...props
}) {
  const classes = useButtonStyles();
  const { hasFullAccess } = useContext(UserContext);

  const renderButton = () => {
    return (
      <Button
        tooltip={{
          title: (disabled && tooltip?.disabled) || '',
          placement: 'top'
        }}
        disabled={disabled}
        className={clsx(classes.button, className)}
        // id={button.id || `form_button_${i}`}
        key={shortid.generate()}
        type={type || 'primary'}
        onClick={onClick}
        loading={loading}
        {...props}
      >
        {props.children || (
          <div className={classes.wrapperButton}>
            {!loading && icon}
            <p className={classes.textButton}>{text}</p>
          </div>
        )}
      </Button>
    );
  };

  return requiresAccess ? hasFullAccess() && renderButton() : renderButton();
}

ButtonCmms.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  onSubmit: PropTypes.func.isRequired,
  entity: PropTypes.shape({
    id: PropTypes.string
  }),
  requiredProps: PropTypes.array,
  successMessage: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  handleCloseEditModal: PropTypes.func.isRequired,
  handleAcceptEditModal: PropTypes.func.isRequired,
  onSubmitEditModal: PropTypes.func.isRequired,
  className: PropTypes.string,
  errorInfo: PropTypes.object
};
