const AUTH_STORAGE = {
  PATH_TO_GO: 'path_to_go',
  TOKEN: 'TOKEN',
  REFRESH_TOKEN: 'REFRESH_TOKEN'
};

class AuthStorage {
  setToken(token) {
    localStorage.setItem(AUTH_STORAGE.TOKEN, token);
  }

  getToken() {
    return localStorage.getItem(AUTH_STORAGE.TOKEN);
  }

  setPathToGo(url) {
    return localStorage.setItem(AUTH_STORAGE.PATH_TO_GO, url);
  }

  getPathToGo() {
    return localStorage.getItem(AUTH_STORAGE.PATH_TO_GO);
  }

  removePathToGo() {
    return localStorage.removeItem(AUTH_STORAGE.PATH_TO_GO);
  }
}

export const authStorage = new AuthStorage();
