import { http } from './http';
import { APIS } from 'app.apis.const';
import { apiHelper } from 'shared';

class Notifications {
  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/notifications/my/${queryString}`, {}, APIS.NOTIFICATIONS);
  }

  getCountPending() {
    return http.get('/notifications/my/pending-count', {}, APIS.NOTIFICATIONS);
  }

  setViewed() {
    return http.post('/notifications/my/viewed', null, {}, APIS.NOTIFICATIONS);
  }

  delete(id, multiple) {
    return http.delete(`/notifications/${id}`, { multiple }, APIS.NOTIFICATIONS);
  }
}

export const notifications = new Notifications();
