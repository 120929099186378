import { Box } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useSimpleTableStyles } from './useSimpleTable.styles';

export function SimpleTable({ rows, cells, onRemove, actionWidth }) {
  const classes = useSimpleTableStyles();

  function handleDelete(row) {
    onRemove(row);
  }

  return (
    rows?.length > 0 && (
      <table className={classes.table}>
        <thead>
          <tr>
            {cells.map(headCell => (
              <th key={headCell.id} style={{ width: headCell.width ? headCell.width : `${(cells + 1) / 100}%` }}>
                {headCell.label}
              </th>
            ))}
            <th className={classes.action} style={{ width: actionWidth ? actionWidth : `${(cells + 1) / 100}%` }} />
          </tr>
        </thead>
        <tbody>
          {rows.map(row => (
            <tr key={row.label}>
              {cells.map(cell => (
                <td key={cell.id}>
                  <span key={row[cell.id]}>{row[cell.id]}</span>
                </td>
              ))}

              <th>
                <Box className={clsx(classes.button, classes.delete)}>
                  <DeleteIcon onClick={() => handleDelete(row)} />
                </Box>
              </th>
            </tr>
          ))}
        </tbody>
      </table>
    )
  );
}

SimpleTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
  cells: PropTypes.arrayOf(PropTypes.object),
  onRemove: PropTypes.func,
  actionWidth: PropTypes.string
};
