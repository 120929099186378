function groupBy(data, groupingFunc) {
  return [
    ...data.reduce((a, b, i, x) => {
      const k = groupingFunc(b, i, x);
      a.get(k)?.push(b) ?? a.set(k, [b]);
      return a;
    }, new Map())
  ];
}

function removeDuplicates(array, idProp) {
  const uniqueIds = [];

  return (array ?? []).filter(element => {
    const isDuplicate = uniqueIds.includes(element[idProp]);
    if (!isDuplicate) {
      uniqueIds.push(element[idProp]);
      return true;
    }
    return false;
  });
}

export const groupHelper = {
  groupBy,
  removeDuplicates
};
