import { apiHelper } from 'shared';
import { http } from './http';

const controllerName = 'work-order-automations';

class WorkOrderAutomations {
  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/${controllerName}/${queryString}`, {});
  }

  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });
    const response = await http.get(`/${controllerName}${queryString}`, {});
    const items = response.data && response.data.items;

    return items;
  }

  getById(id) {
    return http.get(`/${controllerName}/${id}`, {});
  }

  create(woAutomation) {
    return http.post(`/${controllerName}`, this.prepareData(woAutomation));
  }

  delete(id, multiple) {
    return http.delete(`/${controllerName}/${id}`, { multiple });
  }

  update(id, woAutomation) {
    const woAutomationToUpdate = this.prepareData(woAutomation);
    return http.put(`/${controllerName}/${id}`, {
      ...woAutomationToUpdate,
      forceUpdateAsset: true,
      forceUpdateEveryMonthSelect: true,
      forceUpdateEveryMonthWeekDay: true,
      forceUpdateEveryMonthDay: true,
      forceUpdateEveryWeekDays: true
    });
  }

  nextExecutions(data) {
    const woAutomation = this.prepareData(data);
    let queryString = '';
    queryString = apiHelper.composeQueryString(woAutomation);
    return http.get(`/${controllerName}/next-executions${queryString}`);
  }

  activate(id) {
    return http.put(`/${controllerName}/${id}/activation`);
  }

  desactivate(id) {
    return http.delete(`/${controllerName}/${id}/activation`);
  }

  prepareData(woAutomation) {
    return {
      id: woAutomation?.id,
      name: woAutomation.name,
      projectId: woAutomation.projectId,
      sourceId: woAutomation.sourceId,
      sourceTermId: woAutomation.sourceTermId,
      assetId: woAutomation?.assetId,
      everyNumber: woAutomation?.everyNumber,
      everyCalendar: woAutomation?.everyCalendar,
      everyMonthSelect: woAutomation?.everyMonthSelect,
      everyMonthWeekDay: woAutomation?.everyMonthWeekDay,
      everyMonthDay: woAutomation?.everyMonthDay,
      everyWeekDays: woAutomation?.everyWeekDays,
      time: woAutomation?.time
    };
  }
}

export const workOrderAutomations = new WorkOrderAutomations();
