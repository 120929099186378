import { http } from './http';
import { apiHelper } from 'shared';

class Measures {
  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/measures${queryString}`, {});
    const items = response.data && response.data.items;

    return items;
  }

  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/measures/${queryString}`);
  }

  getById(id) {
    return http.get(`/measures/${id}`);
  }

  create(measure) {
    return http.post('/measures', measure);
  }

  delete(id, multiple) {
    return http.delete(`/measures/${id}`, { multiple });
  }

  update(measure) {
    return http.put(`/measures/${measure.id}`, measure);
  }
}

export const measures = new Measures();
