import { useDndContext } from '@dnd-kit/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useWidgetsStyles } from './widgets.styles';

const useDndIsReallyActiveId = id => {
  const context = useDndContext();
  const isActive = context.active?.id === id;
  return isActive;
};

export const DragOverlayWidget = ({ id, children }) => {
  const isReallyActive = useDndIsReallyActiveId(id);
  const classes = useWidgetsStyles(isReallyActive);

  return <div className={classes.isActive}>{children}</div>;
};

DragOverlayWidget.propTypes = {
  id: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};
