import { SKELETON_KEY, SKELETON_KEY_DATE, SKELETON_KEY_NUMBER } from './skeletonFactory';

export function skeletonRealEstateDocument() {
  return {
    id: SKELETON_KEY,
    workOrderId: SKELETON_KEY,
    documentName: SKELETON_KEY,
    documentUrl: SKELETON_KEY,
    entryDate: SKELETON_KEY_DATE,
    expectedDate: SKELETON_KEY_DATE,
    realDate: SKELETON_KEY_DATE,
    status: SKELETON_KEY,
    userId: SKELETON_KEY,
    user: SKELETON_KEY,
    remarks: SKELETON_KEY,
    documentType: SKELETON_KEY,
    documentTypeId: SKELETON_KEY,
    workOrderReference: SKELETON_KEY
  };
}

export function skeletonRealEstate() {
  return {
    id: SKELETON_KEY,
    externalReference: SKELETON_KEY,
    secondExternalReference: SKELETON_KEY,
    district: SKELETON_KEY,
    city: SKELETON_KEY,
    fullAddress: SKELETON_KEY,
    cadastralReference: SKELETON_KEY,
    realEstateType: SKELETON_KEY,
    location: SKELETON_KEY,
    operationType: SKELETON_KEY,
    commercial: SKELETON_KEY,
    contactMethod: SKELETON_KEY,
    synchronization: SKELETON_KEY,
    synchronizationReference: SKELETON_KEY,
    cee: SKELETON_KEY,
    electricalPower: SKELETON_KEY
  };
}

export function skeletonOperationType() {
  return {
    id: SKELETON_KEY_NUMBER,
    name: SKELETON_KEY
  };
}

export function skeletonCEE() {
  return {
    id: SKELETON_KEY_NUMBER,
    name: SKELETON_KEY
  };
}
