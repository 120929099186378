import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

const UserStateContext = React.createContext({ isLoading: false, setIsLoading: () => null });

export function UserStateProvider({ children }) {
  const [notificationsCounter, setNotificationsCounter] = useState(null);
  const [listFilters, _setListFilters] = useState({});
  const [isLoading, setIsLoading] = useState();

  const values = React.useMemo(() => {
    const setListFilters = (key, filters) => {
      _setListFilters(prevFilters => ({
        ...prevFilters,
        [key]: filters
      }));
    };

    const getListFilters = key => {
      return listFilters[key] ?? {};
    };

    const clearUserState = () => {
      _setListFilters({});
      setNotificationsCounter(null);
    };

    return {
      clearUserState,
      notificationsCounter,
      listFilters,
      getListFilters,
      setListFilters,
      setNotificationsCounter,
      isLoading,
      setIsLoading
    };
  }, [notificationsCounter, listFilters, setNotificationsCounter, isLoading, setIsLoading]);

  return <UserStateContext.Provider value={values}>{children}</UserStateContext.Provider>;
}

UserStateProvider.propTypes = {
  children: PropTypes.node
};

export const useUserStateContext = () => {
  const context = useContext(UserStateContext);
  if (!context) {
    throw new Error('useUserStateContext must be used within UserStateProvider');
  }
  return context;
};
