import { SKELETON_KEY, SKELETON_KEY_BOOL, SKELETON_KEY_DATE, SKELETON_KEY_NUMBER } from './skeletonFactory';

export function skeletonSupplier() {
  return {
    id: SKELETON_KEY,
    fullName: SKELETON_KEY,
    name: SKELETON_KEY,
    alias: SKELETON_KEY,
    firstSurname: SKELETON_KEY,
    secondSurname: SKELETON_KEY,
    documentIdType: SKELETON_KEY_NUMBER,
    documentIdValue: SKELETON_KEY,
    documentsGroups: [SKELETON_KEY, SKELETON_KEY],
    businessLines: [SKELETON_KEY, SKELETON_KEY],
    specialities: [SKELETON_KEY, SKELETON_KEY],
    paymentMethods: [SKELETON_KEY, SKELETON_KEY],
    isHomologated: SKELETON_KEY_BOOL,
    pendingWorkersHomologation: SKELETON_KEY_BOOL,
    hasActiveGarnishments: SKELETON_KEY_BOOL,
    remarks: SKELETON_KEY,
    geographicalScopes: Array(10).fill({ id: SKELETON_KEY, type: SKELETON_KEY, scope: SKELETON_KEY }),
    contacts: Array(10).fill({
      name: SKELETON_KEY,
      id: SKELETON_KEY,
      email: SKELETON_KEY,
      phoneNumber: SKELETON_KEY,
      remarks: SKELETON_KEY
    }),
    city: SKELETON_KEY,
    district: SKELETON_KEY,
    supplierTypes: Array(10).fill({ id: SKELETON_KEY, name: SKELETON_KEY, remarks: SKELETON_KEY }),
    supplierCreditInsurer: Array(1).fill({ id: SKELETON_KEY, risk: SKELETON_KEY, name: SKELETON_KEY })
  };
}

export function skeletonSupplierDocument() {
  return {
    supplierDocumentType: SKELETON_KEY,
    validFrom: SKELETON_KEY_DATE,
    validTo: SKELETON_KEY_DATE,
    documentName: SKELETON_KEY,
    id: SKELETON_KEY,
    entryDate: SKELETON_KEY_DATE,
    user: SKELETON_KEY
  };
}

export function skeletonSupplierBudgetItem() {
  return {
    id: SKELETON_KEY,
    workOrderReference: SKELETON_KEY,
    workOrderStatus: SKELETON_KEY_NUMBER,
    assetReference: SKELETON_KEY,
    itemReference: SKELETON_KEY,
    itemDescription: SKELETON_KEY,
    quantityAwarded: SKELETON_KEY_NUMBER,
    unitCost: SKELETON_KEY_NUMBER
  };
}

export function skeletonWorker() {
  return {
    id: SKELETON_KEY,
    fullName: SKELETON_KEY,
    secondSurname: SKELETON_KEY,
    firstSurname: SKELETON_KEY,
    name: SKELETON_KEY,
    documentIdType: SKELETON_KEY_NUMBER,
    documentIdValue: SKELETON_KEY,
    phoneNumber: SKELETON_KEY,
    email: SKELETON_KEY,
    isActive: SKELETON_KEY_BOOL
  };
}

export function skeletonGarnishment() {
  return {
    id: SKELETON_KEY,
    documentName: SKELETON_KEY,
    validFrom: SKELETON_KEY_DATE,
    validTo: SKELETON_KEY_DATE,
    documentIdType: SKELETON_KEY_NUMBER,
    documentIdValue: SKELETON_KEY,
    entryDate: SKELETON_KEY_DATE,
    user: SKELETON_KEY
  };
}

export function skeletonCreditInsurer() {
  return {
    id: SKELETON_KEY,
    insuranceCompany: SKELETON_KEY,
    validFrom: SKELETON_KEY,
    validTo: SKELETON_KEY,
    insuredRisk: SKELETON_KEY_NUMBER
  };
}

function skeletonAgreementTaxRate() {
  return { id: SKELETON_KEY, taxRateId: SKELETON_KEY, taxRate: SKELETON_KEY, taxRateType: SKELETON_KEY };
}

function skeletonAgreementIRPFType() {
  return { id: SKELETON_KEY, irpfTypeId: SKELETON_KEY, irpfType: SKELETON_KEY, rate: SKELETON_KEY };
}

export function skeletonAgreement() {
  return {
    id: SKELETON_KEY,
    name: SKELETON_KEY,
    businessLines: [SKELETON_KEY],
    projects: [SKELETON_KEY],
    startDate: SKELETON_KEY_DATE,
    endDate: SKELETON_KEY_DATE,
    paymentMethodTerm: SKELETON_KEY,
    remarks: SKELETON_KEY,
    taxRates: Array(2).fill(skeletonAgreementTaxRate()),
    irpfTypes: Array(2).fill(skeletonAgreementIRPFType()),
    retention: SKELETON_KEY_NUMBER,
    retentionTerm: SKELETON_KEY_NUMBER
  };
}
