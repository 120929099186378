import { makeStyles } from '@material-ui/core/styles';

export const useAddressFormStyles = makeStyles(theme => ({
  form: {
    flexGrow: 1
  },
  divider: {
    width: '100%',
    marginTop: '1rem',
    marginBottom: '2rem'
  },
  formAddress: {
    display: 'flex',
    '& > div': {
      flexGrow: 1
    }
  }
}));
