export const SHAPE = {
  ROMBO: 50,
  SQUARE: 80,
  CIRCLE: 36
};

export const ACTIVITY_TYPE = {
  PARENT: 3
};

export const OVERLAYS_TYPES = {
  SUCCES: 'succes',
  SUCCES_OUT: 'succes-out',
  CURRENT: 'current',
  CURRENT_OUT: 'current-out',
  FUTURE: 'future',
  CURRENT_DANGER: 'current-danger',
  CURRENT_WARNING: 'current-warning',
  CURRENT_NORMAL: 'current-normal'
};
