import { APIS } from 'app.apis.const';
import { apiHelper } from 'shared';
import { http } from './http';

const controllerName = 'directory-templates';

class DirectoryTemplates {
  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/${controllerName}${queryString}`, {}, APIS.DOCUMENTARY);
    const items = response.data && response.data.items;
    return items;
  }

  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/${controllerName}/${queryString}`, {}, APIS.DOCUMENTARY);
  }

  async getTreeFromTemplate(directoryTemplateIds) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ directoryTemplateIds });

    const response = await http.get(`/${controllerName}/tree/${queryString}`, {}, APIS.DOCUMENTARY);
    const items = response.data && response.data.items;
    return items;
  }

  getById(id) {
    return http.get(`/${controllerName}/${id}`, {}, APIS.DOCUMENTARY);
  }

  create(directoryTemplate) {
    return http.post(`/${controllerName}`, directoryTemplate, {}, APIS.DOCUMENTARY);
  }

  update(directoryTemplate) {
    return http.put(`/${controllerName}/${directoryTemplate.id}`, directoryTemplate, {}, APIS.DOCUMENTARY);
  }

  delete(id, multiple) {
    return http.delete(`/${controllerName}/${id}`, { multiple }, APIS.CMMS);
  }
}

export const directoryTemplates = new DirectoryTemplates();
