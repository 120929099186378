import { useState, useEffect } from 'react';
import { processDefinitions } from 'services';
import { skeletonFactory } from 'shared';

const processVisibilitiesDummy = skeletonFactory.processVisibilities();

export function useProcessVisibilities() {
  const [processVisibilitiesData, setProcessVisibilitiesData] = useState(processVisibilitiesDummy);

  useEffect(() => {
    async function getData() {
      const response = await processDefinitions.getProcessVisibilities();
      if (!response) {
        return;
      }

      setProcessVisibilitiesData(response.data || []);
    }
    getData();
  }, []);

  return { processVisibilities: processVisibilitiesData, setProcessVisibilitiesData };
}
