import { APIS } from 'app.apis.const';
import { apiHelper } from 'shared';
import { http } from './http';
import FileSaver from 'file-saver';

const controllerName = 'real-estates';

class RealEstates {
  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/${controllerName}${queryString}`, {}, APIS.REAL_ESTATES);
    const items = response.data && response.data.items;

    return items;
  }

  async getTypesUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/real-estate-types${queryString}`, {}, APIS.REAL_ESTATES);
    const items = response.data && response.data.items;

    return items;
  }

  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/${controllerName}/${queryString}`, {}, APIS.REAL_ESTATES);
  }

  getById(id) {
    return http.get(`/${controllerName}/${id}`, {}, APIS.REAL_ESTATES);
  }

  create(realEstate) {
    return http.post(`/${controllerName}`, realEstate, {}, APIS.REAL_ESTATES);
  }

  delete(id, multiple) {
    return http.delete(`/assets/${controllerName}/${id}`, { multiple });
  }

  update(realEstate) {
    return http.put(
      `/${controllerName}/${realEstate.id}`,
      {
        ...realEstate,
        forceUpdateRealEstateType: true,
        forceUpdateAddress: true,
        forceUpdateContactMethod: true,
        forceUpdateSynchornization: true,
        forceUpdateCEE: true,
        forceUpdateElectricalPower: true
      },
      {},
      APIS.REAL_ESTATES
    );
  }

  getDocuments(id, paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/assets/${controllerName}/${id}/documents/${queryString}`, {});
  }

  async getCadastralInfo(cadastralReference) {
    const response = await http.get(`/catastro/${cadastralReference}`, {}, APIS.REAL_ESTATES);
    return response && response.data[0];
  }

  getDuplicates(realEstate) {
    let queryString = '';
    queryString = apiHelper.composeQueryString(realEstate);
    return http.get(`/${controllerName}/duplicates/${queryString}`, {}, APIS.REAL_ESTATES);
  }

  getOperationTypes() {
    return http.get(`/${controllerName}/operation-types`, {}, APIS.REAL_ESTATES);
  }

  getCEEs() {
    return http.get(`/${controllerName}/cees`, {}, APIS.REAL_ESTATES);
  }

  import(blobUrl) {
    const obj = { blobUrl: blobUrl };
    return http.post(`/${controllerName}/import`, obj, {}, APIS.REAL_ESTATES);
  }

  async downloadImportTemplate() {
    const headersT = await http.getBearerToken();
    const api_url = localStorage.getItem('REAL_ESTATES_API_URL');
    const options = {
      headers: headersT.headers,
      method: 'GET',
      responseType: 'blob'
    };

    const url = `${api_url}/api/v1.0/${controllerName}/import/template`;
    const response = await fetch(url, options);

    if (response && response.status === 200) {
      const miBlob = await response.blob().catch(e => {
        throw e;
      });
      FileSaver.saveAs(miBlob, 'import_real_estates_template.xlsx');
    }
  }
}

export const realEstates = new RealEstates();
