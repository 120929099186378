function downloadFile(url, name, version) {
  return new Promise((resolve, reject) => {
    if (version > 1) {
      // Raw bytes version
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = name;
      downloadLink.click();
      resolve();
    } else {
      // Base64 version
      decodeFileFromURL(url, response => {
        const downloadLink = document.createElement('a');
        downloadLink.href = response;
        downloadLink.download = name;
        downloadLink.click();
        resolve();
      });
    }
  });
}

function decodeFileFromURL(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      var dataUrl = reader.result;
      var base64 = dataUrl.split(',')[1];
      base64 = window.atob(base64);
      callback(dataUrl.split(',')[0] + ',' + base64);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
}

function download_files(files) {
  function download_next(i) {
    if (i >= files.length) {
      return;
    }
    downloadFile(files[i].document, files[i].documentName, files[i].version)
      .then(() => {
        setTimeout(() => {
          download_next(i + 1);
        }, 500);
      })
      .catch(error => {
        console.error(`Failed to download ${files[i].documentName}:`, error);
        download_next(i + 1);
      });
  }

  download_next(0);
}

const openFile = (url, version, fileName) => {
  const newWindow = window.open();
  newWindow.document.write(`<html><title>${fileName}</title><body style="margin: 0px"></body></html>`);
  if (version > 1) {
    fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const objectUrl = URL.createObjectURL(blob);
      newWindow.document.body.innerHTML += `<iframe src="${objectUrl}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`;
    })
    .catch(error => console.error('Error opening the file:', error));
  } else {
    return new Promise((resolve, reject) => {
      // Base64 version
      decodeFileFromURL(url, response => {
        newWindow.document.body.innerHTML += `<iframe src="${response}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`;
        resolve();
      });
    });
  }
};

export const domHelper = {
  downloadFile,
  download_files,
  openFile
};
