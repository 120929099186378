import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import AdjustIcon from '@material-ui/icons/Adjust';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import BarChart from '@material-ui/icons/BarChart';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import ClassOutlinedIcon from '@material-ui/icons/ClassOutlined';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import DescriptionIcon from '@material-ui/icons/Description';
import EqualizerOutlinedIcon from '@material-ui/icons/EqualizerOutlined';
import EventIcon from '@material-ui/icons/Event';
import EventNoteIcon from '@material-ui/icons/EventNote';
import GroupsIcon from '@material-ui/icons/Group';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import LocationCityOutlinedIcon from '@material-ui/icons/LocationCityOutlined';
import LoyaltyOutlinedIcon from '@material-ui/icons/LoyaltyOutlined';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';
import PostAddOutlinedIcon from '@material-ui/icons/PostAddOutlined';
import ReceiptIcon from '@material-ui/icons/Receipt';
import RecentActorsOutlinedIcon from '@material-ui/icons/RecentActorsOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import StoreOutlinedIcon from '@material-ui/icons/StoreOutlined';
import SupervisedUserCircleOutlinedIcon from '@material-ui/icons/SupervisedUserCircleOutlined';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import TimerIcon from '@material-ui/icons/Timer';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import TurnedInNotIcon from '@material-ui/icons/TurnedInNot';
import UpdateOutlinedIcon from '@material-ui/icons/UpdateOutlined';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import React from 'react';
import { matchPath } from 'react-router-dom';

import { PATH } from 'app.routes.const';
import { ACCESS } from 'auth';
import { NAME_SPACE } from 'i18n';
import { bigintHelper } from 'shared';
import { ICONS } from 'app.routes.const';

function generate(t) {
  const navigation = [
    {
      name: t(`${NAME_SPACE.WORK_ORDERS}:name`),
      icon: <WorkOutlineIcon />,
      paths: [
        {
          name: t(`${NAME_SPACE.WORK_ORDERS}:dashboard`),
          url: PATH.WORK_ORDERS.DASHBOARD,
          access: ACCESS.WORK_ORDERS_DASHBOARD,
          icon: <BarChart />,
          highlightedOnlyBy: [PATH.WORK_ORDERS.DASHBOARD]
        },
        {
          name: t(`${NAME_SPACE.WORK_ORDERS}:name`),
          url: PATH.WORK_ORDERS.LIST,
          access: ACCESS.WORK_ORDERS,
          icon: <WorkOutlineIcon />
        },
        {
          name: t(`${NAME_SPACE.CURRENT_MILESTONES}:name`),
          url: PATH.WORK_ORDERS.CURRENT_MILESTONES.LIST,
          access: ACCESS.WORK_ORDERS_PENDING_ACTIVITIES,
          icon: <CardTravelIcon />
        },
        {
          name: t(`${NAME_SPACE.WO_AUTOMATIONS}:name`),
          url: PATH.WORK_ORDERS.WO_AUTOMATION.LIST,
          access: ACCESS.WORK_ORDERS_AUTOMATIONS,
          icon: <TimerIcon />
        }
      ]
    },
    {
      name: t(`${NAME_SPACE.REAL_ESTATES}:name`),
      icon: <HomeWorkIcon />,
      paths: [
        {
          name: t(`${NAME_SPACE.REAL_ESTATES}:name`),
          url: PATH.REAL_ESTATES.LIST,
          access: ACCESS.REAL_ESTATES,
          icon: <HomeWorkIcon />
        }
      ]
    },
    {
      name: t('navigation.agenda.name'),
      icon: <EventIcon />,
      paths: [
        {
          name: t(`${NAME_SPACE.AGENDA}:name`),
          url: PATH.AGENDA.LIST,
          access: ACCESS.SCHEDULE,
          icon: <EventIcon />
        },
        {
          name: t(`${NAME_SPACE.APPOINTMENT_TYPES}:name`),
          url: PATH.APPOINTMENT_TYPES.LIST,
          access: ACCESS.APPOINTMENT_TYPES,
          icon: <EventNoteIcon />
        }
      ]
    },
    {
      name: t(`${NAME_SPACE.RATES}:name`),
      icon: <LocalOfferIcon />,
      paths: [
        {
          name: t(`${NAME_SPACE.RATES}:name`),
          url: PATH.RATES.LIST,
          access: ACCESS.RATES,
          icon: <LocalOfferIcon />
        },
        {
          name: t(`${NAME_SPACE.ITEMS}:name`),
          url: PATH.ITEMS.LIST,
          access: ACCESS.ITEMS,
          icon: <TurnedInNotIcon />
        },
        {
          name: t(`${NAME_SPACE.MEASURES}:name`),
          url: PATH.MEASURES.LIST,
          access: ACCESS.MEASURES,
          icon: <LinearScaleIcon />
        },
        {
          name: t(`${NAME_SPACE.AGGRUPATIONS}:name`),
          url: PATH.AGGRUPATIONS.LIST,
          access: ACCESS.AGGRUPATIONS,
          icon: <GroupWorkIcon />
        }
      ]
    },
    {
      name: t(`${NAME_SPACE.SUPPLIERS}:name`),
      prefixUrlName: 'supplier',
      icon: <BusinessCenterOutlinedIcon />,
      paths: [
        {
          name: t(`${NAME_SPACE.SUPPLIERS}:dashboard`),
          url: PATH.SUPPLIERS.DASHBOARD,
          access: ACCESS.SUPPLIERS_DASHBOARD,
          icon: <BarChart />,
          highlightedOnlyBy: [PATH.SUPPLIERS.DASHBOARD]
        },
        {
          name: t(`${NAME_SPACE.SUPPLIERS}:name`),
          url: PATH.SUPPLIERS.LIST,
          access: ACCESS.SUPPLIERS,
          icon: <BusinessCenterOutlinedIcon />,
          highlightedAlsoBy: [...getRoutes(PATH.SUPPLIERS.AGREEMENTS), ...getRoutes(PATH.SUPPLIERS.DOCUMENTS)]
        },
        {
          name: t(`${NAME_SPACE.CREDITORS}:name`),
          url: PATH.SUPPLIERS.CREDITORS.LIST,
          access: ACCESS.CREDITORS,
          icon: <GroupsIcon />,
          highlightedOnlyBy: [...getRoutes(PATH.SUPPLIERS.CREDITORS)]
        },
        {
          name: t(`${NAME_SPACE.SUPPLIER_TYPES}:name`),
          url: PATH.SUPPLIERS.SUPPLIER_TYPES.LIST,
          access: ACCESS.SUPPLIER_TYPES,
          highlightedOnlyBy: [...getRoutes(PATH.SUPPLIERS.SUPPLIER_TYPES)],
          icon: <PostAddOutlinedIcon />
        },
        {
          name: t(`${NAME_SPACE.SUPPLIERS}:specialities.name`),
          url: PATH.SUPPLIERS.SPECIALITIES.LIST,
          access: ACCESS.SPECIALITIES,
          icon: <ClassOutlinedIcon />,
          highlightedOnlyBy: [...getRoutes(PATH.SUPPLIERS.SPECIALITIES)]
        },
        {
          name: t(`${NAME_SPACE.DOCUMENT_TYPES}:name`),
          url: PATH.SUPPLIERS.DOCUMENT_TYPES.LIST,
          access: ACCESS.SUPPLIER_DOCUMENT_TYPES,
          highlightedOnlyBy: [...getRoutes(PATH.SUPPLIERS.DOCUMENT_TYPES)],
          icon: <PostAddOutlinedIcon />
        },
        {
          name: t(`${NAME_SPACE.SUPPLIER_DOCUMENT_GROUPS}:name`),
          url: PATH.SUPPLIERS.DOCUMENT_GROUPS.LIST,
          access: ACCESS.SUPPLIER_DOCUMENT_GROUPS,
          highlightedOnlyBy: [...getRoutes(PATH.SUPPLIERS.DOCUMENT_GROUPS)],
          icon: <CreateNewFolderIcon />
        },
        {
          name: t(`${NAME_SPACE.INSURANCE_COMPANIES}:name`),
          url: PATH.SUPPLIERS.INSURANCE_COMPANIES.LIST,
          access: ACCESS.INSURANCE_COMPANIES,
          highlightedOnlyBy: [...getRoutes(PATH.SUPPLIERS.INSURANCE_COMPANIES)],
          icon: <AssignmentTurnedInOutlinedIcon />
        },
        {
          name: t(`${NAME_SPACE.RECEIVED_INVOICES}:name`),
          url: PATH.SUPPLIERS.RECEIVED_INVOICES.LIST,
          access: ACCESS.RECEIVED_INVOICES,
          highlightedOnlyBy: [
            PATH.SUPPLIERS.RECEIVED_INVOICES.LIST,
            PATH.SUPPLIERS.RECEIVED_INVOICES.CREATE,
            PATH.SUPPLIERS.RECEIVED_INVOICES.EDIT
          ],
          icon: <ReceiptIcon />
        }
      ]
    },
    {
      name: t(`${NAME_SPACE.CUSTOMERS}:name`),
      prefixUrlName: 'customer',
      icon: <SupervisedUserCircleOutlinedIcon />,
      paths: [
        {
          name: t(`${NAME_SPACE.CUSTOMERS}:name`),
          url: PATH.CUSTOMERS.LIST,
          access: ACCESS.CUSTOMERS,
          icon: <SupervisedUserCircleOutlinedIcon />
        },
        {
          name: t(`${NAME_SPACE.CUSTOMERS}:groups.name`),
          url: PATH.CUSTOMERS.GROUPS.LIST,
          access: ACCESS.CUSTOMER_GROUPS,
          icon: <RecentActorsOutlinedIcon />,
          highlightedOnlyBy: [PATH.CUSTOMERS.GROUPS.LIST]
        },
        {
          name: t(`${NAME_SPACE.ISSUED_INVOICES}:name`),
          url: PATH.CUSTOMERS.ISSUED_INVOICES.LIST,
          access: ACCESS.ISSUED_INVOICES,
          icon: <ReceiptIcon />,
          highlightedOnlyBy: [
            PATH.CUSTOMERS.ISSUED_INVOICES.LIST,
            PATH.CUSTOMERS.ISSUED_INVOICES.EDIT,
            PATH.CUSTOMERS.ISSUED_INVOICES.CREATE
          ]
        }
      ]
    },
    {
      name: t('navigation.process'),
      icon: <AccountTreeOutlinedIcon />,
      paths: [
        {
          name: t(`${NAME_SPACE.SOURCES}:name`),
          url: PATH.SOURCES.LIST,
          access: ACCESS.SOURCES,
          icon: <EqualizerOutlinedIcon />
        },
        {
          name: t(`${NAME_SPACE.PROCESS_DEFINITION}:name`),
          url: PATH.PROCESS_DEFINITION.LIST,
          access: ACCESS.PROCESS_DEFINITIONS,
          icon: <AccountTreeOutlinedIcon />
        },
        {
          name: t(`${NAME_SPACE.PROCESS_DEFINITION}:roles.name`),
          url: PATH.PROCESS_DEFINITION.PROCESS_ROLES.LIST,
          access: ACCESS.PROCESS_ROLES,
          icon: <GroupAddOutlinedIcon />,
          highlightedOnlyBy: [PATH.PROCESS_DEFINITION.PROCESS_ROLES.LIST]
        },
        {
          name: t(`${NAME_SPACE.CALENDARS}:name`),
          url: PATH.CALENDARS.LIST,
          access: ACCESS.CALENDARS,
          icon: <CalendarTodayIcon />
        },
        {
          name: t(`${NAME_SPACE.WORK_ORDER_STATUSES}:name`),
          url: PATH.WORK_ORDER_STATUSES.LIST,
          access: ACCESS.PROCESS_STATES,
          icon: <AdjustIcon />
        }
      ]
    },
    {
      name: t('navigation.organization.name'),
      icon: <LocationCityOutlinedIcon />,
      paths: [
        {
          name: t(`${NAME_SPACE.COMPANIES}:name`),
          url: PATH.COMPANIES.LIST,
          access: ACCESS.COMPANIES,
          icon: <StoreOutlinedIcon />
        },
        {
          name: t(`${NAME_SPACE.BUSINESS_LINES}:name`),
          url: PATH.BUSINESS_LINES.LIST,
          access: ACCESS.BUSINESS_LINES,
          icon: <BusinessOutlinedIcon />
        },
        {
          name: t(`${NAME_SPACE.PROJECTS}:name`),
          url: PATH.PROJECTS.LIST,
          access: ACCESS.PROJECTS,
          icon: <LoyaltyOutlinedIcon />
        },
        {
          name: t(`${NAME_SPACE.WORK_GROUPS}:name`),
          url: PATH.WORK_GROUPS.LIST,
          access: ACCESS.WORK_GROUPS,
          icon: <GroupsIcon />
        },
        {
          name: t(`${NAME_SPACE.CONTACT_ROLES}:name`),
          url: PATH.CONTACT_ROLES.LIST,
          access: ACCESS.CONTACT_ROLES,
          icon: ICONS.CONTACT_ROLES.LIST
        },
        {
          name: t(`${NAME_SPACE.DOCUMENT_TYPES}:name`),
          url: PATH.DOCUMENT_TYPES.LIST,
          access: ACCESS.DOCUMENT_TYPES,
          icon: <PostAddOutlinedIcon />
        },
        {
          name: t(`${NAME_SPACE.GALLERY_TAGS}:name`),
          url: PATH.GALLERY_TAGS.LIST,
          access: ACCESS.GALLERY_TAGS,
          icon: <PhotoAlbumIcon />
        },
        {
          name: t(`${NAME_SPACE.REPORTS}:name`),
          url: PATH.REPORTS.LIST,
          access: ACCESS.REPORTS,
          icon: <DescriptionIcon />
        },
        {
          name: t(`${NAME_SPACE.DIRECTORY_TEMPLATES}:name`),
          url: PATH.DIRECTORY_TEMPLATES.LIST,
          access: ACCESS.DIRECTORY_TEMPLATES,
          icon: <FolderOpenIcon />
        },
        {
          name: t(`${NAME_SPACE.PAYMENT_METHODS}:name`),
          url: PATH.PAYMENT_METHODS.LIST,
          access: ACCESS.PAYMENT_METHODS,
          icon: <AccountBalanceWalletIcon />
        },
        {
          name: t(`${NAME_SPACE.ALERTS}:name`),
          url: PATH.ALERTS.LIST,
          access: ACCESS.ALERTS,
          icon: <NotificationsIcon />
        }
      ]
    },
    {
      name: t('navigation.configuration.name'),
      icon: <SettingsIcon />,
      paths: [
        {
          name: t(`${NAME_SPACE.USERS}:name`),
          url: PATH.USERS.LIST,
          access: ACCESS.USERS,
          icon: <GroupOutlinedIcon />
        },
        {
          name: t(`${NAME_SPACE.USERS}:roles.name`),
          url: PATH.USERS.ROLES.LIST,
          access: ACCESS.ROLES,
          icon: <GroupAddOutlinedIcon />,
          highlightedOnlyBy: [...getRoutes(PATH.USERS.ROLES)]
        },
        {
          name: t(`${NAME_SPACE.INTEGRATIONS}:name`),
          url: PATH.INTEGRATIONS.LIST,
          access: ACCESS.INTEGRATIONS,
          icon: <UpdateOutlinedIcon />
        },
        {
          name: t(`${NAME_SPACE.EQUIVALENCES}:name`),
          url: PATH.EQUIVALENCES.LIST,
          access: ACCESS.EQUIVALENCES,
          icon: <SyncAltIcon />
        }
      ]
    }
  ];
  navigation.forEach(nav => {
    nav.access = nav.paths.reduce((partialSum, path) => bigintHelper.or(partialSum, path.access), 0);
  });
  return navigation;
}

const getRoutes = (data, values = []) => {
  if (typeof data !== 'object') {
    return [...values, data];
  }
  return Object.values(data).flatMap(v => getRoutes(v, values));
};

const _guidRegex = /\b[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}\b/g;

const getCleanPath = pathname => {
  let cleanPath = pathname.toLowerCase().split(_guidRegex)[0].slice(0, -2);
  const numberOfSlash = cleanPath.split('/').length - 1;
  if (numberOfSlash >= 3) {
    cleanPath = cleanPath.split('/', 3).join('/');
  }
  return cleanPath;
};

function highlighted(navigations, pathname) {
  return navigations.map(navigation => {
    if (navigation.paths) {
      navigation.paths = navigation.paths
        ? navigation.paths.map(path => {
            const cleanPath = getCleanPath(pathname);
            if (pathname !== '/' && path.url.includes(cleanPath)) {
              if (navigation.prefixUrlName) {
                if (pathname.includes(navigation.prefixUrlName)) {
                  navigation.open = true;
                } else {
                  navigation.open = false;
                }
              } else {
                navigation.open = true;
              }
              if (path.highlightedOnlyBy) {
                const itsAMatch = path.highlightedOnlyBy.some(path => matchPath(pathname, { path }));
                path.highlighted = itsAMatch;
              } else {
                path.highlighted = true;
              }
            } else {
              if (path.highlightedAlsoBy) {
                const itsAMatch = path.highlightedAlsoBy.some(path => matchPath(pathname, { path }));
                path.highlighted = itsAMatch;
                if (itsAMatch) navigation.open = true;
              }
            }
            return path;
          })
        : null;
    } else {
      navigation.highlighted = pathname !== '/' && navigation.url && navigation.url.includes(pathname);
    }
    return navigation;
  });
}

function byAccess(navigations, access) {
  if (access) {
    return navigations.map(navigation => {
      navigation.show = bigintHelper.and(access, navigation.access);
      navigation.paths = navigation.paths
        ? navigation.paths.map(path => {
            path.show = bigintHelper.and(access, path.access);
            return path;
          })
        : null;

      return navigation;
    });
  }

  return navigations;
}

export const navigationHelper = {
  generate,
  highlighted,
  byAccess
};
