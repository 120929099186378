import { SKELETON_KEY, SKELETON_KEY_DATE } from './skeletonFactory';

export function skeletonBudgetItemSupplier() {
  return {
    id: SKELETON_KEY,
    fullName: SKELETON_KEY,
    supplier: SKELETON_KEY,
    supplierId: SKELETON_KEY,
    hireDate: SKELETON_KEY_DATE,
    isSelected: SKELETON_KEY
  };
}
