function convertIntToArray(constArray, number, t, parentToken) {
  const newArray = [];
  for (const [key, value] of Object.entries(constArray)) {
    if (value & number) {
      newArray.push({ id: value, name: t(`${parentToken}.${key.toLowerCase()}`) });
    }
  }
  return newArray;
}

function convertArrayToInt(newArray) {
  let number = 0;
  for (const item of newArray) {
    number |= item.id;
  }
  return number;
}

export const flagHelper = {
  convertIntToArray,
  convertArrayToInt
};
