import { useState, useEffect, useCallback } from 'react';
import { SKELETON_KEY } from 'shared';
import { objectHelper, skeletonFactory, useAzureStorage, useHandleRequest } from 'shared';

//TODO: canviar
const realEstateDocumentDummy = skeletonFactory.realEstateDocument();
//TODO: tipar
export function useDocument(entityId, extraIdRequest, id, service) {
  const [documentData, setDocumentData] = useState(id ? realEstateDocumentDummy : {});
  const { request, errorInfo, setErrorInfo } = useHandleRequest();
  const { uploadFile } = useAzureStorage(entityId);

  useEffect(() => {
    async function getData() {
      await request(async () => {
        const response = extraIdRequest
          ? await service.getById(entityId, ...extraIdRequest, id)
          : await service.getById(entityId, id);
        if (response) {
          setDocumentData(response.data || {});
        }
      });
    }
    id && getData();
  }, [request, entityId, id, extraIdRequest]);

  const createDocument = useCallback(
    async document => {
      await request(async () => {
        if (!objectHelper.isEmpty(document.selectedFile)) {
          setDocumentData(realEstateDocumentDummy);
          const urlFile = await uploadFile(document.selectedFile);
          document.documentUrl = urlFile;
          document.documentName = document.documentName || document.selectedFile.path;
          document.mimeType = document.selectedFile.mimeType;
        }

        setDocumentData(document);
        extraIdRequest
          ? await service.create(entityId, ...extraIdRequest, document)
          : await service.create(entityId, document);
      });
    },
    [request, entityId, uploadFile, extraIdRequest]
  );

  const updateDocument = useCallback(
    async document => {
      await request(async () => {
        if (!objectHelper.isEmpty(document.selectedFile)) {
          setDocumentData(prev => ({
            ...prev,
            realEstateDocument: SKELETON_KEY,
            realEstateDocumentName: SKELETON_KEY
          }));
          const urlFile = await uploadFile(document.selectedFile);
          document.documentUrl = urlFile;
          document.documentName = document.selectedFile.path;
        }

        setDocumentData(document);
        extraIdRequest
          ? await service.update(entityId, ...extraIdRequest, id, document)
          : await service.update(entityId, id, document);
      });
    },
    [request, entityId, id, uploadFile, extraIdRequest]
  );

  return {
    document: documentData,
    errorInfo,
    setErrorInfo,
    setDocument: setDocumentData,
    createDocument,
    updateDocument
  };
}
