export const STATUSES = {
  WORK_ORDER: {
    0: 'open',
    1: 'inProgress',
    2: 'cancelled',
    3: 'closed'
  },
  DOCUMENT: {
    0: 'pending',
    1: 'notApplicable',
    2: 'inProcess',
    3: 'available',
    4: 'sentToCustomer',
    5: 'sentToSupplier'
  },
  BUDGETITEMSTATUS: {
    0: 'noImpact',
    1: 'billable',
    2: 'billed',
    3: 'cancelled',
    4: 'pendingApproval',
    5: 'awarded'
  },
  BUDGETITEM_SUPPLIERBILLINGSTATUS: {
    0: 'pending',
    1: 'completedPendingValidation',
    2: 'billable',
    3: 'billed',
    4: 'canceled'
  },
  RECEIVEDINVOICE: {
    0: 'proforma',
    1: 'invoice'
  },
  ISSUEDINVOICE: {
    0: 'proforma',
    1: 'invoice'
  }
};

export const STATUS_TYPES = {
  WORK_ORDER: 'workOrder',
  DOCUMENT: 'document',
  BUDGETITEMSTATUS: 'budgetItemStatus',
  BUDGETITEM_SUPPLIERBILLINGSTATUS: 'budgetItem_supplierBillingStatus',
  RECEIVEDINVOICE: 'receivedInvoice',
  ISSUEDINVOICE: 'issuedInvoice'
};

export const REQUIRED_FILE_STATUS_WODOC = [3, 4, 5];
