import { makeStyles } from '@material-ui/core/styles';

export const useEditableTableStyles = makeStyles(theme => ({
  toolBarMargin: {
    marginTop: '0.5rem'
  },
  headCell: {
    color: theme.palette.primary.main + ' !important',
    fontWeight: theme.font.weight.black + ' !important',
    fontSize: '13px !important',
    minWidth: '6.5rem'
  },
  noStyle: {
    border: 'none',
    backgroundColor: 'transparent',
    fontSize: '12px',
    color: '#100760',
    width: '4.5rem'
  },

  table: {
    background: 'white',
    '& .MuiTableBody-root': {
      '& .MuiTableCell-root': {
        fontFamily: 'Montserrat'
      },
      '& .MuiTableRow-root': {
        transition: '0.1s ease-in-out',
        '&.isHeader:hover': {
          background: `${theme.palette.primary.main} !important`,
          '& .MuiTableCell-root': {
            color: 'white'
          },
          '& .MuiIconButton-label': {
            color: 'white !important'
          },
          '& .MuiSvgIcon-root': {
            color: 'white !important'
          },
          '& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track': {
            backgroundColor: theme.palette.primary.light
          }
        },
        '&.isChild:hover': {
          background: `${theme.palette.primary.light} !important`
        },
        '&:nth-child(odd)': {
          border: '1px solid red',
          backgroundColor: theme.palette.primary.lightest,
          '& >td': {
            backgroundColor: theme.palette.primary.lightest
          }
        },
        '&:nth-child(even)': {
          backgroundColor: 'white',
          '& >td': {
            backgroundColor: 'white'
          }
        }
      },
      '& .MuiTableRow-root .isChild': {
        '&:hover': {
          backgroundColor: theme.palette.primary.dark
        }
      }
    },
    '& .MuiTableCell-root': {
      borderBottom: 'none',
      padding: theme.spacing(2),
      fontSize: '12px',
      color: theme.palette.primary.dark
    },

    '& .MuiTableCell-stickyHeader': {
      backgroundColor: 'white'
    },
    '& .MuiTableRow-root.MuiTableRow-hover:hover': {
      backgroundColor: 'transparent'
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
      cursor: 'pointer'
    },
    '& .MuiFormControlLabel-root': {
      margin: 0,
      '& .MuiSwitch-root': {}
    }
  },
  totalCell: {
    position: 'sticky',
    right: 0
  },
  totalHeadCell: {
    position: 'sticky',
    right: 0,
    backgroundColor: 'white'
  }
}));
