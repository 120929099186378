import { apiHelper } from 'shared';
import { http } from './http';

const controllerName = 'work-orders';

class WorkOrdersRelated {
  get(workOrderId, paging, sorting, filters, advancedFilters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters, ...advancedFilters });
    return http.get(`/${controllerName}/${workOrderId}/related/${queryString}`);
  }

  getById(workOrderId, id) {
    return http.get(`/${controllerName}}/${workOrderId}/related/${id}`);
  }

  create(workOrderId, workOrderRelated) {
    return http.post(`/${controllerName}/${workOrderId}/related`, workOrderRelated);
  }

  delete(workOrderId, id, multiple) {
    return http.delete(`/${controllerName}/${workOrderId}/related/${id}`, { multiple });
  }

  getAvailableRelatedWorkOrders(workOrderId, paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });
    return http.get(`/${controllerName}/${workOrderId}/related/availables${queryString}`);
  }
}

export const workOrdersRelated = new WorkOrdersRelated();
