import { http } from './http';
import { apiHelper } from 'shared';
const controllerName = 'equivalences';

class Equivalences {
  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/${controllerName}/${queryString}`);
  }
  getById(id) {
    return http.get(`/${controllerName}/${id}`);
  }

  create(equivalence) {
    return http.post(`/${controllerName}`, equivalence);
  }

  delete(id, multiple) {
    return http.delete(`/${controllerName}/${id}`, { multiple });
  }

  update(equivalence) {
    return http.put(`/${controllerName}/${equivalence.id}`, equivalence);
  }
}

export const equivalences = new Equivalences();
