import { useSortable } from '@dnd-kit/sortable';
import { Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { PageHeader } from 'shared/PageHeader';
import { useWidgetsStyles } from './widgets.styles';

export const Widget = ({
  id,
  width = 1,
  children,
  title = '',
  icon,
  linkTo = '',
  handleDeleteWidget,
  stateLink = {}
}) => {
  const { setNodeRef, attributes, listeners, isDragging, transform, transition } = useSortable({
    id
  });
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useWidgetsStyles({ span: width, isDragging, transform, transition });

  return (
    <div ref={setNodeRef} className={classes.widget}>
      <PageHeader className={classes.pageHeader}>
        <div className={classes.title}>
          {icon && icon}
          {title}
        </div>

        <div className={classes.actions}>
          {linkTo && (
            <Tooltip title={t('common:actions.link')}>
              <OpenInNewIcon className={classes.links} onClick={() => history.push(linkTo, { state: stateLink })} />
            </Tooltip>
          )}
          <Tooltip title={t('common:actions.move')}>
            <button className={classes.drag} {...attributes} {...listeners}>
              <DragIndicatorIcon className={classes.drag} />
            </button>
          </Tooltip>
          <Tooltip title={t('common:actions.close')}>
            <button className={classes.drag}>
              <CloseIcon onClick={() => handleDeleteWidget(id)} />
            </button>
          </Tooltip>
        </div>
      </PageHeader>
      {children}
    </div>
  );
};

Widget.propTypes = {
  id: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  title: PropTypes.string,
  width: PropTypes.number,
  icon: PropTypes.node,
  linkTo: PropTypes.string,
  handleDeleteWidget: PropTypes.func,
  stateLink: PropTypes.object
};
