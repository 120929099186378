import React from 'react';
import PropTypes from 'prop-types';
import { PageHeader } from 'shared';

export function Dashboard({ pbixReportUrl, pageTitle, showTitle = true }) {
  return (
    <>
      {showTitle && <PageHeader>{pageTitle}</PageHeader>}
      <iframe
        title={pageTitle}
        src={pbixReportUrl}
        allowFullScreen="true"
        height="773.5"
        width="100%"
        frameBorder="0"
        border="0"
        cellSpacing="0"
      ></iframe>
    </>
  );
}

Dashboard.propTypes = {
  showTitle: PropTypes.bool,
  pbixReportUrl: PropTypes.string,
  pageTitle: PropTypes.string
};
