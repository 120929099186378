import { http } from './http';
import { APIS } from 'app.apis.const';
import { apiHelper } from 'shared';

const controllerName = 'suppliers';

class Suppliers {
  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/${controllerName}${queryString}`, {}, APIS.FINANCES);
    const items = response.data && response.data.items;

    return items;
  }

  async getByProjectUsingQueryFilter(projectId, queryFilter) {
    const queryString = apiHelper.composeQueryString({
      name: queryFilter,
      projectId,
      filterByProjectBusinessLine: true
    });
    const response = await http.get(`/${controllerName}${queryString}`, {}, APIS.FINANCES);
    const items = response.data && response.data.items;

    return items;
  }

  async getByDistrictUsingQueryFilter(districtId, stateId, countryId, queryFilter) {
    const queryString = apiHelper.composeQueryString({ name: queryFilter, districtId, stateId, countryId });
    const response = await http.get(`/${controllerName}${queryString}`, {}, APIS.FINANCES);
    const items = response.data && response.data.items;

    return items;
  }

  get(paging, sorting, filters, advancedFilters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({
      ...paging,
      ...sorting,
      ...filters,
      ...advancedFilters
    });

    return http.get(`/${controllerName}/all/${queryString}`, {}, APIS.FINANCES);
  }

  export(sorting, filters, advancedFilters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...sorting, ...filters, ...advancedFilters });
    return http.get(`/${controllerName}/export${queryString}`, {}, APIS.FINANCES);
  }

  getById(id) {
    return http.get(`/${controllerName}/${id}`, {}, APIS.FINANCES);
  }

  create(supplier) {
    return http.post(`/${controllerName}`, supplier, {}, APIS.FINANCES);
  }

  delete(id, multiple) {
    return http.delete(`/${controllerName}/${id}`, { multiple }, APIS.FINANCES);
  }

  update(id, supplier) {
    return http.put(`/${controllerName}/${id}`, { ...supplier, forceUpdateAddress: true }, {}, APIS.FINANCES);
  }

  activate(id) {
    return http.put(`/${controllerName}/${id}/activation`, {}, {}, APIS.FINANCES);
  }

  desactivate(id) {
    return http.delete(`/${controllerName}/${id}/activation`, {}, APIS.FINANCES);
  }

  async getPlacesTypes() {
    var response = await http.get(`/${controllerName}/geographical-scope`, {}, APIS.FINANCES);
    const scopeTypes = response && response.data;
    if (scopeTypes) {
      return scopeTypes.map(scopeType => {
        return { value: scopeType.id, name: scopeType.name };
      });
    }
  }
}

export const suppliers = new Suppliers();
