import { APIS } from 'app.apis.const';
import { apiHelper } from 'shared';
import { http } from './http';

class Specialities {
  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/item-categories${queryString}`, {});
    const items = response.data && response.data.items;

    return items;
  }

  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/specialities/${queryString}`, {}, APIS.FINANCES);
  }

  getById(id) {
    return http.get(`/specialities/${id}`, {}, APIS.FINANCES);
  }

  create(speciality) {
    return http.post('/specialities', speciality, {}, APIS.FINANCES);
  }

  delete(id, multiple) {
    return http.delete(`/specialities/${id}`, { multiple }, APIS.FINANCES);
  }

  update(speciality) {
    return http.put(`/specialities/${speciality.id}`, speciality, {}, APIS.FINANCES);
  }
}

export const specialities = new Specialities();
