import { APIS } from 'app.apis.const';
import { apiHelper } from 'shared';
import { http } from './http';

const controllerName = 'issued-invoices';

class IssuedInvoices {
  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/${controllerName}${queryString}`, {}, APIS.FINANCES);
    const items = response.data && response.data.items;

    return items;
  }

  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/${controllerName}/${queryString}`, {}, APIS.FINANCES);
  }

  getById(id) {
    return http.get(`/${controllerName}/${id}`, {}, APIS.FINANCES);
  }

  createOrUpdate(issuedInvoice) {
    return issuedInvoice.id ? this.update(issuedInvoice) : this.create(issuedInvoice);
  }

  create(issuedInvoice) {
    return http.post(`/${controllerName}`, issuedInvoice, {}, APIS.FINANCES);
  }

  delete(id, multiple) {
    return http.delete(`/${controllerName}/${id}`, { multiple }, APIS.FINANCES);
  }

  update(issuedInvoice) {
    return http.put(
      `/${controllerName}/${issuedInvoice.id}`,
      {
        ...issuedInvoice,
        forceUpdatePaymentMethodTerm: true,
        forceUpdateChargeDate: true,
        forceUpdateDueDate: true
      },
      {},
      APIS.FINANCES
    );
  }

  getPaymentMethods() {
    return http.get(`/${controllerName}/payment-methods`, {}, APIS.FINANCES);
  }

  export(id) {
    return http.post(`/${controllerName}/${id}/export`, {}, {}, APIS.FINANCES);
  }

  getSelfBillingFormats() {
    return http.get(`/${controllerName}/self-billing-formats`, {}, APIS.FINANCES);
  }

  getInvoiceDueDate(issuedInvoice) {
    const config = {
      disableErrorToasters: true
    };
    return http.post(`/${controllerName}/due-date`, issuedInvoice, config, APIS.FINANCES);
  }
}

export const issuedInvoices = new IssuedInvoices();
