import { makeStyles } from '@material-ui/core/styles';
import { color } from 'styles/color.styles';

export const useSimpleTableStyles = makeStyles(theme => ({
  table: {
    borderCollapse: 'collapse',
    margin: 0,
    padding: 0,
    width: '100%',
    tableLayout: 'fixed',
    marginTop: '10px',
    '& thead': {
      color: color.primary.main,
      borderBottom: `1px solid ${color.primary.main}`,
      textAlign: 'left',
      '& tr th': {
        padding: '5px 15px'
      }
    },
    '& tbody': {
      textAlign: 'left',
      '& tr': {
        borderBottom: `1px solid ${color.primary.light}`
      },
      '& tr th': {
        padding: '5px 15px'
      },
      '& tr td': {
        padding: '5px 15px',
        '& span': {
          fontSize: '14px',
          // backgroundColor: color.primary.lighter,
          borderRadius: '4px',
          padding: '2px 8px',
          marginLeft: '2px'
        }
      }
    }
  },
  button: {
    borderRadius: '4px',
    height: '25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: '0.3s',
    cursor: 'pointer',
    width: '35px',
    '& >svg': {
      margin: '0',
      width: '20px'
    }
  },
  delete: {
    background: color.buttons.delete.light,
    color: color.buttons.delete.main,
    '&:hover': {
      background: color.buttons.delete.main,
      color: 'white'
    },
    textTransform: 'none'
  },
  action: {
    width: '10%'
  }
}));
