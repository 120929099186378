import { ErrorSummary, SuccessSummary } from '@engloba-tech/englobity';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import DoneAll from '@material-ui/icons/DoneAll';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NAME_SPACE } from 'i18n';
import { useHandleRequest } from 'shared/useHandleRequest';

import { DialogModal } from 'shared';
import { useUserStateContext } from 'state/useUserStateContext';
import { useEditModalStyles } from './editModal.styles';

export function EditModal({
  entity,
  onSubmit,
  handleCloseEditModal,
  onSubmitEditModal,
  onKeepCreating,
  className,
  readonly,
  title,
  ...props
}) {
  const { t } = useTranslation(NAME_SPACE.COMMON);
  const classes = useEditModalStyles();
  const $formRef = useRef(null);
  const [keepCreating, setKeepCreating] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const { request } = useHandleRequest();

  const clearSummaries = () => {
    setErrorMessage(null);
    setSuccessMessage(null);
  };

  const handleAccept = keepCreating => {
    setKeepCreating(keepCreating);
    clearSummaries();
    $formRef.current.submit();
  };

  const { isLoading } = useUserStateContext();

  const handleSubmit = async element => {
    try {
      await request(async () => {
        const success = await onSubmitEditModal(element);
        if (!success) throw '';
        if (!keepCreating) {
          handleCloseEditModal();
        } else {
          setSuccessMessage(t('common:responses.addedSuccessfully'));
          setErrorMessage(null);
          onKeepCreating();
        }
      });
    } catch (error) {
      setSuccessMessage(null);
      setErrorMessage(error.message);
    }
  };

  const handleClose = () => {
    clearSummaries();
    handleCloseEditModal();
  };

  return (
    <>
      {entity && (
        <DialogModal
          fullWidth
          title={title ? title : entity.id ? t('actions.edit') : t('actions.newone')}
          description=""
          isOpen={true}
          id={'edit-modal'}
          className={clsx(className, classes.modal)}
          onClose={handleClose}
          buttons={
            readonly
              ? [
                  {
                    children: <CloseIcon />,
                    text: t('common:actions.close'),
                    type: 'secondary',
                    onClick: handleClose
                  }
                ]
              : [
                  {
                    children: <CloseIcon />,
                    text: t('common:actions.close'),
                    type: 'secondary',
                    onClick: handleClose
                  },
                  {
                    children: <DoneIcon />,
                    text: t('common:actions.accept'),
                    type: 'primary',
                    onClick: () => handleAccept(false),
                    loading: isLoading
                  },
                  !entity.id && {
                    children: <DoneAll />,
                    text: t('common:actions.createMore'),
                    type: 'primary',
                    onClick: () => handleAccept(true),
                    loading: isLoading
                  }
                ]
          }
        >
          {Boolean(successMessage) && <SuccessSummary text={successMessage} />}

          {Boolean(errorMessage) && <ErrorSummary text={t(errorMessage)} />}

          {React.cloneElement(props.children, {
            ...props.children,
            isModal: true,
            formRef: $formRef,
            onSubmit: handleSubmit
          })}
        </DialogModal>
      )}
    </>
  );
}

EditModal.propTypes = {
  onSubmit: PropTypes.func,
  entity: PropTypes.shape({
    id: PropTypes.string
  }),
  handleCloseEditModal: PropTypes.func.isRequired,
  onSubmitEditModal: PropTypes.func.isRequired,
  onKeepCreating: PropTypes.func,
  className: PropTypes.string,
  readonly: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.string
};
