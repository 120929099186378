import { http } from './http';
import { apiHelper } from 'shared';
import { APIS } from 'app.apis.const';

class DocumentTypes {
  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/document-types${queryString}`, {}, APIS.DOCUMENTARY);
    const items = response.data && response.data.items;

    return items;
  }

  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/document-types/${queryString}`, {}, APIS.DOCUMENTARY);
  }

  getById(id) {
    return http.get(`/document-types/${id}`, {}, APIS.DOCUMENTARY);
  }

  create(documentType) {
    return http.post('/document-types', documentType, {}, APIS.DOCUMENTARY);
  }

  delete(id, multiple) {
    return http.delete(`/document-types/${id}`, { multiple }, APIS.DOCUMENTARY);
  }

  update(documentType) {
    return http.put(`/document-types/${documentType.id}`, documentType, {}, APIS.DOCUMENTARY);
  }
}

export const documentTypes = new DocumentTypes();
