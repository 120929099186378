import { useState, useEffect } from 'react';
import { realEstates } from 'services';
import { skeletonFactory } from 'shared';

const ceesDummy = skeletonFactory.CEEs();

export function useCEEs() {
  const [ceesData, setCEEsData] = useState(ceesDummy);

  useEffect(() => {
    async function getData() {
      const response = await realEstates.getCEEs();
      if (!response) {
        return;
      }

      setCEEsData(response.data || []);
    }
    getData();
  }, []);

  return { cees: ceesData, setCEEsData };
}
