import { makeStyles } from '@material-ui/core/styles';

export const useTabsStyles = makeStyles(theme => ({
  tabsContainer: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
    background: 'white',
    marginTop: theme.spacing(3),
    boxShadow: theme.palette.boxShadow,
    borderRadius: '4px',
    flexGrow: 1,
    '& .toolbarContainerContent':{
      flexGrow: 0
    },
    '& .MuiBox-root':{
      marginTop: theme.spacing(3)
    },
    '& .MuiTab-root': {
      paddingTop: 0,
      opacity: 0.8,
      color: theme.palette.primary.dark,
      '&.Mui-selected':{
        opacity: 1
      },
      '& .MuiSvgIcon-root':{
        color: theme.palette.primary.main
      }
    }
  },
  flex:{
    display: 'flex',
    flexDirection: 'column'
  },
  tabsMinHeight: {
    height: '100%',
    '& form':{
      boxShadow: 'none',
      paddingLeft: 0,
      paddingTop: 0
    },
    minHeight: '22.1875rem',
    display: 'flex',
    '& .react-swipeable-view-container': {
      display: 'flex',
      width: '100%',
      '& > div': {
        width: '100%',
        '& #full-width-tabpanel-1':{
          height: '100%',
          marginTop: theme.spacing(3),
          '& .MuiBox-root': {
            height: '100%'
          }
        }
      }
    }
  },
}));
