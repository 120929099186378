import { WarningSummary } from '@engloba-tech/englobity';
import { Card, CardContent, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router';

import { UserContext } from 'auth';
import { ButtonBack, ButtonSave } from 'shared/Buttons';
import { PageHeader } from 'shared/PageHeader';
import { Toolbar } from 'shared/Toolbar';

import { useFormPageStyles } from './formPage.styles';

export function FormPage({
  children,
  disabled,
  title,
  className,
  isChildForm = false,
  onSubmit,
  notRequiredAccess,
  onBack,
  onSave,
  extraActions,
  formRef,
  disableUnsavedFeature,
  unstyled = false
}) {
  const classes = useFormPageStyles();
  const { t } = useTranslation();
  const [unsaved, setUnsaved] = useState(false);
  const validateUnsaved = onSubmit !== undefined;
  const formPageFormRef = useRef(null);

  const { hasFullAccess } = useContext(UserContext);

  function handleUnsavedInput() {
    setUnsaved(true);
  }

  function genericHandleSubmit(...params) {
    setUnsaved(false);
    onSubmit(...params);
  }

  function renderModifiedChildren() {
    const childrenWithProps = React.Children.map(children, child => {
      if (validateUnsaved && React.isValidElement(child))
        return React.cloneElement(child, {
          ...child.props,
          formRef: formRef ? formRef : formPageFormRef,
          onChangeInput: handleUnsavedInput,
          onSubmit: genericHandleSubmit,
          onSubformSubmit: () => setUnsaved(false)
        });
      else return child;
    });
    return childrenWithProps;
  }

  return (
    <div className={`${classes.root} ${className}`} style={{ minHeight: '100%' }}>
      <Prompt when={validateUnsaved && unsaved} message={t('unsaved')} />

      <div className={classes.header}>
        <PageHeader>{title}</PageHeader>
        <Toolbar>
          {onBack && <ButtonBack onClick={onBack} />}
          {extraActions}
          {!disabled && (
            <ButtonSave
              onClick={() => (onSave ? onSave() : formPageFormRef.current.submit())}
              disableUnsavedFeature={disableUnsavedFeature}
              unsaved={unsaved}
            />
          )}
        </Toolbar>
      </div>

      <Card
        className={`${unstyled ? classes.container : classes.containerStyled} ${
          isChildForm ? classes.containerChildForm : ''
        }`}
      >
        <CardContent className={`${classes.content} ${isChildForm ? classes.contentChildForm : ''}`}>
          {!hasFullAccess() && !notRequiredAccess && (
            <Grid item xs={12} className={classes.noWritePermission}>
              <WarningSummary className={classes.summary} text={t('common:validations:noWritePermission')} />
            </Grid>
          )}
          <>{renderModifiedChildren()}</>
        </CardContent>
      </Card>
    </div>
  );
}

FormPage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  onBack: PropTypes.func,
  onSave: PropTypes.func,
  extraActions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  formRef: PropTypes.string,
  disableUnsavedFeature: PropTypes.bool,
  unstyled: PropTypes.bool,
  breadcrumbsEntries: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      paths: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          action: PropTypes.string
        })
      )
    })
  ),
  isChildForm: PropTypes.bool,
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  notRequiredAccess: PropTypes.bool
};

FormPage.defaultProps = {
  title: '',
  breadcrumbsEntries: []
};
