import { SKELETON_KEY } from './skeletonFactory';

export function skeletonWorkOrderAutomation() {
  return {
    id: SKELETON_KEY,
    name: SKELETON_KEY,
    project: SKELETON_KEY,
    source: SKELETON_KEY
  };
}
