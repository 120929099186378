import { useCallback, useContext, useEffect } from 'react';
import { SignalRNotificationsContext } from 'signalR';

let eventDeck = {};
export function useNotificationsSignalR() {
  const { connection, connected } = useContext(SignalRNotificationsContext);

  const newNotification = 'new-notification';

  const disconnectNotifications = useCallback(() => {
    if (eventDeck[newNotification]?.connected) connection.off(newNotification, eventDeck[newNotification].handler);

    eventDeck = {};
  }, [connection]);

  const registerEvent = useCallback(
    (message, handler) => {
      if (eventDeck[message]?.connected) {
        connection.off(message, eventDeck[message].handler);
      }

      eventDeck[message] = { handler, connected };
      if (connected) {
        connection.on(message, handler);
      }
    },
    [connection, connected]
  );

  useEffect(() => {
    if (connected) {
      if (eventDeck)
        Object.keys(eventDeck).forEach(att => {
          if (!eventDeck[att].connected) registerEvent(att, eventDeck[att].handler);
        });
    }

    return () => {
      if (connected) {
        disconnectNotifications();
      }
    };
  }, [connected, registerEvent, disconnectNotifications]);

  const onNewNotification = useCallback(
    handler => {
      registerEvent(newNotification, handler);
    },
    [registerEvent]
  );

  return {
    disconnectNotifications,
    onNewNotification
  };
}
