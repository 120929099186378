import { APIS } from 'app.apis.const';
import { apiHelper } from 'shared';
import { http } from './http';

const controllerName = 'customers';
class Customers {
  async getByProject(projectId, queryFilter, customerGroup) {
    const queryString = apiHelper.composeQueryString({ name: queryFilter, projectId, customerGroups: customerGroup });
    const response = await http.get(`/${controllerName}${queryString}`, {}, APIS.FINANCES);
    const items = response.data && response.data.items;

    return items;
  }

  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ reference: queryFilter });
    const response = await http.get(`/${controllerName}/all/${queryString}`, {}, APIS.FINANCES);
    const items = response.data && response.data.items;

    return items;
  }

  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/${controllerName}/all/${queryString}`, {}, APIS.FINANCES);
  }

  getById(id) {
    return http.get(`/${controllerName}/${id}`, {}, APIS.FINANCES);
  }

  create(customer) {
    return http.post(`/${controllerName}`, this.formatRequest(customer), {}, APIS.FINANCES);
  }

  delete(id, multiple) {
    return http.delete(`/${controllerName}/${id}`, { multiple }, APIS.FINANCES);
  }

  update(id, customer) {
    return http.put(`/${controllerName}/${id}`, this.formatRequest(customer), {}, APIS.FINANCES);
  }

  activate(id) {
    return http.put(`/${controllerName}/${id}/activation`, {}, {}, APIS.FINANCES);
  }
  desactivate(id) {
    return http.delete(`/${controllerName}/${id}/activation`, {}, APIS.FINANCES);
  }

  formatRequest(customer) {
    return {
      ...customer,
      customerGroupsIds: customer.customerGroups.map(cg => {
        return cg.id;
      })
    };
  }
}

export const customers = new Customers();
