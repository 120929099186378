import { color } from './color.styles';

export const globals = {
  html: {
    fontSize: '16px', // 1rem = 16px,
    height: '100%'
  },
  body: {
    '& *': {
      fontFamily: 'Montserrat'
    },
    fontFamily: 'Montserrat',
    fontWeight: '300',
    backgroundColor: color.primary.lighter,
    height: '100%',
    '& .MuiGrid-root.MuiGrid-container': {
      // height: '100%'
    }
  },
  '.MuiTooltip-popper': {
    '& .MuiTooltip-tooltip': {
      fontSize: '0.875rem !important',
      fontFamily: 'Montserrat !important'
    },
    zIndex: '2300 !important'
  },
  '.MuiButton-label': {
    fontFamily: 'Montserrat'
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: color.primary.main
  },
  '.MuiInputBase-root': {
    fontSize: 'inherit',
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: `${color.primary.main}`
    },
    '&.Mui-disabled': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.26) !important'
      }
    },
    '&.Mui-error': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: `${color.toast.error} !important`
      }
    }
  },
  '.MuiInputLabel-outlined': {
    backgroundColor: 'white',
    padding: '0 0.5rem 0 0.2rem'
  },
  '.MuiTypography-root': {
    fontFamily: 'Montserrat'
  },
  '#alert_title': {
    fontWeight: 'bold',
    marginBottom: '0'
  },
  '#nprogress': {
    width: '100%',
    position: 'absolute',
    top: 0,
    zIndex: '9999',
    '& bar': {
      zIndex: '9999',
      backgroundColor: color.primary.main
    },
    '& peg': {
      zIndex: '9999',

      boxShadow: 'none'
    }
  },
  '.MuiAlert-root': {
    padding: '0px 15px !important',
    lineHeight: 1,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row !important'
    // marginBottom: '24px'
  },
  '.MuiAlert-standardInfo': {
    padding: '0px 15px !important',
    backgroundColor: color.primary.light,
    color: color.primary.main,
    '& .MuiAlert-icon': {
      color: color.primary.main
    }
    // border: '1px solid #b0ddff'
  },
  '.MuiAlert-standardWarning': {
    padding: '0px 15px !important',
    border: '1px solid #ffcf88'
  },
  '.MuiAlert-standardSuccess': {
    padding: '0px 15px !important',
    border: '1px solid #7bd76a'
  },
  '.MuiAlert-standardError': {
    alignItems: 'center',
    padding: '0px 15px !important',
    color: 'white',
    backgroundColor: color.toast.error,
    '& .MuiAlert-icon': {
      color: 'white'
    },
    '& .MuiAlert-message': {
      '& p': {
        color: 'white !important',
        fontWeight: 'bold !important'
      }
    }
  },
  '.MuiToolbar-root': {
    minHeight: 'auto'
  },
  '.MuiPaper-elevation1.MuiPaper-rounded': {
    boxShadow: color.boxShadow,
    '& .MuiCardContent-root': {
      padding: 0
    }
  },
  '.MuiTypography-h6': {
    fontWeight: 'bold',
    fontSize: '1.2rem'
  },
  '.MuiTableFooter-root': {
    backgroundColor: color.table.summary
  },
  '.MuiTableCell-root': {
    '& .MuiCollapse-root': {
      marginTop: '5px'
    },
    // padding: '5px 15px !important',

    '& .MuiIconButton-root': {
      padding: 0
    },
    '& .MuiSwitch-thumb': {
      marginTop: '9px'
    },
    '& .MuiSwitch-colorPrimary': {
      '&.Mui-checked': {
        marginLeft: '15px'
      }
    }
  },

  '.viewtable': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  '.MuiAutocomplete-popper': {
    zIndex: '2300 !important'
  },
  '.MuiPopover-root': {
    zIndex: '2300 !important'
  },
  '.MuiSnackbar-root': {
    zIndex: '2100'
  },
  '#global div .MuiSnackbar-root': {
    zIndex: '2300'
  },
  '.MuiDialog-root': {
    zIndex: '2200 !important',
    '& .MuiDialogTitle-root': {
      marginBottom: '1.5rem !important'
    }
  },
  '.MuiDialog-paperScrollPaper': {
    display: 'flex',
    padding: '0',
    '& .viewtable': {
      '& > .MuiPaper-root': {
        paddingBottom: 0,
        marginBottom: 0
      }
    },
    '& .MuiGrid-root.MuiGrid-container': {
      height: 'auto'
    },
    '& .MuiAlert-root': {
      display: 'flex',
      alignItems: 'center',
      '& h6': {
        fontSize: '13px'
      }
    },
    '&.MuiDialog-paperWidthSm.MuiDialog-paperFullWidth': {
      width: 'auto',
      '& .MuiDialogContent-root': {
        '& form': {
          padding: '0.3rem'
        }
      },
      '& .MuiDialogActions-root': {
        marginTop: '1.5rem'
      },
      '& h2': {
        textAlign: 'center',
        fontWeight: 'bold'
      }
    },
    '& .MuiPaper-root': {
      boxShadow: 'none'
    },
    '& .MuiCollapse-root': {
      '&.MuiCollapse-hidden': {
        marginBottom: '0px'
      }
    }
  },
  '.MuiAppBar-root': {
    padding: '0 !important'
  },
  '.MuiTab-root': {
    height: 'auto !important'
  },
  '.react-swipeable-view-container': {
    '& div[data-swipeable=true]': {
      overflow: 'hidden !important'
    }
  },
  '.MuiBox-root': {
    '& .MuiPaper-root': {
      '& .MuiAlert-standardInfo.MuiAlert-standardWarning.MuiAlert-standardError': {
        '& .MuiAlert-icon': {
          marginLeft: '10px'
        },
        '& .MuiAlert-action': {
          marginRight: '6px'
        }
      }
    },
    '& .viewtable': {
      paddingBottom: 0,
      '& > .MuiPaper-root': {
        padding: '0 !important'
      }
    }
  },
  '.MuiDialogContent-root': {
    '& .viewtable': {
      padding: 0
    }
  },
  '.MuiDialogContentText-root': {
    fontSize: '13px',
    textAlign: 'center'
  },
  '.react-bpmn-diagram-container *': {
    fontFamily: 'inherit'
  },

  '.MuiLink-root': {
    cursor: 'pointer'
  },
  '.djs-label': {
    '& tspan': {
      fontFamily: 'PT Sans Caption, sans-serif, arial, Times New Roman'
    }
  },
  '.MuiChip-root': {
    borderRadius: '8px !important'
  }
  // '.MuiCollapse-entered':{
  //   marginBottom: '0 !important'
  // }
};
