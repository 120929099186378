import React from 'react';
import PropTypes from 'prop-types';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import allLocales from '@fullcalendar/core/locales-all';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';

export function Calendar({
  events,
  onDateClick,
  onEventClick,
  onChangeDatesClick,
  eventContentRenderer,
  firstDayOfWeek = 1,
  ...props
}) {
  const { t } = useTranslation(NAME_SPACE.COMMON);

  return (
    <FullCalendar
      plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
      locales={allLocales}
      initialView="timeGridWeek"
      headerToolbar={{
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
      }}
      eventContent={eventContentRenderer}
      firstDay={firstDayOfWeek}
      dateClick={onDateClick}
      eventClick={onEventClick}
      datesSet={onChangeDatesClick}
      events={events}
      locale={window?.localStorage?.i18nextLng || 'en'}
      slotEventOverlap={false}
      allDayText={t('calendar.allDay')}
      buttonText={{
        today: t('calendar.today'),
        month: t('calendar.month'),
        week: t('calendar.week'),
        day: t('calendar.day'),
        list: t('calendar.list')
      }}
      titleFormat={{
        month: 'long',
        year: 'numeric',
        day: 'numeric'
      }}
      eventTimeFormat={{
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      }}
      {...props}
    />
  );
}
Calendar.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      start: PropTypes.string,
      end: PropTypes.string,
      color: PropTypes.string,
      extendedProps: PropTypes.object
    })
  ).isRequired,
  onDateClick: PropTypes.func,
  onEventClick: PropTypes.func,
  onChangeDatesClick: PropTypes.func,
  eventContentRenderer: PropTypes.func,
  firstDayOfWeek: PropTypes.number
};
