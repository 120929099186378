import { useHandleOpen } from '@engloba-tech/englobity';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import {
  ButtonAdd,
  ButtonDelete,
  DialogModal,
  EditModal,
  ToolbarContainer,
  ViewTable,
  domHelper,
  useCheckedElements,
  useDocument,
  useDocumentsPaginated,
  useSelectedElement
} from 'shared';

import { useViewDocumentsStyles } from './viewDocuments.styles';

export function ViewDocuments({
  entityId,
  useBuildTable,
  service,
  component: Component,
  children,
  extraIdRequest,
  checkedProps,
  afterSave,
  viewTableProps = {},
  t,
  disabled,
  defaultFilters
}) {
  const $formWorkOrderDocumentRef = useRef(null);
  const { documents, getDocuments, deleteDocuments, refresh } = useDocumentsPaginated({
    entityId,
    service,
    extraIdRequest,
    afterSave,
    t,
    ...checkedProps,
    defaultFilters
  });
  const { selectedElement: selectedDocument, handleSelect, handleUnselect } = useSelectedElement();
  const { document, createDocument, updateDocument } = useDocument(
    entityId,
    extraIdRequest,
    selectedDocument?.id,
    service
  );
  const { isOpen, handleClose, handleOpen } = useHandleOpen(false);
  const classes = useViewDocumentsStyles();

  function handleDownload({ documentUrl, documentName, version }) {
    domHelper.downloadFile(documentUrl, documentName, version);
  }

  const { rows, headCells } = useBuildTable(documents, t, handleSelect, handleDownload);
  const {
    checkedElements,
    getDeleteDescription,
    handleCheckedElements,
    onCheckElement,
    isAnyChecked,
    onCheckAllElements,
    checkedElementsCount,
    isChecked
  } = useCheckedElements({
    t,
    mainKey: checkedProps.mainKey,
    prefix: checkedProps.prefix,
    action: deleteDocuments
  });

  const handleCreateDocumentSubmit = () => {
    $formWorkOrderDocumentRef.current.submit();
  };

  const handleFormSubmit = async document => {
    try {
      document.id ? await updateDocument(document) : await createDocument(document);
      refresh();
      afterSave && afterSave();
      handleUnselect();
      handleClose();
      return true;
    } catch (e) {
      throw new Error(e);
    }
  };
  return (
    <>
      {children && children}
      <ToolbarContainer
        className={children && classes.marginTop}
        title={t('documents.name')}
        actions={
          !disabled && (
            <>
              <ButtonAdd onClick={handleOpen} disabled={isAnyChecked} />
              <ButtonDelete
                elementsToDelete={checkedElements}
                confirmDescription={getDeleteDescription}
                onConfirm={handleCheckedElements}
                disabled={!isAnyChecked}
              />
            </>
          )
        }
      >
        <ViewTable
          {...viewTableProps}
          rows={rows}
          cells={headCells}
          serverSidePaging
          onFetchData={getDocuments}
          totalRows={documents.total}
          onCheckElement={onCheckElement}
          onCheckAllElements={onCheckAllElements}
          checkedElementsCount={checkedElementsCount}
          isChecked={isChecked}
          isAnyChecked={isAnyChecked}
        />
      </ToolbarContainer>
      {!disabled && (
        <>
          <EditModal
            entity={selectedDocument}
            handleCloseEditModal={handleUnselect}
            onSubmitEditModal={handleFormSubmit}
            className={classes.modal}
          >
            <Component containerId={entityId} document={document} />
          </EditModal>
          <DialogModal
            fullWidth
            className={classes.modal}
            title={t('common:documents.dialogTitleAdd')}
            isOpen={isOpen}
            onClose={handleClose}
            buttons={[
              {
                children: <CloseIcon />,
                text: t('common:actions.close'),
                type: 'secondary',
                onClick: handleClose
              },
              {
                children: <DoneIcon />,
                text: t('common:actions.accept'),
                type: 'primary',
                onClick: handleCreateDocumentSubmit
              }
            ]}
          >
            <Component containerId={entityId} onSubmit={handleFormSubmit} formRef={$formWorkOrderDocumentRef} />
          </DialogModal>
        </>
      )}
    </>
  );
}
ViewDocuments.propTypes = {
  entityId: PropTypes.string.isRequired,
  extraIdRequest: PropTypes.array,
  service: PropTypes.func.isRequired,
  useBuildTable: PropTypes.func.isRequired,
  component: PropTypes.node.isRequired,
  children: PropTypes.node,
  t: PropTypes.func,
  viewTableProps: PropTypes.object,
  checkedProps: PropTypes.shape({
    mainKey: PropTypes.string,
    prefix: PropTypes.string
  }).isRequired,
  afterSave: PropTypes.func,
  disabled: PropTypes.bool,
  defaultFilters: PropTypes.object
};
