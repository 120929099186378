import { SKELETON_KEY, SKELETON_KEY_DATE, SKELETON_KEY_NUMBER } from './skeletonFactory';

export function skeletonIssuedInvoiceLine() {
  return {
    id: SKELETON_KEY,
    externalReference: SKELETON_KEY,
    districtId: SKELETON_KEY_DATE,
    quantity: SKELETON_KEY_NUMBER,
    amount: SKELETON_KEY_NUMBER
  };
}

export function skeletonIssuedInvoiceDocument() {
  return {
    id: SKELETON_KEY,
    issuedInvoiceId: SKELETON_KEY,
    documentName: SKELETON_KEY,
    documentUrl: SKELETON_KEY,
    entryDate: SKELETON_KEY_DATE,
    userId: SKELETON_KEY,
    user: SKELETON_KEY,
    remarks: SKELETON_KEY,
    documentType: SKELETON_KEY,
    documentTypeId: SKELETON_KEY
  };
}

export function skeletonIssuedInvoice(linesNum) {
  return {
    id: SKELETON_KEY,
    chargeDate: SKELETON_KEY_DATE,
    invoiceDate: SKELETON_KEY_DATE,
    invoiceNumber: SKELETON_KEY,
    customerName: SKELETON_KEY,
    customerFirstSurname: SKELETON_KEY,
    customerSecondSurname: SKELETON_KEY,
    customerFullName: SKELETON_KEY,
    dueDate: SKELETON_KEY_DATE,
    taxBase: SKELETON_KEY_NUMBER,
    outlays: SKELETON_KEY_NUMBER,
    applicableTaxType: SKELETON_KEY_NUMBER,
    tax: SKELETON_KEY_NUMBER,
    total: SKELETON_KEY_NUMBER,
    districtIds: Array(linesNum).fill(SKELETON_KEY),
    status: SKELETON_KEY_NUMBER,
    project: SKELETON_KEY,
    paymentMethodTermName: SKELETON_KEY,
    lines: Array(linesNum).fill(skeletonIssuedInvoiceLine())
  };
}
