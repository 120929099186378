import { apiHelper } from 'shared';
import { http } from './http';

const controllerName = 'work-orders';
const controlerRoute = 'budget-items';
const routeBreakdowns = 'breakdowns';

class Breakdowns {
  async getUsingQueryFilter(workOrderId, budgetItemId, queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ description: queryFilter });
    const response =  await http.get(
      `/${controllerName}/${workOrderId}/${controlerRoute}/${budgetItemId}/${routeBreakdowns}/${queryString}`
    );
    const items = response.data && response.data.items;

    return items;
  }

  get(workOrderId, budgetItemId, paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(
      `/${controllerName}/${workOrderId}/${controlerRoute}/${budgetItemId}/${routeBreakdowns}/${queryString}`
    );
  }

  getById(workOrderId, budgetItemId, id) {
    return http.get(`/${controllerName}/${workOrderId}/${controlerRoute}/${budgetItemId}/${routeBreakdowns}/${id}`);
  }

  create(breakdown, workOrderId, budgetItemId) {
    return http.post(`/${controllerName}/${workOrderId}/budget-items/${budgetItemId}/${routeBreakdowns}`, breakdown);
  }

  update(breakdown, workOrderId, budgetItemId) {
    return http.put(
      `/${controllerName}/${workOrderId}/budget-items/${budgetItemId}/${routeBreakdowns}/${breakdown.id}`,
      breakdown
    );
  }

  delete(workOrderId, budgetItemId, id, multiple) {
    return http.delete(`/${controllerName}/${workOrderId}/budget-items/${budgetItemId}/${routeBreakdowns}/${id}`, {
      multiple
    });
  }
}

export const workOrderBudgetItemBreakdowns = new Breakdowns();
