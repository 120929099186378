import { apiHelper } from 'shared';
import { http } from './http';

const controllerName = 'appointments';

class Appointments {
  get(filters) {
    let queryString = apiHelper.composeQueryString({ ...filters });

    return http.get(`/${controllerName}${queryString}`);
  }
}

export const appointments = new Appointments();
