import { http } from './http';
import { apiHelper } from 'shared';
import { APIS } from 'app.apis.const';

const controllerName = 'real-estates';
const partialName = 'documents';

class RealEstateDocuments {
  get(realEstateId, paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });
    return http.get(`/assets/${controllerName}/${realEstateId}/documents/${queryString}`, {});
  }

  getById(realEstateId, id) {
    return http.get(`/${controllerName}/${realEstateId}/${partialName}/${id}`, {}, APIS.REAL_ESTATES);
  }

  create(realEstateId, document) {
    const { fileObject, selectedFile, ...cleanedRequest } = document;
    return http.post(`/${controllerName}/${realEstateId}/${partialName}`, cleanedRequest, {}, APIS.REAL_ESTATES);
  }

  delete(realEstateId, id, multiple) {
    return http.delete(`/${controllerName}/${realEstateId}/${partialName}/${id}`, { multiple }, APIS.REAL_ESTATES);
  }

  update(realEstateId, id, document) {
    const { fileObject, selectedFile, ...cleanedRequest } = document;
    return http.put(`/${controllerName}/${realEstateId}/documents/${id}`, cleanedRequest, {}, APIS.REAL_ESTATES);
  }
}

export const realEstateDocuments = new RealEstateDocuments();
