import { useState, useEffect } from 'react';
import { issuedInvoices } from 'services';
import { useTranslation } from 'react-i18next';
import { stringHelper } from 'shared';

export function useSelfBillingFormats(withAnyLabel) {
  const { t } = useTranslation();
  const [selfBillingFormats, setSelfBillingFormats] = useState([]);

  useEffect(() => {
    async function getSelfBillingFormats() {
      const selfBillingFormats = await issuedInvoices.getSelfBillingFormats();
      if (selfBillingFormats?.data.length) {
        const selfBillingFormatsTranslated = selfBillingFormats.data.map(format => {
          return {
            value: format.id,
            name: t(`issuedInvoices:selfBilling.formats.${stringHelper.desCapitalizeFirstLetter(format.name)}`)
          };
        });
        setSelfBillingFormats(
          withAnyLabel
            ? [{ value: undefined, name: t('common:options.choose'), isAny: true }, ...selfBillingFormatsTranslated]
            : selfBillingFormatsTranslated
        );
      }
    }
    getSelfBillingFormats();
  }, [t, withAnyLabel]);

  return { selfBillingFormats };
}
