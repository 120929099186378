import { ButtonDelete } from '@engloba-tech/englobity';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from 'auth';
import { execConfirmModal } from 'shared';
import { useUserStateContext } from 'state/useUserStateContext';

export function CmmsButtonDelete({
  elementsToDelete,
  tooltip,
  text,
  onConfirm,
  confirmDescription,
  requiredAccess,
  onClick,
  noText,
  ...props
}) {
  const { t } = useTranslation();
  const disabled = !elementsToDelete?.length;
  const { hasFullAccess } = useContext(UserContext);
  const { isLoading } = useUserStateContext();

  const renderButton = () =>
    hasFullAccess() && (
      <ButtonDelete
        disabled={disabled}
        text={noText ? '' : text ? text : t('actions.remove')}
        tooltip={{
          title: (disabled && (tooltip?.disabled || t('actions.disabled.select'))) || '',
          placement: 'top'
        }}
        onClick={async () =>
          onClick
            ? await onClick(elementsToDelete, onConfirm)
            : await execConfirmModal({
                title: t('common:actions.remove'),
                description: confirmDescription(elementsToDelete?.length),
                confirmText: t('common:actions.accept'),
                declineText: t('common:actions.cancel'),
                onConfirm: async () => await onConfirm(elementsToDelete)
              })
        }
        loading={isLoading}
        {...props}
      />
    );

  return requiredAccess ? hasFullAccess(requiredAccess) && renderButton() : hasFullAccess() && renderButton();
}

CmmsButtonDelete.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  elementsToDelete: PropTypes.arrayOf(PropTypes.object).isRequired,
  tooltip: PropTypes.PropTypes.object,
  text: PropTypes.string,
  confirmTitle: PropTypes.func,
  confirmDescription: PropTypes.func,
  requiredAccess: PropTypes.bool
};
