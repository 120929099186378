import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import EqualizerOutlinedIcon from '@material-ui/icons/EqualizerOutlined';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import GroupsIcon from '@material-ui/icons/Group';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import LoyaltyOutlinedIcon from '@material-ui/icons/LoyaltyOutlined';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';
import PostAddOutlinedIcon from '@material-ui/icons/PostAddOutlined';
import ReceiptIcon from '@material-ui/icons/Receipt';
import StoreOutlinedIcon from '@material-ui/icons/StoreOutlined';
import SupervisedUserCircleOutlinedIcon from '@material-ui/icons/SupervisedUserCircleOutlined';
import TimerIcon from '@material-ui/icons/Timer';
import TurnedInNotIcon from '@material-ui/icons/TurnedInNot';
import UpdateOutlinedIcon from '@material-ui/icons/UpdateOutlined';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import React from 'react';
export const ANONYMOUS_PATH = {
  CALLBACK: '/callback',
  ERROR: '/error',
  RENEW: '/renew'
};

export const PATH = {
  HOME: '/',
  HOMEPAGE: '/home',
  WORK_ORDERS: {
    DASHBOARD: '/work-orders/dashboard',
    LIST: '/work-orders',
    EDIT: '/work-order/:id',
    EDIT_WITH_TAB: {
      DOCUMENTS: '/work-order/:id?tab=3',
      BUDGET_ITEMS: '/work-order/:id?tab=2'
    },
    CREATE: '/work-order',
    CREATE_WITH_SOURCE: '/work-order?withSource=true',
    DOCUMENTS: {
      CREATE: '/work-order/:workOrderId/document',
      EDIT: '/work-order/:workOrderId/document/:id'
    },
    CURRENT_MILESTONES: {
      LIST: '/current-milestones'
    },
    WO_AUTOMATION: {
      LIST: '/wo-automations',
      CREATE: '/wo-automation',
      EDIT: '/wo-automation/:id'
    },
    BUDGET_ITEMS: {
      CREATE: '/work-order/:workOrderId/budgetItem',
      EDIT: '/work-order/:workOrderId/budgetItem/:id'
    }
  },
  REAL_ESTATES: {
    LIST: '/real-estates',
    EDIT: '/real-estate/:id',
    CREATE: '/real-estate',
    EDIT_WITH_TAB: {
      DOCUMENTS: '/real-estate/:id?tab=3'
    },
    DOCUMENTS: {
      CREATE: '/real-estate/:realEstateId/document',
      EDIT: '/real-estate/:realEstateId/document/:id'
    }
  },
  SOURCES: {
    LIST: '/sources',
    EDIT: '/source/:id',
    CREATE: '/source'
  },
  PROCESS_DEFINITION: {
    LIST: '/process-definitions',
    CREATE: '/process-definition',
    EDIT: '/process-definition/:id',
    PROCESS_ROLES: {
      LIST: '/process-definition/process-roles'
    }
  },
  RATES: {
    LIST: '/rates',
    CREATE: '/rate',
    EDIT: '/rate/:id'
  },
  ITEMS: {
    LIST: '/items',
    CREATE: '/item',
    EDIT: '/item/:id'
  },
  AGGRUPATIONS: {
    LIST: '/aggrupations',
    CREATE: '/aggrupation',
    EDIT: '/aggrupation/:id'
  },
  MEASURES: {
    LIST: '/measures',
    CREATE: '/measure',
    EDIT: '/measure/:id'
  },
  USERS: {
    LIST: '/users',
    EDIT: '/user/:id',
    PROFILEEDIT: '/profileuser/:id',
    CREATE: '/user',
    ROLES: {
      LIST: '/users/roles',
      EDIT: '/users/role/:id',
      CREATE: '/users/role'
    }
  },
  DOCUMENT_TYPES: {
    LIST: '/document-types'
  },
  PROJECTS: {
    LIST: '/projects',
    EDIT: '/project/:id',
    CREATE: '/project'
  },
  SUPPLIERS: {
    DASHBOARD: '/suppliers/dashboard',
    LIST: '/suppliers',
    EDIT: '/supplier/:id',
    CREATE: '/supplier',
    EDIT_WITH_TAB: {
      AGREEMENTS: '/supplier/:id?tab=1',
      DOCUMENTS: '/supplier/:id?tab=2',
      INVOICES: '/supplier/:id?tab=4'
    },
    DOCUMENTS: {
      CREATE: '/supplier/:supplierId/document',
      EDIT: '/supplier/:supplierId/document/:id'
    },
    DOCUMENT_TYPES: {
      LIST: '/suppliers/document-types'
    },
    SPECIALITIES: {
      LIST: '/suppliers/specialities',
      CREATE: '/suppliers/speciality',
      EDIT: '/suppliers/speciality/:id'
    },
    RECEIVED_INVOICES: {
      LIST: '/suppliers/received-invoices',
      EDIT: '/suppliers/received-invoice/:id',
      EDIT_SIMPLE_INVOICE: '/suppliers/received-invoice/:id/:supplierId',
      EDIT_WITH_TAB: {
        DOCUMENTS: '/suppliers/received-invoice/:id?tab=2'
      },
      CREATE: '/suppliers/received-invoice',
      CREATE_SIMPLE_INVOICE: '/suppliers/:supplierId/received-invoice',
      DOCUMENTS: {
        CREATE: '/suppliers/received-invoice/:receivedInvoiceId/document/:supplierId',
        EDIT: '/suppliers/received-invoice/:receivedInvoiceId/document/:id/:supplierId'
      }
    },
    INSURANCE_COMPANIES: {
      LIST: '/suppliers/insurance-companies',
      EDIT: '/suppliers/insurance-company/:id',
      CREATE: '/suppliers/insurance-company'
    },
    AGREEMENTS: {
      CREATE: '/supplier/:supplierId/agreement',
      EDIT: '/supplier/:supplierId/agreement/:id',
      EDIT_READONLY: '/supplier/:supplierId/view-agreement/:id',
      EDIT_WITH_TAB: {
        WORKERS: '/supplier/:supplierId/agreement/:id?tab=1'
      },
      EDIT_READONLY_WITH_TAB: {
        WORKERS: '/supplier/:supplierId/view-agreement/:id?tab=1'
      },
      WORKERS: {
        CREATE: '/supplier/:supplierId/agreement/:agreementId/worker',
        EDIT: '/supplier/:supplierId/agreement/:agreementId/worker/:id'
      }
    },
    DOCUMENT_GROUPS: {
      LIST: '/suppliers/document-groups',
      EDIT: '/suppliers/document-group/:id',
      CREATE: '/suppliers/document-group'
    },
    SUPPLIER_TYPES: {
      LIST: '/suppliers/supplier-types',
      EDIT: '/suppliers/supplier-types/:id',
      CREATE: '/suppliers/supplier-types'
    },
    CREDITORS: {
      LIST: '/suppliers/creditors',
      EDIT: '/suppliers/creditors/:id',
      CREATE: '/suppliers/creditor',
      RECEIVED_INVOICES: {
        EDIT_CREDITOR_INVOICE: '/suppliers/creditors/received-invoice/:id/:creditorId',
        CREATE_CREDITOR_INVOICE: '/suppliers/creditors/:creditorId/received-invoice',
        DOCUMENTS: {
          CREATE: '/suppliers/creditors/received-invoice/:receivedInvoiceId/document/:creditorId',
          EDIT: '/suppliers/creditors/received-invoice/:receivedInvoiceId/document/:id/:creditorId'
        }
      }
    }
  },
  CUSTOMERS: {
    LIST: '/customers',
    EDIT: '/customer/:id',
    CREATE: '/customer',
    GROUPS: {
      LIST: '/customers/groups'
    },
    ISSUED_INVOICES: {
      LIST: '/customers/issued-invoices',
      EDIT: '/customers/issued-invoice/:id',
      EDIT_WITH_TAB: {
        DOCUMENTS: '/customers/issued-invoice/:id?tab=2'
      },
      CREATE: '/customers/issued-invoice'
    }
  },
  BUSINESS_LINES: {
    LIST: '/business-lines',
    EDIT: '/business-line/:id',
    CREATE: '/business-line'
  },
  CALENDARS: {
    LIST: '/calendars',
    EDIT: '/calendar/:id',
    CREATE: '/calendar'
  },
  WORK_ORDER_STATUSES: {
    LIST: '/statuses',
    EDIT: '/status/:id',
    CREATE: '/status'
  },
  COMPANIES: {
    LIST: '/companies',
    EDIT: '/company/:id',
    CREATE: '/company'
  },
  WORK_GROUPS: {
    LIST: '/work-groups',
    EDIT: '/work-group/:id',
    CREATE: '/work-group'
  },
  PAYMENT_METHODS: {
    LIST: '/payment-methods',
    EDIT: '/payment-method/:id',
    CREATE: '/payment-method'
  },
  ALERTS: {
    LIST: '/alerts',
    EDIT: '/alert/:id',
    CREATE: '/alert'
  },
  INTEGRATIONS: {
    LIST: '/integrations',
    CREATE: '/integration',
    EDIT: '/integration/:id'
  },
  EQUIVALENCES: {
    LIST: '/equivalences'
  },
  APPOINTMENT_TYPES: {
    LIST: '/appointment-types'
  },
  AGENDA: {
    LIST: '/agenda'
  },
  GALLERY_TAGS: {
    LIST: '/gallery-tags'
  },
  REPORTS: {
    LIST: '/reports'
  },
  DIRECTORY_TEMPLATES: {
    LIST: '/directory-templates',
    EDIT: '/directory-template/:id',
    CREATE: '/directory-template'
  },
  CONTACT_ROLES: {
    LIST: '/contact-roles'
  }
};

export const ICONS = {
  HOME: '/',
  CALLBACK: '/callback',
  ERROR: '/error',
  RENEW: '/renew',
  WORK_ORDERS: {
    DASHBOARD: '/work-orders/dashboard',
    LIST: '/work-orders',
    EDIT: <WorkOutlineIcon />,
    EDIT_WITH_TAB: {
      DOCUMENTS: '/work-order/:id?tab=3'
    },
    CREATE: '/work-order',
    DOCUMENTS: {
      CREATE: '/work-order/:workOrderId/document',
      EDIT: '/work-order/:workOrderId/document/:id'
    },
    CURRENT_MILESTONES: {
      LIST: '/current-milestones'
    },
    WO_AUTOMATION: {
      LIST: <TimerIcon />,
      CREATE: <TimerIcon />,
      EDIT: <TimerIcon />
    },
    BUDGET_ITEMS: {
      EDIT: <AssignmentOutlinedIcon />
    }
  },
  REAL_ESTATES: <HomeWorkIcon />,
  SOURCES: {
    LIST: <EqualizerOutlinedIcon />,
    EDIT: <EqualizerOutlinedIcon />,
    CREATE: <EqualizerOutlinedIcon />
  },
  PROCESS_DEFINITION: {
    LIST: <AccountTreeOutlinedIcon />,
    CREATE: <AccountTreeOutlinedIcon />,
    EDIT: <AccountTreeOutlinedIcon />,
    PROCESS_ROLES: {
      LIST: '/process-definition/process-roles'
    }
  },
  RATES: <LocalOfferIcon />,
  ITEMS: <TurnedInNotIcon />,
  AGGRUPATIONS: <GroupWorkIcon />,
  USERS: {
    LIST: <GroupOutlinedIcon />,
    EDIT: <GroupOutlinedIcon />,
    PROFILEEDIT: <GroupOutlinedIcon />,
    CREATE: <GroupOutlinedIcon />,
    ROLES: {
      LIST: <GroupAddOutlinedIcon />,
      EDIT: <GroupAddOutlinedIcon />,
      CREATE: <GroupAddOutlinedIcon />
    }
  },
  DOCUMENT_TYPES: {
    LIST: '/document-types'
  },
  PROJECTS: {
    LIST: <LoyaltyOutlinedIcon />,
    EDIT: <LoyaltyOutlinedIcon />,
    CREATE: <LoyaltyOutlinedIcon />
  },
  SUPPLIERS: {
    DASHBOARD: '/suppliers/dashboard',
    LIST: <BusinessCenterOutlinedIcon />,
    EDIT: <BusinessCenterOutlinedIcon />,
    CREATE: <BusinessCenterOutlinedIcon />,
    DOCUMENTS: {
      CREATE: '/supplier/:supplierId/document',
      EDIT: '/supplier/:supplierId/document/:id'
    },
    DOCUMENT_TYPES: {
      LIST: <PostAddOutlinedIcon />
    },
    SPECIALITIES: {
      LIST: '/suppliers/specialities',
      CREATE: <AccountBalanceWalletIcon />,
      EDIT: <AccountBalanceWalletIcon />
    },
    RECEIVED_INVOICES: <ReceiptIcon />,
    INSURANCE_COMPANIES: {
      LIST: <AssignmentTurnedInOutlinedIcon />,
      EDIT: <AssignmentTurnedInOutlinedIcon />,
      CREATE: <AssignmentTurnedInOutlinedIcon />
    },
    AGREEMENTS: {
      CREATE: <DescriptionOutlinedIcon />,
      EDIT: <DescriptionOutlinedIcon />,
      WORKERS: {
        CREATE: <DescriptionOutlinedIcon />,
        EDIT: <DescriptionOutlinedIcon />
      }
    },
    DOCUMENT_GROUPS: <CreateNewFolderIcon />,
    CREDITORS: {
      LIST: <GroupsIcon />,
      CREATE: <GroupsIcon />,
      EDIT: <GroupsIcon />
    }
  },
  CUSTOMERS: {
    LIST: <SupervisedUserCircleOutlinedIcon />,
    EDIT: <SupervisedUserCircleOutlinedIcon />,
    CREATE: <SupervisedUserCircleOutlinedIcon />,
    GROUPS: {
      LIST: '/customers/groups'
    },
    DOCUMENT_TYPES: {
      LIST: <PostAddOutlinedIcon />
    },
    ISSUED_INVOICES: {
      LIST: '/customers/issued-invoices',
      EDIT: '/customers/issued-invoice/:id',
      EDIT_WITH_TAB: {
        DOCUMENTS: '/customers/issued-invoice/:id?tab=2'
      },
      CREATE: '/customers/issued-invoice'
    }
  },
  BUSINESS_LINES: {
    LIST: <BusinessOutlinedIcon />,
    EDIT: <BusinessOutlinedIcon />,
    CREATE: <BusinessOutlinedIcon />
  },
  CALENDARS: {
    LIST: <CalendarTodayIcon />,
    EDIT: <CalendarTodayIcon />,
    CREATE: <CalendarTodayIcon />
  },
  WORK_ORDER_STATUSES: {
    LIST: '/statuses',
    EDIT: '/status/:id',
    CREATE: '/status'
  },
  COMPANIES: {
    LIST: <StoreOutlinedIcon />,
    EDIT: <StoreOutlinedIcon />,
    CREATE: <StoreOutlinedIcon />
  },
  WORK_GROUPS: {
    LIST: <GroupsIcon />,
    EDIT: <GroupsIcon />,
    CREATE: <GroupsIcon />
  },
  PAYMENT_METHODS: {
    LIST: <AccountBalanceWalletIcon />,
    EDIT: <AccountBalanceWalletIcon />,
    CREATE: <AccountBalanceWalletIcon />
  },
  ALERTS: {
    LIST: <NotificationsIcon />,
    EDIT: <NotificationsIcon />,
    CREATE: <NotificationsIcon />
  },
  INTEGRATIONS: {
    LIST: <UpdateOutlinedIcon />,
    CREATE: <UpdateOutlinedIcon />,
    EDIT: <UpdateOutlinedIcon />
  },
  GALLERY_TAGS: {
    LIST: <PhotoAlbumIcon />,
    EDIT: <PhotoAlbumIcon />
  },
  DIRECTORY_TEMPLATES: {
    LIST: <FolderOpenIcon />,
    CREATE: <FolderOpenIcon />,
    EDIT: <FolderOpenIcon />
  },
  CONTACT_ROLES: {
    LIST: <ContactPhoneIcon />,
    EDIT: <ContactPhoneIcon />,
    CREATE: <ContactPhoneIcon />
  }
};
