import { makeStyles } from '@material-ui/core/styles';

export const useFormContainerStyles = makeStyles(theme => ({
    formContainer: {
      padding: theme.spacing(3),
      background: 'white',
      borderRadius: '4px',
      marginBottom: 0,
      marginTop: theme.spacing(3),
      flexGrow: 1,
      '&.grow-0':{
        flexGrow: 0
      },
      '& .MuiGrid-item':{
        paddingTop: 0,
        paddingBottom: 0
      },
    }
}));
