import React from 'react';
import { Header } from './Header';
import { Navigation } from './Navigation';
import { usContainerStyles } from './container.styles';

export function Container({ children }) {
  const classes = usContainerStyles();
  return (
    <>
      <div className={classes.root}>
        <Navigation />
        <main className={classes.content}>
          <Header />
          <div className={classes.pageContainer}>{children}</div>
        </main>
      </div>
    </>
  );
}
