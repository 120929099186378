import { useRef } from 'react';

export const useDoubleClick = () => {
  const clickTimeoutRef = useRef(null);

  const handleClick = action => {
    if (clickTimeoutRef.current) {
      clearTimeout(clickTimeoutRef.current);
    }
    clickTimeoutRef.current = setTimeout(() => {
      action && action();
    }, 250); // Adjust delay as necessary
  };

  const handleDoubleClick = action => {
    if (clickTimeoutRef.current) {
      clearTimeout(clickTimeoutRef.current);
      action && action();
    }
  };

  return { handleClick, handleDoubleClick };
};
