import { SKELETON_KEY } from './skeletonFactory';

export function skeletonWorkOrderBudgetItemBreakdown() {
  return {
    id: SKELETON_KEY,
    description: SKELETON_KEY,
    breakdownType: SKELETON_KEY,
    budgetedAmount: SKELETON_KEY,
    unitPrice: SKELETON_KEY,
    measure: SKELETON_KEY,
    remarks: SKELETON_KEY,
    budgetedTotal: SKELETON_KEY,
    supplier: SKELETON_KEY
  };
}
