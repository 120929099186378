import { SKELETON_KEY, SKELETON_KEY_BOOL, SKELETON_KEY_DATE, SKELETON_KEY_NUMBER } from './skeletonFactory';

function workOrder() {
  const womilestones = {
    completionDate: SKELETON_KEY_DATE,
    expectedCompletionDate: SKELETON_KEY_DATE,
    id: SKELETON_KEY,
    milestone: SKELETON_KEY,
    order: SKELETON_KEY_NUMBER,
    term: SKELETON_KEY_NUMBER
  };

  const workOrderMilestones = Array(5).fill(womilestones);

  return {
    id: SKELETON_KEY,
    reference: SKELETON_KEY,
    externalReference: SKELETON_KEY,
    project: SKELETON_KEY,
    projectId: SKELETON_KEY,
    source: SKELETON_KEY,
    sourceId: SKELETON_KEY,
    processDefinitionId: SKELETON_KEY,
    milestones: workOrderMilestones,
    term: SKELETON_KEY,
    sourceTerm: SKELETON_KEY,
    managers: Array(10).fill(SKELETON_KEY),
    entryDate: SKELETON_KEY_DATE,
    finishDate: SKELETON_KEY_DATE,
    status: SKELETON_KEY_NUMBER,
    customerId: SKELETON_KEY,
    customer: SKELETON_KEY,
    sourceWorkOrder: SKELETON_KEY,
    customerGroupId: SKELETON_KEY,
    customerGroup: SKELETON_KEY
  };
}

function status() {
  return {
    id: SKELETON_KEY_NUMBER,
    name: SKELETON_KEY,
    priority: SKELETON_KEY,
    visibility: SKELETON_KEY,
    isEndStatus: SKELETON_KEY_BOOL
  };
}

// TODO: add more asset types
function assetRealEstate() {
  return {
    id: SKELETON_KEY_NUMBER,
    externalReference: SKELETON_KEY,
    secondExternalReference: SKELETON_KEY,
    realEstateType: SKELETON_KEY,
    assetId: SKELETON_KEY,
    building: SKELETON_KEY,
    city: SKELETON_KEY,
    cityId: SKELETON_KEY,
    district: SKELETON_KEY,
    doorNumber: SKELETON_KEY,
    floor: SKELETON_KEY,
    fullAddress: SKELETON_KEY,
    cadastralReference: SKELETON_KEY,
    km: SKELETON_KEY,
    letter: SKELETON_KEY,
    staircase: SKELETON_KEY,
    street: SKELETON_KEY,
    streetNumber: SKELETON_KEY,
    type: SKELETON_KEY,
    urbanType: SKELETON_KEY,
    urbanTypeId: SKELETON_KEY,
    workOrderId: SKELETON_KEY,
    zipCode: SKELETON_KEY,
    zipCodeId: SKELETON_KEY,
    numDocuments: SKELETON_KEY
  };
}
function comments() {
  return {
    id: SKELETON_KEY_NUMBER,
    entryDate: SKELETON_KEY_DATE,
    user: SKELETON_KEY,
    description: SKELETON_KEY
  };
}
function contacts() {
  return {
    id: SKELETON_KEY_NUMBER,
    fullName: SKELETON_KEY,
    phoneNumber: SKELETON_KEY,
    secondPhoneNumber: SKELETON_KEY,
    role: SKELETON_KEY,
    email: SKELETON_KEY,
    remarks: SKELETON_KEY,
  };
}
function budgetItem() {
  return {
    id: SKELETON_KEY_NUMBER,
    assetReference: SKELETON_KEY,
    itemReference: SKELETON_KEY,
    itemDescription: SKELETON_KEY,
    itemCategory: SKELETON_KEY,
    unitCost: SKELETON_KEY_NUMBER,
    totalCost: SKELETON_KEY_NUMBER,
    unitSellingPrice: SKELETON_KEY_NUMBER,
    totalSellingPrice: SKELETON_KEY_NUMBER,
    supplier: SKELETON_KEY,
    quantity: SKELETON_KEY_NUMBER,
    quantityAwarded: SKELETON_KEY_NUMBER,
    totalCostAwarded: SKELETON_KEY_NUMBER,
    remarks: SKELETON_KEY,
    status: SKELETON_KEY,
    supplierBillingStatus: SKELETON_KEY_NUMBER,
    startDate: SKELETON_KEY_DATE,
    endDate: SKELETON_KEY_DATE,
    expectedStartDate: SKELETON_KEY_DATE,
    expectedEndDate: SKELETON_KEY_DATE,
    item: {
      description: SKELETON_KEY,
      measure: SKELETON_KEY_NUMBER,
      itemCategoryId: SKELETON_KEY,
      itemCategory: SKELETON_KEY,
      itemCategorySubSpeciality: SKELETON_KEY,
      itemCategorySubSpecialityId: SKELETON_KEY,
      aggrupation: SKELETON_KEY
    }
  };
}

function document() {
  return {
    id: SKELETON_KEY_NUMBER,
    documentType: SKELETON_KEY,
    status: SKELETON_KEY,
    expectedDate: SKELETON_KEY_DATE,
    realDate: SKELETON_KEY_DATE,
    sentDate: SKELETON_KEY_DATE,
    remarks: SKELETON_KEY,
    document: SKELETON_KEY,
    documentName: SKELETON_KEY,
    assetId: SKELETON_KEY,
    supplierId: SKELETON_KEY,
    directoryTree: SKELETON_KEY,
    directoryTreeId: SKELETON_KEY
  };
}

function folder() {
  return {
    id: SKELETON_KEY_NUMBER,
    name: SKELETON_KEY,
    parentId: SKELETON_KEY,
    path: SKELETON_KEY
  };
}

function workOrderRelated() {
  return {
    id: SKELETON_KEY,
    reference: SKELETON_KEY,
    externalReference: SKELETON_KEY,
    source: SKELETON_KEY,
    status: SKELETON_KEY
  };
}

function historyItem() {
  return {
    id: SKELETON_KEY_NUMBER,
    entity: SKELETON_KEY_NUMBER,
    action: SKELETON_KEY_NUMBER,
    entryDate: SKELETON_KEY_DATE,
    userName: SKELETON_KEY,
    reference: SKELETON_KEY
  };
}

function galleryImage() {
  return {
    id: SKELETON_KEY,
    asset: SKELETON_KEY,
    budgetItem: SKELETON_KEY,
    galleryTag: SKELETON_KEY,
    imageName: SKELETON_KEY,
    imageUrl: SKELETON_KEY,
    mimeType: SKELETON_KEY,
    remarks: SKELETON_KEY,
    thumbnail: SKELETON_KEY,
    userName: SKELETON_KEY
  };
}

export const skeletonWorkOrder = {
  workOrder,
  status,
  assetRealEstate,
  budgetItem,
  comments,
  contacts,
  document,
  folder,
  workOrderRelated,
  historyItem,
  galleryImage
};
