import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const useWidgetFilters = onlyUpdateFilters => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { state } = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (state) {
      onlyUpdateFilters(state.state);
      history.replace({ ...history.location, state: null });
    }
    setIsLoaded(true);
  }, []);

  return {
    isLoaded
  };
};
