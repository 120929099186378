import { http } from './http';
import { apiHelper } from 'shared';
import FileSaver from 'file-saver';

class ProcessDefinitions {
  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/process-definitions/${queryString}`, {});
  }

  async getUsingQueryFilter(queryFilter, isPublished, processVisibility) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter, isPublished, processVisibility });
    const response = await http.get(`/process-definitions${queryString}`, {});
    const items = response.data && response.data.items;

    return items;
  }

  async getById(processDefinitionId) {
    return http.get(`/process-definitions/${processDefinitionId}`);
  }

  create(processDefinition) {
    return http.post('/process-definitions', processDefinition);
  }

  clone(processDefinition) {
    return http.post(`/process-definitions/${processDefinition.idToClone}/clone`, processDefinition);
  }

  update(processDefinitionId, processDefinition) {
    return http.put(`/process-definitions/${processDefinitionId}`, processDefinition);
  }

  publish(processDefinitionId) {
    return http.put(`/process-definitions/${processDefinitionId}/publish`);
  }

  getMilestones(id) {
    return http.get(`/process-definitions/${id}/milestones`);
  }

  getXML(id) {
    return http.get(`/process-definitions/${id}/xml`);
  }

  delete(id, multiple) {
    return http.delete(`/process-definitions/${id}`, { multiple });
  }

  getProcessVisibilities() {
    return http.get('/process-definitions/process-visibilities');
  }

  async export(id, name) {
    const headersT = await http.getBearerToken();
    const api_url = localStorage.getItem('API_URL');
    const options = {
      headers: headersT.headers,
      method: 'GET',
      responseType: 'blob'
    };

    
    const url = `${api_url}/api/v1.0/process-definitions/${id}/bpmn`;
    const response = await fetch(url, options);
    
    if (response && response.status === 200) {
      const miBlob = await response.blob().catch(e => {
        throw e;
      });
      
      name = name?.length ? name : id;
      FileSaver.saveAs(miBlob, `process_definition_${name}.bpmn`);
    }
  }

  async getTopics(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });
    const response = await http.get(`/process-definitions/topics/${queryString}`, {});
    const items = response.data && response.data.items;
    return items;
  }

  async getVariables(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });
    const response = await http.get(`/process-definitions/variables/${queryString}`, {});
    const items = response.data && response.data.items;
    return items;
  }
}

export const processDefinitions = new ProcessDefinitions();
