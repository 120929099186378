import { useEffect, useState } from 'react';

export const useCarouselWidget = ({ service, sorting }) => {
  const [items, setItems] = useState([]);
  const [paging, setPaging] = useState({ pageSize: 5, pageIndex: 0 });
  const [total, setTotal] = useState({ totalPages: 0, totalItems: 0 });
  const [loading, setLoading] = useState(false);

  const getItems = async pageIndex => {
    setLoading(true);
    setItems([]);
    const newPaging = { ...paging, pageIndex };
    const items = await service(newPaging, sorting);
    if (items.data.items?.length) {
      setTotal({ totalPages: items.data.totalPages, totalItems: items.data.total });
      setItems(items.data?.items);
    }
    setLoading(false);
  };

  useEffect(() => {
    getItems(paging.pageIndex);
  }, [paging.pageIndex]);

  return { items, total, paging, setPaging, loading };
};
