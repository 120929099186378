import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { objectHelper } from 'shared/helper';
import { isSkeletonLoading } from 'shared/skeletonFactory';

export const useTreeSelect = ({ propName, value, fetchData, entityId }) => {
  const [options, setOptions] = useState([]);
  const { t } = useTranslation();

  function buildNestedStructure(data, parentId = null) {
    const result = data[propName]
      .filter(item => item.parentId === parentId)
      .map(item => ({
        ...item,
        [propName]: buildNestedStructure(data, item.id)
      }));
    return result.length > 0 ? result : [];
  }

  function prepareData(inputArray) {
    function transformItem(item) {
      let transformedItem = {
        ...item,
        value: item.id,
        label: item.name,
        tagLabel: item.path,
        children: item?.[propName]
      };

      if (item?.[propName] && item[propName].length > 0) {
        transformedItem.children = item[propName].map(transformItem);
      }

      delete transformedItem.id;
      delete transformedItem.name;
      delete transformedItem[propName];

      return transformedItem;
    }
    return inputArray.map(transformItem);
  }

  function updateChecked({ elements, checkedElementId, isChecked }) {
    const newArray = objectHelper.deepClone(elements ?? options);

    function updateRecursive(arr) {
      let childChecked = false;
      for (let i = 0; i < arr.length; i++) {
        const currentObject = arr[i];
        if (currentObject.value === checkedElementId && isChecked) {
          const updatedObject = { ...currentObject, checked: true };
          arr[i] = updatedObject;
          childChecked = true;
        } else if (
          (!isChecked && currentObject.checked === true && currentObject.value === checkedElementId) ||
          (currentObject.checked === true && currentObject.value !== checkedElementId)
        ) {
          delete currentObject.checked;
          delete currentObject.expanded;
        }
        if (currentObject.children && currentObject.children.length > 0) {
          if (updateRecursive(currentObject.children)) {
            currentObject.expanded = true;
            childChecked = true;
          }
        }
      }

      return childChecked;
    }
    updateRecursive(newArray);
    setOptions(newArray);
  }

  const getArrayOptions = async () => {
    if (entityId && !isSkeletonLoading(entityId)) {
      const response = await fetchData(entityId);
      const data = response.data ? response.data : { [propName]: response };

      if (data && data?.[propName]?.length !== 0) {
        const nestedData = buildNestedStructure(data);
        const changeLabels = prepareData(nestedData);
        if (value?.id) {
          updateChecked({ elements: changeLabels, checkedElementId: value.id, isChecked: true });
        } else {
          setOptions(changeLabels);
        }
      } else {
        setOptions([{ label: t('common:empty'), disabled: true }]);
      }
    }
  };

  useEffect(() => {
    if (value.id) {
      updateChecked({
        elements: [{ value: value.id, tagLabel: value.name }],
        checkedElementId: value.id,
        isChecked: true
      });
    } else {
      updateChecked({ id: value.id, isChecked: false });
    }
  }, [value.id]);

  return { options, updateChecked, getArrayOptions };
};
