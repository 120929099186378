import i18next from 'i18next';
import moment from 'moment';

moment.locale(i18next.language);

function validateBeforeExecute(string, cb) {
  if (typeof string === 'string') {
    return cb();
  } else {
    throw new Error(`Error ocurred formatting a date: string required, ${typeof string} found`);
  }
}

function toShortDate(string) {
  return validateBeforeExecute(string, () => moment(string).format('DD/MM/YYYY'));
}

function toLongDate(string) {
  return validateBeforeExecute(string, () => moment(string).format('DD/MM/YY HH:mm'));
}

function toXLLocalizedDate(string) {
  return validateBeforeExecute(string, () => {
    let localeData = moment.localeData();
    let format = localeData.longDateFormat('LLLL');
    return moment(string).format(format);
  });
}

function toTime(string) {
  return validateBeforeExecute(string, () => moment(string).format('HH:mm'));
}

function getMonthName(date, t) {
  const monthNumber = new Date(date).getMonth() + 1;
  const monthName = t(`common:months.${monthNumber}`);
  return monthName;
}

function addMonthsToDate(initialDate, monthsToAdd) {
  return validateBeforeExecute(initialDate, () => moment(initialDate).add(monthsToAdd, 'months').format('yyyy-MM-DD'));
}

function calculateMonthDifference(date1, date2) {
  const startDate = new Date(date1);
  const endDate = new Date(date2);

  const startYear = startDate.getFullYear();
  const startMonth = startDate.getMonth();
  const startDay = startDate.getDate();

  const endYear = endDate.getFullYear();
  const endMonth = endDate.getMonth();
  const endDay = endDate.getDate();

  let monthDifference = (endYear - startYear) * 12 + (endMonth - startMonth);
  if (endDay > startDay) {
    monthDifference++;
  }
  return monthDifference;
}

function generateDates(initialDate, numMonths) {
  const startDate = new Date(initialDate);
  const dateArray = [];

  for (let i = 0; i < numMonths; i++) {
    const month = startDate.getMonth() + 1;
    const year = startDate.getFullYear();
    dateArray.push(`${month.toString().padStart(2, '0')}/${year}`);

    startDate.setMonth(month);
  }

  return dateArray;
}

export const dateHelper = {
  toShortDate,
  toLongDate,
  toXLLocalizedDate,
  getMonthName,
  toTime,
  addMonthsToDate,
  generateDates,
  calculateMonthDifference
};
