import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import React, { useMemo } from 'react';

import { dateHelper, isSkeletonLoading } from 'shared';
import { FILTER_TYPE } from 'shared/ViewTable/AdvancedFilters';

export function useNotificationsBuildTable(inputs, t, handleLink) {
  const headCells = useMemo(() => {
    return [
      { id: 'name', numeric: false, disablePadding: false, label: t('properties.name')},
      {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: t('properties.description'),
      },
      {
        id: 'creationDate',
        numeric: false,
        disablePadding: false,
        label: t('properties.creationDate'),
        filterType: FILTER_TYPE.DATE
      },
      { id: 'link', action: true }
    ];
  }, [t]);

  const rows = useMemo(() => {
    if (!inputs || !inputs.items) {
      return [];
    }
    return inputs.items.map(notification => ({
      name: isSkeletonLoading(notification.name) ? (
        notification.name
      ) : (
        <div style={{ position: 'relative', fontFamily: 'Montserrat,Roboto' }}>
          {!notification.isViewed && (
            <FiberManualRecordIcon
              color="primary"
              style={{ position: 'absolute', left: '-0.9rem', fontSize: '0.75rem', top: '0.20rem' }}
            />
          )}
          {notification.name}
        </div>
      ),

      id: notification.id,
      sourceType: notification.sourceType,
      sourceReference: notification.sourceReference,
      description: notification.description,
      creationDate: isSkeletonLoading(notification.creationDate)
        ? notification.creationDate
        : notification.creationDate
        ? dateHelper.toShortDate(notification.creationDate)
        : '',
      link: {
        title: t('actions.link'),
        component: <OpenInNewOutlinedIcon onClick={() => handleLink(notification.sourceReference, notification.sourceType)} />
      }
    }));
  }, [inputs, t, handleLink]);

  return { headCells, rows };
}
