import { APIS } from 'app.apis.const';
import { apiHelper } from 'shared';

import { http } from './http';

class Aggrupations {
  async getUsingQueryFilter(queryFilter, businessLineId) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({
      name: queryFilter?.name ?? null,
      businessLineId: businessLineId ?? null
    });

    const response = await http.get(`/aggrupations${queryString}`, {}, APIS.FINANCES);
    const items = response.data && response.data.items;

    return items;
  }

  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/aggrupations${queryString}`, {}, APIS.FINANCES);
  }

  getById(id) {
    return http.get(`/aggrupations/${id}`, {}, APIS.FINANCES);
  }

  create(aggrupations) {
    return http.post('/aggrupations', aggrupations, {}, APIS.FINANCES);
  }

  delete(id, multiple) {
    return http.delete(`/aggrupations/${id}`, { multiple }, APIS.CMMS);
  }

  update(aggrupation) {
    return http.put(`/aggrupations/${aggrupation.id}`, { ...aggrupation }, {}, APIS.FINANCES);
  }
}

export const aggrupations = new Aggrupations();
