import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { NAME_SPACE } from 'i18n';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button';

export function CmmsButtonBack({ onClick }) {
  const { t } = useTranslation(NAME_SPACE.COMMON);

  return <Button text={t('actions.back')} onClick={onClick} icon={<KeyboardBackspaceIcon />} />;
}

CmmsButtonBack.propTypes = {
  onClick: PropTypes.func.isRequired
};
