import { useCallback, useEffect, useState } from 'react';
import { azureStorage } from 'services';
import { isSkeletonLoading } from 'shared';

export function useAzureStorage(containerIdentifier) {
  const [sasToken, setSasToken] = useState('');

  const getSasToken = useCallback(
    async containerId => {
      const response = isValidId(containerId) && (await azureStorage.getSasToken(containerId));
      setSasToken(response.data);
      return response.data;
    },
    [containerIdentifier]
  );

  useEffect(() => {
    const getData = async () => await getSasToken(containerIdentifier);
    isValidId(containerIdentifier) && getData();
  }, [containerIdentifier]);

  const uploadFile = useCallback(
    async ({ content, mimeType, originalFilename }) => {
      return await azureStorage.uploadFile({
        content,
        mimeType,
        containerIdentifier,
        sasToken,
        originalFilename,
      });
    },
    [containerIdentifier, sasToken]
  );

  const getReadToken = async containerId => {
    const response = isValidId(containerId) && (await azureStorage.getReadToken(containerId));
    return response.data;
  };

  const isValidId = id => {
    return Boolean(id) && !isSkeletonLoading(id);
  };

  return { uploadFile, getSasToken, getReadToken, sasToken };
}
