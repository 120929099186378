import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { APIS } from 'app.apis.const';
import { http } from './http';

class AzureStorage {
  async uploadFile({ content, mimeType, containerIdentifier, sasToken, originalFilename }) {
      const date = moment().utc().format('ddd, MMM D YYYY hh:mm A');
      const uuid = uuidv4();

      const headers = {
        'x-ms-content-type': mimeType,
        'x-ms-blob-content-disposition': `attachment;filename="${encodeURI(originalFilename)}"`,
        'Content-Type': mimeType,
        'x-ms-blob-type': 'BlockBlob',
        'x-ms-version': '2018-03-28',
        'x-ms-date': date,
        Accept: '*/*'
      };

      await http.put(
        `/${containerIdentifier}/${uuid}?${sasToken}&api-version=2019-12-12`,
        content,
        { headers },
        APIS.AZURE_STORAGE,
        false
      );

      return `${http.getBaseUrl(APIS.AZURE_STORAGE)}/${containerIdentifier}/${uuid}`;
  }

  getSasToken(id) {
    return http.get(`/storage/${id}/shared-access-token`);
  }

  getReadToken(id) {
    return http.get(`/storage/${id}/shared-read-access-token`);
  }
}

export const azureStorage = new AzureStorage();
