function getPostalAddress(entity) {
  return `${entity.zipCode || ''} ${entity.city} ${entity.district ? '(' + entity.district + ')' : ''}`.trim();
}
function getFullAddress(entity, t) {
  if (entity.street) {
    return `${entity.urbanType || ''} ${entity.street || ''} ${entity.streetNumber || ''} ${
      entity.km ? t('address:km') + ' ' + entity.km : ''
    } ${entity.building ? t('address:building') + ' ' + entity.building : ''} ${
      entity.staircase ? t('address:staircase') + ' ' + entity.staircase : ''
    } ${entity.floor ? t('address:floor') + ' ' + entity.floor : ''} ${
      entity.doorNumber ? t('address:door') + ' ' + entity.doorNumber : ''
    } ${entity.letter ? t('address:letter') + ' ' + entity.letter : ''} ${
      entity.additionalInfo || ''
    } - ${getPostalAddress(entity)}`
      .replace(/\s+/g, ' ')
      .trim();
  }
  return '';
}

export const addressHelper = {
  getFullAddress,
  getPostalAddress
};
