import { http } from './http';
import { APIS } from 'app.apis.const';
import { apiHelper } from 'shared';

const controllerName = 'suppliers';
const controllerRoute = 'ratings';

class SupplierRatings {
  async getRatings(paging, sorting, filters, advancedFilters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({
      ...paging,
      ...sorting,
      ...filters,
      ...advancedFilters
    });

    var response = await http.get(`/${controllerName}/${controllerRoute}${queryString}`, {}, APIS.FINANCES);
    const data = response && response.data;
    if (data) {
      return data;
    }
  }

  async getRatingHistory(supplierId, paging, sorting, filters, advancedFilters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({
      ...paging,
      ...sorting,
      ...filters,
      ...advancedFilters
    });

    var response = await http.get(
      `/${controllerName}/${supplierId}/${controllerRoute}${queryString}`,
      {},
      APIS.FINANCES
    );
    const data = response && response.data;
    if (data) {
      return data;
    }
  }

  async getRatingAvg(supplierId) {
    var response = await http.get(`/${controllerName}/${supplierId}/${controllerRoute}/averages`, {}, APIS.FINANCES);

    const data = response && response.data;
    if (data) {
      return data;
    }
  }

  updateSupplierRating(rating) {
    return http.put(`/${controllerName}/${controllerRoute}/${rating.id}`, rating, {}, APIS.FINANCES);
  }
}

export const supplierRatings = new SupplierRatings();
