import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { Button } from '../Button';
import GetAppIcon from '@material-ui/icons/GetApp';

export function CmmsButtonExport({ disabled, text, onClick }) {
  const { t } = useTranslation(NAME_SPACE.COMMON);
  return <Button disabled={disabled} text={text || t('actions.export')} onClick={onClick} icon={<GetAppIcon />} />;
}

CmmsButtonExport.propTypes = {
  disabled: PropTypes.bool,
  text: PropTypes.string,
  onClick: PropTypes.func
};
