import { http } from './http';
import { apiHelper } from 'shared';

class SupplierItemRates {
  get(rateId, paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/rates/${rateId}/supplier-item-rates/${queryString}`);
  }

  getById(rateId, id) {
    return http.get(`/rates/${rateId}/supplier-item-rates/${id}`);
  }

  create(rateId, supplierItemRate) {
    return http.post(`/rates/${rateId}/supplier-item-rates`, supplierItemRate);
  }

  delete(rateId, id, multiple) {
    return http.delete(`/rates/${rateId}/supplier-item-rates/${id}`, { multiple });
  }

  update(rateId, id, supplierItemRate) {
    return http.put(`/rates/${rateId}/supplier-item-rates/${id}`, supplierItemRate);
  }

  async getByProjectUsingQueryFilter(projectId, itemId, queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter, itemId });

    const response = await http.get(`/projects/${projectId}/supplier-rates${queryString}`);
    const items = response.data && response.data.items;

    return items;
  }
}

export const supplierItemRates = new SupplierItemRates();
