import { http } from './http';
import { apiHelper } from 'shared';
import FileSaver from 'file-saver';

const controllerName = 'integrations';

class IntegrationExecutions {
  get(integrationId, paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/${controllerName}/${integrationId}/executions/${queryString}`, {});
  }

  async downloadLog(integrationId, executionId) {
    const headersT = await http.getBearerToken();
    const api_url = localStorage.getItem('API_URL');
    const options = {
      headers: headersT.headers,
      method: 'GET',
      responseType: 'blob'
    };

    const url = `${api_url}/api/v1.0/integrations/${integrationId}/executions/${executionId}/log`;
    const response = await fetch(url, options);

    if (response && response.status === 200) {
      const miBlob = await response.blob().catch(e => {
        throw e;
      });
      FileSaver.saveAs(miBlob, `integration_execution_${executionId}.txt`);
    }
  }
}

export const integrationExecutions = new IntegrationExecutions();
