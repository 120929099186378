import React, { Suspense, useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Container } from 'layout';

import { LoadingPage } from 'pages';

import { ACCESS, SilentRenewContainer, UserContext, authStorage, ALL_ACCESS } from 'auth';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { bigintHelper } from 'shared';
import { ANONYMOUS_PATH, PATH } from './app.routes.const';
// The 'page' components must be exported with 'export default', not named as usual.

const HomePage = React.lazy(() => import('./pages/Home/Home'));
const WorkOrdersDashboard = React.lazy(() => import('./pages/WorkOrders/Dashboard/WorkOrdersDashboard'));
const ListWorkOrders = React.lazy(() => import('./pages/WorkOrders/List/ListWorkOrders'));
const EditWorkOrder = React.lazy(() => import('./pages/WorkOrders/Edit/EditWorkOrder'));
const CurrentMilestone = React.lazy(() => import('./pages/WorkOrders/CurrentMilestone/CurrentMilestone'));
const ListRealEstates = React.lazy(() => import('./pages/RealEstates/List/ListRealEstates'));
const EditRealEstate = React.lazy(() => import('./pages/RealEstates/Edit/EditRealEstate'));
const EditRealEstateDocument = React.lazy(() => import('./pages/RealEstates/Edit/Documents/ViewRealEstateDocuments'));
const ViewAgenda = React.lazy(() => import('./pages/Agenda/ViewAgenda'));
const ListAppointmentTypes = React.lazy(() => import('./pages/AppointmentTypes/List/ListAppointmentTypes'));
const ListRates = React.lazy(() => import('./pages/Rates/List/ListRates'));
const EditRate = React.lazy(() => import('./pages/Rates/Edit/EditRate'));
const ListItems = React.lazy(() => import('./pages/Items/List/ListItems'));
const EditItem = React.lazy(() => import('./pages/Items/Edit/EditItem'));
const ListMeasures = React.lazy(() => import('./pages/Measures/List/ListMeasures'));
const ListAggrupations = React.lazy(() => import('./pages/Aggrupations/List/ListAggrupations'));
const SuppliersDashboard = React.lazy(() => import('./pages/Suppliers/Dashboard/SuppliersDashboard'));
const ListSuppliers = React.lazy(() => import('./pages/Suppliers/List/ListSuppliers'));
const EditSupplier = React.lazy(() => import('./pages/Suppliers/Edit/EditSupplier'));
const EditAgreement = React.lazy(() => import('./pages/Suppliers/Edit/ViewAgreements/Edit/EditAgreement'));
const EditAgreementReadonly = React.lazy(() =>
  import('./pages/Suppliers/Edit/ViewAgreements/Edit/EditAgreementReadonly')
);
const EditWorker = React.lazy(() => import('./pages/Suppliers/Edit/ViewAgreements/Edit/ViewWorkers/Edit/EditWorker'));
const ListCreditors = React.lazy(() => import('./pages/Suppliers/Creditors/List/ListCreditors'));
const EditCreditor = React.lazy(() => import('./pages/Suppliers/Creditors/Edit/EditCreditor'));
const ListSupplierTypes = React.lazy(() => import('./pages/SupplierTypes/List/ListSupplierTypes'));
const ListSpecialities = React.lazy(() => import('./pages/Suppliers/Specialities/List/ListSpecialities'));
const EditSpeciality = React.lazy(() => import('./pages/Suppliers/Specialities/Edit/EditSpeciality'));
const ListSupplierDocumentTypes = React.lazy(() =>
  import('./pages/SupplierDocumentTypes/List/ListSupplierDocumentTypes')
);
const ListDocumentGroups = React.lazy(() => import('./pages/SupplierDocumentGroups/List/ListDocumentGroups'));
const EditDocumentGroup = React.lazy(() => import('./pages/SupplierDocumentGroups/Edit/EditDocumentGroup'));
const ListInsuranceCompanies = React.lazy(() => import('./pages/InsuranceCompanies/List/ListInsuranceCompanies'));
const EditInsuranceCompany = React.lazy(() => import('./pages/InsuranceCompanies/Edit/EditInsuranceCompany'));
const ListReceivedInvoices = React.lazy(() => import('./pages/Suppliers/ReceivedInvoices/List/ListReceivedInvoices'));
const EditReceivedInvoice = React.lazy(() => import('./pages/Suppliers/ReceivedInvoices/Edit/EditReceivedInvoice'));
const ListCustomers = React.lazy(() => import('./pages/Customers/List/ListCustomers'));
const EditCustomer = React.lazy(() => import('./pages/Customers/Edit/EditCustomer'));
const ListCustomerGroups = React.lazy(() => import('./pages/Customers/Groups/List/ListGroups'));
const ListIssuedInvoices = React.lazy(() => import('./pages/Customers/IssuedInvoices/List/ListIssuedInvoices'));
const EditIssuedInvoice = React.lazy(() => import('./pages/Customers/IssuedInvoices/Edit/EditIssuedInvoice'));
const ListSources = React.lazy(() => import('./pages/Sources/List/ListSources'));
const EditSource = React.lazy(() => import('./pages/Sources/Edit/EditSource'));
const ListProcessDefinitions = React.lazy(() => import('./pages/ProcessDefinition/List/ListProcessDefinitions'));
const EditProcessDefinition = React.lazy(() => import('./pages/ProcessDefinition/Edit/EditProcessDefinition'));
const ListProcessRoles = React.lazy(() => import('./pages/ProcessDefinition/ProcessRoles/List/ListProcessRoles'));
const ListCalendars = React.lazy(() => import('./pages/Calendars/List/ListCalendars'));
const EditCalendar = React.lazy(() => import('./pages/Calendars/Edit/EditCalendar'));
const ListStatuses = React.lazy(() => import('./pages/Statuses/List/ListStatuses'));
const ListCompanies = React.lazy(() => import('./pages/Companies/List/ListCompanies'));
const EditCompany = React.lazy(() => import('./pages/Companies/Edit/EditCompany'));
const ListBusinessLines = React.lazy(() => import('./pages/BusinessLines/List/ListBusinessLines'));
const EditBusinessLine = React.lazy(() => import('./pages/BusinessLines/Edit/EditBusinessLine'));
const ListProjects = React.lazy(() => import('./pages/Projects/List/ListProjects'));
const EditProject = React.lazy(() => import('./pages/Projects/Edit/EditProject'));
const ListWorkGroups = React.lazy(() => import('./pages/WorkGroups/List/ListWorkGroups'));
const EditWorkGroup = React.lazy(() => import('./pages/WorkGroups/Edit/EditWorkGroup'));
const ListDocumentTypes = React.lazy(() => import('./pages/DocumentTypes/List/ListDocumentTypes'));
const ListGalleryTags = React.lazy(() => import('./pages/GalleryTags/List/ListGalleryTags'));
const ListDirectoryTemplates = React.lazy(() => import('./pages/DirectoryTemplates/List/ListDirectoryTemplates'));
const EditDirectoryTemplate = React.lazy(() => import('./pages/DirectoryTemplates/Edit/EditDirectoryTemplate'));
const ListPaymentMethods = React.lazy(() => import('./pages/PaymentMethods/List/ListPaymentMethods'));
const EditPaymentMethod = React.lazy(() => import('./pages/PaymentMethods/Edit/EditPaymentMethod'));
const ListAlerts = React.lazy(() => import('./pages/Alerts/List/ListAlerts'));
const EditAlert = React.lazy(() => import('./pages/Alerts/Edit/EditAlert'));
const ListUsers = React.lazy(() => import('./pages/Users/List/ListUsers'));
const EditUser = React.lazy(() => import('./pages/Users/Edit/EditUser'));
const ListRoles = React.lazy(() => import('./pages/Users/Roles/List/ListRoles'));
const EditRole = React.lazy(() => import('./pages/Users/Roles/Edit/EditRole'));
const ListIntegrations = React.lazy(() => import('./pages/Integrations/List/ListIntegrations'));
const EditIntegration = React.lazy(() => import('./pages/Integrations/Edit/EditIntegration'));
const ListEquivalences = React.lazy(() => import('./pages/Equivalences/List/ListEquivalences'));
const ListContactRoles = React.lazy(() => import('./pages/ContactRoles/List/ListContactRoles'));

const EditBudgetItem = React.lazy(() => import('./pages/WorkOrders/Edit/ViewBudgetItems/Edit/EditBudgetItem'));
const ListAutomation = React.lazy(() => import('./pages/WorkOrders/Automation/List/ListAutomation'));
const EditAutomation = React.lazy(() => import('./pages/WorkOrders/Automation/Edit/EditAutomation'));
const EditReceivedInvoiceDocument = React.lazy(() =>
  import('./pages/Suppliers/ReceivedInvoices/Edit/ViewDocuments/ViewReceivedInvoiceDocuments')
);
const EditSupplierDocument = React.lazy(() =>
  import('./pages/Suppliers/Edit/ViewSupplierDocuments/ViewSupplierDocuments')
);
const ListReports = React.lazy(() => import('./pages/Reports/List/ListReports'));

const Unauthorized = React.lazy(() => import('./pages/Unauthorized'));
const Error = React.lazy(() => import('./pages/Error'));

const _anonymousRoutes = () => {
  return (
    <>
      <Route path={ANONYMOUS_PATH.ERROR} component={Error} />
      <Route path={ANONYMOUS_PATH.RENEW} component={SilentRenewContainer} />
      <Route path={ANONYMOUS_PATH.CALLBACK} component={LoadingPage} />
    </>
  );
};
export const AnonymousRoutes = React.memo(_anonymousRoutes);

const redirectToUrl = pathToGo => {
  try {
    return <Redirect to={pathToGo} />;
  } finally {
    authStorage.removePathToGo();
  }
};

const _switchRoutes = () => {
  const auth = useAuth();
  const userContext = useContext(UserContext);
  const { user } = userContext;
  const pathToGo = authStorage.getPathToGo();

  return (
    <Switch>
      {Boolean(pathToGo) && user && user.access && redirectToUrl(pathToGo)}
      {window.location.pathname === ANONYMOUS_PATH.CALLBACK || window.location.pathname === PATH.HOME ? (
        user && bigintHelper.and(user.access, ACCESS.WORK_ORDERS) ? (
          <Redirect from={PATH.HOME} to={PATH.HOMEPAGE} />
        ) : user && bigintHelper.and(user.access, ACCESS.WORK_ORDERS) ? (
          <Redirect from={PATH.HOME} to={PATH.WORK_ORDERS.LIST} />
        ) : user && bigintHelper.and(user.access, ACCESS.RATES) ? (
          <Redirect from={PATH.HOME} to={PATH.RATES.LIST} />
        ) : user && bigintHelper.and(user.access, ACCESS.SUPPLIERS_DASHBOARD) ? (
          <Redirect from={PATH.HOME} to={PATH.SUPPLIERS.DASHBOARD} />
        ) : user && bigintHelper.and(user.access, ACCESS.CUSTOMERS) ? (
          <Redirect from={PATH.HOME} to={PATH.CUSTOMERS.LIST} />
        ) : user && bigintHelper.and(user.access, ACCESS.SOURCES) ? (
          <Redirect from={PATH.HOME} to={PATH.SOURCES.LIST} />
        ) : user && bigintHelper.and(user.access, ACCESS.DOCUMENT_TYPES) ? (
          <Redirect from={PATH.HOME} to={PATH.DOCUMENT_TYPES.LIST} />
        ) : user && bigintHelper.and(user.access, ACCESS.APPOINTMENT_TYPES) ? (
          <Redirect from={PATH.HOME} to={PATH.APPOINTMENT_TYPES.LIST} />
        ) : user && bigintHelper.and(user.access, ACCESS.USERS) ? (
          <Redirect from={PATH.HOME} to={PATH.USERS.LIST} />
        ) : !hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading ? (
          auth.signinRedirect()
        ) : (
          ''
        )
      ) : (
        <></>
      )}
    </Switch>
  );
};
export const SwitchRoutes = React.memo(_switchRoutes);

const _routes = () => {
  const GuardedRoute = ({ component: Component, access, ...rest }) => {
    const auth = useAuth();
    const userContext = useContext(UserContext);
    const { user, setCurrentPathAccess } = userContext;

    setCurrentPathAccess(access);
    return (
      <Route
        {...rest}
        render={props =>
          !(!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) ? (
            !access || (user && bigintHelper.and(user.access, access)) ? (
              <Component {...props} />
            ) : (
              user && user.access && <Unauthorized />
            )
          ) : (
            <Redirect to={`${PATH.HOME}`} />
          )
        }
      />
    );
  };

  return (
    <Container>
      <Suspense fallback={<LoadingPage />}>
        <Switch>
          <GuardedRoute exact path={PATH.HOME} component={SwitchRoutes} />
          <GuardedRoute exact path={PATH.HOMEPAGE} component={HomePage} access={ALL_ACCESS} />
          <GuardedRoute
            exact
            path={PATH.WORK_ORDERS.DASHBOARD}
            access={ACCESS.WORK_ORDERS_DASHBOARD}
            component={WorkOrdersDashboard}
          />
          <GuardedRoute exact path={PATH.WORK_ORDERS.LIST} access={ACCESS.WORK_ORDERS} component={ListWorkOrders} />
          <GuardedRoute exact path={PATH.WORK_ORDERS.EDIT} access={ACCESS.WORK_ORDERS} component={EditWorkOrder} />
          <GuardedRoute exact path={PATH.WORK_ORDERS.CREATE} access={ACCESS.WORK_ORDERS} component={EditWorkOrder} />
          <GuardedRoute
            exact
            path={PATH.WORK_ORDERS.CURRENT_MILESTONES.LIST}
            access={ACCESS.WORK_ORDERS_PENDING_ACTIVITIES}
            component={CurrentMilestone}
          />
          <GuardedRoute
            exact
            path={PATH.WORK_ORDERS.WO_AUTOMATION.LIST}
            access={ACCESS.WORK_ORDERS_AUTOMATIONS}
            component={ListAutomation}
          />
          <GuardedRoute
            exact
            path={PATH.WORK_ORDERS.WO_AUTOMATION.CREATE}
            access={ACCESS.WORK_ORDERS_AUTOMATIONS}
            component={EditAutomation}
          />
          <GuardedRoute
            exact
            path={PATH.WORK_ORDERS.WO_AUTOMATION.EDIT}
            access={ACCESS.WORK_ORDERS_AUTOMATIONS}
            component={EditAutomation}
          />
          <GuardedRoute
            exact
            path={PATH.WORK_ORDERS.BUDGET_ITEMS.CREATE}
            access={ACCESS.WORK_ORDERS}
            component={EditBudgetItem}
          />
          <GuardedRoute
            exact
            path={PATH.WORK_ORDERS.BUDGET_ITEMS.EDIT}
            access={ACCESS.WORK_ORDERS}
            component={EditBudgetItem}
          />

          <GuardedRoute exact path={PATH.REAL_ESTATES.LIST} access={ACCESS.REAL_ESTATES} component={ListRealEstates} />
          <GuardedRoute exact path={PATH.REAL_ESTATES.CREATE} access={ACCESS.REAL_ESTATES} component={EditRealEstate} />
          <GuardedRoute exact path={PATH.REAL_ESTATES.EDIT} access={ACCESS.REAL_ESTATES} component={EditRealEstate} />
          <GuardedRoute
            exact
            path={PATH.REAL_ESTATES.DOCUMENTS.CREATE}
            access={ACCESS.REAL_ESTATES}
            component={EditRealEstateDocument}
          />
          <GuardedRoute
            exact
            path={PATH.REAL_ESTATES.DOCUMENTS.EDIT}
            access={ACCESS.REAL_ESTATES}
            component={EditRealEstateDocument}
          />

          <GuardedRoute exact path={PATH.SOURCES.LIST} access={ACCESS.SOURCES} component={ListSources} />
          <GuardedRoute exact path={PATH.SOURCES.CREATE} access={ACCESS.SOURCES} component={EditSource} />
          <GuardedRoute exact path={PATH.SOURCES.EDIT} access={ACCESS.SOURCES} component={EditSource} />
          <GuardedRoute
            exact
            path={PATH.PROCESS_DEFINITION.PROCESS_ROLES.LIST}
            access={ACCESS.PROCESS_ROLES}
            component={ListProcessRoles}
          />
          <GuardedRoute
            exact
            path={PATH.PROCESS_DEFINITION.LIST}
            access={ACCESS.PROCESS_DEFINITIONS}
            component={ListProcessDefinitions}
          />
          <GuardedRoute
            exact
            path={PATH.PROCESS_DEFINITION.EDIT}
            access={ACCESS.PROCESS_DEFINITIONS}
            component={EditProcessDefinition}
          />
          <GuardedRoute
            path={PATH.PROCESS_DEFINITION.CREATE}
            access={ACCESS.PROCESS_DEFINITIONS}
            component={EditProcessDefinition}
          />
          <GuardedRoute exact path={PATH.CALENDARS.LIST} access={ACCESS.CALENDARS} component={ListCalendars} />
          <GuardedRoute exact path={PATH.CALENDARS.CREATE} access={ACCESS.CALENDARS} component={EditCalendar} />
          <GuardedRoute exact path={PATH.CALENDARS.EDIT} access={ACCESS.CALENDARS} component={EditCalendar} />
          <GuardedRoute
            exact
            path={PATH.WORK_ORDER_STATUSES.LIST}
            access={ACCESS.PROCESS_STATES}
            component={ListStatuses}
          />

          <GuardedRoute exact path={PATH.RATES.LIST} access={ACCESS.RATES} component={ListRates} />
          <GuardedRoute exact path={PATH.RATES.CREATE} access={ACCESS.RATES} component={EditRate} />
          <GuardedRoute exact path={PATH.RATES.EDIT} access={ACCESS.RATES} component={EditRate} />

          <GuardedRoute exact path={PATH.ITEMS.LIST} access={ACCESS.ITEMS} component={ListItems} />
          <GuardedRoute exact path={PATH.ITEMS.CREATE} access={ACCESS.ITEMS} component={EditItem} />
          <GuardedRoute exact path={PATH.ITEMS.EDIT} access={ACCESS.ITEMS} component={EditItem} />

          <GuardedRoute exact path={PATH.MEASURES.LIST} access={ACCESS.MEASURES} component={ListMeasures} />
          <GuardedRoute exact path={PATH.AGGRUPATIONS.LIST} access={ACCESS.AGGRUPATIONS} component={ListAggrupations} />
          <GuardedRoute exact path={PATH.USERS.ROLES.LIST} access={ACCESS.ROLES} component={ListRoles} />
          <GuardedRoute exact path={PATH.USERS.ROLES.CREATE} access={ACCESS.ROLES} component={EditRole} />
          <GuardedRoute exact path={PATH.USERS.ROLES.EDIT} access={ACCESS.ROLES} component={EditRole} />
          <GuardedRoute exact path={PATH.USERS.LIST} access={ACCESS.USERS} component={ListUsers} />
          <GuardedRoute exact path={PATH.USERS.CREATE} access={ACCESS.USERS} component={EditUser} />
          <GuardedRoute exact path={PATH.USERS.EDIT} access={ACCESS.USERS} component={EditUser} />
          <GuardedRoute exact path={PATH.USERS.PROFILEEDIT} component={() => <EditUser profile />} />

          <GuardedRoute
            exact
            path={PATH.DOCUMENT_TYPES.LIST}
            access={ACCESS.DOCUMENT_TYPES}
            component={ListDocumentTypes}
          />
          <GuardedRoute exact path={PATH.PROJECTS.LIST} access={ACCESS.PROJECTS} component={ListProjects} />
          <GuardedRoute exact path={PATH.PROJECTS.CREATE} access={ACCESS.PROJECTS} component={EditProject} />
          <GuardedRoute exact path={PATH.PROJECTS.EDIT} access={ACCESS.PROJECTS} component={EditProject} />
          <GuardedRoute
            exact
            path={PATH.BUSINESS_LINES.LIST}
            access={ACCESS.BUSINESS_LINES}
            component={ListBusinessLines}
          />
          <GuardedRoute
            exact
            path={PATH.BUSINESS_LINES.CREATE}
            access={ACCESS.BUSINESS_LINES}
            component={EditBusinessLine}
          />
          <GuardedRoute
            exact
            path={PATH.BUSINESS_LINES.EDIT}
            access={ACCESS.BUSINESS_LINES}
            component={EditBusinessLine}
          />
          <GuardedRoute exact path={PATH.COMPANIES.LIST} access={ACCESS.COMPANIES} component={ListCompanies} />
          <GuardedRoute exact path={PATH.COMPANIES.CREATE} access={ACCESS.COMPANIES} component={EditCompany} />
          <GuardedRoute exact path={PATH.COMPANIES.EDIT} access={ACCESS.COMPANIES} component={EditCompany} />
          <GuardedRoute exact path={PATH.WORK_GROUPS.LIST} access={ACCESS.WORK_GROUPS} component={ListWorkGroups} />
          <GuardedRoute exact path={PATH.WORK_GROUPS.CREATE} access={ACCESS.WORK_GROUPS} component={EditWorkGroup} />
          <GuardedRoute exact path={PATH.WORK_GROUPS.EDIT} access={ACCESS.WORK_GROUPS} component={EditWorkGroup} />
          <GuardedRoute
            exact
            path={PATH.PAYMENT_METHODS.LIST}
            access={ACCESS.PAYMENT_METHODS}
            component={ListPaymentMethods}
          />
          <GuardedRoute
            exact
            path={PATH.PAYMENT_METHODS.CREATE}
            access={ACCESS.PAYMENT_METHODS}
            component={EditPaymentMethod}
          />
          <GuardedRoute
            exact
            path={PATH.PAYMENT_METHODS.EDIT}
            access={ACCESS.PAYMENT_METHODS}
            component={EditPaymentMethod}
          />
          <GuardedRoute exact path={PATH.ALERTS.LIST} access={ACCESS.ALERTS} component={ListAlerts} />
          <GuardedRoute exact path={PATH.ALERTS.CREATE} access={ACCESS.ALERTS} component={EditAlert} />
          <GuardedRoute exact path={PATH.ALERTS.EDIT} access={ACCESS.ALERTS} component={EditAlert} />

          <GuardedRoute exact path={PATH.REPORTS.LIST} access={ACCESS.REPORTS} component={ListReports} />

          {/* Suppliers */}

          <GuardedRoute
            exact
            path={PATH.SUPPLIERS.DASHBOARD}
            access={ACCESS.SUPPLIERS_DASHBOARD}
            component={SuppliersDashboard}
          />
          <GuardedRoute exact path={PATH.SUPPLIERS.LIST} access={ACCESS.SUPPLIERS} component={ListSuppliers} />
          <GuardedRoute exact path={PATH.SUPPLIERS.CREATE} access={ACCESS.SUPPLIERS} component={EditSupplier} />
          <GuardedRoute exact path={PATH.SUPPLIERS.EDIT} access={ACCESS.SUPPLIERS} component={EditSupplier} />
          <GuardedRoute
            exact
            path={PATH.SUPPLIERS.DOCUMENTS.CREATE}
            access={ACCESS.SUPPLIERS}
            component={EditSupplierDocument}
          />
          <GuardedRoute
            exact
            path={PATH.SUPPLIERS.DOCUMENTS.EDIT}
            access={ACCESS.SUPPLIERS}
            component={EditSupplierDocument}
          />
          <GuardedRoute
            exact
            path={PATH.SUPPLIERS.DOCUMENT_TYPES.LIST}
            access={ACCESS.SUPPLIER_DOCUMENT_TYPES}
            component={ListSupplierDocumentTypes}
          />
          <GuardedRoute
            exact
            path={PATH.SUPPLIERS.DOCUMENT_GROUPS.LIST}
            access={ACCESS.SUPPLIER_DOCUMENT_GROUPS}
            component={ListDocumentGroups}
          />
          <GuardedRoute
            exact
            path={PATH.SUPPLIERS.DOCUMENT_GROUPS.CREATE}
            access={ACCESS.SUPPLIER_DOCUMENT_GROUPS}
            component={EditDocumentGroup}
          />
          <GuardedRoute
            exact
            path={PATH.SUPPLIERS.DOCUMENT_GROUPS.EDIT}
            access={ACCESS.SUPPLIER_DOCUMENT_GROUPS}
            component={EditDocumentGroup}
          />
          <GuardedRoute
            exact
            path={PATH.SUPPLIERS.SPECIALITIES.LIST}
            access={ACCESS.SPECIALITIES}
            component={ListSpecialities}
          />
          <GuardedRoute
            exact
            path={PATH.SUPPLIERS.SPECIALITIES.CREATE}
            access={ACCESS.SPECIALITIES}
            component={EditSpeciality}
          />
          <GuardedRoute
            exact
            path={PATH.SUPPLIERS.SPECIALITIES.EDIT}
            access={ACCESS.SPECIALITIES}
            component={EditSpeciality}
          />
          <GuardedRoute
            exact
            path={PATH.SUPPLIERS.INSURANCE_COMPANIES.LIST}
            access={ACCESS.INSURANCE_COMPANIES}
            component={ListInsuranceCompanies}
          />
          <GuardedRoute
            exact
            path={PATH.SUPPLIERS.INSURANCE_COMPANIES.CREATE}
            access={ACCESS.INSURANCE_COMPANIES}
            component={EditInsuranceCompany}
          />
          <GuardedRoute
            exact
            path={PATH.SUPPLIERS.INSURANCE_COMPANIES.EDIT}
            access={ACCESS.INSURANCE_COMPANIES}
            component={EditInsuranceCompany}
          />
          <GuardedRoute
            exact
            path={PATH.SUPPLIERS.RECEIVED_INVOICES.LIST}
            access={ACCESS.RECEIVED_INVOICES}
            component={ListReceivedInvoices}
          />
          <GuardedRoute
            exact
            path={PATH.SUPPLIERS.RECEIVED_INVOICES.DOCUMENTS.CREATE}
            access={ACCESS.RECEIVED_INVOICES}
            component={EditReceivedInvoiceDocument}
          />
          <GuardedRoute
            exact
            path={PATH.SUPPLIERS.RECEIVED_INVOICES.DOCUMENTS.EDIT}
            access={ACCESS.RECEIVED_INVOICES}
            component={EditReceivedInvoiceDocument}
          />
          <GuardedRoute
            exact
            path={PATH.SUPPLIERS.RECEIVED_INVOICES.CREATE}
            access={ACCESS.RECEIVED_INVOICES}
            component={EditReceivedInvoice}
          />
          <GuardedRoute
            exact
            path={PATH.SUPPLIERS.RECEIVED_INVOICES.CREATE_SIMPLE_INVOICE}
            access={ACCESS.RECEIVED_INVOICES}
            component={EditReceivedInvoice}
          />
          <GuardedRoute
            exact
            path={PATH.SUPPLIERS.RECEIVED_INVOICES.EDIT}
            access={ACCESS.RECEIVED_INVOICES}
            component={EditReceivedInvoice}
          />
          <GuardedRoute
            exact
            path={PATH.SUPPLIERS.RECEIVED_INVOICES.EDIT_SIMPLE_INVOICE}
            access={ACCESS.RECEIVED_INVOICES}
            component={EditReceivedInvoice}
          />
          <GuardedRoute
            exact
            path={PATH.SUPPLIERS.AGREEMENTS.WORKERS.CREATE}
            access={ACCESS.SUPPLIERS}
            component={EditWorker}
          />
          <GuardedRoute
            exact
            path={PATH.SUPPLIERS.AGREEMENTS.WORKERS.EDIT}
            access={ACCESS.SUPPLIERS}
            component={EditWorker}
          />
          <GuardedRoute
            exact
            path={PATH.SUPPLIERS.CREDITORS.LIST}
            access={ACCESS.CREDITORS}
            component={ListCreditors}
          />
          <GuardedRoute
            exact
            path={PATH.SUPPLIERS.CREDITORS.CREATE}
            access={ACCESS.CREDITORS}
            component={EditCreditor}
          />
          <GuardedRoute
            exact
            path={PATH.SUPPLIERS.CREDITORS.RECEIVED_INVOICES.EDIT_CREDITOR_INVOICE}
            access={ACCESS.RECEIVED_INVOICES}
            component={EditReceivedInvoice}
          />
          <GuardedRoute
            exact
            path={PATH.SUPPLIERS.CREDITORS.RECEIVED_INVOICES.CREATE_CREDITOR_INVOICE}
            access={ACCESS.RECEIVED_INVOICES}
            component={EditReceivedInvoice}
          />
          <GuardedRoute exact path={PATH.SUPPLIERS.CREDITORS.EDIT} access={ACCESS.CREDITORS} component={EditCreditor} />
          <GuardedRoute
            exact
            path={PATH.SUPPLIERS.CREDITORS.RECEIVED_INVOICES.DOCUMENTS.CREATE}
            access={ACCESS.RECEIVED_INVOICES}
            component={EditReceivedInvoiceDocument}
          />
          <GuardedRoute
            exact
            path={PATH.SUPPLIERS.CREDITORS.RECEIVED_INVOICES.DOCUMENTS.EDIT}
            access={ACCESS.RECEIVED_INVOICES}
            component={EditReceivedInvoiceDocument}
          />
          <GuardedRoute
            exact
            path={PATH.SUPPLIERS.SUPPLIER_TYPES.LIST}
            access={ACCESS.SUPPLIER_TYPES}
            component={ListSupplierTypes}
          />

          <GuardedRoute
            exact
            path={PATH.SUPPLIERS.AGREEMENTS.CREATE}
            access={ACCESS.SUPPLIERS}
            component={EditAgreement}
          />
          <GuardedRoute
            exact
            path={PATH.SUPPLIERS.AGREEMENTS.EDIT}
            access={ACCESS.SUPPLIERS}
            component={EditAgreement}
          />
          <GuardedRoute
            exact
            path={PATH.SUPPLIERS.AGREEMENTS.EDIT_READONLY}
            access={ACCESS.SUPPLIERS}
            component={EditAgreementReadonly}
          />

          {/* Agenda */}
          <GuardedRoute
            exact
            path={PATH.APPOINTMENT_TYPES.LIST}
            access={ACCESS.APPOINTMENT_TYPES}
            component={ListAppointmentTypes}
          />

          <GuardedRoute exact path={PATH.AGENDA.LIST} access={ACCESS.SCHEDULE} component={ViewAgenda} />

          <GuardedRoute exact path={PATH.INTEGRATIONS.LIST} access={ACCESS.INTEGRATIONS} component={ListIntegrations} />
          <GuardedRoute
            exact
            path={PATH.INTEGRATIONS.CREATE}
            access={ACCESS.INTEGRATIONS}
            component={EditIntegration}
          />
          <GuardedRoute exact path={PATH.INTEGRATIONS.EDIT} access={ACCESS.INTEGRATIONS} component={EditIntegration} />
          <GuardedRoute exact path={PATH.EQUIVALENCES.LIST} access={ACCESS.EQUIVALENCES} component={ListEquivalences} />
          <GuardedRoute
            exact
            path={PATH.CONTACT_ROLES.LIST}
            access={ACCESS.CONTACT_ROLES}
            component={ListContactRoles}
          />

          {/* Customer */}

          <GuardedRoute exact path={PATH.CUSTOMERS.LIST} access={ACCESS.CUSTOMERS} component={ListCustomers} />
          <GuardedRoute exact path={PATH.CUSTOMERS.CREATE} access={ACCESS.CUSTOMERS} component={EditCustomer} />
          <GuardedRoute exact path={PATH.CUSTOMERS.EDIT} access={ACCESS.CUSTOMERS} component={EditCustomer} />
          <GuardedRoute
            exact
            path={PATH.CUSTOMERS.GROUPS.LIST}
            access={ACCESS.CUSTOMER_GROUPS}
            component={ListCustomerGroups}
          />
          <GuardedRoute
            exact
            path={PATH.CUSTOMERS.ISSUED_INVOICES.LIST}
            access={ACCESS.ISSUED_INVOICES}
            component={ListIssuedInvoices}
          />
          <GuardedRoute
            exact
            path={PATH.CUSTOMERS.ISSUED_INVOICES.CREATE}
            access={ACCESS.ISSUED_INVOICES}
            component={EditIssuedInvoice}
          />
          <GuardedRoute
            exact
            path={PATH.CUSTOMERS.ISSUED_INVOICES.EDIT}
            access={ACCESS.ISSUED_INVOICES}
            component={EditIssuedInvoice}
          />
          {/* GALLERY */}
          <GuardedRoute exact path={PATH.GALLERY_TAGS.LIST} access={ACCESS.GALLERY_TAGS} component={ListGalleryTags} />

          {/* Directory templates */}
          <GuardedRoute
            exact
            path={PATH.DIRECTORY_TEMPLATES.LIST}
            access={ACCESS.DIRECTORY_TEMPLATES}
            component={ListDirectoryTemplates}
          />
          <GuardedRoute
            exact
            path={PATH.DIRECTORY_TEMPLATES.CREATE}
            access={ACCESS.DIRECTORY_TEMPLATES}
            component={EditDirectoryTemplate}
          />
          <GuardedRoute
            exact
            path={PATH.DIRECTORY_TEMPLATES.EDIT}
            access={ACCESS.DIRECTORY_TEMPLATES}
            component={EditDirectoryTemplate}
          />
        </Switch>
      </Suspense>
    </Container>
  );
};

export const Routes = React.memo(_routes);
