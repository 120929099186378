import { APIS } from 'app.apis.const';
import { apiHelper } from 'shared';
import { http } from './http';

const controllerName = 'received-invoices';

class ReceivedInvoices {
  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/${controllerName}${queryString}`, {}, APIS.FINANCES);
    const items = response.data && response.data.items;

    return items;
  }

  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...{ isSimpleInvoice: false, ...filters } });

    return http.get(`/${controllerName}/${queryString}`, {}, APIS.FINANCES);
  }

  getById(id) {
    return http.get(`/${controllerName}/${id}`, {}, APIS.FINANCES);
  }

  createOrUpdate(receivedInvoice) {
    return receivedInvoice.id ? this.update(receivedInvoice) : this.create(receivedInvoice);
  }

  createOrUpdateSimpleInvoice(receivedInvoice) {
    return receivedInvoice.id ? this.updateSimpleInvoice(receivedInvoice) : this.createSimpleInvoice(receivedInvoice);
  }

  create(receivedInvoice) {
    return http.post(`/${controllerName}`, receivedInvoice, {}, APIS.FINANCES);
  }

  createSimpleInvoice(receivedInvoice) {
    return http.post(`/${controllerName}/simple-invoice`, receivedInvoice, {}, APIS.FINANCES);
  }

  delete(id, multiple) {
    return http.delete(`/${controllerName}/${id}`, { multiple }, APIS.FINANCES);
  }

  update(receivedInvoice) {
    return http.put(
      `/${controllerName}/${receivedInvoice.id}`,
      {
        ...receivedInvoice,
        forceUpdatePaymentMethodTerm: true,
        forceUpdateDueDate: true,
        forceUpdatePaymentDate: true,
        forceUpdateRetention: true,
        forceUpdateEntity: true,
        forceUpdateRegistrationDate: true,
      },
      {},
      APIS.FINANCES
    );
  }

  updateSimpleInvoice(receivedInvoice) {
    return http.put(
      `/${controllerName}/${receivedInvoice.id}/simple-invoice`,
      {
        ...receivedInvoice,
        forceUpdatePaymentMethodTerm: true,
        forceUpdateDueDate: true,
        forceUpdatePaymentDate: true,
        forceUpdateRetentionType: true,
        forceUpdateRetention: true,
        forceUpdateOutlays: true,
        forceUpdateEntity: true,
        forceUpdateRegistrationDate: true,
      },
      {},
      APIS.FINANCES
    );
  }

  getPaymentMethods() {
    return http.get(`/${controllerName}/payment-methods`, {}, APIS.FINANCES);
  }

  getRetentionTypes() {
    return http.get(`/${controllerName}/retention-types`, {}, APIS.FINANCES);
  }
}

export const receivedInvoices = new ReceivedInvoices();
