import { Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { PropTypes } from 'prop-types';
import React from 'react';
import { domHelper } from 'shared/helper';
import { useDoubleClick } from 'shared/useDoubleClick';
import { useFileStyles } from './file.styles';
import FileIcon from './FileIcons';

export const File = ({ file, onCheckDocuments, isDocumentChecked }) => {
  const classes = useFileStyles();
  const { handleClick, handleDoubleClick } = useDoubleClick();

  const fileTypesToOpen = ['png', 'jpeg', 'jpg', 'pdf', 'txt'];
  const getFileType = mimeType => mimeType.split('/').pop();

  const onDoubleClickFile = file => {
    const fileType = file.mimeType ? getFileType(file.mimeType) : null;
    if (fileType && fileTypesToOpen.includes(fileType)) {
      domHelper.openFile(file.document, file.version, file.documentName);
    } else {
      const fileUrl = file.mimeType ? file.document : file.templateUrl ? file.templateUrl : null;
      if (fileUrl) domHelper.downloadFile(fileUrl, file.documentName, file.version);
    }
  };

  return (
    <Tooltip title={`${file.documentType} ${file.documentName ? `- ${file.documentName}` : ''}`} placement="top">
      <div
        className={clsx(classes.main, isDocumentChecked(file) && classes.checked)}
        onClick={() => handleClick(onCheckDocuments(file))}
        onDoubleClick={() => handleDoubleClick(onDoubleClickFile(file))}
      >
        <FileIcon
          extension={file.documentName?.substring(file.documentName?.lastIndexOf('.') + 1)}
          empty={!file.documentName}
        />
        <p className={classes.fileName}>{file.documentName ? file.documentName : file.documentType}</p>
      </div>
    </Tooltip>
  );
};
File.propTypes = {
  onCheckDocuments: PropTypes.func,
  isDocumentsChecked: PropTypes.func,
  file: PropTypes.shape({
    id: PropTypes.string,
    documentName: PropTypes.string,
    documentType: PropTypes.string,
    document: PropTypes.string,
    version: PropTypes.number,
    mimeType: PropTypes.string,
    templateUrl: PropTypes.string
  }),
  isDocumentChecked: PropTypes.bool
};
