import { SKELETON_KEY, SKELETON_KEY_DATE } from './skeletonFactory';

export function skeletonWorkOrderBudgetItemHistory() {
  return {
    id: SKELETON_KEY,
    fieldName: SKELETON_KEY,
    fieldValue: SKELETON_KEY,
    reference: SKELETON_KEY,
    entryDateStart: SKELETON_KEY_DATE,
    entryDateEnd: SKELETON_KEY_DATE,
    user: SKELETON_KEY,
    workGroup: SKELETON_KEY,
  };
}
