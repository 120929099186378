import { SKELETON_KEY } from './skeletonFactory';

export function skeletonWorkGroup() {
  return {
    id: SKELETON_KEY,
    name: SKELETON_KEY,
    businessLineId: SKELETON_KEY,
    projects: Array(10).fill({name: SKELETON_KEY, id: SKELETON_KEY}),
    users: Array(10).fill({alias: SKELETON_KEY, id: SKELETON_KEY})
  };
}
