import { http } from './http';
import { apiHelper } from 'shared';

class WorkGroups {
  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/work-groups/${queryString}`, {});
  }

  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });
    const response = await http.get(`/work-groups${queryString}`, {});
    const items = response.data && response.data.items;

    return items;
  }

  getById(id) {
    return http.get(`/work-groups/${id}`, {});
  }

  create(workGroup) {
    return http.post('/work-groups', this.formatRequest(workGroup), {});
  }

  delete(id, multiple) {
    return http.delete(`/work-groups/${id}`, { multiple });
  }

  update(id, workGroup) {
    return http.put(`/work-groups/${id}`, this.formatRequest(workGroup), {});
  }

  formatRequest(workGroup) {
    return {
      ...workGroup,
      userIds: workGroup.users.map(user => {
        return user.id;
      }),
      projectIds: workGroup.projects.map(project => {
        return project.id;
      })
    };
  }

  getMyWorkGroupAnalytics = async () => {
    return http.get('/work-groups/my/analytics', {});
  };
}

export const workGroups = new WorkGroups();
