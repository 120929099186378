import { apiHelper } from 'shared';
import { http } from './http';
import { APIS } from 'app.apis.const';

class Dashboards {
  getSuppliersDashboard = async () => {
    return http.get('/dashboards/suppliers', {}, APIS.CMMS);
  };

  getWorkOrdersDashboard(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/dashboards/${queryString}`, {});
  }
}

export const dashboards = new Dashboards();
