import axios from 'axios';

export const KEY_SETTINGS = {
  API_URL: 'API_URL',
  API_VERSION: 'API_VERSION',
  ENVIRONMENT: 'ENVIRONMENT',
  SITE_ID: 'SITE_ID',
  IDENTITY_URL: 'IDENTITY_URL',
  FINANCES_API_URL: 'FINANCES_API_URL',
  FINANCES_API_VERSION: 'FINANCES_API_VERSION',
  PLACES_API_URL: 'PLACES_API_URL',
  PLACES_API_VERSION: 'PLACES_API_VERSION',
  SIGNALR_URL: 'SIGNALR_URL',
  SIGNALR_NOTIFICATIONS_URL: 'SIGNALR_NOTIFICATIONS_URL',
  BPMN_API_URL: 'BPMN_API_URL',
  BPMN_API_VERSION: 'BPMN_API_VERSION',
  AZURE_STORAGE: 'AZURE_STORAGE',
  ASSET_TYPE: 'ASSET_TYPE',
  NOTIFICATIONS_API_URL: 'NOTIFICATIONS_API_URL',
  NOTIFICATIONS_API_VERSION: 'NOTIFICATIONS_API_VERSION',
  REAL_ESTATES_API_URL: 'REAL_ESTATES_API_URL',
  REAL_ESTATES_API_VERSION: 'REAL_ESTATES_API_VERSION',
  DOCUMENTARY_API_URL: 'DOCUMENTARY_API_URL',
  DOCUMENTARY_API_VERSION: 'DOCUMENTARY_API_VERSION',
  IDENTITY_API_URL: 'IDENTITY_API_URL',
  IDENTITY_API_VERSION: 'IDENTITY_API_VERSION',
  COUNTRY_CODES: 'COUNTRY_CODES',
  SUPPLIER_DASHBOARD_URL: 'SUPPLIER_DASHBOARD_URL',
};

class Settings {
  async loadSettings() {
    const isLocalHost = window.location.hostname.includes('localhost');
    const $SETTINGS_PATH = '/config/settings.json';
    const $HOST = isLocalHost ? window.location.host : window.location.hostname;
    const $URL_CONFIG = `${isLocalHost ? 'http' : 'https'}://${$HOST}${$SETTINGS_PATH}`;

    try {
      const { data } = await axios.get($URL_CONFIG);
      Object.keys(data).forEach(settingName => localStorage.setItem(settingName, data[settingName]));
    } catch (error) {
      throw error || 'Api error';
    }
  }

  async loadDockerSettings() {
    // TODO: New apis Docker
    const { REACT_APP_API_URL, REACT_APP_SITE_ID } = process.env;
    const data = { API_URL: REACT_APP_API_URL, SITE_ID: REACT_APP_SITE_ID };
    Object.keys(data).forEach(settingName => localStorage.setItem(settingName, data[settingName]));
  }

  getSetting(settingName) {
    return localStorage.getItem(settingName);
  }
}

export const settings = new Settings();
