export const STATUSES_ENUM = {
  BUDGETITEM_BILLINGSTATUS: {
    UNSELECTED: -1,
    DOESNT_AFFECT: 0,
    BILLABLE: 1,
    BILLED: 2,
    CANCELED: 3,
    PENDING_APPROVAL: 4,
    AWARDED: 5
  },
  BUDGETITEM_SUPPLIERBILLINGSTATUS: {
    PENDING: 0,
    COMPLETED_PENDING_VALIDATION: 1,
    BILLABLE: 2,
    BILLED: 3,
    CANCELED: 4
  },
  RECEIVEDINVOICE: {
    PROFORMA: 0,
    INVOICE: 1
  },
  ISSUEDINVOICE: {
    PROFORMA: 0,
    INVOICE: 1
  }
};
