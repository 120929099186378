import { apiHelper } from 'shared';
import { http } from './http';

const controllerName = 'work-orders';
const controllerPath = 'budget-items';

class BudgetItemSuppliers {
  async getUsingQueryFilter(workOrderId, budgetItemId, queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(
      `/${controllerName}/${workOrderId}/${controllerPath}/${budgetItemId}/suppliers${queryString}`
    );
    const items = response.data && response.data.items;

    return items;
  }

  get(workOrderId, budgetItemId, paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({
      ...paging,
      ...sorting,
      ...filters
    });

    return http.get(`/${controllerName}/${workOrderId}/${controllerPath}/${budgetItemId}/suppliers/${queryString}`);
  }

  create(biSupplier, workOrderId, budgetItemId ) {
    return http.post(`/${controllerName}/${workOrderId}/${controllerPath}/${budgetItemId}/suppliers`, biSupplier);
  }

  delete(workOrderId, budgetItemId, id, multiple) {
    return http.delete(`/${controllerName}/${workOrderId}/${controllerPath}/${budgetItemId}/suppliers/${id}`, {
      multiple
    });
  }

  update(biSupplier, workOrderId, budgetItemId, ) {
    return http.put(`/${controllerName}/${workOrderId}/${controllerPath}/${budgetItemId}/suppliers/${biSupplier.id}`, {
      ...biSupplier,
      forceUpdateHireDate: true,
      forceUpdateBudgetRequestDate: true,
      forceUpdateBudgetedUnitCost: true,
      forceUpdateBudgetItemBreakdown: true,
    });
  }
}

export const budgetItemSuppliers = new BudgetItemSuppliers();
