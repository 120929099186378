import { http } from './http';
import { apiHelper } from 'shared';

const controllerName = 'process-executions/history';

class HistoryItems {
  get(entityId, entityType, paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ entityId, entityType, ...paging, ...sorting, ...filters });

    return http.get(`/${controllerName}/${queryString}`);
  }
}

export const historyItems = new HistoryItems();
