import { Tabs } from '@engloba-tech/englobity';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTabsStyles } from './Tabs.styles';

export function CmmsTabs({ tabs, defaultPosition, ...props }) {
  const classes = useTabsStyles();

  return (
    <Grid container className={classes.tabsContainer}>
      <Grid item xs={12} className={classes.flex}>
        <Tabs classNameContent={classes.tabsMinHeight} tabs={tabs} defaultPosition={defaultPosition} {...props} />
      </Grid>
    </Grid>
  );
}

CmmsTabs.propTypes = {
  tabs: PropTypes.array,
  defaultPosition: PropTypes.number,
  className: PropTypes.string
};
