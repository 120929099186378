import { SKELETON_KEY } from './skeletonFactory';

export function skeletonDirectoryTemplateTree() {
  return {
    id: SKELETON_KEY,
    name: SKELETON_KEY,
    parentId: SKELETON_KEY,
    path: SKELETON_KEY
  };
}

export function skeletonDirectoryTemplate() {
  return {
    id: SKELETON_KEY,
    name: SKELETON_KEY,
    tree: Array(5).fill(skeletonDirectoryTemplateTree())
  };
}
