import { useState, useEffect } from 'react';
import { realEstates } from 'services';
import { skeletonFactory } from 'shared';

const operationTypesDummy = skeletonFactory.operationTypes();

export function useOperationTypes() {
  const [operationTypesData, setOperationTypesData] = useState(operationTypesDummy);

  useEffect(() => {
    async function getData() {
      const response = await realEstates.getOperationTypes();
      if (!response) {
        return;
      }

      setOperationTypesData(response.data || []);
    }
    getData();
  }, []);

  return { operationTypes: operationTypesData, setOperationTypesData };
}
