import { Drawer, SvgIcon } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { PATH } from 'app.routes.const';
import { UserContext } from 'auth';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { ReactComponent as SetflowsLogoNoText } from './../../assets/images/setflows-logo-no-text.svg';
import { ReactComponent as SetflowsLogo } from './../../assets/images/setflows-logo.svg';
import { NavigationList } from './NavigationList';
import { navigationHelper } from './navigation.helper';
import { useNavigationStyle } from './navigation.styles';
import { useNavigationStorage } from './useNavigationStorage';

export function Navigation() {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const classes = useNavigationStyle();
  let location = useLocation();
  const history = useHistory();
  const { isNavOpenisOpen, toggleNavOpen } = useNavigationStorage();
  const navigation = navigationHelper.generate(t);
  const navigationItems = navigationHelper.byAccess(navigation, user.access);
  const highlightedNavigations = navigationHelper.highlighted(navigationItems, location.pathname);

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: isNavOpenisOpen,
        [classes.drawerClose]: !isNavOpenisOpen
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: isNavOpenisOpen,
          [classes.drawerClose]: !isNavOpenisOpen
        })
      }}
    >
      {isNavOpenisOpen ? (
        <SvgIcon
          component={SetflowsLogo}
          // TODO: class works but shows warning, classes and className won't work as expected
          class={classes.setFlowsLogo}
          viewBox="0 0 118.53 42.3"
          onClick={() => history.push(PATH.HOME)}
        />
      ) : (
        <SvgIcon
          component={SetflowsLogoNoText}
          // TODO: class works but shows warning, classes and className won't work as expected
          class={classes.setFlowsLogoNoText}
          viewBox="0 0 43.1 45.1"
          onClick={() => history.push(PATH.HOME)}
        />
      )}

      <div className={classes.spaceBetween}>
        <NavigationList navigations={highlightedNavigations} isOpen={isNavOpenisOpen} />
        <div className={classes.flexCol}>
          <Divider />
          <IconButton className={classes.togglerButton} onClick={toggleNavOpen}>
            {!isNavOpenisOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
      </div>
    </Drawer>
  );
}
