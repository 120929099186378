import { ButtonMulti } from '@engloba-tech/englobity';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useButtonStyles } from './buttonMulti.styles';

export function ButtonMultiCmms({
  text,
  actions,
  className,
  classNameBtn,
  keepOpenAfterSelect,
  onClickAway,
  ...props
}) {
  const classes = useButtonStyles();

  return (
    <div className={className}>
      <ButtonMulti
        text={text}
        className={clsx(classes.button, classNameBtn)}
        popperClassName={classes.popper}
        actions={actions}
        keepOpenAfterSelect={keepOpenAfterSelect}
        onClickAway={onClickAway}
        {...props}
      />
    </div>
  );
}

ButtonMultiCmms.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  actions: PropTypes.array,
  classNameBtn: PropTypes.string,
  keepOpenAfterSelect: PropTypes.bool,
  onClickAway: PropTypes.func
};
