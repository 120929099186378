import { http } from './http';
import { apiHelper } from 'shared';

class Reminders {
  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/reminders/my/${queryString}`, {});
  }

  getCountPending() {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ pageSize: 1, pageIndex: 0 });

    return http.get(`/reminders/my/${queryString}`, {});
  }

  delete(id) {
    return http.delete(`/reminders/${id}`, {});
  }
}

export const reminders = new Reminders();
