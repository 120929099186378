import { apiHelper } from 'shared';
import { http } from './http';

class CurrentMilestones {
  get(paging, sorting, filters, advancedFilters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters, ...advancedFilters });

    return http.get(`/current-milestones/${queryString}`);
  }
}

export const currentMilestones = new CurrentMilestones();
