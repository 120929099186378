export const HEADERS_ACCESS = {
  WORK_ORDERS: ['WORK_ORDERS_DASHBOARD', 'WORK_ORDERS', 'WORK_ORDERS_PENDING_ACTIVITIES', 'WORK_ORDERS_AUTOMATIONS'],
  REAL_ESTATES: ['REAL_ESTATES'],
  AGENDA: ['SCHEDULE', 'APPOINTMENT_TYPES'],
  RATES: ['RATES', 'ITEMS', 'MEASURES', 'AGGRUPATIONS'],
  SUPPLIERS: [
    'SUPPLIERS_DASHBOARD',
    'SUPPLIERS',
    'CREDITORS',
    'SUPPLIER_TYPES',
    'SPECIALITIES',
    'SUPPLIER_DOCUMENT_TYPES',
    'SUPPLIER_DOCUMENT_GROUPS',
    'INSURANCE_COMPANIES',
    'RECEIVED_INVOICES'
  ],
  CUSTOMERS: ['CUSTOMERS', 'CUSTOMER_GROUPS', 'ISSUED_INVOICES'],
  DEFINITIONS: ['SOURCES', 'PROCESS_DEFINITIONS', 'PROCESS_ROLES', 'CALENDARS', 'PROCESS_STATES'],
  ORGANIZATION: [
    'COMPANIES',
    'BUSINESS_LINES',
    'PROJECTS',
    'WORK_GROUPS',
    'CONTACT_ROLES',
    'DOCUMENT_TYPES',
    'GALLERY_TAGS',
    'PAYMENT_METHODS',
    'ALERTS',
    'REPORTS',
    'DIRECTORY_TEMPLATES'
  ],
  CONFIGURATION: ['USERS', 'ROLES', 'INTEGRATIONS', 'EQUIVALENCES']
};

export const ACCESS = {
  WORK_ORDERS_DASHBOARD: 2 ** 0,
  WORK_ORDERS: 2 ** 1,
  WORK_ORDERS_PENDING_ACTIVITIES: 2 ** 2,
  RATES: 2 ** 3,
  ITEMS: 2 ** 4,
  MEASURES: 2 ** 39,
  AGGRUPATIONS: 2 ** 40,
  SUPPLIERS_DASHBOARD: 2 ** 5,
  SUPPLIERS: 2 ** 6,
  CREDITORS: 2 ** 7,
  SUPPLIER_TYPES: 2 ** 8,
  SPECIALITIES: 2 ** 9,
  SUPPLIER_DOCUMENT_TYPES: 2 ** 10,
  SUPPLIER_DOCUMENT_GROUPS: 2 ** 11,
  INSURANCE_COMPANIES: 2 ** 12,
  RECEIVED_INVOICES: 2 ** 13,
  CUSTOMERS: 2 ** 14,
  CUSTOMER_GROUPS: 2 ** 15,
  ISSUED_INVOICES: 2 ** 16,
  SOURCES: 2 ** 17,
  PROCESS_DEFINITIONS: 2 ** 18,
  PROCESS_ROLES: 2 ** 19,
  CALENDARS: 2 ** 20,
  PROCESS_STATES: 2 ** 21,
  COMPANIES: 2 ** 22,
  BUSINESS_LINES: 2 ** 23,
  PROJECTS: 2 ** 24,
  WORK_GROUPS: 2 ** 25,
  DOCUMENT_TYPES: 2 ** 26,
  GALLERY_TAGS: 2 ** 27,
  PAYMENT_METHODS: 2 ** 28,
  ALERTS: 2 ** 29,
  USERS: 2 ** 30,
  ROLES: 2 ** 31,
  INTEGRATIONS: 2 ** 32,
  EQUIVALENCES: 2 ** 33,
  REAL_ESTATES: 2 ** 34,
  SCHEDULE: 2 ** 35,
  APPOINTMENT_TYPES: 2 ** 36,
  REPORTS: 2 ** 37,
  WORK_ORDERS_AUTOMATIONS: 2 ** 38,
  DIRECTORY_TEMPLATES: 2 ** 41,
  CONTACT_ROLES: 2 ** 42

  // ... till 53
};

export const ALL_ACCESS = Object.entries(ACCESS).reduce((acc, [key, value]) => acc | value, 0);
