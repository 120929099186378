import { ButtonSave } from '@engloba-tech/englobity';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from 'auth';
import { NAME_SPACE } from 'i18n';
import { useUserStateContext } from 'state/useUserStateContext';

export function CmmsButtonSave({ disabled, unsaved, text, onClick, requiredAccess, disableUnsavedFeature, ...props }) {
  const { t } = useTranslation(NAME_SPACE.COMMON);
  const { hasFullAccess } = useContext(UserContext);
  const { isLoading } = useUserStateContext();

  const renderButton = () => (
    <ButtonSave
      disabled={disableUnsavedFeature ? false : !unsaved || disabled}
      text={text || t('actions.save')}
      onClick={onClick}
      loading={isLoading}
      {...props}
    />
  );

  return requiredAccess ? hasFullAccess(requiredAccess) && renderButton() : hasFullAccess() && renderButton();
}

CmmsButtonSave.propTypes = {
  disabled: PropTypes.bool,
  unsaved: PropTypes.bool,
  text: PropTypes.string,
  onClick: PropTypes.func,
  requiredAccess: PropTypes.bool,
  disableUnsavedFeature: PropTypes.bool
};
