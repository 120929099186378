import { makeStyles } from '@material-ui/core/styles';
import { color } from 'styles/color.styles';

export const colors = {
  partial: color.toast.warn,
  homologated: color.action.add.main,
  notHomologated: color.action.delete.main
};

export const useBooleanElementsStyles = makeStyles(theme => {
  const iconStyles = {
    color: 'white !important',
    fontSize: '16px',
    width: '1.3em',
    height: '1.3em',
    borderRadius: '13px',
    padding: '3px'
  };
  return {
    icon: iconStyles,
    checkedIcon: {
      ...iconStyles,
      backgroundColor: colors.homologated
    },

    closedIcon: {
      ...iconStyles,
      backgroundColor: colors.notHomologated
    },

    alertIcon: {
      ...iconStyles,
      backgroundColor: colors.partial
    }
  };
});
