import { makeStyles } from '@material-ui/core/styles';

export const useViewDocumentsStyles = makeStyles(theme => ({
  modal: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: '70rem',
      minHeight: '10rem'
    }
  },
  marginTop: {
    marginTop: '2rem'
  }
}));
