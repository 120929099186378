import { makeStyles } from '@material-ui/core/styles';

export const useButtonStyles = makeStyles(theme => ({
  button: {
    border: 'none',
    background: theme.palette.primary.light,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white'
    }
  },
  popper: {
    '& .MuiList-root': {
      backgroundColor: theme.palette.primary.light,
      '& .MuiMenuItem-root': {
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        color: theme.palette.primary.main,
        fontSize: '14px',
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          color: 'white'
        }
      }
    }
  }
}));
