import { SKELETON_KEY } from './skeletonFactory';

export function skeletonAppointmentType() {
  return {
    id: SKELETON_KEY,
    name: SKELETON_KEY,
    color: SKELETON_KEY,
    groupName: SKELETON_KEY
  };
}
