import { APIS } from 'app.apis.const';
import { apiHelper } from 'shared';
import { http } from './http';

const controllerName = 'contact-methods';

class ContactMethods {
  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/${controllerName}${queryString}`, {}, APIS.REAL_ESTATES);
    const items = response.data && response.data.items;

    return items;
  }
}

export const contactMethods = new ContactMethods();
