import { Avatar } from '@engloba-tech/englobity';
import { Container, Paper, Toolbar } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import { PATH } from 'app.routes.const';
import { UserContext } from 'auth';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { SignalRCMMSContext, SignalRNotificationsContext } from 'signalR';
import { useUserStateContext } from 'state/useUserStateContext';
import backgroundImage from './../../assets/images/header-setflows@2x.png';
import { Notifications } from './Notifications';
import { Reminders } from './Reminders';
import { useHeaderStyles } from './header.styles';

export function Header() {
  const classes = useHeaderStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const { user, logout, authToken } = useContext(UserContext);
  const { disconnect: disconnectCMMSSignalR, setToken: setTokenCMMSSignalR } = useContext(SignalRCMMSContext);
  const { disconnect: disconnectNotificationsSignalR, setToken: setTokenNotificationsSignalR } =
    useContext(SignalRNotificationsContext);
  const { clearUserState } = useUserStateContext();
  const name = user?.alias;
  const email = user?.name || user?.userName || '';

  useEffect(() => {
    setTokenCMMSSignalR(authToken);
    setTokenNotificationsSignalR(authToken);
  }, [setTokenCMMSSignalR, setTokenNotificationsSignalR, authToken]);

  const handleLogout = () => {
    logout();
    disconnectCMMSSignalR();
    disconnectNotificationsSignalR();
    clearUserState();
  };

  return (
    <Paper
      elevation={1}
      className={classes.topBar}
      style={{
        backgroundColor: 'rgb(19 12 123)',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <Container maxWidth={false}>
        <Toolbar className={classes.toolbar}>
          <HomeIcon className={classes.homeIcon} onClick={() => history.push(PATH.HOMEPAGE)} />
          <div className={classes.grow} />
          <Reminders />
          <Notifications />
          <Avatar
            logOutText={t('navigation.logOut')}
            onLogOut={handleLogout}
            userName={name}
            userThumbnail={user?.avatar ? `data:image/jpeg;base64,${user.avatar}` : null}
            userEmail={email}
            myAccountText={t('navigation.myAccount')}
            onMyAccountClick={() => history.push(PATH.USERS.PROFILEEDIT.replace(':id', user.id))}
          />
        </Toolbar>
      </Container>
    </Paper>
  );
}
