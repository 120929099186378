import { useCallback } from 'react';
import { useUserStateContext } from 'state/useUserStateContext';

export function useAdvancedFilters(key, paging, sorting, filteredCells, getFilteredItems) {
  const { getListFilters, setListFilters } = useUserStateContext();

  const getActiveFilters = useCallback(() => {
    return getListFilters(key);
  }, [getListFilters, key]);

  const setActiveFilters = useCallback(
    filters => {
      setListFilters(key, filters);
    },
    [setListFilters, key]
  );

  const mapListFiltersToApi = useCallback(inputs => {
    let toFilters = {};
    if (inputs) {
      Object.keys(inputs).forEach(prop => {
        if (Array.isArray(inputs[prop])) {
          // TODO: SMELL remove id attribute conditional
          toFilters[prop] = inputs[prop].map(e => (e.id ? e.id : e));
        } else if (typeof inputs[prop] === 'object' && inputs[prop] !== null) {
          toFilters[prop] = inputs[prop].id;
          if (inputs[prop].nestedFilters) {
            toFilters = { ...toFilters, ...mapListFiltersToApi(inputs[prop].nestedFilters) };
          }
        } else {
          toFilters[prop] = inputs[prop];
        }
      });
    }
    return toFilters;
  }, []);

  const mapActiveFiltersToApi = useCallback(() => {
    return mapListFiltersToApi(getActiveFilters());
  }, [getActiveFilters, mapListFiltersToApi]);

  const updateActiveFilters = useCallback(
    advancedFilters => {
      let updatedFilters = JSON.parse(JSON.stringify(advancedFilters));
      Object.keys(updatedFilters).forEach(key => {
        if (
          (Array.isArray(updatedFilters[key]) && !updatedFilters[key].length) ||
          (!Array.isArray(updatedFilters[key]) && !updatedFilters[key])
        ) {
          delete updatedFilters[key];
        }
      });

      setListFilters(key, updatedFilters);
      getFilteredItems({ paging, sorting, filteredCells, advancedFilters: mapListFiltersToApi(updatedFilters) });
    },
    [paging, sorting, filteredCells, getFilteredItems, key, setListFilters, mapListFiltersToApi]
  );

  const onlyUpdateFilters = useCallback(
    advancedFilters => {
      let updatedFilters = JSON.parse(JSON.stringify(advancedFilters));
      Object.keys(updatedFilters).forEach(key => {
        if (
          (Array.isArray(updatedFilters[key]) && !updatedFilters[key].length) ||
          (!Array.isArray(updatedFilters[key]) && !updatedFilters[key])
        ) {
          delete updatedFilters[key];
        }
      });

      setListFilters(key, updatedFilters);
    },
    [paging, sorting, filteredCells, getFilteredItems, key, setListFilters, mapListFiltersToApi]
  );

  const deleteActiveFilter = useCallback(
    (filterName, index) => {
      let updatedFilters = JSON.parse(JSON.stringify(getListFilters(key)));
      if (index != null) {
        updatedFilters[filterName] = [
          ...updatedFilters[filterName].slice(0, index),
          ...updatedFilters[filterName].slice(index + 1)
        ];
        !updatedFilters[filterName].length && delete updatedFilters[filterName];
      } else delete updatedFilters[filterName];

      setListFilters(key, updatedFilters);
      getFilteredItems({ paging, sorting, filteredCells, advancedFilters: mapListFiltersToApi(updatedFilters) });
    },
    [paging, sorting, filteredCells, getFilteredItems, key, getListFilters, setListFilters, mapListFiltersToApi]
  );

  return {
    getActiveFilters,
    setActiveFilters,
    mapActiveFiltersToApi,
    updateActiveFilters,
    deleteActiveFilter,
    onlyUpdateFilters
  };
}
