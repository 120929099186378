import { http } from './http';
import { apiHelper } from 'shared';
import { APIS } from 'app.apis.const';

const controllerName = 'suppliers';

class SupplierDocuments {
  get(supplierId, paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters, level: 0 });

    return http.get(`/${controllerName}/${supplierId}/documents/${queryString}`, {}, APIS.FINANCES);
  }

  getRequired(supplierId) {
    return http.get(`/${controllerName}/${supplierId}/required-documents`, {}, APIS.FINANCES);
  }

  getPendingDocumentation(supplierId) {
    return http.get(`/${controllerName}/${supplierId}/pending-documentation`, {}, APIS.FINANCES);
  }

  getById(supplierId, id) {
    return http.get(`/${controllerName}/${supplierId}/documents/${id}`, {}, APIS.FINANCES);
  }

  create(supplierId, document) {
    const { fileObject, selectedFile, ...cleanedRequest } = document;
    return http.post(`/${controllerName}/${supplierId}/documents`, cleanedRequest, {}, APIS.FINANCES);
  }

  delete(supplierId, id, multiple) {
    return http.delete(`/${controllerName}/${supplierId}/documents/${id}`, { multiple }, APIS.FINANCES);
  }

  update(supplierId, id, document) {
    const { fileObject, selectedFile, ...cleanedRequest } = document;
    return http.put(
      `/${controllerName}/${supplierId}/documents/${id}`,
      { ...cleanedRequest, forceUpdateValidTo: true, forceUpdateValidFrom: true },
      {},
      APIS.FINANCES
    );
  }

  validateAEATDebtNegativeCertificate(document) {
    var form_data = new FormData();
    form_data.append('pdfFile', document.fileObject);

    return http.post(`/${controllerName}/validate-debt-certificate`, form_data, {}, APIS.FINANCES);
  }
}

export const supplierDocuments = new SupplierDocuments();
