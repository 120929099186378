import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Typography,
  ListItem,
  List,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useNavigationListStyles } from './navigationList.styles';
import { useHistory } from 'react-router-dom';

export function NavigationList({ navigations, isOpen }) {
  const classes = useNavigationListStyles();
  const history = useHistory();
  const [expanded, setExpanded] = React.useState(navigations[findOpen()]?.name);

  function findOpen() {
    var i = -1;
    navigations.forEach((nav, index) => {
      if (nav.open) {
        i = index;
      }
    });
    return i;
  }

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function handleNavigateTo(path) {
    history.push(path);
  }

  function handleAction(action) {
    action();
  }

  return (
    <div className={classes.navigation} role="presentation">
      {React.Children.toArray(
        navigations.map(navigation => (
          <>
            {navigation.show ? (
              !navigation.paths ? (
                <AccordionSummary
                  onClick={() =>
                    navigation.action
                      ? handleAction(navigation.action(), navigation.name)
                      : handleNavigateTo(navigation.url, navigation.name)
                  }
                  className={`${classes.summary} ${classes.noIcon} ${
                    navigation.url && navigation.highlighted ? classes.highlighted : ''
                  }
                `}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  classes={{
                    content: clsx({
                      [classes.navContentOpen]: isOpen,
                      [classes.navContentClose]: !isOpen
                    })
                  }}
                >
                  {navigation.icon}
                  <Typography className={classes.heading}>{navigation.name}</Typography>
                </AccordionSummary>
              ) : (
                <Accordion expanded={expanded === navigation.name} onChange={handleChange(navigation.name)} square>
                  <AccordionSummary
                    // isOpen
                    className={`${classes.summary}`}
                    IconButtonProps={{ edge: 'start' }}
                    expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                    aria-controls={navigation.name}
                    id={navigation.name}
                    classes={{
                      content: clsx({
                        [classes.navContentOpen]: isOpen,
                        [classes.navContentClose]: !isOpen
                      })
                    }}
                  >
                    {navigation.icon}
                    <Typography className={classes.heading}>{navigation.name}</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.root}>
                    <List className={classes.content}>
                      {React.Children.toArray(
                        navigation.paths.map(({ name, url, action, show, icon, highlighted }) => (
                          <>
                            {Boolean(show) && (
                              <ListItem
                                key={name}
                                style={{ transition: 'none' }}
                                className={`${classes.elementContent} ${clsx({
                                  [classes.elementContentClose]: !isOpen
                                })} ${highlighted ? classes.highlighted : ''}`}
                                onClick={() =>
                                  action
                                    ? handleAction(action, navigation.name)
                                    : handleNavigateTo(url, navigation.name)
                                }
                                button
                              >
                                <div
                                  className={`${classes.wrapperIconText} ${clsx({
                                    [classes.wrapperIconTextClose]: !isOpen
                                  })}`}
                                >
                                  <Tooltip className={classes.tooltip} title={name} placement="top">
                                    {icon && icon}
                                  </Tooltip>
                                  <ListItemText className={classes.elementText} primary={name}></ListItemText>
                                </div>
                              </ListItem>
                            )}
                          </>
                        ))
                      )}
                    </List>
                  </AccordionDetails>
                </Accordion>
              )
            ) : null}
          </>
        ))
      )}
    </div>
  );
}

NavigationList.propTypes = {
  onNavigate: PropTypes.func,
  isOpen: PropTypes.bool,
  navigations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      icon: PropTypes.element,
      show: PropTypes.number,
      paths: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          url: PropTypes.string,
          action: PropTypes.func,
          show: PropTypes.number
        })
      )
    }).isRequired
  )
};
