import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { stringHelper } from 'shared/helper';
import { useUserStateContext } from 'state/useUserStateContext';

export function useHandleRequest() {
  const [errorInfo, setErrorInfo] = useState({ message: '', detail: '' });
  const { t } = useTranslation();
  const { setIsLoading } = useUserStateContext();

  function splitAndTranslateEach(value = '') {
    if (value.includes('/nl')) {
      return value.split('/nl').map(item => `- ${t(item)}`);
    }
    return t(value);
  }

  const request = useCallback(
    async (requestCallback, trowError = true, onErrorCallbac) => {
      try {
        setErrorInfo({ message: '', detail: '' });
        setIsLoading(true);
        return await requestCallback();
      } catch (error) {
        let errorMsg = { message: error.message, detail: '' };
        if (error?.response?.data) {
          try {
            errorMsg = JSON.parse(
              error.response ? (error.response.data ? error.response.data : error.message) : error.message
            );
          } catch (x) {
            if (error.response.data.message) errorMsg = error.response.data;
          }
        }

        const errorDetail = errorMsg.detail ? stringHelper.normalizeQuotes(errorMsg.detail) : '';
        setErrorInfo({
          message: t(stringHelper.normalizeQuotes(errorMsg.message)),
          detail: splitAndTranslateEach(stringHelper.normalizeQuotes(errorDetail))
        });
        onErrorCallbac && onErrorCallbac();
        if (trowError) {
          throw new Error(errorMsg.message);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [t]
  );

  return { request, errorInfo, setErrorInfo };
}
