import { realEstates } from 'services';
import { cadastralHelper, useHandleRequest } from 'shared';

export function useCadastral() {

  const { request, errorInfo } = useHandleRequest();

  async function getCadastralUrl(cadastralReference) {
    return await request(async () => {
      const cadastralData = await realEstates.getCadastralInfo(cadastralReference);
      if (cadastralData) {
        const cadastralUrl = cadastralHelper.generateURL(cadastralData);
        return cadastralUrl;
      }
    }, false);
  }
  return { getCadastralUrl, errorInfo };
}
