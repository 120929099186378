import { Select } from '@engloba-tech/englobity';
import { NAME_SPACE } from 'i18n';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isSkeletonLoading } from 'shared';
import { DOCUMENTID_TYPES } from '../documentIdTypes.const';

export function DocumentIdTypesSelect({ value, withAnyLabel, ...props }) {
  const { t } = useTranslation(NAME_SPACE.SUPPLIERS);

  const elements = useMemo(() => {
    let el = [];
    if (withAnyLabel) el.push({ value: -1, name: t('common:selectFirstOption') });

    for (const property in DOCUMENTID_TYPES) {
      el.push({ value: property, name: t(DOCUMENTID_TYPES[property]) });
    }
    return el;
  }, [t, withAnyLabel]);

  return (
    <Select
      isLoading={isSkeletonLoading(DOCUMENTID_TYPES[0]) || isSkeletonLoading(value)}
      {...props}
      value={value}
      title={t('properties.documentIdType')}
      elements={elements}
    />
  );
}

DocumentIdTypesSelect.propTypes = {
  value: PropTypes.number,
  withAnyLabel: PropTypes.bool
};
