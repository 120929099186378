import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { resources } from './assets/locales';

export const NAME_SPACE = {
  COMMON: 'common',
  WORK_ORDERS: 'workOrders',
  CURRENT_MILESTONES: 'currentMilestones',
  SOURCES: 'sources',
  PROCESS_DEFINITION: 'processDefinition',
  ITEMS: 'items',
  MEASURES: 'measures',
  RATES: 'rates',
  USERS: 'users',
  DOCUMENT_TYPES: 'documentTypes',
  PROJECTS: 'projects',
  SUPPLIERS: 'suppliers',
  CUSTOMERS: 'customers',
  BUSINESS_LINES: 'businessLines',
  NOTIFICATIONS: 'notifications',
  RECEIVED_INVOICES: 'receivedInvoices',
  ISSUED_INVOICES: 'issuedInvoices',
  STATUSES: 'statuses',
  ADDRESS: 'address',
  CALENDARS: 'calendars',
  WORK_ORDER_STATUSES: 'workOrderStatuses',
  COMPANIES: 'companies',
  WORK_GROUPS: 'workGroups',
  SUPPLIER_DOCUMENT_GROUPS: 'supplierDocumentGroups',
  REAL_ESTATES: 'realEstates',
  PAYMENT_METHODS: 'paymentMethods',
  PAYMENT_TERMS: 'paymentTerms',
  RATINGS: 'supplierRatings',
  ALERTS: 'alerts',
  SUPPLIER_TYPES: 'supplierTypes',
  INSURANCE_COMPANIES: 'insuranceCompanies',
  INTEGRATIONS: 'integrations',
  REH_TASK_FORM: 'rehTaskForm',
  EQUIVALENCES: 'equivalences',
  CREDITORS: 'creditors',
  APPOINTMENT_TYPES: 'appointmentTypes',
  COMMON_USER_TASKS: 'commonUserTasks',
  AGENDA: 'agenda',
  PROCESS_EXECUTIONS: 'processExecutions',
  GALLERY_TAGS: 'galleryTags',
  GALLERY_IMAGES: 'galleryImages',
  REMINDERS: 'reminders',
  REPORTS: 'reports',
  PLAN_JEFE_OBRA_TASK_FORM: 'planJefeObraTaskForm',
  SPECIALITIES: 'specialities',
  WO_AUTOMATIONS: 'workOrderAutomations',
  AGGRUPATIONS: 'aggrupations',
  DIRECTORY_TEMPLATES: 'directoryTemplates',
  DIRECTORY_TREE: 'directoryTree',
  CONTACT_ROLES: 'contactRoles',
  HOME: 'home'
};

export const LANGUAGE = {
  ENGLISH: 'en',
  SPANISH: 'es',
  CATALAN: 'ca'
};

export const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: [LANGUAGE.ENGLISH, LANGUAGE.SPANISH],
    ns: Object.values(NAME_SPACE),
    defaultNS: NAME_SPACE.COMMON,
    fallbackNS: NAME_SPACE.COMMON,
    react: {
      useSuspense: true
    },
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
