import { makeStyles } from '@material-ui/core/styles';

// TODO: theme variables
export const useDragableModalStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    zIndex: '1000',
    cursor: 'move',
    width: '46rem',
    maxWidth: '46rem',
    maxHeight: '45rem',
    overflow: 'auto',

    '& .MuiAccordionDetails-root': {
      display: 'block'
    },
    '&.MuiPaper-outlined': {
      borderRadius: '8px',
      borderColor: '#4d5df1ab'
    },
    '& .MuiListItem-root': {
      cursor: 'auto'
    },
    '& .MuiAccordionSummary-root': {
      background: '#F5F8FD',
      borderTop: ' 1px solid #B9C6DA',
      '&.Mui-expanded': {
        minHeight: 'auto',
        borderBottom: '1px solid #B9C6DA'
      }
    },
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      flexDirection: 'column',
      '&.Mui-expanded': {
        margin: '12px 0'
      }
    },
    '& li': {
      color: '#4A566D',
      '&::before': {
        left: '2px',
        content: '">"',
        position: 'absolute',
        top: '15px',
        fontSize: '1rem',
        fontWeight: '600'
      }
    }
  },
  selectableText: {
    '& .MuiTypography-root': {
      userSelect: ' text',
      cursor: 'text'
    }
  },
  nested: {
    paddingLeft: theme.spacing(4),
    '&::before': {
      left: '14px !important',
      content: '">"',
      position: 'absolute',
      top: '15px',
      fontSize: '1rem',
      fontWeight: '600'
    }
  },

  close: {
    position: 'absolute',
    right: '3px',
    top: '3px'
  },
  title: {
    paddingBottom: '0.3rem',
    paddingTop: '0.5rem'
  },
  description: { margin: '1rem 0' },
  heading: { fontWeight: '600' },
  withAccordion: {
    '& .MuiAccordion-root':{
      padding: '0 !important',
      '& .MuiAccordionSummary-root.Mui-disabled':{
        opacity: '1 !important'
      }
    }
  }
}));
