import { SKELETON_KEY, SKELETON_KEY_NUMBER } from './skeletonFactory';

function _skeletonAlert() {
  return {
    id: SKELETON_KEY,
    name: SKELETON_KEY,
    type: SKELETON_KEY_NUMBER,
    role: SKELETON_KEY,
    roleId: SKELETON_KEY,
    workGroup: SKELETON_KEY,
    workGroupId: SKELETON_KEY,
    source: SKELETON_KEY,
    sourceId: SKELETON_KEY,
    activity: SKELETON_KEY,
    activityId: SKELETON_KEY,
    days: SKELETON_KEY
  };
}

function skeletonAlertType() {
  return {
    id: SKELETON_KEY_NUMBER,
    name: SKELETON_KEY
  };
}

export const skeletonAlert = {
  alert: _skeletonAlert,
  alertType: skeletonAlertType
};
