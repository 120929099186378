import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { receivedInvoices } from 'services';
import { stringHelper, skeletonFactory } from 'shared';

const retentionTypesDummy = skeletonFactory.receivedInvoiceRetentionTypes();

export function useReceivedInvoiceRetentionTypes(withAnyLabel) {
  const { t } = useTranslation(NAME_SPACE.SUPPLIERS);
  const [retentionTypes, setRetentionTypes] = useState(retentionTypesDummy);
  const [retentionTypesEnum, setRetentionTypesEnum] = useState({});

  useEffect(() => {
    async function getData() {
      const response = await receivedInvoices.getRetentionTypes();
      if (!response) {
        return;
      }
      setRetentionTypesEnum(response.data.reduce((acc, type) => ({ ...acc, [type.name]: type.id.toString() }), {}));
      let types = (response.data || []).map(type => ({
        value: type.id.toString(),
        name: t(`retentionTypes.${stringHelper.desCapitalizeFirstLetter(type.name)}`)
      }));

      setRetentionTypes(
        withAnyLabel ? [{ value: null, name: t('common:selectFirstOption'), isAny: true }, ...types] : types || []
      );
    }
    getData();
  }, [t, withAnyLabel]);

  const getTotalToPay = (retentionType, total, retention) => {
    return retentionType == retentionTypesEnum.IncludedInInvoice
      ? Number(total) - Number(retention)
      : Number(total);
  };

  return {
    retentionTypes,
    getTotalToPay
  };
}
