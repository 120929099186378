import { ViewTable as EnglobityViewTable } from '@engloba-tech/englobity';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { NAME_SPACE } from 'i18n';

export function ViewTable(props) {
  const { t } = useTranslation(NAME_SPACE.COMMON);

  return (
    <EnglobityViewTable
      {...props}
      emptyText={props.emptyText || t('empty')}
      checkedElementsCountText={t('actions.selected')}
      rowsPerPageText={t('viewTable.rowsPerPage')}
      displayedRowsText={t('viewTable.of')}
      clearFiltersText={t('viewTable.clearFilters')}
      backIconButtonText={t('viewTable.backIconButtonText')}
      nextIconButtonText={t('viewTable.nextIconButtonText')}
      dateFromText={t('actions.from')}
      dateUntilText={t('actions.until')}
      todayDatePickerLabel={t('common:actions.today')}
      clearDatePickerLabel={t('common:actions.clear')}
      cancelDatePickerLabel={t('common:actions.cancel')}
      okDatePickerLabel={t('common:actions.accept')}
      dateFormat="DD/MM/YYYY"
    />
  );
}

ViewTable.propTypes = {
  emptyText: PropTypes.string
};
