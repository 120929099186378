import { http } from './http';
import { APIS } from 'app.apis.const';
import { apiHelper } from 'shared';

const controllerName = 'applicable-taxes';
class ApplicableTaxes {
  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/${controllerName}${queryString}`, {}, APIS.FINANCES);
    const items = response.data && response.data.items;

    return items;
  }

  getByDistrictIdAndDate(districtId, date) {
    return http.get(`/${controllerName}/districts/${districtId}/tax-vigencies/${date}`, {}, APIS.FINANCES);
  }
}

export const applicableTaxes = new ApplicableTaxes();
