import React from 'react';
import { InputLabel, Slider } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useRangeSliderStyles } from './rangeSlider.styles';

export const RangeSlider = ({ label, value, onChange, marks, max = 5, min = 0, step = 1, disabled = false, name }) => {
  const classes = useRangeSliderStyles();

  const labelClassNames =
    'MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined Mui-required Mui-required';

  return (
    <div
      className={`MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth MuiOutlinedInput-input ${classes.root}`}
    >
      <InputLabel className={labelClassNames}>{label}</InputLabel>
      <Slider
        className={classes.slider}
        disabled={disabled}
        name={name}
        value={value}
        onChange={onChange}
        aria-labelledby="range-slider"
        max={max}
        min={min}
        step={step}
        marks={marks}
      />
    </div>
  );
};

RangeSlider.propTypes = {
  label: PropTypes.string,
  value: PropTypes.array,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  step: PropTypes.number,
  marks: PropTypes.array,
  disabled: PropTypes.bool
};
