import { makeStyles } from '@material-ui/core/styles';

export const useStatusStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    position: 'relative',
    '&::before': {
      content: '"•"',
      fontWeight: theme.font.weight.black,
      fontSize: theme.font.size.xxl,
      position: 'absolute',
      left: '5px'
    }
  },
  'workOrder-open': {
    color: theme.palette.status.green
  },
  'workOrder-inProgress': {
    color: theme.palette.status.yellow
  },
  'workOrder-cancelled': {
    color: theme.palette.status.red
  },
  'workOrder-closed': {
    color: theme.palette.status.navyBlue
  },
  'document-pending': {
    color: theme.palette.status.red
  },
  'document-notApplicable': {
    color: theme.palette.status.yellow
  },
  'document-inProcess': {
    color: theme.palette.status.blue
  },
  'document-available': {
    color: theme.palette.status.green
  },
  'document-sentToCustomer': {
    color: theme.palette.status.green
  },
  'document-sentToSupplier': {
    color: theme.palette.status.green
  },
  'workOrder-open-outlined': {
    border: `2px solid ${theme.palette.status.green}`
  },
  'workOrder-inProgress-outlined': {
    border: `2px solid ${theme.palette.status.yellow}`
  },
  'workOrder-cancelled-outlined': {
    border: `2px solid ${theme.palette.status.red}`
  },
  'workOrder-closed-outlined': {
    border: `2px solid ${theme.palette.status.navyBlue}`
  },
  'document-pending-outlined': {
    border: `2px solid ${theme.palette.status.yellow}`
  },
  'document-notApplicable-outlined': {
    border: `2px solid ${theme.palette.status.red}`
  },
  'document-providedByCustomer-outlined': {
    border: `2px solid ${theme.palette.status.blue}`
  },
  'document-inProgress-outlined': {
    border: `2px solid ${theme.palette.status.green}`
  },
  'document-sentToCustomer-outlined': {
    border: `2px solid ${theme.palette.status.navyBlue}`
  },
  'document-sentToSupplier-outlined': {
    border: `2px solid ${theme.palette.status.purple}`
  }
}));
