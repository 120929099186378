import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { getOidcConfig } from 'auth';
import { settings, KEY_SETTINGS } from './settings';
import { http } from './services/http';
import reportWebVitals from './reportWebVitals';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from 'react-oidc-context';
import globalStyles from './styles';
import 'poppins-font';
import 'nprogress/nprogress.css';
import './i18n';
import { APIS } from 'app.apis.const';

async function startUp() {
  /**
   * Here we are fetching settings
   * set BaseUrl for the Static HTTP
   */
  process.env.REACT_APP_DOCKER ? await settings.loadDockerSettings() : await settings.loadSettings();

  const cmmsApiURL = `${settings.getSetting(KEY_SETTINGS.API_URL)}/api/${settings.getSetting(
    KEY_SETTINGS.API_VERSION
  )}`;
  const financesApiURL = `${settings.getSetting(KEY_SETTINGS.FINANCES_API_URL)}/api/${settings.getSetting(
    KEY_SETTINGS.FINANCES_API_VERSION
  )}`;
  const placesApiURL = `${settings.getSetting(KEY_SETTINGS.PLACES_API_URL)}/api/${settings.getSetting(
    KEY_SETTINGS.PLACES_API_VERSION
  )}`;
  const realEstatesApiURL = `${settings.getSetting(KEY_SETTINGS.REAL_ESTATES_API_URL)}/api/${settings.getSetting(
    KEY_SETTINGS.REAL_ESTATES_API_VERSION
  )}`;
  const documentaryApiURL = `${settings.getSetting(KEY_SETTINGS.DOCUMENTARY_API_URL)}/api/${settings.getSetting(
    KEY_SETTINGS.DOCUMENTARY_API_VERSION
  )}`;
  const notificationsApiURL = `${settings.getSetting(KEY_SETTINGS.NOTIFICATIONS_API_URL)}/api/${settings.getSetting(
    KEY_SETTINGS.NOTIFICATIONS_API_VERSION
  )}`;
  const identityApiURL = `${settings.getSetting(KEY_SETTINGS.IDENTITY_API_URL)}/api/${settings.getSetting(
    KEY_SETTINGS.IDENTITY_API_VERSION
  )}`;

  const azureStorageURL = `https://${settings.getSetting(KEY_SETTINGS.AZURE_STORAGE)}.blob.core.windows.net`;

  http.setBaseUrls([
    { key: APIS.CMMS, url: cmmsApiURL },
    { key: APIS.FINANCES, url: financesApiURL },
    { key: APIS.PLACES, url: placesApiURL },
    { key: APIS.AZURE_STORAGE, url: azureStorageURL },
    { key: APIS.NOTIFICATIONS, url: notificationsApiURL },
    { key: APIS.REAL_ESTATES, url: realEstatesApiURL },
    { key: APIS.DOCUMENTARY, url: documentaryApiURL },
    { key: APIS.IDENTITY, url: identityApiURL }
  ]);
}

(async () => {
  try {
    await startUp();
    const oidcConfig = getOidcConfig();
    ReactDOM.render(
      <AuthProvider {...oidcConfig}>
        <BrowserRouter>
          <ThemeProvider theme={globalStyles}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <App />
          </ThemeProvider>
        </BrowserRouter>
      </AuthProvider>,
      document.getElementById('root')
    );
  } catch (error) {
    ReactDOM.render(
      <React.StrictMode>
        <dir>{error}</dir>
      </React.StrictMode>,
      document.getElementById('root')
    );
  }
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
