import { Input } from '@engloba-tech/englobity';
import { NAME_SPACE } from 'i18n';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { statusTypes } from '../statusTypes.const';
import { STATUSES } from '../statuses.const';
import { useStatusStyles } from './status.styles';

export function Status({ className, outlined, status, type, loading }) {
  const classes = useStatusStyles();
  const { t } = useTranslation(NAME_SPACE.STATUSES);
  const statusName = type ? STATUSES[statusTypes[type]][status] : status;
  const typeAndStatus = `${type}-${statusName}`;
  return (
    <>
      {!type ? (
        <Input
          isLoading={loading}
          disabled
          className={classes.formWorkOrder}
          name={t('name')}
          label={t('name')}
          variant="outlined"
          value={(Boolean(status) && `${status}`) || t('unspecified')}
          inputProps={{ 'aria-label': 'reference' }}
        />
      ) : (
        <>
          {Boolean(status !== null) && (
            <div
              className={`
      ${classes.root}
      ${classes[typeAndStatus]} ${className}
      ${outlined ? classes[`${typeAndStatus}-outlined`] : ''}`}
            >
              {t(`${type}.${statusName}`)}
            </div>
          )}
        </>
      )}
    </>
  );
}

Status.propTypes = {
  className: PropTypes.string,
  status: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 901901901901]),
  outlined: PropTypes.bool,
  loading: PropTypes.bool,
  type: PropTypes.oneOf([
    'workOrder',
    'document',
    'budgetItemStatus',
    'budgetItem_supplierBillingStatus',
    'receivedInvoice'
  ])
};
