import { SKELETON_KEY, SKELETON_KEY_NUMBER } from './skeletonFactory';

export function skeletonWorkOrderContact() {
  return {
    id: SKELETON_KEY,
    roleId: SKELETON_KEY,
    contact: {
        name: SKELETON_KEY,
        firstSurname: SKELETON_KEY,
        secondSurname: SKELETON_KEY,
        email: SKELETON_KEY,
        phoneNumber: SKELETON_KEY,
        secondPhoneNumber: SKELETON_KEY,
        remarks: SKELETON_KEY,
        documentId: {
            type: SKELETON_KEY_NUMBER,
            value: SKELETON_KEY,
        }
    }
  };
}
