import { apiHelper } from 'shared';
import { http } from './http';

const controllerName = 'work-orders';

class WorkOrdersAvailables {
  get(paging, sorting, filters, advancedFilters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters, ...advancedFilters });
    return http.get(`/${controllerName}/availables${queryString}`);
  }
}

export const workOrdersAvailables = new WorkOrdersAvailables();
