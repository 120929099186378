import MomentUtils from '@date-io/moment';
import { DateTimePicker, Input } from '@engloba-tech/englobity';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getLanguage, LANGUAGE } from 'i18n';

import 'moment/locale/ca';
import 'moment/locale/es';

export function LocaleDatePicker({ onChange, name, validators, errorMessages, ...props }) {
  const [locale, setLocale] = useState(LANGUAGE.ENGLISH);
  const [error, setError] = useState(null);
  const [isEmpty, setIsEmpty] = useState(props.value === '');
  const { t } = useTranslation();
  useEffect(() => {
    const lng = getLanguage();
    moment.locale(lng);
    setLocale(lng);
  }, []);

  const handleChange = event => {
    setIsEmpty(event.target.value?._i === '');
    const selectedDate = !isNaN(event.target.value?.hours()) ? event.target.value : event.target.value?.hours(0);
    return onChange && onChange({ target: { name: name, value: selectedDate } });
  };

  const handleError = error => {
    setError(isEmpty ? false : Boolean(error?.length));
  };

  const populateError = () => {
    return isEmpty ? null : t('validations.invalidDate');
  };

  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale}>
      <DateTimePicker
        {...props}
        onError={handleError}
        showTodayButton={false}
        icon={<DateRangeIcon />}
        onChange={handleChange}
        todayLabel={t('actions.today')}
        clearLabel={t('actions.clear')}
        cancelLabel={t('actions.cancel')}
        okLabel={t('actions.accept')}
        validators={error ? ['dateFormat'] : validators}
        errorMessages={error ? [t('validations.invalidDate')] : errorMessages}
        invalidDateMessage={populateError()}
        maxDateMessage={t('validations.maxDate')}
        minDateMessage={t('validations.minDate')}
        TextFieldComponent={Input}
      />
    </MuiPickersUtilsProvider>
  );
}

LocaleDatePicker.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  validators: PropTypes.array,
  errorMessages: PropTypes.array,
  value: PropTypes.any
};
