function deepClone(object) {
  return JSON.parse(JSON.stringify(object));
}

function isEmpty(obj) {
  if (!obj) {
    return true;
  }

  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

function isAnObject(obj) {
  return typeof obj === 'object' && obj !== null;
}

function isArray(myArray) {
  return myArray.constructor.toString().indexOf('Array') > -1;
}

function areArrayEquals(array1, array2) {
  if (array1.length !== array2.length) {
    return false;
  }
  for (let i = 0; i < array1.length; i++) {
    const obj1 = array1[i];
    const obj2 = array2[i];
    for (const prop in obj1) {
      if (obj1[prop] !== obj2[prop]) {
        return false;
      }
    }
  }
  return true;
}

function replaceUndefinedPerNull(arr) {
  return arr.map(item => (typeof item === 'undefined' ? null : item));
}

const sumArray = array => {
  return array.reduce((sum, value) => sum + value, 0);
};

const removeDuplicatesArray = (array, prop) => {
  const uniqueMap = {};
  return array.filter(obj => {
    const key = obj[prop];
    if (!uniqueMap[key]) {
      uniqueMap[key] = true;
      return true;
    }
    return false;
  });
};

const sortArray = (array = [], prop) => {
  return array.slice().sort((a, b) => a[prop].localeCompare(b[prop]));
};

export const objectHelper = {
  deepClone,
  isEmpty,
  isAnObject,
  isArray,
  areArrayEquals,
  sumArray,
  replaceUndefinedPerNull,
  removeDuplicatesArray,
  sortArray
};
