import { http } from './http';
import { APIS } from 'app.apis.const';
import { apiHelper } from 'shared';

const controllerName = 'creditors';

class Creditors {
  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/${controllerName}${queryString}`, {}, APIS.FINANCES);
    const items = response.data && response.data.items;

    return items;
  }

  get(paging, sorting, filters, advancedFilters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({
      ...paging,
      ...sorting,
      ...filters,
      ...advancedFilters
    });

    return http.get(`/${controllerName}/all/${queryString}`, {}, APIS.FINANCES);
  }

  export(sorting, filters, advancedFilters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...sorting, ...filters, ...advancedFilters });
    return http.get(`/${controllerName}/export${queryString}`, {}, APIS.FINANCES);
  }

  getById(id) {
    return http.get(`/${controllerName}/${id}`, {}, APIS.FINANCES);
  }

  create(creditor) {
    return http.post(`/${controllerName}`, creditor, {}, APIS.FINANCES);
  }

  delete(id, multiple) {
    return http.delete(`/${controllerName}/${id}`, { multiple }, APIS.FINANCES);
  }

  update(id, creditor) {
    return http.put(`/${controllerName}/${id}`, { ...creditor, forceUpdateAddress: true }, {}, APIS.FINANCES);
  }
}

export const creditors = new Creditors();
