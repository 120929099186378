import { makeStyles } from '@material-ui/core/styles';
import { color } from 'styles/color.styles';

export const useRangeSliderStyles = makeStyles(theme => ({
  root: {
    padding: '4px 15px 0px 15px',
    border: '1px solid black',
    borderColor: color.primary.main,
    borderRadius: '8px',
    height: '45.625px',
    '& .MuiSlider-marked': {
      marginBottom: 0
    },
    '& .MuiSlider-thumb': {
      zIndex: 99
    },
    '& .MuiSlider-root': {
      alignContent: 'center'
    },
    '& .MuiSlider-active': {
      boxShadow: '0px 0px 0px 8px rgba(77, 93, 241, 0.16)'
    }
  },
  slider: {
    '& .MuiSlider-markLabel': {
      padding: 0,
      margin: '6px 0px',
      top: theme.spacing(2),
      fontSize: 'smaller'
    }
  }
}));
