import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useButtonOptionsStyles } from './buttonOptions.styles';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem);

export default function ButtonOptions({ options = [] }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useButtonOptionsStyles();

  const handleClickButton = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickItem = onClick => {
    if (onClick) {
      onClick();
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        onClick={handleClickButton}
        className={classes.btn}
      >
        <MoreVertIcon />
      </Button>
      <StyledMenu id="customized-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {options.map(({ icon, title, onClick }) => (
          <StyledMenuItem key={title} onClick={() => handleClickItem(onClick)}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={title} />
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </div>
  );
}

ButtonOptions.propTypes = {
  options: PropTypes.array
};
