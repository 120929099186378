import { SKELETON_KEY, SKELETON_KEY_NUMBER } from './skeletonFactory';

function skeletonSpeciality() {
  return {
    name: SKELETON_KEY,
    id: SKELETON_KEY,
    subSpecialities: Array(10).fill({id: SKELETON_KEY, name: SKELETON_KEY})
  };
}

function skeletonMeasure() {
  return {
    name: SKELETON_KEY,
    id: SKELETON_KEY_NUMBER
  };
}

function _skeletonItem() {
  return {
    id: SKELETON_KEY,
    reference: SKELETON_KEY,
    description: SKELETON_KEY,
    itemCategoryId: SKELETON_KEY,
    itemCategory: SKELETON_KEY,
    itemCategorySubSpecialityId: SKELETON_KEY,
    itemCategorySubSpeciality: SKELETON_KEY,
    measure: SKELETON_KEY_NUMBER,
    allowNegativeAmount: SKELETON_KEY,
    isTaxExempt: SKELETON_KEY
  };
}

export const skeletonItem = {
  speciality: skeletonSpeciality,
  measure: skeletonMeasure,
  item: _skeletonItem
};
