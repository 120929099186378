import { APIS } from 'app.apis.const';
import { apiHelper } from 'shared';

import { http } from './http';

class Projects {
  async getUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({
      name: queryFilter?.name ?? null,
      businessLineIds: queryFilter?.businessLineIds ?? null,
      withoutRates: queryFilter?.withoutRates ?? null
    });

    const response = await http.get(`/projects${queryString}`, {}, APIS.FINANCES);
    const items = response.data && response.data.items;

    return items;
  }

  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/projects/all/${queryString}`, {}, APIS.FINANCES);
  }

  async getMyUsingQueryFilter(queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/projects/my${queryString}`, {}, APIS.CMMS);
    const items = response.data && response.data.items;

    return items;
  }

  getById(id) {
    return http.get(`/projects/${id}`, {}, APIS.FINANCES);
  }

  create(project) {
    return http.post('/projects', project, {}, APIS.FINANCES);
  }

  clone(project) {
    return http.post(`/projects/${project.idToClone}/clone`, project, { disableErrorToasters: true }, APIS.FINANCES);
  }

  delete(id, multiple) {
    return http.delete(`/projects/${id}`, { multiple }, APIS.FINANCES);
  }

  update(id, project) {
    return http.put(`/projects/${id}`, { ...project, forceUpdateEndDate: true }, {}, APIS.FINANCES);
  }

  activate(id) {
    return http.put(`/projects/${id}/activation`, {}, {}, APIS.FINANCES);
  }

  desactivate(id) {
    return http.delete(`/projects/${id}/activation`, {}, APIS.FINANCES);
  }

  getProjectReports(id) {
    return http.get(`/projects/${id}/reports`, {});
  }
}

export const projects = new Projects();
