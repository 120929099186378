import { makeStyles } from '@material-ui/core/styles';

export const useButtonDownloadFile = makeStyles(theme => ({
  buttonRoot: {
    display: 'flex',
    justifyContent: 'right'
  },
  downloadButton: {
    marginTop: '3px',
    width: '100%',
    fontSize: '12px',
    '& .MuiButton-label >svg': {
      width: '20px',
      marginRight: '4px'
    }
  }
}));
