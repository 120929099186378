import { renderToString } from 'react-dom/server';
import { objectHelper } from 'shared/helper';
import { skeletonAggrupations } from './skeletonAggrupations';
import { skeletonAlert } from './skeletonAlert';
import { skeletonAppointmentType } from './skeletonAppointmentType';
import { skeletonBudgetItemSupplier } from './skeletonBudgetItemSupplier';
import { skeletonBusinessLine } from './skeletonBusinessLine';
import { skeletonCalendar } from './skeletonCalendars';
import { skeletonCompany } from './skeletonCompany';
import { skeletonCondition } from './skeletonCondition';
import { skeletonContactRole } from './skeletonContactRole';
import { skeletonCreditor } from './skeletonCreditor';
import { skeletonCurrentMilestone } from './skeletonCurrentMilestone';
import { skeletonCustomer, skeletonCustomerBudgetItem } from './skeletonCustomer';
import { skeletonCustomerGroup } from './skeletonCustomerGroup';
import { skeletonDocumentGroup } from './skeletonDocumentGroup';
import { skeletonDocumentType } from './skeletonDocumentType';
import { skeletonEnum } from './skeletonEnum';
import { skeletonEquivalence } from './skeletonEquivalence';
import { skeletonGalleryTag } from './skeletonGalleryTag';
import { skeletonInsuranceCompany } from './skeletonInsuranceCompany';
import { skeletonExecution, skeletonIntegration } from './skeletonIntegration';
import { skeletonIssuedInvoice, skeletonIssuedInvoiceDocument } from './skeletonIssuedInvoice';
import { skeletonItem } from './skeletonItem';
import { skeletonItemRate } from './skeletonItemRate';
import { skeletonMeasure } from './skeletonMeasure';
import { skeletonMilestone } from './skeletonMilestone';
import { skeletonNotification } from './skeletonNotification';
import { skeletonPaymentMethod } from './skeletonPaymentMethod';
import { skeletonProcessDefinition } from './skeletonProcess';
import { skeletonProcessRole } from './skeletonProcessRole';
import { skeletonProject } from './skeletonProject';
import { skeletonRate } from './skeletonRate';
import {
  skeletonCEE,
  skeletonOperationType,
  skeletonRealEstate,
  skeletonRealEstateDocument
} from './skeletonRealEstate';
import {
  skeletonReceivedInvoice,
  skeletonReceivedInvoiceDocument,
  skeletonReceivedInvoiceLine,
  skeletonReceivedInvoiceRetentionTypes
} from './skeletonReceivedInvoice';
import { skeletonReminder } from './skeletonReminder';
import { skeletonReport } from './skeletonReport';
import { skeletonRole } from './skeletonRole';
import { skeletonSource } from './skeletonSource';
import {
  skeletonAgreement,
  skeletonCreditInsurer,
  skeletonGarnishment,
  skeletonSupplier,
  skeletonSupplierBudgetItem,
  skeletonSupplierDocument,
  skeletonWorker
} from './skeletonSupplier';
import { skeletonSupplierDocumentType } from './skeletonSupplierDocumentType';
import { skeletonSupplierItemRate } from './skeletonSupplierItemRate';
import { skeletonSupplierRating } from './skeletonSupplierRating';
import { skeletonSupplierType } from './skeletonSupplierType';
import { skeletonUser } from './skeletonUser';
import { skeletonWorkGroup } from './skeletonWorkGroup';
import { skeletonWorkOrder } from './skeletonWorkOrder';
import { skeletonWorkOrderAutomation } from './skeletonWorkOrderAutomation';
import { skeletonWorkOrderBudgetItemHistory } from './skeletonWorkOrderBudgetItemHistory';
import { skeletonDirectoryTemplate } from './skeletonDirectoryTemplate';
import { skeletonWorkOrderBudgetItemBreakdown } from './skeletonWorkOrderBudgetItemBreakdowns';
import { skeletonWorkOrderContact } from './skeletonWorkOrderContact';

export const SKELETON_KEY = 'skeleton_loading';
export const SKELETON_KEY_NUMBER = 901901901901;
export const SKELETON_KEY_DATE = 'May 1,1890 11:20:00';
export const SKELETON_KEY_BOOL = false;
export const SKELETON_ZERO = 0;

export function isSkeletonLoading(value) {
  if (objectHelper.isAnObject(value)) {
    return Object.keys(value).some(function (k) {
      return value[k] === SKELETON_KEY || value[k] === SKELETON_KEY_NUMBER || value[k] === SKELETON_KEY_DATE;
    });
  }

  return value === SKELETON_KEY || value === SKELETON_KEY_NUMBER || value === SKELETON_KEY_DATE;
}

export function containsSkeletonLoading(value) {
  var parsedValue = renderToString(value);
  return (
    parsedValue.includes(SKELETON_KEY) ||
    parsedValue.includes(SKELETON_KEY_DATE) ||
    parsedValue.includes(SKELETON_KEY_NUMBER)
  );
}

const pagination = { page: 0, pageSize: 2147483647, total: 3, totalPages: 1 };

export const skeletonFactory = {
  workOrders: function workOrders(num = 10) {
    const _workOrder = skeletonWorkOrder.workOrder();
    return { ...pagination, items: Array(num).fill(_workOrder) };
  },
  workOrder: function workOrder() {
    return skeletonWorkOrder.workOrder();
  },
  sources: function sources(num = 10) {
    const source = skeletonSource();
    return { ...pagination, items: Array(num).fill(source) };
  },
  source: function source() {
    return skeletonSource();
  },
  milestone: function milestone() {
    return skeletonMilestone();
  },
  currentMilestone: function currentMilestone() {
    return skeletonCurrentMilestone();
  },
  currentMilestones: function currentMilestones(num = 10) {
    const currentMilestone = skeletonCurrentMilestone();
    return { ...pagination, items: Array(num).fill(currentMilestone) };
  },
  condition: function condition() {
    return skeletonCondition();
  },
  conditions: function sources(num = 10) {
    const condition = skeletonCondition();
    return Array(num).fill(condition);
  },
  workOrderStatus: function workOrderStatus() {
    return skeletonWorkOrder.status;
  },
  workOrderStatuses: function workOrderStatuses(num = 10) {
    const statuses = skeletonWorkOrder.status();
    return Array(num).fill(statuses);
  },
  speciality: function speciality() {
    return skeletonItem.speciality();
  },
  specialities: function specialities(num = 10) {
    const specialities = skeletonItem.speciality();
    return { ...pagination, items: Array(num).fill(specialities) };
  },
  item: function item() {
    return skeletonItem.item();
  },
  items: function items(num = 10) {
    const item = skeletonItem.item();
    return { ...pagination, items: Array(num).fill(item) };
  },
  rate: function rate() {
    return skeletonRate.rate();
  },
  rates: function rates(num = 10) {
    const rates = skeletonRate.rate();
    return { ...pagination, items: Array(num).fill(rates) };
  },
  measure: function measure() {
    return skeletonMeasure.measure();
  },
  measures: function measures(num = 10) {
    const measures = skeletonMeasure.measure();
    return { ...pagination, items: Array(num).fill(measures) };
  },
  supplierDocument: function supplierDocument() {
    return skeletonSupplierDocument();
  },
  supplierDocuments: function supplierDocuments(num = 10) {
    const supplierDocuments = skeletonSupplierDocument();
    return { ...pagination, items: Array(num).fill(supplierDocuments) };
  },
  processDefinitions: function processDefinitions(num = 10) {
    const processDefinition = skeletonProcessDefinition.processDefinition();
    return { ...pagination, items: Array(num).fill(processDefinition) };
  },
  processDefinition: function processDefinition() {
    return skeletonProcessDefinition.processDefinition();
  },
  processVisibility: function processVisibility() {
    return skeletonProcessDefinition.processVisibility();
  },
  processVisibilities: function processVisibilities(num = 10) {
    const processVisibility = skeletonProcessDefinition.processVisibility();
    return Array(num).fill(processVisibility);
  },
  // TODO: add more asset types

  comment: function comment() {
    return skeletonWorkOrder.comments();
  },
  comments: function comments(num = 10) {
    const comments = skeletonWorkOrder.comments();
    return { ...pagination, items: Array(num).fill(comments) };
  },
  contactRole: function contactRole() {
    return skeletonContactRole();
  },
  contactRoles: function contactRoles(num = 10) {
    const contactRoles = skeletonContactRole();
    return { ...pagination, items: Array(num).fill(contactRoles) };
  },
  contacts: function contacts(num = 10) {
    const contacts = skeletonWorkOrder.contacts();
    return { ...pagination, items: Array(num).fill(contacts) };
  },
  assetRealEstate: function asset() {
    return skeletonWorkOrder.assetRealEstate();
  },
  assetRealEstates: function assets(num = 10) {
    const assets = skeletonWorkOrder.assetRealEstate();
    return { ...pagination, items: Array(num).fill(assets) };
  },
  budgetItem: function budgetItem() {
    return skeletonWorkOrder.budgetItem();
  },
  budgetItems: function budgetItems(num = 10) {
    const budgetItems = skeletonWorkOrder.budgetItem();
    return { ...pagination, items: Array(num).fill(budgetItems) };
  },
  document: function document() {
    return skeletonWorkOrder.document();
  },
  folder: function folder() {
    return skeletonWorkOrder.folder();
  },
  documents: function documents(num = 10) {
    const documents = skeletonWorkOrder.document();
    return { ...pagination, items: Array(num).fill(documents) };
  },
  workOrderRelated: function workOrderRelated() {
    return skeletonWorkOrder.workOrderRelated();
  },
  workOrdersRelated: function workOrdersRelated(num = 10) {
    const workOrderRelated = skeletonWorkOrder.workOrderRelated();
    return { ...pagination, items: Array(num).fill(workOrderRelated) };
  },
  historyItem: function historyItem() {
    return skeletonWorkOrder.historyItem();
  },
  historyItems: function historyItems(num = 10) {
    const historyItems = skeletonWorkOrder.historyItem();
    return { ...pagination, items: Array(num).fill(historyItems) };
  },
  itemRates: function itemRates(num = 10) {
    const itemRate = skeletonItemRate.itemRate();
    return { ...pagination, items: Array(num).fill(itemRate) };
  },
  itemRate: function itemRate() {
    return skeletonItemRate.itemRate();
  },
  supplierItemRates: function supplierItemRates(num = 10) {
    const supplierItemRate = skeletonSupplierItemRate.supplierItemRate();
    return { ...pagination, items: Array(num).fill(supplierItemRate) };
  },
  supplierItemRate: function supplierItemRate() {
    return skeletonSupplierItemRate.supplierItemRate();
  },
  users: function users(num = 10) {
    const user = skeletonUser();
    return { ...pagination, items: Array(num).fill(user) };
  },
  user: function user() {
    return skeletonUser();
  },
  roles: function roles(num = 10) {
    const role = skeletonRole();
    return { ...pagination, items: Array(num).fill(role) };
  },
  role: function role() {
    return skeletonRole();
  },
  documentTypes: function documentTypes(num = 10) {
    const documentType = skeletonDocumentType();
    return { ...pagination, items: Array(num).fill(documentType) };
  },
  documentType: function documentType() {
    return skeletonDocumentType();
  },
  customers: function customers(num = 10) {
    const customer = skeletonCustomer();
    return { ...pagination, items: Array(num).fill(customer) };
  },
  customer: function customer() {
    return skeletonCustomer();
  },
  customerGroups: function customerGroups(num = 10) {
    const group = skeletonCustomerGroup();
    return { ...pagination, items: Array(num).fill(group) };
  },
  customerGroup: function customerGroup() {
    return skeletonCustomerGroup();
  },
  projects: function projects(num = 10) {
    const project = skeletonProject();
    return { ...pagination, items: Array(num).fill(project) };
  },
  project: function project() {
    return skeletonProject();
  },
  suppliers: function suppliers(num = 10) {
    const supplier = skeletonSupplier();
    return { ...pagination, items: Array(num).fill(supplier) };
  },
  supplier: function supplier() {
    return skeletonSupplier();
  },
  businessLines: function businessLines(num = 10) {
    const businessLine = skeletonBusinessLine();
    return { ...pagination, items: Array(num).fill(businessLine) };
  },
  businessLine: function businessLine() {
    return skeletonBusinessLine();
  },
  itemMeasure: function itemMeasure() {
    return skeletonItem.measure();
  },
  itemMeasures: function itemMeasures(num = 10) {
    const measures = skeletonItem.measure();
    return Array(num).fill(measures);
  },
  notification: function notification() {
    return skeletonNotification();
  },
  notifications: function notifications(num = 10) {
    const notification = skeletonNotification();
    return { ...pagination, items: Array(num).fill(notification) };
  },
  supplierDocumentTypes: function supplierDocumentTypes(num = 10) {
    const supplierDocumentType = skeletonSupplierDocumentType();
    return { ...pagination, items: Array(num).fill(supplierDocumentType) };
  },
  supplierDocumentType: function supplierDocumentType() {
    return skeletonSupplierDocumentType();
  },
  receivedInvoices: function receivedInvoice(num = 10) {
    const receivedInvoice = skeletonReceivedInvoice();
    return { ...pagination, items: Array(num).fill(receivedInvoice) };
  },
  receivedInvoice: function receivedInvoice() {
    return skeletonReceivedInvoice();
  },
  receivedInvoiceLines: function receivedInvoiceLines(num = 10) {
    const receivedInvoiceLine = skeletonReceivedInvoiceLine();
    return { ...pagination, items: Array(num).fill(receivedInvoiceLine) };
  },
  receivedInvoiceLine: function receivedInvoiceLine() {
    return skeletonReceivedInvoiceLine();
  },
  receivedInvoiceDocument: function receivedInvoiceDocument() {
    return skeletonReceivedInvoiceDocument();
  },
  receivedInvoiceDocuments: function receivedInvoiceDocuments(num = 10) {
    const receivedInvoiceDocument = skeletonReceivedInvoiceDocument();
    return { ...pagination, items: Array(num).fill(receivedInvoiceDocument) };
  },
  enumList: function enumList(num = 10) {
    const enumItem = skeletonEnum();
    return Array(num).fill(enumItem);
  },
  enumItem: function enumItem() {
    return skeletonEnum();
  },
  issuedInvoices: function issuedInvoice(num = 10) {
    const issuedInvoice = skeletonIssuedInvoice();
    return { ...pagination, items: Array(num).fill(issuedInvoice) };
  },
  issuedInvoice: function issuedInvoice() {
    return skeletonIssuedInvoice();
  },
  issuedInvoiceDocument: function issuedInvoiceDocument() {
    return skeletonIssuedInvoiceDocument();
  },
  issuedInvoiceDocuments: function issuedInvoiceDocuments(num = 10) {
    const issuedInvoiceDocument = skeletonIssuedInvoiceDocument();
    return { ...pagination, items: Array(num).fill(issuedInvoiceDocument) };
  },
  calendars: function calendars(num = 10) {
    const calendar = skeletonCalendar();
    return { ...pagination, items: Array(num).fill(calendar) };
  },
  calendar: function calendar() {
    return skeletonCalendar();
  },
  equivalences: function equivalences(num = 10) {
    const equivalence = skeletonEquivalence();
    return { ...pagination, items: Array(num).fill(equivalence) };
  },
  equivalence: function equivalence() {
    return skeletonEquivalence();
  },
  supplierBudgetItem: function supplierBudgetItem() {
    return skeletonSupplierBudgetItem();
  },
  supplierBudgetItems: function supplierBudgetItems(num = 10) {
    const supplierBudgetItems = skeletonSupplierBudgetItem();
    return { ...pagination, items: Array(num).fill(supplierBudgetItems) };
  },
  customerBudgetItem: function customerBudgetItem() {
    return skeletonCustomerBudgetItem();
  },
  customerBudgetItems: function customerBudgetItems(num = 10) {
    const customerBudgetItems = skeletonCustomerBudgetItem();
    return { ...pagination, items: Array(num).fill(customerBudgetItems) };
  },
  workOrderCustomStatuses: function workOrderStatuses(num = 10) {
    const statuses = skeletonWorkOrder.status();
    return { ...pagination, items: Array(num).fill(statuses) };
  },
  companies: function companies(num = 10) {
    const company = skeletonCompany();
    return { ...pagination, items: Array(num).fill(company) };
  },
  company: function company() {
    return skeletonCompany();
  },
  processRoles: function processRoles(num = 10) {
    const processRole = skeletonProcessRole();
    return { ...pagination, items: Array(num).fill(processRole) };
  },
  processRole: function processRole() {
    return skeletonProcessRole();
  },
  workGroups: function workGroups(num = 10) {
    const workGroup = skeletonWorkGroup();
    return { ...pagination, items: Array(num).fill(workGroup) };
  },
  workGroup: function workGroup() {
    return skeletonWorkGroup();
  },
  supplierWorkers: function supplierWorkers(num = 10) {
    const supplierWorker = skeletonWorker();
    return { ...pagination, items: Array(num).fill(supplierWorker) };
  },
  supplierWorker: function supplierWorker() {
    return skeletonWorker();
  },
  supplierGarnishments: function supplierGarnishments(num = 10) {
    const supplierGarnishment = skeletonGarnishment();
    return { ...pagination, items: Array(num).fill(supplierGarnishment) };
  },
  supplierGarnishment: function supplierGarnishment() {
    return skeletonGarnishment();
  },
  supplierCreditInsurers: function supplierCreditInsurers(num = 10) {
    const supplierCreditInsurer = skeletonCreditInsurer();
    return { ...pagination, items: Array(num).fill(supplierCreditInsurer) };
  },
  supplierCreditInsurer: function supplierCreditInsurer() {
    return skeletonCreditInsurer();
  },
  supplierAgreements: function supplierAgreements(num = 10) {
    const supplierAgreement = skeletonAgreement();
    return { ...pagination, items: Array(num).fill(supplierAgreement) };
  },
  supplierAgreement: function supplierAgreement() {
    return skeletonAgreement();
  },
  documentGroups: function documentGroups(num = 10) {
    const documentGroup = skeletonDocumentGroup();
    return { ...pagination, items: Array(num).fill(documentGroup) };
  },
  documentGroup: function documentGroups() {
    return skeletonDocumentGroup();
  },
  realEstates: function realEstates(num = 10) {
    const realEstate = skeletonRealEstate();
    return { ...pagination, items: Array(num).fill(realEstate) };
  },
  realEstate: function realEstate() {
    return skeletonRealEstate();
  },
  realEstateDocument: function realEstateDocument() {
    return skeletonRealEstateDocument();
  },
  realEstateDocuments: function realEstateDocuments(num = 10) {
    const realEstateDocument = skeletonRealEstateDocument();
    return { ...pagination, items: Array(num).fill(realEstateDocument) };
  },
  paymentMethods: function paymentMethods(num = 10) {
    const paymentMethod = skeletonPaymentMethod();
    return { ...pagination, items: Array(num).fill(paymentMethod) };
  },
  paymentMethod: function paymentMethod() {
    return skeletonPaymentMethod();
  },
  supplierRatings: function supplierRatings(num = 5) {
    const rating = skeletonSupplierRating();
    return { ...pagination, items: Array(num).fill(rating) };
  },
  supplierRating: function supplierRating() {
    return skeletonSupplierRating();
  },
  alerts: function alerts(num = 10) {
    const alert = skeletonAlert.alert();
    return { ...pagination, items: Array(num).fill(alert) };
  },
  alert: function alert() {
    return skeletonAlert.alert();
  },
  alertType: function alertType() {
    return skeletonAlert.alertType();
  },
  alertTypes: function alertTypes(num = 10) {
    const alertTypes = skeletonAlert.alertType();
    return Array(num).fill(alertTypes);
  },
  supplierTypes: function supplierTypes(num = 10) {
    const supplierType = skeletonSupplierType();
    return { ...pagination, items: Array(num).fill(supplierType) };
  },
  supplierType: function supplierType() {
    return skeletonSupplierType();
  },
  insuranceCompanies: function insuranceCompanies(num = 10) {
    const insuranceCompany = skeletonInsuranceCompany();
    return { ...pagination, items: Array(num).fill(insuranceCompany) };
  },
  insuranceCompany: function insuranceCompany() {
    return skeletonInsuranceCompany();
  },
  integrations: function integrations(num = 10) {
    const integration = skeletonIntegration();
    return { ...pagination, items: Array(num).fill(integration) };
  },
  integration: function integration() {
    return skeletonIntegration();
  },
  integrationExecutions: function integrationExecutions(num = 10) {
    const integrationExecution = skeletonExecution();
    return { ...pagination, items: Array(num).fill(integrationExecution) };
  },
  integrationExecution: function integration() {
    return skeletonExecution();
  },
  creditors: function creditor(num = 10) {
    const creditor = skeletonCreditor();
    return { ...pagination, items: Array(num).fill(creditor) };
  },
  creditor: function creditor() {
    return skeletonCreditor();
  },
  operationType: function operationType() {
    return skeletonOperationType();
  },
  operationTypes: function operationTypes(num = 10) {
    const operationTypes = skeletonOperationType();
    return Array(num).fill(operationTypes);
  },
  appointmentTypes: function appointmentTypes(num = 10) {
    const appointmentTypes = skeletonAppointmentType();
    return { ...pagination, items: Array(num).fill(appointmentTypes) };
  },
  CEE: function CEE() {
    return skeletonCEE();
  },
  CEEs: function CEEs(num = 10) {
    const cees = skeletonCEE();
    return Array(num).fill(cees);
  },
  galleryTag: function galleryTags() {
    return skeletonGalleryTag();
  },
  galleryTags: function galleryTags(num = 10) {
    const galleryTag = skeletonGalleryTag();
    return { ...pagination, items: Array(num).fill(galleryTag) };
  },
  galleryImage: function galleryImage() {
    return skeletonWorkOrder.galleryImage();
  },
  galleryImages: function galleryImages(num = 10) {
    const galleryImages = skeletonWorkOrder.galleryImage();
    return { ...pagination, items: Array(num).fill(galleryImages) };
  },
  reminders: function reminders(num = 10) {
    const reminder = skeletonReminder();
    return { ...pagination, items: Array(num).fill(reminder) };
  },
  reports: function reports(num = 10) {
    const report = skeletonReport();
    return { ...pagination, items: Array(num).fill(report) };
  },
  workOrderAutomation: function workOrderAutomation(num = 10) {
    const woAutomation = skeletonWorkOrderAutomation();
    return { ...pagination, items: Array(num).fill(woAutomation) };
  },
  receivedInvoiceRetentionTypes: function receivedInvoiceRetentionTypes(num = 2) {
    const retentionTypes = skeletonReceivedInvoiceRetentionTypes();
    return Array(num).fill(retentionTypes);
  },
  budgetItemSuppliers: function budgetItemSuppliers(num = 10) {
    const biSupplier = skeletonBudgetItemSupplier();
    return { ...pagination, items: Array(num).fill(biSupplier) };
  },
  budgetItemSupplier: function budgetItemSupplier() {
    return skeletonBudgetItemSupplier();
  },
  workOrderBudgetItemHistory: function workOrderBudgetItemHistory() {
    return skeletonWorkOrderBudgetItemHistory();
  },
  aggrupations: function aggrupations(num = 10) {
    const aggrupations = skeletonAggrupations();
    return { ...pagination, items: Array(num).fill(aggrupations) };
  },
  directoryTemplates: function directoryTemplates(num = 10) {
    const directoryTemplate = skeletonDirectoryTemplate();
    return { ...pagination, items: Array(num).fill(directoryTemplate) };
  },
  directoryTemplate: function directoryTemplate() {
    return skeletonDirectoryTemplate();
  },
  workOrderBudgetItemBreakdowns: function workOrderBudgetItemBreakdowns(num = 10) {
    const breakdown = skeletonWorkOrderBudgetItemBreakdown();
    return { ...pagination, items: Array(num).fill(breakdown) };
  },
  workOrderBudgetItemBreakdown: function directoryTemplate() {
    return skeletonWorkOrderBudgetItemBreakdown();
  },
  workOrderContact: function workOrderContact() {
    return skeletonWorkOrderContact();
  }
};
