import { apiHelper } from 'shared';
import { http } from './http';
class Sources {
  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/sources/all${queryString}`);
  }

  getById(id) {
    return http.get(`/sources/${id}`);
  }

  async getUsingQueryFilter(searchString, visibility) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: searchString, visibility });

    const response = await http.get(`/sources${queryString}`);
    const items = response.data && response.data.items;

    return items;
  }

  async getByProjectUsingQueryFilter(projectId, searchString, visibility) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: searchString, visibility });

    const response = await http.get(`/projects/${projectId}/sources${queryString}`);
    const items = response.data && response.data.items;

    return items;
  }

  async getTermsByProject(projectId, sourceId) {
    const response = await http.get(`/projects/${projectId}/sources/${sourceId}/terms`);
    const items = response.data && response.data.items;

    return items;
  }

  create(source) {
    return http.post('/sources', source);
  }

  delete(id, multiple) {
    return http.delete(`/sources/${id}`, { multiple });
  }

  update(id, source) {
    return http.put(`/sources/${id}`, { ...source, forceUpdateManagerProcessRoleId: true });
  }

  activate(id) {
    return http.put(`/sources/${id}/activation`);
  }

  desactivate(id) {
    return http.delete(`/sources/${id}/activation`);
  }

  getSlaEntities() {
    return http.get('/sources/sla-entities');
  }

  getSlaDates() {
    return http.get('/sources/sla-dates');
  }

  clone(id) {
    return http.post(`/sources/${id}/clone`);
  }

  changeProcessDefinition(id, processDefinitionId) {
    return http.get(`/sources/${id}/process-definition/${processDefinitionId}`);
  }

  changeProject(id, projectId) {
    return http.get(`/sources/${id}/project/${projectId}`);
  }

  getActivityTypes() {
    return http.get('/sources/activity-types');
  }
}

export const sources = new Sources();
