import { Input } from '@engloba-tech/englobity';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToolbarContainer } from 'shared';
import { NumberFormatCustom } from 'shared/NumberFormatCustom';
import { objectHelper } from 'shared/helper';
import { useEditableTableStyles } from './editableTable.styles';

export function EditableTable({
  headCells,
  rows,
  inputs,
  tableChanges,
  handleChange,
  actions,
  isToggled,
  inputProps,
  getClassName,
  showTotal,
  numericInputs
}) {
  const classes = useEditableTableStyles();
  const { t } = useTranslation();
  return (
    <ToolbarContainer containerClassName={classes.toolBarMargin}>
      <TableContainer>
        <Table className={classes.table} stickyHeader>
          <TableHead>
            <TableRow>
              {headCells.map(column => (
                <TableCell key={column} className={classes.headCell}>
                  {column}
                </TableCell>
              ))}
              {showTotal && (
                <TableCell className={clsx(classes.headCell, classes.totalHeadCell)}>
                  {t('rehTaskForm:workProgress.fields.total')}
                </TableCell>
              )}
              <TableCell role={'checkbox'} />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => {
              const isEditing = isToggled(row);
              row = { ...row, isEditing };
              return (
                <TableRow key={row.id}>
                  {/* FIELDS */}
                  {headCells.map((column, i) => {
                    if (i === 0) {
                      return <TableCell key={row.id + '_' + column}>{row.name}</TableCell>;
                    }
                    if (!isEditing) {
                      return (
                        <TableCell key={row.id + '_' + column} className={getClassName && getClassName(i)}>
                          {numericInputs ? (
                            <NumberFormatCustom
                              className={clsx(getClassName && getClassName(i), classes.noStyle)}
                              suffix=""
                              value={inputs[row.id][i - 1] ?? ' - '}
                              disabled={true}
                            />
                          ) : (
                            inputs[row.id][i - 1] ?? ' - '
                          )}
                        </TableCell>
                      );
                    }

                    return (
                      <TableCell key={row.id + '_' + column + '(editing)'}>
                        <Input
                          name={row.id + '_' + column}
                          value={tableChanges[row.id][i - 1] ?? 0}
                          onChange={handleChange(row.id, i - 1)}
                          InputProps={inputProps}
                          disabled={row.isDisabled && row.isDisabled(i, row.id)}
                        />
                      </TableCell>
                    );
                  })}

                  {showTotal && (
                    <TableCell className={clsx(classes.totalCell, getClassName)}>
                      {numericInputs ? (
                        <NumberFormatCustom
                          className={clsx(classes.noStyle, classes.total)}
                          suffix=""
                          value={objectHelper.sumArray(objectHelper.replaceUndefinedPerNull(inputs[row.id]))}
                          disabled={true}
                          onChange={() => {}}
                        />
                      ) : (
                        objectHelper.sumArray(objectHelper.replaceUndefinedPerNull(inputs[row.id]))
                      )}
                    </TableCell>
                  )}
                  {/* ACTIONS */}
                  <TableCell>
                    {actions.map(action => {
                      if (action.component && action.condition && action.condition(row)) {
                        return (
                          <Tooltip key={action.title} title={action.title} placement="right">
                            {action.component(row)}
                          </Tooltip>
                        );
                      }
                      return <></>;
                    })}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </ToolbarContainer>
  );
}

EditableTable.propTypes = {
  headCells: PropTypes.array,
  rows: PropTypes.array,
  inputs: PropTypes.object,
  tableChanges: PropTypes.object,
  handleChange: PropTypes.func,
  isToggled: PropTypes.func,
  actions: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.node), PropTypes.node),
  inputProps: PropTypes.object,
  getClassName: PropTypes.func,
  maxWidth: PropTypes.string,
  showTotal: PropTypes.any,
  numericInputs: PropTypes.any
};
