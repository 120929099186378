import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Select } from '@engloba-tech/englobity';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { isSkeletonLoading, stringHelper } from 'shared';

export function ProcessVisibilitiesSelect({ processVisibilities, value, withAnyLabel, ...props }) {
  const { t } = useTranslation(NAME_SPACE.PROCESS_DEFINITION);

  const elements = useMemo(() => {
    return [
      ...(withAnyLabel ? [{ value: undefined, name: t('common:options.choose'), isAny: true }] : []),
      ...processVisibilities?.map(processVisibility => {
        return {
          value: processVisibility.id,
          name: t(`processVisibilities.${stringHelper.desCapitalizeFirstLetter(processVisibility.name)}`)
        };
      })
    ];
  }, [processVisibilities, t, withAnyLabel]);

  return (
    <Select
      isLoading={isSkeletonLoading(processVisibilities[0]) || isSkeletonLoading(value)}
      {...props}
      value={value}
      title={t('properties.processVisibility')}
      elements={elements}
    />
  );
}

ProcessVisibilitiesSelect.propTypes = {
  value: PropTypes.number,
  processVisibilities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  withAnyLabel: PropTypes.bool
};
