import { http } from './http';
import { apiHelper } from 'shared';
import { STATUS_TYPES, statusTypes, STATUSES_ENUM } from 'pages/shared';

class SupplierBudgetItems {
  getBillableUsingQueryFilterBydistrictIds(supplierId, districtIds, projectId, paging, sorting, filters) {
    let queryString = '';

    queryString = apiHelper.composeQueryString({
      projectId,
      supplierBillingStatus: STATUSES_ENUM[statusTypes[STATUS_TYPES.BUDGETITEM_SUPPLIERBILLINGSTATUS]].BILLABLE,
      ...paging,
      ...sorting,
      ...filters
    });

    if (districtIds.length) {
      queryString += '&districtIds=' + districtIds.join(';');
    }

    return http.get(`/suppliers/${supplierId}/budget-items${queryString}`);
  }

  async createSelfInvoices(projectId) {
    await http.post(`/suppliers/self-invoices/${projectId}`, null);
    return;
  }
}

export const supplierBudgetItems = new SupplierBudgetItems();
