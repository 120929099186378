import { http } from './http';
import { apiHelper } from 'shared';

class Calendars {
  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/calendars/${queryString}`, {});
  }

  async getUsingQueryFilter(queryFilter, isPublished) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter, isPublished });
    const response = await http.get(`/calendars${queryString}`, {});
    const items = response.data && response.data.items;

    return items;
  }

  getById(id) {
    return http.get(`/calendars/${id}`, {});
  }

  create(customer) {
    return http.post('/calendars', customer, {});
  }

  delete(id, multiple) {
    return http.delete(`/calendars/${id}`, { multiple });
  }

  update(id, customer) {
    return http.put(`/calendars/${id}`, customer, {});
  }
}

export const calendars = new Calendars();
