import { SKELETON_KEY, SKELETON_KEY_DATE, SKELETON_KEY_NUMBER } from './skeletonFactory';

export function skeletonCurrentMilestone() {
  return {
    id: SKELETON_KEY,
    expectedCompletionDate: SKELETON_KEY_DATE,
    milestone: SKELETON_KEY,
    term: SKELETON_KEY_NUMBER,
    workOrderReference: SKELETON_KEY,
    workOrderExternalReference: SKELETON_KEY,
    workOrderId: SKELETON_KEY,
    processRole: SKELETON_KEY,
    manager: SKELETON_KEY,
    elapsedDays: SKELETON_KEY
  };
}
