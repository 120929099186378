import React from 'react';
import { useTranslation } from 'react-i18next';

import { PATH } from 'app.routes.const';
import { NAME_SPACE } from 'i18n';
import { notifications as notificationsService } from 'services';
import {
  ButtonDelete,
  skeletonFactory,
  ToolbarContainer,
  useCheckedElements,
  useViewTableData,
  ViewTable
} from 'shared';

import { useNotificationsBuildTable } from './useNotificationsBuildTable';

const notificationsDummy = skeletonFactory.notifications();

export function NotificationsList() {
  const {
    data: notifications,
    get: getNotifications,
    deleteElements: deleteNotifications
  } = useViewTableData({
    service: notificationsService,
    paginatedSkeleton: notificationsDummy,
    mainKey: 'name'
  });
  const { t } = useTranslation(NAME_SPACE.NOTIFICATIONS);
  const { rows, headCells } = useNotificationsBuildTable(notifications, t, handleLink);

  const {
    checkedElements,
    getDeleteDescription,
    handleCheckedElements,
    onCheckElement,
    onCheckAllElements,
    isChecked,
    isAnyChecked,
    checkedElementsCount
  } = useCheckedElements({
    t,
    mainKey: 'name',
    action: deleteNotifications
  });

  function handleLink(sourceReference, sourceType) {
    if (sourceType === 'WorkOrder') {
      window.open(PATH.WORK_ORDERS.EDIT.replace(':id', sourceReference));
    } else if (sourceType === 'WorkOrder_Documents') {
      window.open(PATH.WORK_ORDERS.EDIT_WITH_TAB.DOCUMENTS.replace(':id', sourceReference));
    } else if (sourceType === 'Supplier') {
      window.open(PATH.SUPPLIERS.EDIT.replace(':id', sourceReference));
    } else if (sourceType === 'Issued_Invoice_Documents') {
      window.open(PATH.CUSTOMERS.ISSUED_INVOICES.EDIT_WITH_TAB.DOCUMENTS.replace(':id', sourceReference));
    }
  }

  return (
    <ToolbarContainer
      actions={
        <ButtonDelete
          elementsToDelete={checkedElements}
          confirmDescription={getDeleteDescription}
          onConfirm={handleCheckedElements}
        />
      }
    >
      <ViewTable
        rows={rows}
        cells={headCells}
        defaultOrderBy={{ property: 'creationDate', direction: 'desc' }}
        onFetchData={getNotifications}
        totalRows={notifications.total}
        onCheckElement={onCheckElement}
        onCheckAllElements={onCheckAllElements}
        checkedElementsCount={checkedElementsCount}
        isAnyChecked={isAnyChecked}
        isChecked={isChecked}
        serverSidePaging
        allowRowFilter={true}
      />
    </ToolbarContainer>
  );
}
