import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Accordion,
  Divider,
  List,
  ListItem,
  ListItemText,
  IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Draggable from 'react-draggable';
import { useDragableModalStyles } from './dragableModal.styles';

export function DragableModal({ open, onClose, title, description, elements, ...props }) {
  const classes = useDragableModalStyles();

  /* https://github.com/react-grid-layout/react-draggable */

  return (
    <>
      {open && (
        <Draggable {...props} cancel={'.MuiCollapse-container'} defaultClassName={classes.root} bounds="body">
          <Card variant="outlined">
            <CardContent>
              <Typography className={classes.title} variant="h6" component="h2">
                {title}
                <IconButton className={classes.close} onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Typography>
              <Divider />
              <Typography className={classes.description} color="textSecondary">
                {description}
              </Typography>
              {elements.map((el, index) => (
                <Accordion key={index}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography className={classes.heading}>{el.title}</Typography>
                    <Typography className={classes.description} color="textSecondary">
                      {el.description}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List className={classes.selectableText}>
                      {el.childs.map((c, i) => (
                        <>
                          <ListItem alignItems="flex-start" className={classes.withAccordion}>
                            <Accordion key={i} disableGutters={true}>
                              <AccordionSummary
                                expandIcon={c.childs && <ExpandMoreIcon />}
                                aria-controls="panelchild-content"
                                disabled={!c.childs}
                                id="panelchild-content"
                              >
                                <ListItemText primary={c.title} secondary={c.description} />
                              </AccordionSummary>
                              {c.childs && (
                                <AccordionDetails>
                                  <List component="div" disablePadding>
                                    {c.childs.map((v, i) => (
                                      <ListItem className={classes.nested} alignItems="flex-start" key={i}>
                                        <ListItemText primary={v.title} secondary={v.description} />{' '}
                                      </ListItem>
                                    ))}
                                  </List>
                                </AccordionDetails>
                              )}
                            </Accordion>
                          </ListItem>
                        </>
                      ))}
                    </List>
                  </AccordionDetails>
                </Accordion>
              ))}
            </CardContent>
          </Card>
        </Draggable>
      )}
    </>
  );
}

DragableModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  elements: PropTypes.arrayOf(PropTypes.object).isRequired
};
