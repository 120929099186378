import { InfoSummary } from '@engloba-tech/englobity';
import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { CarouselLine } from './CarouselLine';
import { useCarouselWidgetStyles } from './carouselWidget.styles';
import { useCarouselWidget } from './useCarouselWidget';

export const CarouselWidget = ({ service, sorting, mainKey = 'name', secondaryKey, onClickLine }) => {
  const { items, total, paging, setPaging, loading } = useCarouselWidget({ service, sorting });

  const classes = useCarouselWidgetStyles();
  const { t } = useTranslation();

  const onChange = index => {
    setPaging(prev => ({ ...prev, pageIndex: index }));
  };

  useEffect(() => {
    setPaging(prev => ({ ...prev, pageIndex: 0 }));
  }, []);

  return (
    <Carousel
      showThumbs={false}
      showStatus={false}
      showIndicators={true}
      infiniteLoop={false}
      autoPlay={false}
      stopOnHover={true}
      className={classes.carousel}
      onChange={onChange}
    >
      {loading ? (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      ) : total?.totalItems ? (
        Array.from({ length: total.totalPages }).map((_, pageIndex) => (
          <div key={pageIndex} className={classes.container}>
            {pageIndex === paging.pageIndex &&
              items.map((item, index) => (
                <CarouselLine key={item.id} onClickLine={onClickLine} item={item} index={index}>
                  <p> {item[mainKey]}</p>
                  <span>{item[secondaryKey]}</span>
                </CarouselLine>
              ))}
          </div>
        ))
      ) : (
        <InfoSummary className={classes.info} text={t('common:empty')} />
      )}
    </Carousel>
  );
};

CarouselWidget.propTypes = {
  service: PropTypes.func,
  sorting: PropTypes.shape({}),
  mainKey: PropTypes.string,
  secondaryKey: PropTypes.string,
  onClickLine: PropTypes.func
};
