import React, { useCallback } from 'react';
import { useSignalR } from './useSignalR';

export const SignalRCMMSContext = React.createContext({});

const groupDeck = {};

export const useSignalRCMMSContext = urlConnection => {
  const { connection, connected, disconnect, connect, setToken } = useSignalR(urlConnection);

  const joinGroup = useCallback(
    groupName => {
      if (connected) {
        if (!groupDeck[groupName]) {
          groupDeck[groupName] = true;
          connection.invoke('JoinGroup', groupName).catch(console.error);
        }
      }
    },
    [connection, connected]
  );

  const disjoinGroup = useCallback(
    groupName => {
      if (connected) {
        groupDeck[groupName] = false;
        connection.invoke('DisjoinGroup', groupName).catch(console.error);
      }
    },
    [connection, connected]
  );

  return { connection, connected, disconnect, connect, setToken, joinGroup, disjoinGroup };
};
