import { http } from './http';
import { apiHelper } from 'shared';

class WorkOrderDocumentTypes {
  async getUsingQueryFilter(queryFilter, projectId) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter, projectId });

    const response = await http.get(`/work-order-document-types${queryString}`);
    const items = response.data && response.data.items;

    return items;
  }

  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/work-order-document-types/${queryString}`);
  }

  getById(id) {
    return http.get(`/work-order-document-types/${id}`);
  }
}

export const workOrderDocumentTypes = new WorkOrderDocumentTypes();
