import FileSaver from 'file-saver';
import { apiHelper } from 'shared';
import { http } from './http';

const controllerName = 'work-orders';

class WorkOrders {
  _selectedfilters = [];

  get(paging, sorting, filters, advancedFilters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters, ...advancedFilters });
    return http.get(`/${controllerName}/${queryString}`);
  }

  async export(sorting, filters, advancedFilters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...sorting, ...filters, ...advancedFilters });
    const response = await http.get(`/${controllerName}/export/${queryString}`);
    if (response?.data?.items) {
      response?.data?.items.forEach(item => {
        item.managers = item.managers
          ?.filter(manager => manager.manager !== null)
          .map(manager => `${manager.processRole}: ${manager.manager}`)
          .join(', ');
      });
    }

    return response;
  }

  getById(id) {
    return http.get(`/${controllerName}/${id}`);
  }

  create(workOrder) {
    return http.post(`/${controllerName}`, { ...workOrder, baseUrl: window.location.href });
  }

  createWithSource(workOrder) {
    return http.post(`/${controllerName}/from-source-work-order`, { ...workOrder, baseUrl: window.location.href });
  }

  delete(id, multiple) {
    return http.delete(`/${controllerName}/${id}`, { multiple });
  }

  update(id, workOrder) {
    return http.put(`/${controllerName}/${id}`, {
      ...workOrder,
      forceUpdateFinishDate: true,
      forceUpdateCustomer: true,
      forceUpdateCustomerGroup: true
    });
  }

  getDocumentById(workOrderId, id) {
    return http.get(`/${controllerName}/${workOrderId}/documents/${id}`);
  }

  createDocument(workOrderId, document) {
    return http.post(`/${controllerName}/${workOrderId}/documents`, document);
  }

  updateDocument(workOrderId, id, document) {
    return http.put(`/${controllerName}/${workOrderId}/documents/${id}`, {
      ...document,
      forceUpdateExpectedDate: true,
      forceUpdateSentDate: true,
      forceUpdateAssetId: true,
      forceUpdateSupplierId: true
    });
  }

  deleteDocument(workOrderId, id, multiple) {
    return http.delete(`/${controllerName}/${workOrderId}/documents/${id}`, { multiple });
  }

  getContact(workOrderId, paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/${controllerName}/${workOrderId}/contacts/${queryString}`);
  }

  getContactById(workOrderId, id) {
    return http.get(`/${controllerName}/${workOrderId}/contacts/${id}`);
  }

  createOrUpdateContact(workOrderId, contact) {
    return contact.id
      ? http.put(`/${controllerName}/${workOrderId}/contacts/${contact.id}`, contact)
      : http.post(`/${controllerName}/${workOrderId}/contacts`, contact);
  }

  addWorkOrderFollower(id, userId) {
    return http.post(`/${controllerName}/${id}/followers/${userId}`, null);
  }

  removeWorkOrderFollower(id, userId) {
    return http.delete(`/${controllerName}/${id}/followers/${userId}`);
  }

  import(blobUrl) {
    const obj = { blobUrl: blobUrl };
    return http.post(`/${controllerName}/import`, obj);
  }

  async downloadImportTemplate() {
    const headersT = await http.getBearerToken();
    const api_url = localStorage.getItem('API_URL');
    const options = {
      headers: headersT.headers,
      method: 'GET',
      responseType: 'blob'
    };

    const url = `${api_url}/api/v1.0/${controllerName}/import/template`;
    const response = await fetch(url, options);

    if (response && response.status === 200) {
      const miBlob = await response.blob().catch(e => {
        throw e;
      });
      FileSaver.saveAs(miBlob, 'import_work_orders_template.xlsx');
    }
  }

  async printBudget(id) {
    return http.post(`/${controllerName}/${id}/budget`, null);
  }

  async printOrder(workOrderId, supplierId) {
    return http.post(`/${controllerName}/${workOrderId}/order`, supplierId);
  }

  preserveFilters(filters) {
    this._selectedfilters = filters;
  }

  getStoredAdvancedFilters() {
    return this._selectedfilters;
  }

  async getSuppliers(workOrderId, queryFilter) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ name: queryFilter });

    const response = await http.get(`/${controllerName}/${workOrderId}/suppliers${queryString}`);
    const items = response.data && response.data.items;
    return items;
  }

  async getAppointmentsFormData(workOrderId) {
    const response = await http.get(`/${controllerName}/${workOrderId}/appointments/form`);
    return response.data;
  }

  async getWorkOrderProcessRoles(workOrderId) {
    const response = await http.get(`/${controllerName}/${workOrderId}/process-roles`);
    return response.data;
  }

  generateReport(workOrderId, id) {
    return http.post(`/${controllerName}/${workOrderId}/report/${id}`);
  }

  importBC3file(id, blobUrl) {
    const obj = { blobUrl: blobUrl };
    return http.post(`/${controllerName}/${id}/import-bc3`, obj);
  }

  requestBudget(id, requestBudget) {
    return http.post(`/${controllerName}/${id}/budget-request`, requestBudget);
  }

  importBudget(id, importBudget) {
    return http.post(`/${controllerName}/${id}/import-budget`, importBudget);
  }

  downloadZipFolder(workOrderId, downloadZipFolder) {
    const config = {
      disableErrorToasters: true
    };
    return http.post(`/${controllerName}/${workOrderId}/download-zip-folder`, downloadZipFolder, config);
  }
}

export const workOrders = new WorkOrders();
